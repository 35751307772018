import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter2Part5 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>();

  return (
    <PagePattern partName="Multiplication des fronts : l’heure des choix">
      <>
        <Text>
          La multiplication des chantiers législatifs au sein de l’Union
          européenne entraîne une surchauffe. La Quadrature, avec ses petits
          moyens, n’est pas capable de suivre tous les dossiers avec efficacité.
          Cette saturation oblige l’association à faire des choix qui ont des
          conséquences sur son audience et sa popularité.
        </Text>
        <Text>
          Pourtant, plusieurs décisions européennes sont d’abord reçues comme de
          bonnes nouvelles, porteuses d’espoir. D’abord, en octobre&#160;2015,
          la Cour de justice de l’Union européenne (CJUE) invalide le
          «&#160;Safe Harbor&#160;», un accord passé en juillet&#160;2000 entre
          l’Union européenne et les États-Unis pour réglementer le transfert des
          données personnelles des Européens vers le territoire américain. Le
          droit des États-Unis est beaucoup moins protecteur que le droit
          européen (le RGPD notamment), et un jeune Autrichien de 28&#160;ans,
          Max Schrems, a invoqué cette incohérence pour attaquer l’exploitation
          par Facebook de ses données personnelles. À nos yeux, cette décision
          de la CJUE est évidemment une victoire. Dans un contexte où les géants
          du Web semblent imbattables, ces victoires rebattent les cartes en
          faveur des activistes, au moins ponctuellement. Difficile de ne pas
          espérer de nouveau.
        </Text>
        <Text>
          Autre motif de satisfaction&#160;: au printemps 2016, le BEREC, qui
          est le coordonnateur européen des autorités nationales de régulation
          des télécoms (l’ARCEP en France), présente ses lignes directrices sur
          la neutralité du Net au sein de l’Union européenne et les soumet à une
          consultation publique. L’organe de régulation reçoit en quelques
          semaines 480&#160;000&#160;commentaires, qui plébiscitent le respect
          de la neutralité du réseau. Les opérateurs de réseaux (téléphone et
          Internet), qui avaient mis en avant l’arrivée prochaine de la
          technologie 5G pour justifier l’abandon de la neutralité, en sont pour
          leurs frais. Les organisations de défense des droits dans l’espace
          numérique sont soulagées&#160;: les pires menaces ont été repoussées
          et les mauvaises pratiques de gestion du réseau sont de plus en plus
          rares.
        </Text>
        <Text>
          Mais voici qu’arrivent en même temps deux grands textes
          européens&#160;: la «&#160;directive Copyright&#160;» et le
          «&#160;règlement Terro&#160;». La Quadrature doit choisir dans lequel
          des deux chantiers jeter ses forces.
        </Text>
        <Text type="h3">
          L’Europe et&#160;le&#160;droit d’auteur&#160;: la&#160;directive
          Copyright
        </Text>
        <Text>
          Le 14&#160;septembre 2016, est présentée par la Commission européenne
          la directive sur le droit d’auteur dans le marché unique numérique,
          rapidement surnommée «&#160;directive Copyright&#160;», chargée de
          mettre à jour la législation autour du droit d’auteur dans un monde
          toujours plus connecté et numérisé.
        </Text>
        <Text>
          Cette mise à jour, nous l’appelons de nos vœux depuis longtemps, en
          espérant qu’elle prenne en compte les évolutions de notre rapport et
          de notre accès à la culture&#160;: en particulier, légaliser le
          partage non marchand et autoriser la pratique du remix, voire lui
          donner une légitimité en tant qu’acte créatif. En 2012, déjà, nous
          participions à l’échelle européenne à la bataille du copyright
          (souvenez-vous de l’opération Datalove&#160;!). Nous avions rédigé
          avec Philippe Aigrain, auteur de <i>Sharing</i>, un article intitulé
          «&#160;Éléments pour la réforme du droit d’auteur et des politiques
          culturelles liées&#160;», qui comprenait quatorze points essentiels
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/elements-pour-la-reforme-du-droit-dauteur-et-des-politiques-culturelles-liees"
                >
                  https://www.laquadrature.net/elements-pour-la-reforme-du-droit-dauteur-et-des-politiques-culturelles-liees
                </AppLink>
              )
            }
          >
            124
          </InfoClick>
          &#160;:
        </Text>
        <ol>
          <li>
            <Text>
              Reconnaître le partage non marchand des œuvres numériques entre
              individus par l’épuisement des droits.
            </Text>
          </li>
          <li>
            <Text>Reconnaître la légitimité de la référence.</Text>
          </li>
          <li>
            <Text>
              Mettre en œuvre des exceptions solides et obligatoires pour les
              pratiques éducatives et de recherche.
            </Text>
          </li>
          <li>
            <Text>
              Mettre à disposition sans frais les œuvres orphelines (sans ayant
              droit) par les bibliothèques et les archives et autoriser leurs
              usages larges.
            </Text>
          </li>
          <li>
            <Text>
              Instaurer la liberté des usages collectifs non marchands.
            </Text>
          </li>
          <li>
            <Text>
              Mettre en place de nouveaux financements mutualisés pour un
              financement large (réparti sur de nombreux contributeurs et
              projets) de la culture numérique (la «&#160;contribution
              créative&#160;»).
            </Text>
          </li>
          <li>
            <Text>
              Créer une législation imposant des termes équitables dans les
              contrats d’édition et de distribution.
            </Text>
          </li>
          <li>
            <Text>
              Promouvoir une politique préventive de concurrence pour prévenir
              les monopoles de distribution et leurs abus.
            </Text>
          </li>
          <li>
            <Text>Réformer la gestion collective.</Text>
          </li>
          <li>
            <Text>Maîtriser la pollution publicitaire.</Text>
          </li>
          <li>
            <Text>
              Fixer des normes effectives pour la neutralité du Net et
              l’ouverture des appareils.
            </Text>
          </li>
          <li>
            <Text>Rendre obligatoire l’enregistrement ou copyright 2.0.</Text>
          </li>
          <li>
            <Text>
              Réfléchir aux financements publics culturels et à une réforme
              fiscale.
            </Text>
          </li>
          <li>
            <Text>
              Créer un statut positif protégeant le domaine public et les
              communs volontaires.
            </Text>
          </li>
        </ol>
        <Text>
          En mai&#160;2014, dans le prolongement de cette montée en puissance
          des partisans du libre partage, Julia Reda, membre du Parti pirate
          allemand, est élue députée européenne. Probablement dans l’objectif de
          tester le sérieux du Parti pirate, le Parlement européen lui confie la
          préparation d’un rapport sur la mise en œuvre de la directive
          2001/29/CE concernant l’harmonisation du droit d’auteur en Europe.
          Elle rend son rapport en janvier&#160;2015 et celui-ci est salué par
          la Quadrature
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2015/01/20/reforme-du-droit-dauteur-le-parlement-europeen-doit-suivre-le-rapport-reda"
                >
                  https://www.laquadrature.net/2015/01/20/reforme-du-droit-dauteur-le-parlement-europeen-doit-suivre-le-rapport-reda
                </AppLink>
              )
            }
          >
            125
          </InfoClick>
          car, bien qu’il conserve le principe général du droit d’auteur (on
          aurait pu s’attendre à une position plus radicale de la part du Parti
          pirate, dont le fondateur a pu dire que le copyright était un monopole
          ou encore une religion intégriste), il reprend un certain nombre de
          nos propositions. Toutefois, la «&#160;pirate&#160;» a laissé de côté
          une part importante de notre vision&#160;: la légalisation du partage
          non-marchand des œuvres entre individus.
        </Text>
        <Text>
          Or s’il est bien une chose que la Quadrature a intégrée depuis
          longtemps, c’est qu’il n’y a aucun avantage à faire des propositions
          molles, voire consensuelles, au pouvoir. Avoir des exigences radicales
          permet de garantir un débat sur notre terrain. Si le point de départ
          est déjà un consensus, alors on est certain de perdre sur tous les
          tableaux. Le consensus ne doit être que le résultat obtenu{" "}
          <i>après</i> une lutte farouche pour défendre nos positions. Voire,
          pour une organisation comme la nôtre, il peut ne jamais être
          atteint&#160;: sans carrière politique, sans objectif électoral, ni
          même questions de popularité, la Quadrature n’a pas besoin d’être dans
          le consensus.
        </Text>
        <Text>
          Refuser de se battre sur la légalisation du partage, c’est accepter de
          débattre sur des sujets peut-être intéressants, malheureusement
          nécessaires, mais in fine accessoires. Depuis Hadopi, l’audience de la
          Quadrature est en partie construite autour de gens attachés au
          partage, en particulier via le <i>peer-to-peer</i>. Reculer sur ce
          point signifie en France reculer sur un texte qui aurait rendu Hadopi
          caduque. Avec le rapport Reda, finalement, c’est malheureusement un
          symbole qui disparaît.
        </Text>
        <Text>
          Mais en 2015, puisque le «&#160;gros morceau&#160;» est absent du
          rapport, les militants doivent se rabattre sur quelques luttes
          annexes. Parmi elles, l’obligation dite de «&#160;filtrage a
          priori&#160;» (les «<i>&#160;upload filters&#160;</i>»), qui consiste
          à obliger les plateformes à rendre impossible l’envoi (<i>upload</i>)
          d’œuvres protégées par le copyright, sur la base d’une liste d’œuvres
          établie par des filtres automatiques. C’est l’article&#160;13 du
          texte, qui focalise dès lors les débats.
        </Text>
        <Text>Il pose un nombre important de problèmes graves&#160;:</Text>
        <ul>
          <li>
            <Text>
              L’inversion de la charge de la preuve&#160;: au lieu d’exiger que
              l’ayant droit prouve l’utilisation illicite de son œuvre, c’est à
              chaque personne qui met en ligne un contenu de prouver, après
              suppression automatique, que son contenu ne violait pas les droits
              d’autrui.
            </Text>
          </li>
          <li>
            <Text>
              La rupture d’égalité devant la loi&#160;: alors que les ayants
              droit n’ont pas à intenter d’action judiciaire pour faire
              supprimer des contenus, les éditeurs dont les contenus ont été
              abusivement supprimés doivent, eux, supporter la charge d’une
              action judiciaire pour faire valoir leurs droits a posteriori.
            </Text>
          </li>
          <li>
            <Text>
              Le contrôle des outils de détection&#160;: qui contrôlera les
              programmes de filtrage, les «&#160;robocopyright&#160;», qui
              vérifiera leurs paramétrages&#160;? Ceux-ci seront-ils
              publics&#160;? Qui pourra certifier que ces programmes auront la
              finesse d’analyse nécessaire pour distinguer la reprise illicite
              d’une œuvre et son détournement en parodie&#160;? Qui pourra
              valider qu’il n’y aura pas d’interprétation abusive du droit
              d’auteur&#160;? Au vu du fonctionnement arbitraire et approximatif
              de ces robots sur des plateformes de vidéo, comme le Content ID de
              YouTube (qui a pourtant coûté une centaine de millions de dollars
              en développement), il est d’ores et déjà prouvé qu’ils commettent
              de nombreuses erreurs. Quant à la liste d’œuvres protégées, que
              contient-elle&#160;? Qui a la possibilité d’y ajouter des
              œuvres&#160;? Les œuvres peuvent-elles en être retirées&#160;?
              Sous quelle autorité&#160;?
            </Text>
          </li>
        </ul>
        <Text>
          Un autre article, l’article&#160;11, est lui aussi repéré comme
          dangereux. Il prévoit la création d’un «&#160;droit voisin&#160;» pour
          les éditeurs de presse&#160;: de nombreux acteurs du Web, dont Google,
          agrègent des articles de presse et profitent de ces
          «&#160;contenus&#160;» pour attirer des visiteurs sans jamais reverser
          de revenus aux journaux (bien que cette agrégation soit aussi cause de
          visites supplémentaires sur les sites de presse). Ce qui semble bénin,
          comme souvent, ne l’est pas&#160;: en souhaitant instaurer de nouveaux
          droits pour les éditeurs de presse et de nouvelles contraintes pour
          les grands agrégateurs comme Google, le texte restreint l’usage de ces
          contenus bien au-delà, en affectant aussi potentiellement des acteurs
          non lucratifs et tout l’écosystème de l’accès à l’information.
        </Text>
        <Text>
          Ces deux articles entraînent une mobilisation massive de la société
          civile, qui répond en miroir à l’énorme campagne de lobbying des
          plateformes auprès des eurodéputés, décrite par certains anciens des
          couloirs de Bruxelles comme la plus intense et onéreuse jamais vue. La
          directive Copyright est dans tous les journaux qui, pensant
          nécessairement bénéficier de l’article&#160;11, se positionnent
          généralement en sa faveur. La situation est complexe et rien n’est
          gagné.
        </Text>
        <Text>
          Cette période est aussi celle où certains youtubeurs commencent à plus
          largement s’apercevoir que cette lutte contre la contrefaçon va les
          attaquer de plein fouet. Le filtrage automatique qui arrive par la
          directive Copyright va en effet empêcher par défaut toute
          réutilisation d’œuvres dans leurs vidéos, même pour quelques secondes,
          alors que la loi les y autoriserait. Le pouvoir des ayants droit sur
          la diffusion des œuvres via les grandes plateformes grandit encore. Le
          fait que la directrice générale de YouTube, Susan Wojcicki, prenne la
          plume pour prévenir ses ouailles, dans un magnifique moment de
          communion, que l’article&#160;13 «&#160;pose un danger autant à votre
          gagne-pain qu’à votre capacité à partager votre voix avec le
          monde&#160;» n’y est peut-être pas étranger.
        </Text>
        <Text>
          Le pouvoir du copyright est tellement important que les effets de bord
          sont déjà quasi quotidiens. Par exemple&#160;? L’éditeur du jeu vidéo
          Fortnite se retrouve obligé de développer une fonctionnalité
          supprimant certains titres musicaux lors de la diffusion des parties
          en direct, les droits de ces titres étant couverts dans le jeu mais
          pas sur YouTube. Ou encore la suppression abusive de la vidéo d’un
          professeur à la suite d’une plainte de TF1, car elle contenait un
          extrait d’une série dans laquelle il souhaitait montrer une erreur à
          ses élèves.
        </Text>
        <Text type="h3">Une&#160;position controversée</Text>
        <Text>
          Même si tout ce qui pouvait être dit a été dit sur la directive
          Copyright, les dernières évolutions de l’article&#160;13 sur le
          filtrage automatique des plateformes nous inquiètent. Des critères sur
          les plateformes concernées ont été ajoutés&#160;: la lucrativité, la
          taille, ainsi que la présence d’une recommandation algorithmique…
          Voilà qui réduit par la même occasion la liste des cibles aux seuls
          acteurs de la taille des GAFAM. Et c’est là que commence la discussion
          interne&#160;: finalement, est-ce bien le rôle de la Quadrature
          d’aller défendre YouTube et consorts&#160;? Le soutien à la diffusion
          de contenus sur ces grandes plateformes est de plus en plus intenable,
          ou du moins incohérent, tout comme aller militer contre un texte pour
          l’«&#160;internet libre et ouvert&#160;» main dans la main avec
          Facebook et Google.
        </Text>
        <Text>
          Parallèlement, alors que la bataille du copyright n’est pas encore
          terminée, le terrorisme et la surveillance de masse sont sur toutes
          les lèvres. Et des lois toujours plus sécuritaires débordent des
          tiroirs. La directive Copyright occupe une si grande place dans
          l’esprit des communautés activistes que personne, parmi nos alliés, ne
          fait le choix de se lancer pour faire campagne contre les risques du
          règlement Terroriste. Et sans aller jusqu’à prétendre que les risques
          posés par la directive Copyright semblent légers, disons que la
          brutalité des mesures du règlement Terro le font monter
          comparativement plus haut dans l’échelle de nos priorités.
        </Text>
        <Text>
          La Quadrature, ça n’est pas une armée d’analystes, d’avocats, de
          juristes, d’administrateurs systèmes, de chercheurs et de chargés de
          campagne. C’est, au mieux, trois ou quatre de chaque, dont seulement
          six salariés à plein temps. Attaquer la directive Copyright et le
          règlement Terro en même temps est matériellement impossible. Et, dans
          un contexte où la levée de boucliers contre la directive Copyright est
          déjà massive et bien organisée au niveau européen
          (heureusement&#160;!), nous décidons de concentrer nos efforts sur le
          règlement Terroriste, auquel personne ne s’attaque frontalement.
          Résultat immédiat, la campagne contre l’article&#160;13 de la
          directive Copyright passe de facto en «&#160;priorité basse&#160;».
          Cette décision nous sera durement reprochée par certains alliés, qui
          considèrent que nous abandonnons le navire au moment le plus crucial.
          Avec la distance vient le bénéfice du recul, et nous aurions peut-être
          pu et dû mieux expliquer notre raisonnement sur ce choix stratégique,
          mais après le vote qui entérine l’article&#160;13 de la directive
          Copyright le 12&#160;septembre 2018, la communauté est sous le choc.
          Les membres et l’équipe salariée se retrouvent mis en cause par celles
          et ceux qu’ils estiment pourtant être des alliés historiques. Pour
          preuve, Jérémie Zimmermann, éloigné depuis plusieurs années de
          l’association, demande à faire publier un texte dans lequel il
          démissionne officiellement de son statut de membre de la Quadrature
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2018/10/01/les-quadratures-de-nos-vies"
                >
                  https://www.laquadrature.net/2018/10/01/les-quadratures-de-nos-vies
                </AppLink>
              )
            }
          >
            126
          </InfoClick>
          . Cet épisode restera un point de rupture de l’histoire de
          l’association pour de nombreuses personnes. Au cœur de ces débats,
          quelqu’un nous confirmera malgré tout que notre action contre le
          règlement Terroriste est cruciale&#160;: il s’agit de Julia Reda.
        </Text>
        <Text type="h3">
          Règlement européen contre la&#160;diffusion du&#160;terrorisme
          en&#160;ligne
        </Text>
        <Text>
          Revenons un instant en 2014&#160;: un dîner privé réunit les
          représentants de Google, Facebook, Microsoft, Twitter et des
          gouvernements européens. Le thème&#160;: comment s’attaquer à
          l’extrémisme et à la radicalisation en ligne et créer une meilleure
          coopération entre l’Union européenne et&#160;leurs sites à très fort
          trafic&#160;? Le cœur de l’approche&#160;: la coopération volontaire.
        </Text>
        <Text>
          Cette rencontre marque un changement de stratégie. Se rendant compte
          de la difficulté, tant humaine que technique, de censurer, surveiller
          et finalement contrôler Internet, les États tentent une nouvelle
          approche&#160;: travailler <i>avec</i> les géants de la tech. Les
          traiter en copains, ou en exécutants, plutôt qu’en adversaires. Après
          tout, quand, en France, 43&#160;% des gens utilisent Facebook
          quotidiennement (en décembre&#160;2013), le plus simple pour suivre ce
          qu’ils y font est de passer un accord avec Facebook.
        </Text>
        <Text>
          Les GAFAM, surtout les plus jeunes, sont en quête de reconnaissance.
          Les États courtisent de plus en plus les géants de la tech, en
          témoignent les idées de «&#160;techplomacie&#160;» et
          l’«&#160;ambassadeur auprès de Facebook&#160;», missionné par le
          Danemark. «&#160;D’abord ils vous ignorent, ensuite ils vous
          ridiculisent, ensuite ils vous combattent, puis vous gagnez&#160;»,
          disait Gandhi. Et si, plutôt, ils vous transformaient en
          collaborateur, dissolvant les deux dernières étapes en un partenariat
          public-privé aux contours flous&#160;? L’État, après avoir d’abord
          ignoré, puis regardé mi-circonspect, mi-amusé les sites Web devenir
          des services incontournables du quotidien, a observé avec inquiétude
          le réseau Internet mondial donner corps à de multiples droits (telle
          la liberté d’expression) jusqu’alors assez théoriques. Il voit
          désormais s’enrichir des entreprises privées à l’appétit insatiable.
          Jusque-là adversaire des champions de la liberté sur Internet que
          s’imaginent être Google ou Facebook, l’État décide habilement de faire
          disparaître cette hostilité contre-productive. Et les deux camps
          –&#160;public et privé&#160;– de s’allier&#160;: plutôt que de
          combattre, centralisons donc Internet ensemble, à notre bénéfice
          mutuel&#160;!
        </Text>
        <Text>
          Car à y réfléchir un instant, dans sa vision décentralisée originale,
          Internet est, du fait de sa conception, impossible à censurer et à
          contrôler. «&#160;Le Net interprète la censure comme un dégât et
          contourne celle-ci&#160;», explique dès 1993 un des fondateurs de
          l’Electronic Frontier Foundation (EFF), John Gilmore. En effet, si
          500&#160;millions de personnes utilisent 150&#160;000&#160;sites,
          forums, fournisseurs d’adresses e-mail et applications de chat
          différents pour discuter et échanger, comment contrôler ce
          désordre&#160;? Mais, si on peut écrire une loi pour obtenir ce qu’on
          veut de 5, 10 ou 100&#160;sites, et créer un goulot d’étranglement
          pour que les gens passent par ces sites, on peut commencer à aboutir à
          quelque chose. En&#160;tout cas, c’est plus réaliste que d’essayer de
          contrôler tout l’internet, qui refuse d’être une entité unique et
          s’obstine à être un ensemble de réseaux mouvants et d’utilisateurs
          associés.
        </Text>
        <Text>
          Du côté des GAFAM, un texte qui inscrit dans la loi leur manière de
          faire, leur capacité technique, comme seul horizon possible de la mise
          sous contrôle du désordre d’Internet, c’est un texte positif&#160;:
          quel nouvel entrant aurait les moyens de les rattraper, techniquement
          ou en matière d’investissements&#160;? Une loi qui dirait, en
          substance, «&#160;il faut contrôler les publications de public sur
          Internet par des systèmes automatisés complexes et coûteux, comme le
          font Google et Facebook&#160;» garantit à Google et Facebook d’être,
          si ce n’est les seuls à disposer de ces systèmes, du moins les uniques
          fournisseurs de ces systèmes pour tous les autres acteurs (ce qui
          revient presque au même). Le genre de barrière à l’entrée dont rêve
          tout monopoliste.
        </Text>
        <Text>
          Cette coopération volontaire étant engagée au niveau européen, on
          commence à voir fleurir différents éléments qui la formalisent de plus
          en plus. En juin&#160;2017, le Conseil européen explique qu’il attend
          des entreprises du secteur qu’elles «&#160;mettent au point de
          nouvelles technologies et de nouveaux outils en vue d’améliorer la
          détection automatique et la suppression des contenus qui incitent à la
          commission d’actes terroristes. Cela devrait être complété par les
          mesures législatives appropriées au niveau de l’Union européenne, si
          nécessaire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.consilium.europa.eu/fr/press/press-releases/2017/06/22/euco-security-defence/"
                >
                  https://www.consilium.europa.eu/fr/press/press-releases/2017/06/22/euco-security-defence/
                </AppLink>
              )
            }
          >
            127
          </InfoClick>
          .&#160;» En mars&#160;2018, c’est la Commission européenne qui adopte
          une série de «&#160;recommandations pour lutter efficacement contre le
          contenu illégal en ligne&#160;».
        </Text>
        <Text>
          C’est finalement en septembre&#160;2018 que nous voyons apparaître sur
          notre radar un nouveau texte qui porte en son sein de graves dangers
          pour nos libertés. C’est un règlement sur des mesures antiterroristes
          (évidemment), qui porte le doux nom de «&#160;règlement européen
          contre la diffusion du terrorisme en ligne&#160;» et qui, en tant que
          règlement, une fois voté, s’applique directement dans chaque État
          membre, sans passer par la case du législateur local (le Parlement, en
          France).
        </Text>
        <Text>
          Pourquoi nous alarmons-nous&#160;? Car ce nouveau règlement imposera
          deux obligations à tout acteur du Web (hébergeurs de blogs ou de
          vidéos, sites de presse, petits forums ou grands réseaux
          sociaux)&#160;:&#160;premièrement, bloquer en une heure n’importe quel
          contenu signalé comme «&#160;terroriste&#160;» par la police (sans
          qualification préalable d’un juge) et ce, 24&#160;heures sur 24 et
          7&#160;jours sur 7&#160;; et deuxièmement, devancer les demandes de la
          police en détectant lui-même les contenus illicites à l’aide d’outils
          de filtrage automatisés.
        </Text>
        <Text>
          L’obligation de blocage en une heure est délirante. Elle est intenable
          pour la majorité des acteurs du Net. Ou, plus spécifiquement, elle est
          étudiée pour n’être tenable que par une très petite minorité d’entre
          eux. Celle qui était a priori présente au dîner en 2014… C’est une
          manière d’obtenir le goulot d’étranglement qui cherche à concentrer le
          trafic par un nombre restreint d’acteurs. Et c’est la délégation de la
          censure aux géants du Web qui s’accomplit.
        </Text>
        <Text>
          Par ailleurs, le fait que la qualification de terroriste vienne de la
          seule police est également inacceptable. Nous défendons depuis des
          années la nécessité absolue dans tout cas de censure de la
          qualification par un juge, seul capable de mettre en balance le
          difficile équilibre entre les différentes libertés. Voir ce pouvoir
          confié à l’exécutif seul est aberrant.
        </Text>
        <Text>
          Quant au filtrage automatisé, en revoici un énième avatar, cette
          fois-ci au nom de la lutte contre le terrorisme. Sans surprise, il ne
          répond toujours pas aux sempiternels problèmes&#160;: aucune liste de
          «&#160;contenus terroristes&#160;» ne permet de bloquer une vidéo, un
          article ou une photo a priori… Pire, après avoir fièrement annoncé
          être capable de faire de la modération en direct, Facebook est
          violemment désavoué par la réalité&#160;: en mars&#160;2019, le
          suprémaciste blanc à l’origine du massacre dans deux mosquées de
          Christchurch en Nouvelle-Zélande diffuse en temps réel les images de
          ses crimes via le réseau social. La vidéo se répand et le flux est
          immédiatement repris et décuplé. Malgré ses annonces, Facebook échoue
          à modérer. De toute évidence, l’état de l’art technique des capacités
          de Facebook et des autres est largement en dessous de ce qu’ils ont pu
          vendre aux gouvernements. Mais peu importe, ne laissons pas la réalité
          contrarier un si beau partenariat.
        </Text>
        <Text>
          Tant que les GAFAM ne cherchent pas à empiéter sur les plates-bandes
          de l’État, en se plaçant par exemple en fournisseurs et garants de
          l’identité ou, pire, en proposant le lancement d’une monnaie (on se
          souvient de l’échec du libra de Facebook sur ce point), les États sont
          prêts à se laisser convaincre par le discours commercial. Finalement,
          tout le monde y gagne, à part la population&#160;: les GAFAM
          obtiennent plus de pouvoir et sont toujours plus incontournables,
          les&#160;États réduisent le nombre d’interlocuteurs.
        </Text>
        <Text>
          Au bout du compte, une chose apparaît en tout cas clairement&#160;: le
          rapprochement stratégique entre les entreprises privées et l’État
          s’opère sur (presque) tous les thèmes et si la centralisation qui en
          découle bénéficie aux deux parties, elle se fait toujours aux dépens
          de la population. Dans ce contexte, l’idée de l’interopérabilité des
          services, qui encourage la décentralisation du Web au détriment des
          grandes plateformes, redevient une idée radicale que la Quadrature
          pourra porter avec fierté. Nous y reviendrons.
        </Text>
        <Text>
          Après trente-deux mois de débat, le règlement Terroriste est adopté
          par le Parlement européen le 28&#160;avril&#160;2021. Entré en vigueur
          le 6&#160;juin 2021, il est applicable à partir du 7&#160;juin 2022.
          Les terroristes tremblent déjà.
        </Text>
        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter2Part5;
