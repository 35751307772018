import React from "react";

import Styles from "./Modal.module.scss";

import { ReactComponent as CloseIcon } from "../../assets/cross.svg";

interface ModalProps {
  children?: React.ReactNode;
  onClose?: () => void;
  open?: boolean;
}

export const Modal = ({ children, onClose, open }: ModalProps) => {
  return (
    <>
      {open && (
        <div className={Styles.ModalBackgroundScreen} onClick={onClose}>
          <div
            className={Styles.ModalContainer}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CloseIcon className={Styles.CloseIcon} onClick={onClose} />
            {children}
          </div>
        </div>
      )}
    </>
  );
};
