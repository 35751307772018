import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter1Part5 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Ces politiques qui veulent tout contrôler">
      <>
        <Text>
          Le vieux monde est grand et lourd, son inertie forte. Il est lent à
          réagir, comme ces navires géants qui continuent de naviguer droit
          devant sans réussir à virer de bord. Mais le pouvoir est fort, et
          quand il veut reprendre la main, c’est une main de fer qu’il brandit.
        </Text>
        <Text>
          Devant les nouveautés du numérique et les nouvelles formes
          d’organisation et d’expression de la contestation, les pouvoirs
          institutionnels, pourtant riches de leur légitimité et de leur
          savoir-faire de haute volée, passent d’abord pour des dinosaures, des
          machines dépassées, voire des idiots. Mais c’est seulement dans un
          premier temps.
        </Text>
        <Text>
          Alors jeunes geeks de la Quadrature (et d’ailleurs), nous avons cru
          que le pouvoir politique était un peu à la traîne, et qu’il suffirait
          de lui expliquer les choses (en riant au passage de sa ringardise)
          pour que tout rentre dans l’ordre après quelque temps de confusion. Ce
          qui apparaît finalement met fin à notre naïveté&#160;: le pouvoir ne
          cherche pas à comprendre. Il est avant tout fidèle à lui-même, et suit
          dans le domaine du numérique la logique qui est la sienne dans tous
          les autres domaines&#160;: imposer ses intérêts, avec les méthodes
          qu’il maîtrise.
        </Text>
        <Text>
          Bousculées, désarçonnées par une mobilisation inattendue et
          imprévisible, les institutions se tournent vers leurs interlocuteurs
          et leurs partenaires habituels. Parmi ceux-là, se trouvent bien sûr
          les entreprises privées, loyales et de bon conseil, et notamment les
          grands groupes internationaux.
        </Text>
        <Text>
          De nombreux travaux d’économie politique l’expliquent, soit en
          exhumant les textes fondateurs, soit en constatant la réalité&#160;:
          le rôle attribué par le néolibéralisme à l’État est celui de
          protecteur du libre marché, qu’il doit préserver et faciliter
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Voir Grégoire Chamayou, <i>La Société ingouvernable</i>,
                  Paris, La Fabrique, 2018 et Bruno Amable,{" "}
                  <i>La Résistible Ascension du néolibéralisme</i>, Paris, La
                  Découverte, 2021.
                </Text>
              )
            }
          >
            47
          </InfoClick>
          . Ainsi, les entreprises seront constamment aidées, défendues,
          soutenues, et leurs obligations considérées comme des entraves à
          lever. Il s’agit de démolir les barrières, d’araser les obstacles,
          d’élargir les voies de circulation, mais toujours en faveur des
          entreprises privées et des besoins du marché. Toutes les autres
          considérations sont dès lors secondaires, même dans des domaines
          d’ordre moral. Et pour une association qui défend des choses aussi
          immatérielles que des libertés ou des droits, c’est un obstacle
          récurrent. Au nom d’un «&#160;réalisme&#160;» à sens unique, les
          grandes idées sont souvent balayées par le sens des affaires.
        </Text>
        <Text>
          Cela ne veut pas dire que la lutte ne vaut pas la peine d’être
          menée&#160;: on n’est jamais à l’abri d’une victoire. Les activistes
          peuvent changer la perception des choses et infléchir leur cours
          cynique. L’histoire de la défense de la neutralité du Net en est un
          bon exemple. Elle montre aussi qu’aucune victoire n’est définitive. Et
          que la bataille reprend sans cesse.
        </Text>
        <Text type="h3">
          La&#160;neutralité du&#160;Net&#160;: bataille pour
          une&#160;définition
        </Text>
        <Text>
          Le fossé entre la logique de l’État et la réalité des pratiques du Net
          s’est révélé particulièrement flagrant en 2008, au moment de la
          bataille sémantique entre «&#160;piratage&#160;» et
          «&#160;partage&#160;». Dès 2009, la Quadrature doit mener une autre
          bataille de vocabulaire, mais il ne s’agit pas cette fois d’imposer un
          autre mot en face des mots du pouvoir&#160;: nous cherchons à imposer
          dans le débat public une notion positive que nos adversaires jugent
          mauvaise. Le motif de la querelle est d’ailleurs assez paradoxal,
          puisqu’il est question de définir «&#160;la neutralité du Net&#160;».
        </Text>
        <Text>
          De quoi parle-t-on&#160;? La neutralité est un principe fort de
          l’internet, dès son origine&#160;: le réseau transporte (ou du moins
          doit transporter) toutes les données de la même manière, quelles que
          soient leur nature, leur origine et leur destination. La neutralité du
          Net n’a donc rien à voir avec une quelconque neutralité
          d’opinion&#160;: on ne parle pas de ce que les gens racontent en
          ligne, ni des obligations des hébergeurs ou des auteurs des sites,
          mais bel et bien du réseau matériel lui-même, et du rôle des
          fournisseurs d’accès qui connectent les gens entre eux. Il s’agit
          d’une impartialité technique. Certaines personnes préfèrent d’ailleurs
          parler de «&#160;neutralité des réseaux&#160;».
        </Text>
        <Text>
          Le Net est un réseau, et même un «&#160;réseau de réseaux&#160;», par
          lequel circulent de nombreuses données, dont le Web –&#160;avec ses
          sites, ses pages, ses forums, ses services commerciaux et son célèbre
          «&#160;www&#160;»&#160;– n’est qu’une partie seulement. Le réseau
          Internet véhicule beaucoup d’autres échanges et d’autres données en
          dehors du Web proprement dit&#160;: un téléchargement de document, un
          transfert de fichiers par le protocole FTP, l’envoi d’un e-mail, le
          flux d’images d’une chaîne de télévision ou d’un service de vidéo à la
          demande, les échanges de données entre deux personnes qui jouent en
          ligne au même jeu vidéo, des données météo, des transactions
          bancaires, des données de navigation pour des voitures connectées,
          etc. Ces différents formats d’échanges suivent des protocoles
          techniques distincts, réunis dans ce qu’on appelle par simplification
          la «&#160;couche TCP/IP&#160;»&#160;: dans tous les cas, des câbles et
          des serveurs transportent des paquets de données, que le réseau doit
          considérer comme étant tous égaux.
        </Text>
        <Text>
          Pour les fournisseurs d’accès à Internet (FAI) privés, cette
          neutralité du réseau n’a pas de sens, ni d’un point de vue technique,
          ni d’un point de vue commercial. D’un point de vue technique, il est
          tout à fait préférable d’avoir une connaissance fine des flux de
          données pour mieux les réguler, par exemple pour que la vidéo ne
          monopolise pas le débit d’une ligne, afin d’assurer un service fluide
          et homogène. D’un point de vue commercial, c’est encore plus
          intéressant&#160;: si l’on peut appliquer des traitements différents à
          des trafics de nature différente, alors on peut aussi les facturer à
          la carte, et décliner une offre commerciale de façon très lucrative.
          Par exemple, on peut imaginer une gradation de forfaits&#160;: forfait
          basique pour pouvoir juste envoyer et recevoir des e-mails, forfait
          standard pour les e-mails et la navigation Web, forfait mélomane pour
          écouter de la musique en <i>streaming</i>, forfait cinéphile pour
          regarder de la vidéo à la demande, etc.
        </Text>
        <Text>
          Au lieu de cela, nos abonnements nous donnent tout simplement accès à
          Internet, et c’est très bien comme ça. Mais pour les opérateurs de
          réseaux, la neutralité est considérée comme une contrainte absurde et
          contre-productive&#160;: on a besoin d’intervenir pour maximiser le
          fonctionnement, on a besoin d’intervenir pour bloquer les attaques, on
          a besoin d’intervenir pour augmenter ses revenus, etc.
        </Text>
        <Text>
          Mais alors, d’où vient ce principe de neutralité, et comment s’est-il
          imposé&#160;? On peut déjà se rappeler que le réseau Internet est le
          fruit de la collaboration de militaires et d’universitaires. Ces deux
          corporations, pourtant très étrangères l’une à l’autre, partageaient
          dès le départ des attentes communes au sujet d’Internet&#160;: qu’il
          soit résistant, et qu’il permette un partage homogène de
          l’information. Pour les militaires, en raison des nécessités du
          commandement, la fluidité et la rapidité des échanges avec toutes les
          parties de l’armée étaient vitales. Pour les universitaires, l’enjeu
          était de diffuser le plus largement possible le savoir humain, né de
          la circulation des idées et constitué par le jeu permanent de la
          confrontation et de l’échange.
        </Text>
        <Text>
          Dès lors, l’homogénéité et la résistance du réseau ont marché ensemble
          de manière inséparable&#160;: si le réseau est bien conçu,
          c’est-à-dire décentralisé, alors chacun de ses points peut accéder à
          l’information, et à l’inverse rien d’essentiel au fonctionnement
          global n’est perdu si l’un de ses points est détruit. Pour les
          universitaires, cela assure la circulation des connaissances, et leur
          sauvegarde, dans le respect d’une vision égalitaire et généreuse de la
          société. Pour les militaires, c’est la garantie d’un commandement et
          d’un renseignement efficaces, et la certitude de pouvoir continuer les
          opérations même si des serveurs sont détruits&#160;: les données
          qu’ils contiennent existent ailleurs, et les routeurs trouveront le
          meilleur chemin disponible pour aller les chercher. L’homogénéité du
          réseau Internet est donc capitale pour sa solidité et son efficacité.
          Chaque dissymétrie comporte un risque de blocage, d’étranglement, de
          centralisation, donc de faiblesse structurelle. La neutralité puise
          sans doute une partie de sa légitimité dans cette logique de
          résistance et d’égalité fonctionnelle voulue par les créateurs du
          réseau.
        </Text>
        <Text>
          La neutralité du Net est d’ailleurs un sujet défendu par la Quadrature
          depuis sa fondation, comme en témoigne le nom de la coquille
          associative constituée en 2008 pour payer le premier salaire de
          l’association&#160;: le Fonds de défense de la neutralité du Net
          (FDN²)…
        </Text>
        <Text>
          Quels sont donc les enjeux de la neutralité du réseau pour une
          association de défense des droits et des libertés&#160;? Tout est déjà
          dans le premier article consacré exclusivement à la neutralité du Net
          et publié le 4&#160;septembre 2009&#160;: «&#160;Il est crucial de
          préserver la neutralité du Net&#160;!&#160;». Il lance un cri
          d’alerte&#160;: «&#160;Elle est aujourd’hui menacée par les opérateurs
          de réseaux qui voient des opportunités commerciales dans le contrôle
          des flux d’informations qu’ils véhiculent
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2009/09/04/il-est-crucial-de-preserver-la-neutralite-du-net/"
                >
                  https://www.laquadrature.net/2009/09/04/il-est-crucial-de-preserver-la-neutralite-du-net/
                </AppLink>
              )
            }
          >
            48
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          On pourrait être tenté de croire que la menace est purement
          rhétorique, une exagération de militants qui n’hésitent pas à grossir
          le trait pour inquiéter et attirer l’attention. Et pourtant, les
          opérateurs (FAI) ont mille et une idées pour gagner de l’argent en
          bidouillant leur réseau. Par exemple, ils inventent le{" "}
          <i>zero rating&#160;</i>: certaines données ne sont pas décomptées du
          forfait de l’utilisateur. Les FAI favorisent ainsi leurs propres
          services connectés, ou ceux avec lesquels il a passé les meilleurs
          accords commerciaux (telle messagerie instantanée, tel bouquet de
          chaînes de télévision, tel réseau social, etc.). En revanche, les
          données liées à l’utilisation d’autres services (même similaires) sont
          bien décomptées. Les utilisateurs qui veulent économiser leur petit
          forfait pour qu’il dure tout le mois sont donc bien orientés, par
          l’opérateur du réseau et par le fonctionnement même du réseau, dans
          leurs pratiques du Net&#160;: la neutralité est largement brisée.
          Aujourd’hui, en France, les forfaits Internet et téléphoniques sont
          souvent «&#160;illimités&#160;», c’est-à-dire plafonnés si haut qu’on
          ne se soucie guère du volume de données que l’on consomme. Mais le{" "}
          <i>zero rating</i> est encore pratiqué dans d’autres régions du monde.
        </Text>
        <Text>
          Autre astuce des opérateurs pour contourner l’obligation de
          neutralité&#160;: les «&#160;services spécialisés&#160;», ou
          «&#160;services gérés&#160;». Les exemples les plus simples sont le
          téléphone et la télévision numérique. Aujourd’hui, de très nombreux
          foyers téléphonent et reçoivent la télévision en passant par la box de
          leur fournisseur Internet. Prenons la télévision&#160;: ces flux
          d’images de plus en plus lourds (haute définition, 5K, etc.) sont
          priorisés par rapport aux autres usages, sur la ligne ADSL ou fibre de
          l’abonné. Si vous étiez en train de télécharger un film ou une série
          pour ce soir, et que vous allumez le journal télévisé de
          20&#160;heures, vous verrez votre débit de téléchargement chuter
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Pour une explication technique courte et claire, voir cet
                    article de Benjamin Bayart, « La diffusion de la télévision
                    linéaire comme service géré », 20&#160;mai 2016&#160;:
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2016/05/20/La-diffusion-de-la-television-lineaire-comme-service-gere/"
                  >
                    https://www.laquadrature.net/2016/05/20/La-diffusion-de-la-television-lineaire-comme-service-gere/
                  </AppLink>
                </>
              )
            }
          >
            49
          </InfoClick>
          . La contrainte technique est légitime&#160;: en privilégiant le flux
          télé, l’opérateur s’assure que le client verra une vidéo fluide et de
          bonne définition, ce qui est le service pour lequel l’opérateur est
          payé. En revanche, s’il profite de cette tolérance pour privilégier
          uniquement les chaînes de télévision avec lesquelles il a passé un
          accord commercial, alors l’exception technique à la neutralité du Net
          devient un abus de position dominante.
        </Text>
        <Text>
          Les «&#160;services gérés&#160;» sont un cas particulier des
          exceptions à la neutralité tolérées pour des raisons techniques, et
          réunies sous le terme de <i>Quality of Service</i> (<i>QoS</i>). Elles
          comportent par exemple des possibilités de réduire volontairement
          (mise en forme) ou de prioriser (ordonnancement) certains flux de
          données par rapport à d’autres, pour que l’ensemble du réseau reste
          fluide. Mais les fournisseurs d’accès associatifs, des opérateurs
          locaux souvent présents en zones rurales, et qui n’ont pas l’ambition
          commerciale des grands FAI nationaux, expliquent que ces exceptions ne
          sont en réalité pas des nécessités absolues, mais bel et bien des
          facilités offertes par le législateur à des entreprises qui usent et
          abusent de l’opacité technique de leur métier pour obtenir des
          passe-droits sous prétexte d’efficacité technique.
        </Text>
        <Text>
          Il n’est pas évident de défendre Internet en tant que bien commun et
          service universel, quand on a pour interlocuteurs des opérateurs
          commerciaux privés qui se livrent une concurrence de chaque instant.
          C’est pourquoi la Quadrature tient aussi, à côté de son discours
          humaniste et universaliste, un discours audible par les entreprises
          privées et par les gouvernements libéraux qui les représentent&#160;:
          celui de la libre concurrence.
        </Text>
        <Text>
          Déjà, dans la lutte contre la loi Hadopi et sa «&#160;riposte
          graduée&#160;», on avait eu recours à des arguments piochés dans le
          champ de sensibilité de l’adversaire. Tout comme, à l’automne 2009,
          les articles de la Quadrature à destination des ministres chargés de
          la conciliation du «&#160;paquet Télécom&#160;» étaient remplis de
          cette rhétorique des entreprises, de ce langage des décideurs. De
          fait, ne pas respecter la neutralité du Net revient à introduire dans
          le droit une distorsion de concurrence&#160;: «&#160;Si un fournisseur
          d’accès porte atteinte à la neutralité du Net, il peut très facilement
          favoriser ses services par rapport à ceux de ses concurrents
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2009/09/04/il-est-crucial-de-preserver-la-neutralite-du-net/"
                >
                  https://www.laquadrature.net/2009/09/04/il-est-crucial-de-preserver-la-neutralite-du-net/
                </AppLink>
              )
            }
          >
            50
          </InfoClick>
          .&#160;» En poursuivant le raisonnement, on peut même montrer que ce
          déséquilibre est une entrave à l’innovation technique.
        </Text>
        <Text>
          Bien sûr, ces arguments en faveur de la compétition ouverte et de la
          libre concurrence doivent être entendus en gardant en tête que nous,
          militants de la Quadrature, sommes des <i>libristes</i>, c’est-à-dire
          des partisans et des pratiquants de la culture du logiciel libre,
          disponible pour tous et modifiable par tous. Vouloir un Internet
          «&#160;libre, neutre et ouvert&#160;» où des groupes privés ne brident
          pas les possibilités et ne montent pas la garde à l’entrée, c’est
          aussi la meilleure garantie de donner ses chances à un écosystème
          partageur, humaniste et mondial.
        </Text>
        <Text>
          À côté de ces arguments destinés aux entrepreneurs, on trouve aussi
          des arguments politiques plus généraux qui sont devenus des classiques
          de la Quadrature&#160;: en l’espèce, l’enjeu consiste à faire
          reconnaître l’accès à Internet comme un droit pour chacun.
        </Text>
        <Text type="h3">Opération RespectMyNet</Text>
        <Text>
          On l’a déjà dit, militer pour la neutralité du Net, c’est défendre un
          principe généreux mais non lucratif, contre les intérêts commerciaux
          de la plupart des opérateurs techniques, qui ne lâcheront jamais
          l’affaire, vu la taille du gâteau. Il faut donc sans cesse réorganiser
          la défense et tenir bon.
        </Text>
        <Text>
          Après le texte européen mollasson de novembre&#160;2009, on aurait pu
          penser qu’une forme de compromis et de statu quo avait été trouvée.
          En&#160;France, l’ARCEP, l’autorité régulatrice des télécoms, semble
          prendre au sérieux l’enjeu de la neutralité et travailler sur le
          sujet. Mais en plein été 2011 –&#160;alors que nous mettons beaucoup
          d’énergie dans l’action transnationale contre le traité ACTA&#160;–,
          de mauvais signaux nous parviennent de Bruxelles.
        </Text>
        <Text>
          La commissaire européenne chargée de la société numérique, Neelie
          Kroes, reçoit officiellement les opérateurs et des dirigeants
          d’entreprises connues pour leur lobbying hostile à la neutralité du
          Net. Elle explique publiquement que ce qu’ils demandent est sans
          danger réel, et même bon pour les affaires, et que le marché régulera
          les choses de lui-même (avec sa fameuse petite main qu’on ne voit pas,
          mais qui pousse chacun à choisir finalement le bon opérateur). La
          menace sur la neutralité du Net est directe, précise, imminente
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Mme&#160;Kroes, les laisserez-vous contrôler Internet ? »,
                    21&#160;juillet 2011&#160;:
                  </Text>
                  <AppLink href="https://www.laquadrature.net/2012/02/17/reponse-a-la-consultation-de-larcep-sur-la-qualite-de-service-de-lacces-a-internet/">
                    https://www.laquadrature.net/2012/02/17/reponse-a-la-consultation-de-larcep-sur-la-qualite-de-service-de-lacces-a-internet/
                  </AppLink>
                </>
              )
            }
          >
            51
          </InfoClick>
          . Mais la commissaire feint de penser que les petits écarts et les
          bidouillages des opérateurs ne sont pas des atteintes réelles à la
          neutralité, et qu’il n’existe aucune nécessité de légiférer fermement
          en la matière. En somme, on agira plus tard, et seulement en cas de
          besoin…
        </Text>
        <Text>
          Une campagne militante européenne se met aussitôt en place, pour
          collecter et publier des exemples concrets d’atteintes réelles à la
          neutralité du Net. Les internautes de toute l’Union européenne sont
          invités à témoigner sur un site unique&#160;:{" "}
          <a href="http://RespectMyNet.eu">RespectMyNet.eu</a>. Le site n’est
          plus en ligne aujourd’hui, ce qui rend difficile d’évaluer a
          posteriori le succès de l’opération. Mais on reconnaît les méthodes
          chères aux fondateurs de la Quadrature&#160;: l’appel aux bénévoles,
          le <i>crowdsourcing</i>, l’émulation et le travail en commun.
        </Text>
        <Text>
          La mobilisation permet de ralentir les attaques contre la neutralité
          du Net. Une des commissions du Parlement européen adopte en octobre
          une résolution sur «&#160;l’internet ouvert et la neutralité du
          Net&#160;» appelant, bien que mollement, à les préserver.
        </Text>
        <Text>
          Un peu plus tard, en 2012, la Quadrature répond à une consultation de
          l’ARCEP sur la neutralité du Net et rédige des propositions positives,
          toujours valables aujourd’hui
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Réponse à la consultation de l’ARCEP sur la qualité de
                    service de l’accès Internet »&#160;:
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2012/02/17/reponse-a-la-consultation-de-larcep-sur-la-qualite-de-service-de-lacces-a-internet/"
                  >
                    https://www.laquadrature.net/2012/02/17/reponse-a-la-consultation-de-larcep-sur-la-qualite-de-service-de-lacces-a-internet/
                  </AppLink>
                </>
              )
            }
          >
            52
          </InfoClick>
          . Et le temps passe de cette manière, d’escarmouche en escarmouche.
        </Text>
        <Text>
          Le dénouement n’arrivera qu’en avril&#160;2014, par le biais d’un
          règlement sur le «&#160;marché unique européen des communications
          électroniques&#160;». À la Quadrature, les têtes ont changé
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Adrienne Charmet et Agnès de Cornulier ont remplacé Jérémie
                  Zimmermann.
                </Text>
              )
            }
          >
            53
          </InfoClick>
          , mais le plaidoyer auprès des parlementaires européens, des
          commissaires et des émissaires français n’a jamais baissé d’intensité
          –&#160;en particulier en direction de la commissaire Kroes, toujours
          aussi rétive à trancher, et des parlementaires susceptibles de
          contrebalancer son influence.
        </Text>
        <Text>
          Le 3&#160;avril 2014, la Quadrature publie enfin un communiqué de
          victoire. Sur la photo qui illustre l’article, on voit même le sticker
          logo emblématique de l’association, un pi noir, appuyé contre une
          bouteille de champagne qui ne demande qu’à être ouverte. Le ton est au
          soulagement&#160;: «&#160;Le texte adopté inclut une définition
          rigoureuse de la neutralité du Net, en lui conférant une portée
          normative. Tout en permettant aux opérateurs de développer des offres
          d’accès avec une qualité de service optimisée pour des applications
          qui ne pourraient pas fonctionner correctement sur l’internet
          “classique”, ce texte encadre efficacement de tels “services
          spécialisés” en assurant qu’aucune discrimination ne puisse être
          réalisée entre les différents fournisseurs de telles applications et
          en protégeant la bande passante allouée à Internet
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2014/04/03/neutralite-du-net-un-grand-pas-en-avant-pour-linternet-libre/"
                >
                  https://www.laquadrature.net/2014/04/03/neutralite-du-net-un-grand-pas-en-avant-pour-linternet-libre/
                </AppLink>
              )
            }
          >
            54
          </InfoClick>
          .&#160;» Le compte est bon.
        </Text>
        <Text>
          Malgré les réserves de prudence –&#160;le texte peut encore être
          modifié par les gouvernements réunis dans le Conseil de l’Europe, et
          il faut donc rester mobilisé&#160;–, le communiqué ne cache pas la
          jubilation des membres de l’association&#160;: «&#160;La victoire
          d’aujourd’hui sur la neutralité du Net est la plus importante pour la
          protection des libertés en ligne depuis le rejet de l’ACTA en
          juillet&#160;2012.&#160;»
        </Text>
        <Text>
          Un sentiment rare et précieux, d’autant plus que, sur d’autres fronts,
          les nouvelles ne sont pas bonnes du tout…
        </Text>
        <Text type="h3">LOPPSI&#160;: la&#160;censure sans juge</Text>
        <Text>
          Il faut s’imaginer l’état d’esprit post-Hadopi. La chasse acharnée au
          téléchargement illégal crée une ambiance de paranoïa&#160;:
          l’internaute français lambda, qui télécharge un film de temps en
          temps, découvre qu’il est techniquement sous surveillance et que son
          activité sur Internet est potentiellement répréhensible. Sans compter
          le «&#160;défaut de sécurisation&#160;» potentiel de sa connexion…
          L’idée que l’on puisse cliquer sur un lien et recevoir la visite de la
          police n’a plus rien de délirant. Et c’est sans doute un effet de la
          loi que le gouvernement Fillon n’est pas vraiment fâché de voir
          s’installer dans les esprits…
        </Text>
        <Text>
          Mais comment le fait de télécharger des films a-t-il pu faire l’objet
          d’une telle répression, et amener des législateurs à décider
          d’espionner les activités en ligne de millions d’internautes&#160;?
          Hypnotisés sans doute par les discours angoissants de l’industrie
          culturelle, pour laquelle il était plus facile de réprimer à court
          terme que d’évoluer à toute vitesse, ils ont cédé à la pente de la
          facilité, et à l’esprit du temps. Il y a peut-être dans cette réaction
          disproportionnée un fond psychologique de peur et d’incompréhension.
        </Text>
        <Text>
          Toutefois, les politiques ont bien compris que le réseau est aussi une
          formidable machine de surveillance, que les gens installent eux-mêmes,
          et avec enthousiasme. Parmi les propositions de la Quadrature au sujet
          de la neutralité du Net
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Propositions positives » publiées en
                    juillet&#160;2011&#160;:
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2016/01/21/"
                  >
                    https://www.laquadrature.net/2016/01/21/
                  </AppLink>
                </>
              )
            }
          >
            55
          </InfoClick>
          , on lit ceci&#160;: «&#160;Il est nécessaire d’encadrer l’utilisation
          des technologies d’inspection des paquets de données afin de protéger
          le secret des correspondances et l’intégrité des communications
          électroniques.&#160;» C’est-à-dire qu’un paragraphe sur la gestion
          technique des réseaux se termine sur une phrase qui évoque
          littéralement l’espionnage des correspondances.
        </Text>
        <Text>
          De quoi s’agit-il&#160;? La technologie de l’inspection profonde des
          paquets de données, ou <i>Deep Packet Inspection</i> (DPI) en anglais,
          consiste à jeter un œil à l’intérieur de l’enveloppe qui transporte
          les données (le paquet) pour voir ce qu’il contient. Normalement, un
          routeur «&#160;neutre&#160;» a besoin de lire seulement l’en-tête du
          paquet, pour savoir dans quelle direction l’orienter, mais si on rompt
          la neutralité du Net, pour des raisons techniques ou policières, alors
          il est important de connaître la nature des données transportées, pour
          savoir si elles sont prioritaires… ou suspectes. Les techniques sont
          les mêmes. Autrement dit, si la neutralité du Net est capitale, c’est
          aussi parce qu’elle est un frein à la surveillance de nos échanges
          numériques. Et dans la tête des législateurs peureux et sans
          imagination&#160;: contrôler, c’est surveiller.
        </Text>
        <Text>
          Nous le savions bien et pourtant, l’article que le magazine{" "}
          <i>Next INpact</i> publie le 6&#160;juin 2008 est une surprise&#160;:
          «&#160;Exclusif&#160;: le gouvernement veut un filtrage de masse des
          réseaux
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.nextinpact.com/archive/44018-filtrage-internet-neutralite-FAI-operateurs.htm"
                >
                  https://www.nextinpact.com/archive/44018-filtrage-internet-neutralite-FAI-operateurs.htm
                </AppLink>
              )
            }
          >
            56
          </InfoClick>
          &#160;». L’objet du scoop, c’est une initiative discrète du
          gouvernement Fillon&#160;: «&#160;Le gouvernement tente actuellement
          de faire signer d’ici le 10&#160;juin par les FAI, les opérateurs de
          télécoms et les prestataires (fournisseurs en ligne et éditeurs) une
          charte dite sur la “confiance en ligne”. Ce document, issu du
          ministère de l’Intérieur, est une petite bombe pour ces fournisseurs
          de tuyaux plus habitués à la neutralité de leur fonction.&#160;»
        </Text>
        <Text>
          Que contient cette charte&#160;? Les FAI qui la signent s’engageraient
          à filtrer les connexions en <i>peer-to-peer</i> (la voie privilégiée
          du téléchargement «&#160;illicite&#160;» à l’époque), à lutter contre
          le spam, à informer les internautes sur les arnaques en ligne (
          <i>phishing</i>, <i>spywares</i>), à bloquer l’accès des mineurs aux
          sites pornographiques, à filtrer les contenus accessibles aux mineurs
          (vaste programme) et, bien sûr, à dénoncer à la police les sites
          pédophiles et les contenus répréhensibles (racisme, négationnisme,
          etc.). C’est une des premières tentatives de l’État de faire porter
          tout le poids de la police du Net sur les épaules des intermédiaires
          techniques et des hébergeurs.
        </Text>
        <Text>
          La Quadrature réagit aussitôt et publie un article le même jour&#160;:
          «&#160;Le but du pouvoir exécutif français n’est pas de lutter
          efficacement contre la criminalité avec des mesures adéquates car
          nécessaires, appropriées et proportionnées. Il s’agit de contrôler par
          tous les moyens le seul média libre qui ne lui obéit pas&#160;»,
          déclare Christophe Espern, ajoutant&#160;: «&#160;Il y a quelque chose
          d’odieux à utiliser la lutte contre la pédopornographie comme alibi à
          Big Brother. Les services de police concernés ne demandent pas de
          telles mesures. Parler de confiance en ligne dans ce contexte relève
          de la novlangue
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Big Brother&#160;: Sarkozy en rêvait, Fillon le fait »,
                    24&#160;juin 2008&#160;:
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2008/06/06/big-brother-sarkozy-en-revait-fillon-le-fait/"
                  >
                    https://www.laquadrature.net/2008/06/06/big-brother-sarkozy-en-revait-fillon-le-fait/
                  </AppLink>
                  <Text>.</Text>
                </>
              )
            }
          >
            57
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          La fin de l’article énumère de façon très claire les axes d’action de
          l’association&#160;: «&#160;La Quadrature du Net appelle donc les
          citoyens à contacter immédiatement leurs députés pour leur demander
          d’interpeller le gouvernement lors des prochaines questions
          d’actualité. Elle les invite à se former aux techniques
          d’anonymisation et de chiffrement et publiera prochainement un guide
          permettant aux citoyens de se protéger de l’arbitraire numérique. Elle
          appelle en outre les intermédiaires techniques à refuser
          catégoriquement toute forme de collaboration avec ces projets indignes
          d’une démocratie.&#160;» On reconnaît la mobilisation des citoyens, la
          formation pratique, et l’interpellation des décideurs&#160;: les
          industriels, les législateurs ou même l’exécutif.
        </Text>
        <Text>
          Les termes du débat sont donc posés&#160;: il faut surveiller Internet
          parce que les gens téléchargent des films et parce qu’il faut traquer
          les pédophiles.
        </Text>
        <Text>
          En mai&#160;2009, moins d’un an après l’affaire de la charte, le
          gouvernement Fillon dépose un projet de loi intitulé «&#160;loi
          d’orientation et de programmation pour la performance de la sécurité
          intérieure&#160;» (LOPPSI). Le mot «&#160;performance&#160;» peut
          surprendre –&#160;il doit même surprendre&#160;– mais il est
          révélateur de la cohérence idéologique du néolibéralisme à la
          française&#160;: dans un sens la protection de la rentabilité
          économique conduit à faire des lois répressives (Hadopi), et en retour
          les lois sécuritaires empruntent le vocabulaire de l’efficacité. La
          productivité, on le sait, est entrée dans le vocabulaire de la police
          sous le quinquennat de Sarkozy, et la rentabilité dans celui de
          l’ensemble de la fonction publique, jusque dans les hôpitaux et les
          écoles. Mais revenons à nos lois sécuritaires.
        </Text>
        <Text>
          Le 27&#160;mai 2009, la Quadrature livre sa première lecture du projet
          de loi LOPPSI. Pour l’association c’est une évidence&#160;: l’action
          (nécessaire) contre les sites pédopornographiques sert de prétexte à
          une prise de contrôle du Web tout entier. Il s’agit par exemple
          d’instaurer une liste noire de sites à bloquer, régulièrement mise à
          jour et transmise aux FAI. Un procédé fastidieux et peu efficace, trop
          facile à contourner, disent les spécialistes.
        </Text>
        <Text>
          Il s’agirait surtout, et c’est ce qui inquiète le plus, de donner à la
          police le pouvoir administratif de supprimer directement des contenus
          ou des sites d’apparence illicite, sans la décision d’un juge. Ce qui
          causerait un scandale dans la rue et révolterait tout le monde de
          façon légitime (sanction arbitraire de la police, contrôle des
          discours et des actes…) paraîtrait donc aux yeux du gouvernement et de
          l’opinion publique parfaitement légitime quand cela se passe sur
          Internet. Tout le travail de la Quadrature va donc consister à
          expliquer au gouvernement et à l’opinion publique que non, cela n’est
          pas possible.
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter1Part5;
