import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter3Part2 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="La technopolice est déjà partout : retour sur un futur sans avenir">
      <>
        <Text>
          Le coup de tonnerre de «&#160;l’observatoire Big Data de la
          tranquillité publique&#160;» de Marseille n’est pas arrivé dans un
          ciel bleu. Mais avec l’avantage donné par le regard rétrospectif, on
          retrouve très facilement les premiers nuages auxquels on n’avait pas
          prêté suffisamment attention, et les impacts de foudre qui étaient
          tombés tout près sans qu’on les entende.
        </Text>

        <Text type="h3">Le&#160;sport, un&#160;laboratoire sécuritaire</Text>
        <Text>
          Les grands rassemblements publics font évidemment l’objet d’une
          attention particulière de la part des autorités de police, qui
          craignent à la fois la petite délinquance (dont le gagne-pain se
          trouve dans les poches des touristes), les débordements de supporters
          rivaux pleins d’alcool et de fierté chauvine mais aussi, désormais,
          les attaques terroristes.
        </Text>
        <Text>
          Leur crainte est, hélas, fondée sur des faits douloureux. On se
          souvient par exemple de la bombe qui avait explosé dans la foule lors
          des Jeux olympiques d’Atlanta, en 1996, posée là par un militant
          d’extrême droite opposé à la légalisation de l’avortement par le
          gouvernement fédéral des États-Unis. Mais le terrorisme
          «&#160;domestique&#160;» n’est pas le seul problème. Les pays qui
          accueillent les Jeux olympiques craignent surtout son exposition
          internationale&#160;: toutes les caméras sont braquées sur
          l’événement.
        </Text>
        <Text>
          Un épisode traumatique est à l’origine de cette peur&#160;: la prise
          d’otages des athlètes israéliens par un groupe armé pro-palestinien
          lors des Jeux de Munich en 1972, qui avait fait dix-sept morts, dont
          onze athlètes. Depuis lors, les Jeux olympiques sont sous très haute
          surveillance.
        </Text>
        <Text>
          Les Jeux olympiques de Londres, en août&#160;2012, ont été l’occasion
          d’un déploiement de force sécuritaire démesuré, mais qui avait en
          grande partie été médiatisé en raison de son organisation désastreuse
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La “débâcle” de la sécurité privée des JO de Londres »,{" "}
                    <i>Le Figaro</i>, 17&#160;juillet 2012&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lefigaro.fr/international/2012/07/17/01003-20120717ARTFIG00558-la-debacle-de-la-securite-privee-des-jo-de-londres.php"
                  >
                    https://www.lefigaro.fr/international/2012/07/17/01003-20120717ARTFIG00558-la-debacle-de-la-securite-privee-des-jo-de-londres.php
                  </AppLink>
                </>
              )
            }
          >
            176
          </InfoClick>
          . Les sociétés privées de surveillance qui avaient raflé le marché du
          gardiennage des sites et des événements olympiques s’étaient couvertes
          de ridicule, en se montrant incapables d’embaucher assez de personnel
          pour remplir leur contrat
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La sécurité des JO de Londres vire au fiasco », France
                    Info Sports, 17&#160;juillet 2012&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.francetvinfo.fr/sports/jo/la-securite-des-jo-de-londres-vire-au-fiasco_119645.html"
                  >
                    https://www.francetvinfo.fr/sports/jo/la-securite-des-jo-de-londres-vire-au-fiasco_119645.html
                  </AppLink>
                </>
              )
            }
          >
            177
          </InfoClick>
          , au point que le gouvernement britannique, qui donnait une grande
          importance à la question de la sécurité, avait eu recours à l’armée
          pour sauver la face. Les Londoniens vivant près des grandes
          installations olympiques avaient même eu la surprise de voir l’armée
          britannique installer des batteries de missiles anti-aériens sur le
          toit de leurs immeubles. Le souvenir des avions détournés du
          11&#160;septembre 2001 aux États-Unis, et le souvenir plus proche des
          attentats simultanés du 7&#160;juillet 2005 (quatre bombes avaient
          explosé dans les transports londoniens à quelques minutes
          d’intervalle), tout justifiait la paranoïa du gouvernement britannique
          et son besoin de rassurer sa population
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Londres teste sa sécurité avant les JO »,{" "}
                    <i>Libération</i>, 30&#160;avril 2012&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.liberation.fr/planete/2012/04/30/londres-teste-sa-securite-avant-les-jo_815356/"
                  >
                    https://www.liberation.fr/planete/2012/04/30/londres-teste-sa-securite-avant-les-jo_815356/
                  </AppLink>
                </>
              )
            }
          >
            178
          </InfoClick>{" "}
          et les très nombreux touristes attendus pour l’occasion, quitte à
          placer la ville dans une sorte de camp militaire à ciel ouvert.
        </Text>
        <Text>
          Quatre&#160;ans plus tôt, en 2008, on avait regardé avec incrédulité
          la République populaire de Chine organiser les Jeux olympiques de
          Pékin dans une ambiance policière qui défiait l’imagination. À peine
          vingt&#160;ans après la répression sanglante de l’occupation de la
          place Tiananmen, il était devenu une évidence pour le monde entier que
          «&#160;la Chine&#160;» désignait tout à la fois, ou alternativement,
          une usine géante fournissant le capitalisme mondial en produits de
          toutes sortes, un enfer écologique lointain en raison de cette
          «&#160;délocalisation&#160;» industrielle, et un État-prison
          maintenant l’ordre d’une main de fer dans une société très
          inégalitaire, où l’enrichissement de quelques-uns côtoyait la très
          grande pauvreté de la masse laborieuse, sous un drapeau communiste
          devenu tout à fait incongru. «&#160;La Chine&#160;» était même devenue
          une expression toute faite pour désigner cette bizarrerie du monde
          moderne, avec sans doute un bon fond de condescendance et de préjugés
          xénophobes, comme si «&#160;les Chinois&#160;» avaient une
          prédisposition culturelle pour la surveillance de masse et les régimes
          policiers de toute éternité.
        </Text>
        <Text>
          Lors des Jeux de Pékin, la presse occidentale décrit un monde qui fait
          sans doute frémir les lecteurs de 2008, mais en leur ménageant la
          possibilité de sourire de soulagement, tant l’exotisme et la distance
          géographique atténuent la portée du constat&#160;: «&#160;Un réseau de
          caméras couvrant l’ensemble de la capitale a été mis en place. Chaque
          quartier a été doté d’une salle de surveillance équipée de caméras et
          d’un système d’enregistrement. Dès 2006, on avait atteint un taux de
          couverture de 100&#160;%. Avant les Jeux, les caméras devaient être
          mises en réseau, pour que la police pékinoise puisse avoir une vue
          d’ensemble de la capitale. Cela concerne non seulement les caméras de
          quartier, mais aussi les caméras de surveillance des banques, ainsi
          que celles fixées aux feux tricolores ou dans les rues. La même scène
          pourra ainsi être filmée par plusieurs caméras, ce qui permettra
          d’avoir des preuves d’origine différente. Les poubelles des secteurs
          stratégiques de la capitale ont été numérotées et placées sous la
          surveillance de caméras. Sans en avoir l’impression, on est contrôlé
          en permanence par un système d’alerte très dense. “Tout Pékin se
          trouve couvert par un filet tendu de toutes parts”, fait remarquer Ma
          Xin
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Pékin 2008. Des Jeux sous haute surveillance »,
                    <i>Courrier international</i>, 31&#160;juillet 2008&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.courrierinternational.com/article/2008/07/31/des-jeux-sous-haute-surveillance"
                  >
                    https://www.courrierinternational.com/article/2008/07/31/des-jeux-sous-haute-surveillance
                  </AppLink>
                </>
              )
            }
          >
            179
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          Et pourtant, ce que décrit cet article paraît malheureusement banal,
          et même familier, à un lecteur français de 2022&#160;: les
          «&#160;centres de supervision urbaine&#160;» (CSU) dont la{" "}
          <i>safe city</i> et les polices municipales sont friandes sont très
          exactement ce dont il est question dans «&#160;la Chine&#160;»
          exotique et lointaine de 2008.
        </Text>
        <Text>
          En matière de surveillance, le Royaume-Uni de 2012 n’est pas en reste,
          comme on l’a vu. Il est d’ailleurs intéressant de noter que la
          Grande-Bretagne fait figure, en Europe, avec son statut d’outsider
          encore aggravé depuis le «&#160;Brexit&#160;», de pionnière en la
          matière. Nos collègues de l’association Big Brother Watch
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Site Web de Big Brother Watch&#160;: </Text>
                  <AppLink white href="https://bigbrotherwatch.org.uk/">
                    https://bigbrotherwatch.org.uk/
                  </AppLink>
                </>
              )
            }
          >
            180
          </InfoClick>
          , par exemple, sont plus avancés que nous dans la prise de conscience
          de la vidéosurveillance totale, parce que leur pays est couvert de
          caméras depuis plus de deux décennies. On se rappellera par ailleurs
          que la police du comté du Kent a été l’une des premières à utiliser le
          logiciel PredPol dès 2013, ou encore que le Royaume-Uni fait partie
          des <i>Five Eyes</i> –&#160;un réseau d’espionnage et de partage
          d’informations qui réunit aussi les États-Unis, le Canada,
          l’Australie, la Nouvelle-Zélande et le Royaume-Uni&#160;– et dont les
          documents rendus publics par Edward Snowden en 2013 ont révélé
          plusieurs programmes de surveillance, dont le programme PRISM
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « NSA slides explain the PRISM data-collection program »,
                    <i>The Washington Post</i>, 6&#160;juin 2013&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.washingtonpost.com/wp-srv/special/politics/prism-collection-documents/"
                  >
                    https://www.washingtonpost.com/wp-srv/special/politics/prism-collection-documents/
                  </AppLink>
                </>
              )
            }
          >
            181
          </InfoClick>
          .
        </Text>
        <Text>
          Les Jeux olympiques sont l’occasion de faire deux fois la publicité de
          la sécurité&#160;: d’une part comme affichage politique, à l’intérieur
          comme en direction de l’étranger, et d’autre part comme vitrine
          commerciale, avec la mise en avant des savoir-faire et des équipements
          vendus par les sociétés nationales. Mais le sport sert aussi, et
          depuis longtemps, de terrain d’expérimentation pour la surveillance.
        </Text>
        <Text>
          En France, les supporters de foot sont traités comme une population
          dangereuse, et à ce titre comme une population aux droits inférieurs.
          On peut interdire administrativement, et sans jugement, à des
          personnes d’assister à un match. On peut fermer une tribune, un stade,
          ou interdire des déplacements collectifs. Mais on peut aussi viser une
          personne, sans qu’elle puisse se défendre. Les préfets ont les mains
          libres pour punir et humilier les supporters, assimilés dans leur
          ensemble à des hooligans, quels que soient leurs agissements réels.
          Les décisions de police administrative, dont la motivation ou la
          proportionnalité ne sont pas contrôlées au préalable par la justice,
          nouveauté scandaleuse qui inquiéta beaucoup au moment de l’état
          d’urgence fin 2015, n’avaient rien d’une nouveauté pour les habitués
          des stades&#160;: ils connaissaient ces punitions arbitraires depuis
          des années. Bien sûr, il existe des recours devant la justice
          administrative. Mais la mauvaise réputation des «&#160;mauvais
          garçons&#160;» les précède et saborde leur défense d’avance.
        </Text>
        <Text>
          Début 2020, on apprend par Olivier Tesquet, journaliste et auteur de{" "}
          <i>
            À la trace&#160;: enquête sur les nouveaux territoires de la
            surveillance
          </i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Olivier Tesquet,
                    <i>
                      À la trace&#160;: enquête sur les nouveaux territoires de
                      la surveillance
                    </i>
                    , Premier Parallèle, janvier&#160;2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="http://www.premierparallele.fr/livre/a-la-trace"
                  >
                    http://www.premierparallele.fr/livre/a-la-trace
                  </AppLink>
                </>
              )
            }
          >
            182
          </InfoClick>
          , à l’occasion d’une interview confiée au journal <i>StreetPress</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Interview d’Olivier Tesquet, 21&#160;janvier 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.streetpress.com/sujet/1579625191-reconnaissance-faciale-fichage-generalise-geolocalisation-surveillance-capitalisme-gafa"
                  >
                    https://www.streetpress.com/sujet/1579625191-reconnaissance-faciale-fichage-generalise-geolocalisation-surveillance-capitalisme-gafa
                  </AppLink>
                </>
              )
            }
          >
            183
          </InfoClick>
          , que les supporters du FC Metz auraient été soumis à la
          reconnaissance faciale à l’entrée du stade Saint-Symphorien. Olivier
          Tesquet a découvert cela en s’intéressant à la société Two-i
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Site Web de la société Two-i&#160;: </Text>
                  <AppLink white href="https://two-i.com/">
                    https://two-i.com/
                  </AppLink>
                </>
              )
            }
          >
            184
          </InfoClick>
          , une start-up messine de l’intelligence artificielle appliquée à la
          surveillance vidéo. Jusqu’à présent, le club n’en avait pas parlé.
          Mais à la publication de cette interview, les supporters du FC Metz
          relient les points&#160;: ils se souviennent de ce jour où il leur a
          été demandé d’enlever écharpes, casquettes et lunettes à l’entrée d’un
          match contre Strasbourg. L’Association nationale des supporters publie
          aussitôt un communiqué pour dénoncer une pratique attentatoire aux
          libertés&#160;: «&#160;Nous refusons de devenir les rats de
          laboratoire de la reconnaissance faciale
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Communiqué de l’Association nationale des supporters&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://twitter.com/A_N_Supporters/status/1220692742365425665"
                  >
                    https://twitter.com/A_N_Supporters/status/1220692742365425665
                  </AppLink>
                </>
              )
            }
          >
            185
          </InfoClick>
          .&#160;» Face au tollé, la société Two-i et les responsables du FC
          Metz se noient ensuite dans une série de justifications
          décousues&#160;: c’était seulement une expérimentation, dans un cadre
          légal puisque la loi Larrivé
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Loi de 2016 encadrant « la lutte contre le hooliganisme
                    »&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.vie-publique.fr/loi/20971-loi-renforcant-le-dialogue-avec-les-supporters-et-la-lutte-contre-le-hoo"
                  >
                    https://www.vie-publique.fr/loi/20971-loi-renforcant-le-dialogue-avec-les-supporters-et-la-lutte-contre-le-hoo
                  </AppLink>
                </>
              )
            }
          >
            186
          </InfoClick>{" "}
          autorise la surveillance des personnes interdites d’entrée au stade,
          mais c’est aussi un moyen de lutter contre le terrorisme puisque le
          traitement d’image permet de reconnaître des armes ou des colis
          suspects, d’ailleurs le test n’a pas eu lieu, mais on a le matériel
          pour le faire, et enfin s’il a eu lieu, c’était seulement avec des
          salariés volontaires de l’entreprise de surveillance. Bref, une salade
          de contradictions. La CNIL tranche le débat un an plus tard, en
          publiant une mise en garde formelle contre le club
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Communiqué de la CNIL, 18&#160;février&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.cnil.fr/fr/reconnaissance-faciale-et-interdiction-commerciale-de-stade-la-cnil-adresse-un-avertissement-un-club"
                  >
                    https://www.cnil.fr/fr/reconnaissance-faciale-et-interdiction-commerciale-de-stade-la-cnil-adresse-un-avertissement-un-club
                  </AppLink>
                </>
              )
            }
          >
            187
          </InfoClick>
          &#160;: la reconnaissance faciale sauvage dans les stades est
          illégale.
        </Text>
        <Text>
          On se retrouve une fois de plus face à la démission de l’autorité
          chargée de protéger nos données personnelles. Les données
          biométriques, strictement encadrées par le RGPD en tant que
          «&#160;données sensibles&#160;», «&#160;ne doivent pas être
          traitées&#160;», sauf exception dans des cas bien précis (mais pas
          celui-ci). La légèreté avec laquelle certains décident d’utiliser des
          technologies largement attentatoires aux libertés devrait faire réagir
          l’autorité et provoquer la condamnation et le paiement d’une amende
          pouvant aller jusqu’à plusieurs millions d’euros. Dans le cas
          contraire, aucune raison que l’atteinte aux droits ne se réitère pas,
          ici ou ailleurs.
        </Text>
        <Text>
          Au printemps 2020, une autre expérimentation se tient lors du tournoi
          de tennis de Roland-Garros. En toute discrétion là encore, puisqu’on
          en entend parler presque par hasard, en janvier&#160;2021, par le
          biais d’une réponse du gouvernement à une question écrite posée par un
          sénateur… un an plus tôt
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  {" "}
                  <Text>
                    Réponse du gouvernement au sénateur Karoutchi&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.senat.fr/questions/base/2020/qSEQ200113854.html"
                  >
                    https://www.senat.fr/questions/base/2020/qSEQ200113854.html
                  </AppLink>
                </>
              )
            }
          >
            188
          </InfoClick>
          . À une question du sénateur Karoutchi (les Républicains), qui
          s’enquiert de la volonté du gouvernement de lever les entraves légales
          qui gênent l’expérimentation de la reconnaissance faciale à grande
          échelle, le secrétariat d’État chargé de la Transition numérique et
          des Communications électroniques répond d’abord avec une description
          du cadre légal (RGPD, directive Police-Justice), puis énumère les
          expériences déjà réalisées. Parmi celles qu’on connaît déjà, on en
          découvre une nouvelle&#160;: «&#160;[…] une autre expérimentation a
          été conduite dans le cadre du tournoi de Roland-Garros 2020. Élaborée
          en coordination entre le secrétariat général de la Défense et de la
          Sécurité nationale et le Comité national à la sécurité des Jeux
          olympiques, elle a notamment permis de tester un dispositif de
          contrôle d’accès pour les arbitres dans le cadre d’un grand événement
          sportif, en vue d’une possible application durant les Jeux olympiques
          de Paris 2024.&#160;»
        </Text>
        <Text>
          Sur cette expérimentation, on a très peu d’informations. On sait
          seulement que les outils utilisés étaient fournis par Thales, grande
          entreprise de l’armement qui veut élargir ses débouchés en se lançant
          dans la <i>smart city</i> et la surveillance civile. On constate aussi
          que des autorités publiques et militaires chapeautaient l’opération.
          La finalité de l’expérience semblait être l’authentification des
          juges-arbitres au moment de leur entrée dans les espaces réservés du
          tournoi&#160;: il s’agissait de s’assurer que seules les personnes
          autorisées pouvaient accéder aux coulisses et aux espaces
          professionnels de l’événement.
        </Text>
        <Text>
          Mais du stade de Metz à Roland-Garros, l’ambiance n’est pas la même.
          La reconnaissance faciale a, elle aussi, plusieurs visages. À Metz, on
          se sert de la reconnaissance faciale pour fliquer, sans les prévenir,
          des spectateurs qui ont mauvaise réputation, et viennent en grand
          nombre apprécier bruyamment un sport populaire. Quant à l’opération de
          reconnaissance faciale en tant que telle, elle relève techniquement
          d’une identification&#160;: c’est-à-dire qu’on examine chaque visage
          et qu’on tente d’identifier dans la foule des personnes dont le visage
          est déjà connu par ailleurs, et dont la présence n’est pas souhaitée.
          On recherche activement un visage dangereux dans une foule. Ici, à
          Metz, la reconnaissance faciale est utilisée pour surveiller les
          masses dangereuses.
        </Text>
        <Text>
          Là, à Roland-Garros, il s’agit d’authentification. L’authentification
          consiste à analyser un visage unique qui se présente devant la caméra
          et à le comparer avec un visage unique de référence, gardé en mémoire
          dans un badge par exemple. Il s’agit de prouver qu’une personne est
          bien celle qu’elle dit être. L’authentification est beaucoup plus
          protectrice que l’identification. Quand vous déverrouillez votre
          téléphone avec votre visage, ou avec votre empreinte digitale, autre
          donnée biométrique unique, vous vous authentifiez et c’est bien pour
          protéger l’accès à vos données. Quand un juge-arbitre de Roland-Garros
          se présente à l’entrée de l’espace réservé aux officiels du tournoi et
          place son visage devant une caméra, ce n’est pas pour être puni, ce
          n’est pas pour vérifier qu’il n’est pas un terroriste fiché ou un
          supporter violent, mais c’est pour accéder à un espace où sa présence
          est légitime. La reconnaissance faciale fonctionne alors comme une
          reconnaissance sociale.
        </Text>
        <Text>
          L’authentification ouvre des portes légitimes, l’identification ferme
          des portes et exclut. C’est pourquoi il est vain de parler de la
          reconnaissance faciale de manière abstraite. Il faut toujours se
          demander de quelle reconnaissance faciale il est question, appliquée à
          qui, dans quel but, et par qui. Compliquer la vie des pauvres ou
          faciliter la vie des riches reste malgré tout, sous des abords
          caricaturaux, une assez bonne approximation de la situation, qu’il
          faut ensuite affiner. Mais il est bon d’avoir en tête ces quelques
          éléments de repère la prochaine fois que vous entendrez quelqu’un
          s’enthousiasmer pour un usage ou l’autre de la reconnaissance faciale.
          Nous, à la Quadrature, nous insistons depuis longtemps sur les
          nécessaires distinctions, et sur la façon dont les usages positifs ou
          ludiques de la biométrie (sécuriser mon téléphone) fonctionnent aussi
          comme autant de points d’entrée et de banalisation de l’outil
          technique pour des usages beaucoup plus graves (tri social,
          surveillance, criminalisation et suppression des comportements
          minoritaires
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Le vrai visage de la reconnaissance faciale »,
                    juin&#160;2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/06/21/le-vrai-visage-de-la-reconnaissance-faciale/"
                  >
                    https://www.laquadrature.net/2019/06/21/le-vrai-visage-de-la-reconnaissance-faciale/
                  </AppLink>
                </>
              )
            }
          >
            189
          </InfoClick>
          ).
        </Text>

        <Text type="h3">La&#160;vidéosurveillance intelligente</Text>
        <Text>
          Entre&#160;1990 et&#160;2015, les caméras de surveillance ont
          recouvert le territoire français. Ce déploiement massif, sur fonds
          publics, était porté par une idéologie sécuritaire, et soutenu par une
          industrie. Malgré quelques réticences, locales et minoritaires,
          l’installation des caméras a rencontré peu d’obstacles. Le discours
          médiatico-politique matraque depuis trente ou quarante&#160;ans comme
          une évidence la montée de l’insécurité, et même du «&#160;sentiment
          d’insécurité&#160;». Le cinéma et les séries télé participent à
          banaliser l’idée que les images de vidéosurveillance sont essentielles
          pour identifier les délinquants, résoudre très rapidement les enquêtes
          criminelles, et même trouver les terroristes dans les rues.
        </Text>
        <Text>
          Mais vingt&#160;ans plus tard, on se rend compte qu’on n’a tout
          bêtement pas le temps matériel, le temps humain, de regarder autant
          d’images. Oui, c’est aussi bête que ça, et c’est d’ailleurs un
          argument qu’on rencontre dans les cahiers des charges des communes, ou
          dans les plaquettes publicitaires des équipementiers de la
          vidéosurveillance automatisée&#160;: il y a désormais trop de caméras,
          qui produisent trop d’heures de vidéo, et pas assez de personnel pour
          les regarder toutes.
        </Text>
        <Text>
          Pour y parvenir, il faudrait embaucher, idée honnie par l’économie
          capitaliste. Heureusement, l’informatique est arrivée à un stade où
          elle peut analyser des flux d’images avec une certaine efficacité, et
          à moindre coût&#160;! D’où l’idée de confier le travail à des
          logiciels ou, mieux encore&#160;: à «&#160;l’intelligence
          artificielle&#160;». L’intelligence artificielle, finalement, n’est
          rien d’autre qu’une machine qui peut exécuter des tâches
          intellectuelles.
        </Text>
        <Text>
          Voici sans doute une des premières découvertes que nous avons faites
          grâce aux «&#160;demandes Cada&#160;» envoyées tous azimuts&#160;: la
          vidéosurveillance automatisée est déjà très répandue dans les communes
          françaises. On ne parle pas de «&#160;banales&#160;» caméras de
          surveillance, ce qui serait un moindre mal, mais d’analyse des images
          par des logiciels autonomes, dans le cadre de la
          «&#160;vidéosurveillance intelligente&#160;».
        </Text>
        <Text>
          De quelle intelligence s’agit-il&#160;? On demande aux caméras de
          surveillance de comprendre ce qu’elles filment&#160;: les logiciels
          sont chargés de «&#160;lire&#160;» les images en temps réel, de les
          interpréter, et de lancer une alerte en fonction de la situation
          détectée. Par exemple, les bénévoles de la campagne Technopolice ont
          trouvé en ligne les vidéos publicitaires d’un logiciel vendu à des
          chaînes de supermarchés, prétendument capable d’identifier les
          comportements des voleurs en train d’agir dans les rayons
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Article du site Technopolice paru en juin&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://technopolice.fr/blog/videosurveillance-biometrique-dans-nos-supermarches/"
                  >
                    https://technopolice.fr/blog/videosurveillance-biometrique-dans-nos-supermarches/
                  </AppLink>
                </>
              )
            }
          >
            190
          </InfoClick>
          . Le peu d’informations disponibles en ligne permet cependant de
          comprendre que le programme de cette «&#160;intelligence
          artificielle&#160;» repose en grande partie sur du travail humain. Les
          alertes sont envoyées dans les supermarchés par SMS, accompagnées
          d’une capture d’écran, et souvent suivies d’un échange entre
          l’opérateur de surveillance et le supermarché&#160;: «&#160;Vous
          l’avez attrapé&#160;? –&#160;Oui c’est bon, merci&#160;!&#160;», ou
          «&#160;Fausse alerte, merci quand même&#160;».
        </Text>
        <Text>
          Les «&#160;travailleurs du clic&#160;» chers au sociologue Antonio
          Casilli
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Antonio Casilli, <i>En attendant les robots,</i> Le Seuil,
                    2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.seuil.com/ouvrage/en-attendant-les-robots-antonio-a-casilli/9782021401882"
                  >
                    https://www.seuil.com/ouvrage/en-attendant-les-robots-antonio-a-casilli/9782021401882
                  </AppLink>
                </>
              )
            }
          >
            191
          </InfoClick>{" "}
          sont nombreux dans tous les domaines où l’on promet les miracles de
          «&#160;l’intelligence artificielle&#160;», alors que les outils ne
          sont pas arrivés à maturité. Les commerciaux vendent des fonctions ou
          des performances imaginaires, et les équipes d’employés sont chargées
          de remplir les tâches qualifiées qu’on croit avoir confiées à des
          machines. Il serait étonnant que la surveillance échappe entièrement à
          la règle.
        </Text>
        <Text>
          Mais au-delà des arnaques commerciales et des discours publicitaires,
          l’interprétation numérique des images est une réalité&#160;: les
          logiciels sont capables de reconnaître des formes, des séquences, des
          situations, parce qu’on leur a appris à le faire, en leur montrant des
          quantités d’exemples «&#160;bons&#160;» et «&#160;mauvais&#160;»
          –&#160;les meilleurs d’entre eux étant supposément capables de
          «&#160;s’auto-former&#160;», et d’améliorer la précision de leur
          détection au fil du temps, en accumulant les nouveaux cas. Comme à
          chaque fois qu’il est question «&#160;d’intelligence
          artificielle&#160;», expression poudre aux yeux, il faut comprendre
          qu’un logiciel auquel on a fourni de très grandes quantités d’exemples
          qualifiés, au prix d’un grand travail humain préalable, est capable
          d’identifier les cas similaires dans les nouvelles images qu’on lui
          montrera. C’est une définition de l’intelligence assez médiocre, mais
          suffisante pour monter la garde dans un parking et différencier un
          humain d’une voiture ou d’un cheval.
        </Text>
        <Text>
          D’après les cas recueillis au cours de la campagne Technopolice, les
          critères retenus pour la vidéosurveillance sont souvent assez simples,
          et ne doivent pas demander un travail informatique très poussé. Il
          s’agit par exemple, dans les couloirs du métro parisien, d’identifier
          les personnes qui stationnent trop longtemps au même endroit&#160;: ça
          s’appelle du «&#160;maraudage&#160;» dans le jargon des surveillants
          professionnels, et pour une caméra «&#160;intelligente&#160;» c’est
          défini par une silhouette humaine en station immobile pendant plus de
          trois cents&#160;secondes (ou cinq&#160;minutes) dans un lieu que la
          «&#160;normalité&#160;» estime devoir être consacré au passage&#160;:
          un couloir de métro, ou les allées d’un centre commercial.
          L’immobilité dans les lieux de passage est un excellent critère pour
          repérer un mendiant, un sans-abri qui s’installe au chaud, ou un
          musicien qui fait la manche. Guerre aux pauvres, dit le niveau zéro de
          la surveillance. Le prétexte invoqué étant, bien entendu, toujours le
          pire&#160;: un homme immobile ne sera jamais envisagé comme un homme
          fatigué ou contemplatif, ni même comme un vendeur de cigarettes à la
          sauvette, mais toujours comme un dealer de drogue, ou bien entendu un
          terroriste qui attend le signal pour passer à l’action.
        </Text>
        <Text>
          Dès les premiers temps de la campagne Technopolice, nous nous rendons
          compte que les caméras «&#160;intelligentes&#160;» sont bien plus
          répandues qu’on le pensait, mais qu’il est plutôt difficile de savoir
          ce qui est exactement mis en place. Il semble que certaines communes
          ne savent pas vraiment ce qu’elles ont acheté. Que les fonctions des
          logiciels ne sont pas toujours activées, ou utilisées. Ou qu’elles le
          sont sans que la population soit avertie. Il existe une large gamme de
          «&#160;fonctions&#160;» ou de «&#160;tâches&#160;» que peut remplir un
          logiciel de surveillance&#160;: des plus impersonnelles (détecter des
          tags, repérer des bagages abandonnés, compter les individus composant
          une foule) aux plus individuelles, comme suivre une personne précise
          d’une caméra à l’autre, ou réagir à des gestes violents, des coups,
          une fuite au pas de course…
        </Text>
        <Text>
          Tout le problème est dans les points de suspension. On les voit
          souvent dans les plaquettes de communication des entreprises&#160;:
          «&#160;détection des comportements suspects…&#160;». C’est quoi, un
          comportement suspect&#160;? Des points de suspension… Impossible d’en
          savoir plus. Le comportement suspect est un implicite, et chaque
          société sait très bien ce qu’elle entend, quand elle parle de
          «&#160;crime&#160;», ou de «&#160;suspect&#160;». Il y a les{" "}
          <i>usual suspects</i>, les «&#160;suspects habituels&#160;» dont
          chacun sait qu’ils ont la peau sombre et portent un jogging, des
          baskets, une casquette ou une capuche. Mais les choses ne sont jamais
          dites. Puisque c’est évident, pourquoi le dire&#160;? Un comportement
          suspect, c’est une chose qu’on laisse au non-dit, à l’inconscient
          collectif et au préjugé, sous couvert de bon sens. Les préjugés se
          renforcent de n’être jamais explicités.
        </Text>
        <Text>
          Mais il y a aussi tout ce qu’il est préférable de taire, pour que la
          détection soit efficace. Saviez-vous que si vous restez immobile plus
          de trois cents secondes dans un couloir du métro parisien, vous
          déclenchez peut-être une alerte sur un écran, quelque part dans un
          centre de surveillance vidéo&#160;? Et maintenant que vous le savez,
          allez-vous changer votre comportement pour devenir indétectable, ou
          déclencher au contraire volontairement des alertes
          «&#160;bruitistes&#160;» pour brouiller et saboter la surveillance en
          saturant les capteurs&#160;?
        </Text>
        <Text>
          Le flou entretenu par tous les acteurs de la surveillance bénéficie
          bien sûr uniquement à la surveillance. Le célèbre{" "}
          <i>chilling effect</i>, l’effet «&#160;glaçant&#160;» ou inhibant
          d’une menace à la réalité diffuse et imprécise, est beaucoup plus
          puissant que la crainte précise d’une surveillance localisée. Mais le
          flou sur les techniques et les logiciels nous est souvent renvoyé,
          sous la forme d’une accusation de fantasme ou de délire&#160;:
          «&#160;Vous vous montez le bourrichon, Big Brother n’existe pas, la
          surveillance ne marche pas bien, les logiciels ne sont pas au point,
          etc.&#160;» On ne peut évidemment pas se contenter d’une telle
          logique. Qu’est-ce qui est le plus inquiétant&#160;? Devoir se
          contenter d’en savoir si peu sur les systèmes de surveillance qui sont
          déployés au-dessus de nous&#160;? Ou devoir faire confiance à nos
          gouvernants qui disent «&#160;Ne vous inquiétez pas&#160;»&#160;?
          L’enquête est nécessaire, et l’évaluation publique de la surveillance
          est une nécessité démocratique fondamentale.
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter3Part2;
