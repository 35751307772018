import React from "react";

import { Link } from "react-router-dom";
import cx from "classnames";

import Styles from "./NavbarBigScreens.module.scss";

import { Text } from "src/components";
import { ReactComponent as Arrow } from "src/assets/arrow.svg";
import { UseBookType } from "src/hooks";

import IndexNavbar from "../IndexNavbar/IndexNavbar";

const NavbarBigScreens = ({
  currentChapter,
  isChapter,
  currentLocation,
  isConclusion,
  isIntroduction,
  onClickBeforeArrow,
  onClickNextArrow,
  handleCloseNav,
  handleOpenNav,
  isNavOpen,
}: Partial<UseBookType> & {
  onClickNextArrow: () => void;
  onClickBeforeArrow: () => void;
  handleCloseNav: () => void;
  handleOpenNav: () => void;
  isNavOpen: boolean;
}) => {
  const navOpenStyle = isNavOpen && Styles.NavOpen;

  return (
    <>
      <div
        onMouseLeave={handleCloseNav}
        className={cx(Styles.NavbarContainer, navOpenStyle)}
      >
        <div className={cx(Styles.BackgroundWhite, navOpenStyle)} />
        <div className={cx(Styles.LogoHeaderContainer, navOpenStyle)}>
          <div onMouseEnter={handleOpenNav}>
            <Link to="/" className={Styles.TitleLink}>
              <Text className={Styles.Title} type="h2">
                Internet et Libertés
              </Text>
            </Link>
            {!isNavOpen && (
              <Text className={Styles.Location} type="h3">
                {currentLocation}
              </Text>
            )}
          </div>
          {isNavOpen && (
            <div className={cx(Styles.ChapterTitle, navOpenStyle)}>
              <>
                <Arrow
                  className={cx(
                    Styles.ArrowLeft,
                    isIntroduction && Styles.IsDisplayNone
                  )}
                  onClick={onClickBeforeArrow}
                />

                <div className={Styles.TextTitleContainer}>
                  <Text type="h1" className={Styles.ChapterIndex}>
                    {isChapter ? currentLocation : currentChapter?.chapter}
                  </Text>
                  {isChapter && (
                    <div className={Styles.ChapterName}>
                      <Text type="h3" className={Styles.ChapterNameText}>
                        {currentChapter?.chapter}
                      </Text>
                      <Text type="subtitle">
                        {currentLocation} - {currentChapter?.part}
                      </Text>
                    </div>
                  )}
                </div>
                <div className={Styles.PhantomArrow} />
              </>
            </div>
          )}
          <Arrow
            className={cx(
              Styles.ArrowRight,
              navOpenStyle,
              isConclusion && Styles.IsDisplayNone
            )}
            onMouseEnter={handleOpenNav}
            onClick={onClickNextArrow}
          />
        </div>
        {isNavOpen && <IndexNavbar />}
        <div className={Styles.Hitbox} />
        <div
          className={cx(Styles.LinearGradient, navOpenStyle)}
          onMouseEnter={handleCloseNav}
        />
      </div>
    </>
  );
};

export default NavbarBigScreens;
