import React, { useState } from "react";

import Image from "src/assets/c2p7.png";
import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter2Part7 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>();

  return (
    <PagePattern partName="La campagne anti-GAFAM">
      <>
        <Text>
          Au début de l’année 2018, un bruissement. Des sujets commencent à
          apparaître dans les médias à propos d’une mesure qui risque de
          bouleverser les activités touchant de près ou de loin à la
          conservation des données personnelles. «&#160;Les délais sont trop
          courts&#160;», se plaignent les PME, pendant que les entreprises
          spécialisées dans la captation de données continuent comme si de rien
          n’était, persuadées d’être «&#160;conformes&#160;». Il n’est pire
          aveugle que celui qui ne veut pas voir&#160;: le 25&#160;mai entre en
          application un texte qui a été adopté deux&#160;ans plus tôt&#160;: le
          RGPD. Malgré les déclarations paniquées, les cabinets d’avocats ou
          autres responsables juridiques ont eu vingt-quatre&#160;mois pour
          accorder le fonctionnement de leur entreprise avec la loi.
        </Text>
        <Text>
          Depuis des mois, les GAFAM proposent des campagnes de communication
          dans lesquelles elles se targuent de protéger les données de leurs
          utilisateurs… Il va falloir remettre de l’ordre dans tout ça, car
          depuis ce 25&#160;mai une entreprise ne peut plus justifier de nous
          surveiller au motif que nous y aurions «&#160;consenti&#160;» en
          oubliant de décocher une case obscure rangée en fin de formulaire ou
          derrière divers menus. C’est la victoire du «&#160;consentement
          explicite&#160;», que continuent de bafouer Google, Apple ou Amazon.
        </Text>
        <Text>
          Fin décembre&#160;2017, une idée avait germé parmi l’équipe salariée
          de la Quadrature. Et si on rappelait à tout le monde que les GAFAM ne
          sont pas <i>seulement</i> de grosses industries hégémoniques de la
          tech, mais qu’elles ont aussi et surtout un impact foncièrement
          négatif sur l’ensemble de la société, entre autres en captant
          illégalement des données de tous leurs utilisateurs (et des
          autres)&#160;?
        </Text>
        <Text>
          Une communication très visuelle est mise au point, formée de cinq
          affiches imprimées sur du papier coloré, une par entreprise visée,
          rappelant pour chacune d’entre elles une action contestable&#160;:
        </Text>
        <ul>
          <li>
            <Text>
              <i>Google filtre ta pensée</i>&#160;: Google filtre les résultats
              de recherche et les recommandations YouTube pour analyser tes
              réactions et t’enfermer dans sa bulle.
            </Text>
          </li>
          <li>
            <Text>
              <i>Apple sait où est ta mère</i>&#160;: Si tu as un smartphone
              sous iOS ou Android, il permet à Apple, Google et leurs apps
              d’enregistrer ta position, sans toujours te le dire ou te laisser
              le choix.
            </Text>
          </li>
          <li>
            <Text>
              <i>Facebook contrôle ce que tu peux lire</i>&#160;: Facebook trie
              les contenus de son fil d’actualité pour analyser tes réactions et
              t’enfermer dans sa bulle.
            </Text>
          </li>
          <li>
            <Text>
              <i>Amazon sait quels cadeaux tu auras</i>&#160;: Les comportements
              qui te semblent les plus anodins, analysés en masse, révèlent
              précisément ta personnalité, tes attentes et celles de ton
              entourage.
            </Text>
          </li>
          <li>
            <Text>
              <i>Microsoft formate tes enfants</i>&#160;: En 2015, Microsoft a
              payé 13&#160;millions d’euros à l’Éducation nationale pour pouvoir
              fournir ses outils aux élèves et aux enseignants et les former.
            </Text>
          </li>
        </ul>
        <img
          style={{ width: "100%", height: "auto" }}
          src={Image}
          alt={`Illustration avec "Google filtre ta pensée", "Apple sait où est ta mère", "Facebook contrôle ce que tu peux lire", "Amazon sait quels cadeaux tu auras", "Microsoft formate tes enfants"`}
        />
        <Text>
          Sur le site de campagne, ces textes peuvent être modifiés permettant
          aux utilisateurs de générer leurs propres affiches.
        </Text>
        <Text>
          Proposée au débotté en fin de soirée du Quadr’apéro qui a lieu
          trois&#160;jours plus tard, la campagne entraîne finalement plus d’une
          quinzaine de personnes dans les rues froides de Paris avec sous le
          bras des affiches et des seaux de colle faite avec de la farine, de
          l’eau et du sucre. La campagne GAFAM démarre le soir du vendredi
          22&#160;décembre 2017
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://twitter.com/laquadrature/status/944148790684069888"
                >
                  https://twitter.com/laquadrature/status/944148790684069888
                </AppLink>
              )
            }
          >
            139
          </InfoClick>
          .
        </Text>
        <Text>
          Cinq&#160;jours plus tard, le 27, l’association se rend comme chaque
          année en Allemagne, à Leipzig, à l’occasion d’un congrès de hackers,
          le 34C3, le 34<sup>e</sup>&#160;Chaos Communication Congress
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Toutes les conférences sont visibles sur </Text>
                  <AppLink white href="https://media.ccc.de/c/34c3">
                    https://media.ccc.de/c/34c3
                  </AppLink>
                </>
              )
            }
          >
            140
          </InfoClick>{" "}
          (CCC, ou C3), organisé par le Chaos Computer Club
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://www.ccc.de/en/">
                  https://www.ccc.de/en/
                </AppLink>
              )
            }
          >
            141
          </InfoClick>{" "}
          (CCC), le principal club de hackers en Allemagne. Les affiches sont
          accrochées dans le centre des congrès, sur la Tea House, l’espace géré
          par la Quadrature où il fait bon débattre autour d’un thé, mais aussi
          dans les couloirs, sur les portes
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://photos.cloudfrancois.fr/201712-34c3-leipzig/"
                >
                  https://photos.cloudfrancois.fr/201712-34c3-leipzig/
                </AppLink>
              )
            }
          >
            142
          </InfoClick>
          .
        </Text>
        <Text>
          C’est pendant cet événement que nous décidons, bénévoles et salariés
          de la Quadrature, d’utiliser un nouvel outil rendu possible par le
          RGPD&#160;: lancer une action de groupe pour toucher le plus de monde
          possible. Autour de ces affiches, qui fonctionnent très bien, se
          développe toute une campagne participative et communicative, joyeuse
          mais utile. C’est l’occasion de renouer avec les bénévoles et les
          sympathisants, qui vont pouvoir donner leur voix, leur temps, et
          diffuser le message à l’aide de cette communication simple et
          efficace.
        </Text>
        <Text>
          Le 11&#160;janvier 2018, des bénévoles déposent en Allemagne, de leur
          propre initiative, le nom de domaine gafam.info, et démarrent un outil
          de création automatisée des affiches de campagne. C’est en pas moins
          de vingt-quatre langues que les affiches originales seront rendues
          disponibles.
        </Text>
        <Text>
          Début avril&#160;2018, la campagne de «&#160;recrutement&#160;»
          démarre via le site de campagne{" "}
          <a href="http://gafam.laquadrature.net">gafam.laquadrature.net</a>,
          avec pour objectif d’obtenir le plus de mandats possible
          d’utilisateurs des services des GAFAM qui auraient donc et par
          définition subi leurs pratiques illégales. Chaque semaine, un GAFAM
          est présenté et on explique ce qui lui est reproché. Cette campagne
          est un succès&#160;: plus de 12&#160;000&#160;personnes complètent le
          formulaire et nous donnent mandat pour nous permettre d’attaquer en
          leur nom un ou plusieurs services auprès de la CNIL. C’est à ce jour
          la plus importante plainte collective déposée devant une APD en
          Europe.
        </Text>
        <Text>
          Nous avions par ailleurs préparé le terrain avec la CNIL, en
          expliquant notre démarche, en nous mettant d’accord avec elle sur la
          meilleure façon de rédiger les plaintes et le niveau d’information
          nécessaire sur les mandants (étant entendu que nous ne leur
          demanderions pas de preuve de leur identité). Une trame de plainte est
          ensuite rédigée sur le principe du non-respect du consentement libre,
          finalement équivalent chez les cinq entreprises. Il est nécessaire
          ensuite de compléter cette trame pour chacune d’entre elles. Suit
          ainsi un appel à participation à travers les listes de diffusion et
          sur les réseaux sociaux, le 21&#160;mai
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2018/05/21/plaintegafam/"
                >
                  https://www.laquadrature.net/2018/05/21/plaintegafam/
                </AppLink>
              )
            }
          >
            143
          </InfoClick>
          . Ce sont des dizaines de personnes qui se retrouvent sur des pads
          pour compléter les cinq textes qui seront présentés à la CNIL.
        </Text>
        <Text>
          Nous déposons les plaintes le 28&#160;mai, trois&#160;jours à peine
          après l’entrée en vigueur du RGPD sur lequel s’appuient nos demandes.
          Probablement l’une de ses utilisations les plus rapides. En visant les
          GAFAM, nous nous attaquons aux plus grosses entreprises du numérique…
          Mais que leur reprochions-nous –&#160;que leur reprochons-nous
          toujours d’ailleurs&#160;?
        </Text>

        <Text type="h3">Les&#160;GAFAM, qui&#160;sont-ils&#160;?</Text>
        <Text>
          Fondée il y a vingt&#160;ans par Larry Page et Sergueï Brin, Google
          est aujourd’hui une filiale d’Alphabet, la maison mère d’un groupe
          implanté partout dans le monde. Ses services se sont multipliés&#160;:
          gestion des e-mails avec Gmail, du calendrier avec Agenda, stockage et
          édition de documents avec Drive et GSuite, navigateur Internet avec
          Chrome, régie publicitaire avec Network qui s’appuie sur les outils de
          développement Analytics et Ads, mais aussi plateforme pour smartphones
          avec Android, publication de vidéos avec YouTube, etc. En 2021, le
          groupe réalise 260&#160;milliards de dollars de chiffre d’affaires, à
          plus de 80&#160;% issus de la publicité en ligne.
        </Text>
        <Text>
          Fondée en 1976, notamment par Steve Jobs et bien avant l’avènement
          d’Internet, l’entreprise Apple se concentre sur la vente de ses
          propres ordinateurs, équipés de systèmes d’exploitation qu’elle
          développe elle-même. En 1984, elle annonce le lancement de son
          Macintosh au moyen d’une publicité vidéo réalisée par Ridley Scott,
          intitulée «&#160;1984&#160;» et posant l’entreprise en rempart contre
          une future société de surveillance
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <AppLink
                    white
                    href="https://www.youtube.com/watch?v=VtvjbmoDx-I"
                  >
                    https://www.youtube.com/watch?v=VtvjbmoDx-I
                  </AppLink>
                  <br />
                  <AppLink white href="https://startuffenation.fail/">
                    https://startuffenation.fail/
                  </AppLink>
                </>
              )
            }
          >
            144
          </InfoClick>
          . Tout comme le slogan interne de Google, «&#160;<i>Don’t be evil</i>
          &#160;» («&#160;Ne soyez pas malveillants&#160;»), la posture prise
          par Apple n’est finalement qu’une sinistre anti-prophétie&#160;:
          l’entreprise jouera bien un rôle décisif dans la transformation des
          outils numériques en moyens d’enfermement et de contrôle. Aujourd’hui,
          environ un smartphone sur cinq vendu dans le monde est un iPhone et le
          chiffre d’affaires annuel de l’entreprise (350&#160;milliards d’euros)
          est supérieur au budget annuel de l’État français.
        </Text>
        <Text>
          Facebook a été créé en 2004, notamment par Mark Zuckerberg, son actuel
          directeur général. Son chiffre d’affaires de 118&#160;milliards de
          dollars en 2021 repose à 97&#160;% sur la publicité, affichée à
          2,9&#160;milliards d’utilisateurs actifs mensuels sur la plateforme.
          L’entreprise détient également WhatsApp et Messenger (services de
          messagerie), ainsi qu’Instagram (réseau de partage de photos et de
          vidéos), qui lui apportent 3,6&#160;milliards d’utilisateurs actifs
          mensuels. Un conglomérat regroupant toutes ces entités a été créé fin
          2021&#160;: Meta, l’équivalent de l’Alphabet de Google. L’entreprise
          explique sans pudeur son fonctionnement&#160;: des personnes qui
          souhaitent diffuser un message (une publicité, un article, un
          événement, etc.) désignent à Facebook un public cible, selon certains
          critères sociaux, économiques ou comportementaux, et paient
          l’entreprise pour qu’elle diffuse ce message à ce public, dans les
          meilleures conditions. Ce fonctionnement implique deux choses&#160;:
          connaître chaque utilisateur, puis afficher les messages devant être
          diffusés au bon moment et sous le bon format, pour influencer au mieux
          les personnes ciblées.
        </Text>
        <Text>
          Créée par Jeff Bezos en 1994, Amazon est une entreprise de commerce en
          ligne. Alors qu’elle vendait initialement des livres à distance, on
          trouve aujourd’hui sur sa plateforme MarketPlace toutes sortes de
          produits, du culturel à l’alimentaire. Elle a racheté au fil des
          années des entreprises dans des secteurs variés, pour développer ses
          propres gammes. Parmi ses services, citons Prime Video (plateforme de
          streaming), Kindle (liseuse), Amazon Web Services (AWS –&#160;services
          d’infrastructure informatique), Amazon Mechanical Turk (AMT&#160;–
          plateforme de micro-travail), Amazon Halo (bracelet connecté qui
          analyse l’activité physique et les émotions), Amazon Care
          (télémédecine et télépharmacie), Ring (caméras et sonnettes de portes
          connectées), Amazon Key (déverrouillage de porte à distance, qui
          préoccupe depuis des failles démontrées par des recherches en
          cybersécurité), Amazon Pay (paiements dématérialisés), Amazon Go
          (supermarchés), Amazon Studio (production vidéo), Alexa, Astro, IMDb,
          Twitch… En 2021, Amazon génère 470&#160;milliards de dollars de
          revenus, dont presque 10 sont issus de la publicité.
        </Text>
        <Text>
          Enfin, Microsoft Corporation est une multinationale informatique,
          fondée en 1975 par Bill Gates et Paul Allen. Elle développe des
          systèmes d’exploitation (MS DOS, puis Windows), des logiciels (MS
          Office, Azure), du matériel informatique (les consoles Xbox, la
          tablette Surface, le smartphone Lumia, la montre connectée Band, le
          casque VR HoloLens…) et des sites Internet comme le portail MSN ou le
          moteur de recherche Bing. Au cours de l’année 2020-2021, Microsoft
          génère 168&#160;milliards de dollars de revenus, dont
          10&#160;milliards viennent de LinkedIn, le réseau social aux
          774&#160;millions de comptes utilisateurs, racheté en 2016. C’est
          celui-ci qui nous intéresse ici. La publicité représente 10&#160;% des
          revenus du réseau social et affiche une croissance annuelle de
          97&#160;%.
        </Text>
        <Text>
          Ces chiffres vous font tourner la tête&#160;? Nous aussi. D’autant
          plus quand on sait que ces entreprises dépensent chaque année (au
          moins) 4&#160;millions d’euros cumulés pour leurs activités de
          lobbying, rien qu’en France
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    D’après leurs déclarations auprès de la Haute Autorité pour
                    la transparence de la vie publique.
                  </Text>
                  <AppLink white href="https://www.hatvp.fr/">
                    https://www.hatvp.fr/
                  </AppLink>
                </>
              )
            }
          >
            145
          </InfoClick>
          . Treize&#160;fois le budget annuel de la Quadrature&#160;!
        </Text>

        <Text type="h3">
          La&#160;collecte des&#160;données sans consentement libre
        </Text>
        <Text>
          Les GAFAM obligent leurs utilisateurs à accepter des
          «&#160;règles&#160;» ou «&#160;engagements&#160;» de confidentialité
          pour utiliser leurs services. Celles-ci prévoient que les entreprises
          peuvent collecter un certain nombre de données, dont&#160;:
        </Text>
        <ul>
          <li>
            <Text>
              les classiques –&#160;nom, photo, adresse e-mail et numéro de
              téléphone des utilisateurs&#160;;
            </Text>
          </li>
          <li>
            <Text>
              des informations sur l’utilisation des services&#160;: contenus
              consultés et interactions avec ceux-ci, historique de navigation,
              requêtes de recherches, contenus des conversations (Messenger,
              Gmail ou encore Instagram)&#160;;
            </Text>
          </li>
          <li>
            <Text>
              des «&#160;métadonnées&#160;» (des données à propos des données)
              dont les informations des personnes contactées, la durée des
              appels, la localisation des appareils (définie à partir de
              l’adresse IP, de signaux GPS, des points d’accès wifi et des
              antennes-relais téléphoniques à proximité…), les cookies présents
              sur l’appareil…&#160;;
            </Text>
          </li>
          <li>
            <Text>
              mais aussi parfois le flux des caméras ou des micros qu’embarquent
              les smartphones&#160;!
            </Text>
          </li>
        </ul>
        <Text>
          Notons qu’Apple fait référence à des données «&#160;non
          personnelles&#160;» au motif qu’elles seraient traitées indépendamment
          de l’adresse IP, ce qui révèle que la définition des «&#160;données
          personnelles&#160;» retenue par l’entreprise est bien différente de
          celle du droit européen. En droit européen, une information est une
          donnée personnelle dès lors qu’elle peut être associée à une personne
          unique, peu importe que l’identité de cette personne soit connue ou
          non. Or, l’identifiant unique de l’appareil, ou, dans bien des cas,
          les recherches effectuées ou la localisation sont bien associables à
          une personne unique.
        </Text>
        <Text>
          Chacun des GAFAM explique qu’il utilise toutes ces données pour
          «&#160;améliorer le service&#160;» (ou le personnaliser)&#160;: mieux
          cibler ses utilisateurs, afin de leur proposer les publicités adaptées
          au bon moment. L’analyse en masse d’informations en apparence anodines
          permet d’établir des corrélations censées cerner en détail l’intimité
          de chaque personne. L’accès aux services implique l’obligation de
          céder nos informations personnelles à ces fins. Or, le RGPD prévoit
          que notre consentement n’est pas valide (car non libre) «&#160;si
          l’exécution d’un contrat, y compris la fourniture d’un service, est
          subordonnée au consentement au traitement de données à caractère
          personnel qui n’est pas nécessaire à l’exécution dudit contrat
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Article&#160;7, §4, et considérant 43 du RGPD, interprétés par
                  le groupe de l’article&#160;29.
                </Text>
              )
            }
          >
            146
          </InfoClick>
          &#160;»
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  En 2017, la CNIL a d’ailleurs condamné Facebook à 150 000
                  euros d’amende pour avoir réalisé ses traitements sans base
                  légale. Elle considérait alors que « l’objet principal du
                  service [était] la fourniture d’un réseau social [...], que la
                  combinaison des données des utilisateurs à des fins de ciblage
                  publicitaire ne correspond[ait] ni à l’objet principal du
                  contrat ni aux attentes raisonnables des utilisateurs ».
                </Text>
              )
            }
          >
            147
          </InfoClick>
          .
        </Text>
        <Text>
          La collecte et l’analyse de nos données par les GAFAM sont illicites
          sur la base du RGPD. C’est ce que nous attaquons.
        </Text>
        <Text>
          Pour faire bonne figure, la plupart des GAFAM donnent l’illusion que
          nous pouvons limiter l’interconnexion de certains types de données
          brutes, mais ce prétendu «&#160;contrôle&#160;» est tout à fait
          factice. Qui a déjà activé les options limitant la collecte de données
          personnelles par Google&#160;? Qui a déjà désactivé l’identifiant
          unique de son appareil Apple en lui donnant la valeur «&#160;0&#160;»
          (et qui sait comment le faire&#160;?)&#160;? Le consentement est
          dérobé. Sans compter qu’il ne laisse aucun choix sur tous les autres
          types de données, et surtout qu’il ne bloque pas l’analyse faite sur
          les données dérivées (nos profils), qui sont pourtant les plus
          sensibles.
        </Text>
        <Text>
          Heureusement, le RGPD a parfaitement anticipé cette tentative de
          contourner notre volonté. Il prévoit précisément que, pour être
          «&#160;valide&#160;», notre consentement doit être explicite, par un
          acte positif dont le seul but est d’accepter l’accès aux
          données&#160;: «&#160;Il ne saurait dès lors y avoir de consentement
          en cas de silence, de cases cochées par défaut ou d’inactivité&#160;»
          (considérant 32). Or, les mesures de surveillance sur lesquelles les
          GAFAM feignent de nous laisser la main sont «&#160;acceptées&#160;»
          par défaut au moyen de cases pré-cochées ou pré-installées sur nos
          appareils. Elles sont donc totalement illicites au regard du RGPD.
          Nous l’attaquons aussi.
        </Text>
        <Text>
          De la même manière, la directive ePrivacy exige le consentement de
          l’utilisateur pour accéder aux informations contenues sur sa machine.
          Si l’utilisateur refuse l’installation de certains logiciels ou la
          génération d’un identifiant unique lié à sa machine, il devrait
          pouvoir terminer l’installation et utiliser librement l’appareil.
          C’est ce que nous exigeons collectivement devant la CNIL.
        </Text>

        <Text type="h3">
          Les&#160;méga-cookies Google et&#160;Facebook&#160;: pister,
          influencer
        </Text>
        <Text>
          Google Network est un entremetteur publicitaire, qui met en relation
          des annonceurs avec «&#160;plus de deux millions&#160;» de sites ou de
          blogs tiers qui souhaitent se rémunérer par la publicité. Sur chacun
          de ces sites, c’est Google qui affiche techniquement chaque publicité,
          qui lui permet de déposer les cookies et autres pisteurs grâce
          auxquels il peut retracer la navigation de tout internaute, inscrit ou
          non à ses services. Ici encore, nous n’avons jamais consenti de façon
          active à ce pistage.
        </Text>
        <Text>
          De la même façon, Google nous piste sur les innombrables sites qui ont
          recours au service Google Analytics, ce service qui aide les sites à
          analyser l’identité de leurs visiteurs, tout en laissant Google
          accéder aux mêmes informations. Une simple analyse de trafic sur{" "}
          <a href="http://lemonde.fr">lemonde.fr</a>,{" "}
          <a href="http://lefigaro.fr">lefigaro.fr</a>,{" "}
          <a href="http://hadopi.fr">hadopi.fr</a> ou defense.gouv.fr permet par
          exemple de constater l’envoi de requêtes à{" "}
          <a href="http://doubleclick.net">doubleclick.net</a> (la régie
          publicitaire de Google) et/ou à{" "}
          <a href="http://google-analytics.com">google-analytics.com</a>. Les
          responsables de ces sites sont tout aussi responsables que Google de
          ce pistage illégal, par lequel le géant publicitaire peut précisément
          nous ficher.
        </Text>
        <Text>
          Enfin, Google compte bien étendre sa présence sur l’ensemble du Web en
          régulant lui-même la publicité&#160;: en configurant son navigateur
          Google Chrome (utilisé par 60&#160;% des internautes) pour qu’il
          bloque partout sur le Web les publicités qui ne correspondent pas aux
          critères (de format, d’ergonomie…) décidés par l’entreprise. Le
          message est ainsi clair&#160;: «&#160;Si vous voulez faire de la
          publicité en ligne, utilisez les services Google, vous vous éviterez
          bien des soucis&#160;!&#160;»
        </Text>
        <Text>
          D’une pierre trois coups, Google passe pour un défenseur de la vie
          privée (puisqu’il bloque certaines publicités intrusives), invite les
          internautes à désactiver les bloqueurs tiers (tel que uBlock Origin
          qui neutralise efficacement nombre de traceurs dont ceux de Google)
          puisque Google Chrome en intègre un par défaut, et incite enfin encore
          plus d’éditeurs de pages Web à afficher <i>ses</i> publicités, donc à
          intégrer <i>ses</i> traceurs, partout, tout le temps.
        </Text>
        <Text>
          Quant à Facebook, le groupe ne cache plus son activité de traçage un
          peu partout sur le Web, même s’agissant de personnes n’ayant pas de
          compte Facebook (qui se voient alors créer un «&#160;profil fantôme
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Les personnes qui ne s’inscrivent pas sur la plateforme ont
                  quand même un profil sur celle-ci, créé par l’entreprise pour
                  les représenter dans le « graphe social » des inscrits, et
                  permettre au réseau social d’être le plus « complet »
                  possible.
                </Text>
              )
            }
          >
            148
          </InfoClick>
          &#160;»). Les méthodes de pistage sont nombreuses&#160;: cookies,
          boutons «&#160;J’aime&#160;» ou «&#160;Partager&#160;» affichés sur de
          nombreux sites (qui remontent automatiquement à Facebook les adresses
          IP et les informations sur la configuration de l’utilisateur avec
          l’URL de la page visitée), pixels invisibles (images transparentes de
          1x1 pixel fonctionnant comme les boutons et transmettant à Facebook
          les informations de connexion), Facebook Login (utilisé par certains
          sites ou applications comme outil pour authentifier leurs
          utilisateurs).
        </Text>
        <Text>
          Les personnes ciblées sont rarement ou ne sont jamais informées et
          leur consentement n’est pas obtenu. Facebook se dédouane de cette
          responsabilité en la faisant peser sur les sites et les applications
          ayant intégré ses traceurs. Bien que ces derniers soient responsables
          juridiquement, Facebook l’est tout autant, l’entreprise étant
          régulièrement condamnée (par la CNIL française, espagnole et
          irlandaise ou encore la justice belge) pour ce pistage illicite, sans
          pour autant y mettre un terme
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Dernière condamnation en date&#160;: en septembre&#160;2021,
                  la CNIL irlandaise a condamné Facebook à 225&#160;millions
                  d’euros d’amende pour violation des données personnelles par
                  WhatsApp.
                </Text>
              )
            }
          >
            149
          </InfoClick>
          .
        </Text>
        <Text>
          Et sur mobile&#160;? Google mène le développement d’Android, le
          système d’exploitation pour smartphones. L’entreprise offre par
          ailleurs des outils pour aider les développeurs à créer des
          applications mobiles pour Android, permettant par exemple de
          facilement connaître son nombre d’utilisateurs. Facebook fournit
          également une série d’outils aux développeurs d’applications. Dès
          qu’une application veut se connecter à Facebook, pour une raison ou
          une autre, un certain nombre de données personnelles sont transmises,
          souvent sans lien direct avec le but initial de l’application et, trop
          souvent, sans que l’utilisateur n’en soit même informé.
        </Text>
        <Text>
          L’association Exodus Privacy a mis en évidence l’omniprésence de ces
          traqueurs. Le travail de ses infatigables bénévoles a relevé la
          présence de pisteurs (AdMob, Analytics, ou DoubleClick pour Google,
          Ads, Analytics ou encore Login pour Facebook) dans plus de
          trois&#160;mille&#160;applications analysées, dont Deezer, Spotify,
          Uber, Tinder, Twitter, <i>Le Figaro</i>, <i>L’Équipe</i>, Crédit
          agricole, Boursorama ou Angry Birds. Ainsi, nous avons pu observer que
          l’application de suivi de grossesse Pregnancy + récolte les
          informations privées de l’enfant à naître (afin d’accompagner les
          parents dans la naissance) et les transmet à Facebook (semaine de
          grossesse et mois de naissance attendu). Grâce à Pregnancy +, votre
          enfant a déjà son compte Facebook avant même d’être né&#160;!
        </Text>
        <Text>
          Autre exemple flagrant&#160;: en analysant les données émises par
          l’application Diabetes&#160;: M, on constate qu’elle envoie à Facebook
          l’«&#160;advertising ID&#160;» de l’utilisateur, donnant ainsi à
          l’entreprise une liste de personnes atteintes de diabète. Sur son
          site, l’application se contente d’expliquer travailler avec des
          réseaux publicitaires, sans autre détail…
        </Text>
        <Text>
          Sous couvert de mettre à disposition des outils pour faciliter la vie
          des développeurs, comme les divers <i>analytics</i>, les outils de
          surveillance de Google, Facebook et Apple sont intégrés à des milliers
          d’applis. Ces services –&#160;qui ne sont ni plus ni moins qu’un droit
          d’accès à un fragment de la surveillance de Google ou Facebook&#160;–
          sont gracieusement offerts aux entreprises tierces à l’origine de la
          pléthore d’applications qui rendent ces plateformes si populaires,
          afin d’attirer le plus grand nombre d’applications et donc
          d’utilisateurs sur leurs plateformes.
        </Text>
        <Text>
          Côté Apple, ces entreprises tierces sont d’autant plus incitées à
          venir sur l’App Store depuis qu’Apple les empêche de recourir aux
          juteux «&#160;cookies tiers&#160;» sur le Web –&#160;le navigateur
          Safari les bloquant par défaut. La protection offerte par Safari
          apparaît dès lors sous des traits biens cyniques&#160;: viser l’App
          Store permet de recourir à bien plus de techniques de pistage.
        </Text>
        <Text>
          Côté Google, en plus du système Android, l’entreprise impose aux
          constructeurs de smartphones partenaires d’embarquer sur leurs
          produits les fameux mouchards que sont ses applications. Et si Android
          est sous licence libre, il dépend très lourdement de composants et
          services propres à Google pour être en mesure d’offrir l’expérience
          qu’en attendent ses utilisateurs. Ces services étendent les
          possibilités du système et deviennent souvent indispensables pour
          faire pleinement fonctionner un grand nombre d’applications,
          permettant à l’entreprise de traquer les utilisateurs de
          smartphones&#160;: la géolocalisation continue permet de connaître les
          habitudes de déplacement&#160;; la liste des réseaux wifi est envoyée
          à Google quand bien même l’utilisateur a désactivé le wifi de son
          téléphone&#160;; l’utilisation du Play Store (magasin d’applications)
          impose de connecter son appareil à son compte Google, permettant le
          recoupage des données.
        </Text>
        <Text>
          Enfin, sur Android comme sur iOS, Google et Apple associent à chaque
          appareil un identifiant unique à des fins publicitaires, librement
          accessible par chaque application installée. Cet identifiant, encore
          plus efficace qu’un simple «&#160;cookie&#160;», permet
          d’individualiser chaque utilisateur et, ainsi, de retracer
          parfaitement ses activités sur l’ensemble de ses applications. Un
          outil décisif pour nous soumettre la bonne publicité au bon moment.
        </Text>

        <Text type="h3">Les&#160;multiples dangers du&#160;profilage</Text>
        <Text>
          La majorité des données analysées ne sont pas celles que l’on publie
          spontanément, mais celles qui ressortent de nos activités et qui sont
          primordiales aux GAFAM pour nous «&#160;cibler&#160;»&#160;:
          l’ensemble des caractéristiques sociales, économiques et
          comportementales associées à chaque utilisateur.
        </Text>
        <Text>
          En 2013, l’université de Cambridge a conduit l’étude suivante&#160;:
          58&#160;000&#160;personnes ont répondu à un test de personnalité, puis
          ce test a été recoupé à tous leurs «&#160;j’aime&#160;» laissés sur
          Facebook. En repartant de leurs seuls «&#160;j’aime&#160;»,
          l’université a ensuite pu estimer leur couleur de peau (avec 95&#160;%
          de justesse), leur orientation politique (85&#160;%) et leurs
          préférences sexuelles (88&#160;%), leur confession religieuse
          (82&#160;%), s’ils fumaient (73&#160;%), buvaient (70&#160;%) ou
          consommaient de la drogue (65&#160;%)
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.pnas.org/doi/pdf/10.1073/pnas.1218772110"
                >
                  https://www.pnas.org/doi/pdf/10.1073/pnas.1218772110
                </AppLink>
              )
            }
          >
            150
          </InfoClick>
          .
        </Text>
        <Text>
          Cette démonstration a permis de mettre en lumière le fonctionnement
          profond de l’analyse de masse&#160;: quand beaucoup d’informations
          peuvent être recoupées sur un très grand nombre de personnes (plus de
          2&#160;milliards pour Facebook, rappelons-le), de très nombreuses
          corrélations apparaissent, donnant l’espoir, fondé ou non, de révéler
          le détail de la personnalité de chaque individu.
        </Text>
        <Text>
          Aujourd’hui, Michal Kosinski, le chercheur ayant dirigé cette étude,
          continue de dénoncer les dangers de l’analyse de masse
          automatisée&#160;: il explique qu’à cause de cela de simples photos
          pourraient révéler l’orientation sexuelle d’une personne, ses opinions
          politiques, son QI ou ses prédispositions criminelles. Qu’importe la
          pertinence des corrélations résultant de telles analyses, c’est bien
          cette méthode qui a été à la source du fonctionnement de Cambridge
          Analytica, dont les conséquences politiques sont, elles, bien
          certaines.
        </Text>
        <Text>
          Une fois que l’entreprise s’est fait une idée si précise de qui nous
          sommes, de nos envies, de nos craintes, de notre mode de vie et de nos
          faiblesses, la voie est libre pour nous proposer ses messages au bon
          moment et sous le bon format, quand ils sont les plus à même
          d’influencer notre volonté.
        </Text>
        <Text>
          Le groupe Facebook s’est lui-même vanté de l’ampleur de cette emprise.
          En 2012, il a soumis 700&#160;000&#160;utilisateurs à une expérience,
          sans leur demander leur consentement ni les en informer. Pendant une
          semaine, la plateforme a manipulé leur fil d’actualité en cachant
          certains éléments, dans le but d’influencer leur humeur (rendre
          certaines personnes plus joyeuses, d’autres plus tristes). L’étude a
          conclu que «&#160;les utilisateurs ciblés commençaient à utiliser
          davantage de mots négatifs ou positifs selon la nature des contenus
          auxquels ils avaient été “exposés”&#160;». Cette expérience n’a fait
          que révéler le fonctionnement normal des réseaux sociaux&#160;: afin
          de nous influencer, ils hiérarchisent les informations que nous
          pouvons consulter sur ces services.
        </Text>
        <Text>
          Prenons l’exemple d’un autre réseau social. La plus grosse plateforme
          vidéo d’Internet (et le deuxième site le plus visité au monde),
          YouTube, qui appartient à Google. Elle ne se contente pas d’héberger
          des vidéos&#160;: il s’agit d’un véritable réseau social de contenus
          multimédias, qui met en relation des individus et régule ces
          relations.
        </Text>
        <Text>
          En effet, lorsqu’une vidéo est visionnée sur YouTube, dans 70&#160;%
          des cas, l’utilisateur a été amené à cliquer sur cette vidéo via
          l’algorithme de recommandation du site
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.cnet.com/tech/services-and-software/youtube-ces-2018-neal-mohan/"
                >
                  https://www.cnet.com/tech/services-and-software/youtube-ces-2018-neal-mohan/
                </AppLink>
              )
            }
          >
            151
          </InfoClick>
          . Un ancien employé de YouTube, Guillaume Chaslot, expose les
          conséquences de cet algorithme, dont le but est de faire en sorte que
          l’utilisateur reste le plus longtemps possible sur la plateforme,
          devant les publicités
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Voir notamment son entretien dans le numéro&#160;5 de la revue
                  <i>Vraiment</i>, paru le 18&#160;avril 2018.
                </Text>
              )
            }
          >
            152
          </InfoClick>
          . L’employé raconte que lors de la mise en ligne d’une vidéo, celle-ci
          est d’abord montrée à un échantillon d’utilisateurs du site et n’est
          recommandée aux autres utilisateurs que si elle a retenu cet
          échantillon de spectateurs suffisamment longtemps devant l’écran.
        </Text>
        <Text>
          Cet algorithme ne se pose pas la question du contenu de la vidéo
          –&#160;de sa nature, de son message… En pratique, cependant, les
          vidéos les plus mises en avant se trouvent être les plus incendiaires,
          choquantes, diffamantes ou complotistes. Guillaume Chaslot
          compare&#160;: «&#160;C’est comme une bagarre dans la rue, les gens
          s’arrêtent pour regarder.&#160;» De fait, on&#160;comprend que de
          nombreux créateurs de vidéos, qui plus est ceux qui cherchent à en
          tirer profit, proposent des vidéos de plus en plus agressives.
        </Text>
        <Text>
          Dans le but de maximiser les vues, YouTube surveille donc les moindres
          faits et gestes des utilisateurs afin de les mettre dans les
          conditions les plus propices à recevoir de la publicité et de les
          exposer à cette publicité le plus longtemps possible… mais ce n’est
          pas tout&#160;! YouTube, désirant ne pas perdre une seconde de
          visionnage de ses utilisateurs, ne prend pas le risque de leur
          recommander des contenus trop différents et se complaît à les
          maintenir dans leur «&#160;zone de confort&#160;» –&#160;une pratique
          confirmée par Guillaume Chaslot. Dans ces conditions, le débat public
          est entièrement déformé, les discussions les plus subtiles ou
          précises, jugées peu rentables, s’exposant à une censure par
          enterrement.
        </Text>
        <Text>
          Cette hiérarchisation de l’information ne se contente pas d’écraser
          notre liberté de conscience personnelle&#160;: elle a aussi pour effet
          de distordre entièrement le débat public, selon des critères purement
          économiques et opaques&#160;– pour preuve, la surdiffusion de{" "}
          <i>fake news</i>, qui n’en est qu’un des nombreux symptômes.
        </Text>
        <Text>
          Dans son étude annuelle de 2017, le Conseil d’État lui-même mettait en
          garde contre la prétendue neutralité des algorithmes dans la mise en
          œuvre du tri&#160;: les algorithmes sont au service de la maximisation
          du profit des plateformes et sont dès lors conçus pour favoriser les
          revenus au détriment de la qualité de l’information.
        </Text>

        <Text type="h3">
          Les&#160;solutions libres à&#160;un&#160;modèle économique dépassé
        </Text>
        <Text>
          Le modèle économique fondé sur le ciblage publicitaire est en train
          d’être drastiquement remis en cause. Il n’est plus permis de rémunérer
          un service en contrepartie de libertés fondamentales. Facebook ne va
          pas forcément disparaître, mais ne pourra plus continuer à gagner de
          l’argent de la même façon.
        </Text>
        <Text>
          Il existe déjà de nombreuses solutions de remplacement aux services
          des GAFAM qui sont réellement gratuites (c’est-à-dire qui n’impliquent
          pas de «&#160;monnayer&#160;» nos libertés). Leur financement repose
          sur le modèle originel d’Internet&#160;: la décentralisation, qui
          permet la mutualisation des coûts en stockage, en calcul et en bande
          passante.
        </Text>
        <Text>
          Par exemple, La Quadrature du Net fournit à plus de
          9&#160;000&#160;personnes l’accès au réseau social Mastodon, une sorte
          de Twitter libre et décentralisé, sur{" "}
          <a href="http://Mamot.fr">Mamot.fr</a>, qui n’est qu’un des milliers
          de nœuds du réseau social, chaque nœud étant interconnecté avec les
          autres. Cela permet de répartir les coûts entre de très nombreux
          acteurs qui peuvent ainsi plus facilement les supporter, sans avoir à
          se financer par la pub (donc par la surveillance de masse) mais en
          opérant plutôt de façon transparente auprès de leurs utilisateurs.
        </Text>
        <Text>
          De la même manière, contrairement à ce que Google (ou TikTok) tente de
          nous faire croire, la surveillance et la censure ne sont pas les
          conditions inévitables du partage de vidéos en ligne. On peut
          parfaitement le faire en respectant nos droits. PeerTube par exemple
          est une plateforme de partage de vidéos similaire à YouTube mais avec
          une approche fondamentalement différente&#160;: les vidéos ne sont pas
          toutes hébergées au même endroit. N’importe qui peut créer son
          instance PeerTube et les héberger chez lui, qu’il soit un particulier
          ou une entreprise. Les différentes instances sont ensuite connectées
          entre elles. Chacune a ses propres règles, il n’y a pas de politique
          de censure unifiée comme sur YouTube, et surtout ces règles ne sont
          pas forcément dictées par une logique commerciale.
        </Text>
        <Text>
          Permettre l’essor de ces nouveaux réseaux est bien l’objectif final de
          nos actions, mais pour y parvenir il est nécessaire que chacun et
          chacune puisse se libérer de l’emprise des GAFAM. Ce faisant, nous
          pourrons construire l’internet de nos rêves&#160;: libre, émancipateur
          et décentralisé.
        </Text>

        <Text type="h3">Et&#160;les&#160;plaintes dans tout ça&#160;?</Text>
        <Text>
          Le 28&#160;mai 2018, la Quadrature dépose auprès de la CNIL les cinq
          plaintes collectives. La CNIL transfère immédiatement celle visant
          Amazon au Luxembourg, et celles établies contre Microsoft, Apple et
          Facebook en Irlande, où ces entreprises ont leurs sièges sociaux
          respectifs. Or la Data Protection Commission (DPC), équivalent
          irlandais de la CNIL, est de notoriété publique en sous-effectif et
          débordée. Installer leur siège en Irlande permet donc aux entreprises
          de faire traîner les procédures judiciaires à leur encontre depuis des
          années. À ce jour, près de quatre&#160;ans plus tard, nous sommes
          toujours sans nouvelles des plaintes contre Microsoft, Apple et
          Facebook.
        </Text>
        <Text>
          Pour ce qui est de Google, la CNIL commence par s’attaquer aux
          pratiques de l’entreprise concernant Android. Le 21&#160;janvier 2019,
          elle prononce une sanction contre Google qui fait un certain
          bruit&#160;: une amende à hauteur de 50&#160;millions d’euros,
          considérant que le ciblage publicitaire qu’il réalise sur son système
          d’exploitation Android n’est pas conforme au RGPD
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.cnil.fr/fr/la-formation-restreinte-de-la-cnil-prononce-une-sanction-de-50-millions-deuros-lencontre-de-la"
                >
                  https://www.cnil.fr/fr/la-formation-restreinte-de-la-cnil-prononce-une-sanction-de-50-millions-deuros-lencontre-de-la
                </AppLink>
              )
            }
          >
            153
          </InfoClick>
          . Cette sanction n’est qu’une toute première partie de la réponse à
          notre plainte, qui dénonçait surtout le ciblage publicitaire imposé
          sur YouTube, Gmail et Google Search en violation de notre
          consentement, à laquelle s’ajoutait une autre plainte déposée elle
          aussi devant la CNIL par nos amis de l’association autrichienne NOYB
          (l’organisation de Max Schrems) contre Android
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://noyb.eu/wp-content/uploads/2018/05/complaint-android.pdf"
                >
                  https://noyb.eu/wp-content/uploads/2018/05/complaint-android.pdf
                </AppLink>
              )
            }
          >
            154
          </InfoClick>
          .
        </Text>
        <Text>
          50&#160;millions d’euros&#160;: un montant «&#160;record&#160;» et
          néanmoins très faible en comparaison du chiffre d’affaires annuel de
          l’entreprise. La CNIL s’est limitée aux «&#160;traitements couverts
          par la politique de confidentialité présentée à l’utilisateur lors de
          la création de son compte à l’occasion de la configuration de son
          téléphone mobile sous Android&#160;» et a délégué le reste de notre
          plainte, au sujet de YouTube, Gmail et Google Search à l’APD
          irlandaise. En effet, Google avait, peu de temps après notre plainte,
          déménagé son siège en Irlande, pour bénéficier des longs délais de
          procédure. Or depuis, la DPC a constaté que, bien que l’entreprise ait
          un siège en Irlande, elle ne possède aucune autorité décisionnaire en
          Europe, celle-ci demeure aux États-Unis. En conséquence, elle s’estime
          non compétente et renvoie, en 2021, la plainte devant la CNIL
          française. En réaction, cette dernière a déposé une requête auprès du
          Comité européen pour la protection des données (CEPD) afin d’obtenir
          une décision contraignante quant à qui doit juger la plainte. Ce jeu
          de ping-pong dure depuis maintenant près de quatre ans, au cours
          desquels la plainte n’a même pas commencé à être examinée. Mais ce
          faisant, la CNIL se protège par anticipation, en demandant au CEPD de
          confirmer sa compétence pour juger l’affaire. C’est en effet un des
          arguments majeurs de la défense de Google, qui menace de faire
          retoquer une éventuelle sanction de la CNIL au prétexte de sa
          non-compétence, ce qui serait un désaveu politique énorme pour
          l’autorité française.
        </Text>
        <Text>
          Sur le continent européen, une autre autorité que la CNIL travaille en
          silence. Le 16&#160;juillet&#160;2021, l’autorité luxembourgeoise de
          protection des données personnelles, la Commission nationale pour la
          protection des données (CNPD), s’est prononcée sur notre plainte
          collective appuyée par 10&#160;000&#160;personnes contre Amazon.
        </Text>
        <Text>
          Après trois&#160;ans de silence absolu, c’est l’agence de presse
          Bloomberg, qui remarque, dans le <i>SEC filing</i> (déclaration
          fiscale américaine annuelle) d’Amazon, un trou de plusieurs centaines
          de millions d’euros. En creusant, les journalistes remontent la piste
          de la plainte de la Quadrature, et quelques mois plus tard, la CNIL
          confirmera qu’ils avaient vu juste
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  À ce jour, la Quadrature n’a toujours pas accès au texte de la
                  décision luxembourgeoise, que celle-ci maintient secrète. Un
                  recours est en cours auprès de la Commission d’accès aux
                  documents administratifs (CADA).
                </Text>
              )
            }
          >
            155
          </InfoClick>
          .
        </Text>
        <Text>
          La décision semble sans ambiguïté&#160;: le système de ciblage
          publicitaire imposé par Amazon est réalisé sans notre consentement
          libre, en violation du RGPD
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.bloomberg.com/news/articles/2021-07-30/amazon-given-record-888-million-eu-fine-for-data-privacy-breach"
                >
                  https://www.bloomberg.com/news/articles/2021-07-30/amazon-given-record-888-million-eu-fine-for-data-privacy-breach
                </AppLink>
              )
            }
          >
            156
          </InfoClick>
          . L’entreprise est condamnée à une amende de 746&#160;millions
          d’euros. Il s’agit du record européen en matière d’amendes prononcées
          contre une violation du RGPD. Cette sanction historique frappe au cœur
          du système de prédation des GAFAM. Elle rend encore plus flagrante la
          démission généralisée de l’autorité irlandaise de protection des
          données qui, en trois&#160;ans, n’a été capable de clore aucune des
          quatre autres plaintes que nous avions engagées contre Facebook,
          Apple, Microsoft et Google.
        </Text>
        <Text>
          La posture exemplaire de l’autorité luxembourgeoise contraste avec la
          situation de la CNIL en France qui, pendant longtemps, faisait, en
          Europe, figure de tête de file pour la protection des données. En
          2021, elle n’est plus que l’ombre d’elle-même, alors que nos plaintes
          collectives, initialement introduites devant elle, lui offraient
          l’occasion idéale d’être le fer de lance du RGPD contre les violations
          systémiques des données personnelles au cœur du modèle économique des
          GAFAM.
        </Text>
        <Text>
          L’autorité luxembourgeoise, dans sa décision, reconnaît qu’Amazon nous
          cible bien à des fins publicitaires sans base légale et contrevient
          donc au RGPD. Mieux&#160;: Amazon a six&#160;mois pour corriger,
          c’est-à-dire mettre fin au ciblage publicitaire ou obtenir notre
          consentement libre pour ce faire, sans quoi il devra payer une
          astreinte de 746&#160;000&#160;euros par jour de retard
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Malheureusement, l’omerta de la CNPD ne permet pas de savoir
                  si la mise en conformité a eu lieu, ni si les astreintes ont
                  été payées. La Quadrature travaille actuellement à obtenir les
                  informations sur ces décisions dont elle n’a pas été tenue au
                  courant, alors même qu’elle était à l’origine de la plainte.
                </Text>
              )
            }
          >
            157
          </InfoClick>
          . C’est exactement le genre de décision que nous attendions&#160;! Car
          avec des chiffres d’affaires colossaux rendus possibles par des
          violations permanentes du droit, ces entreprises peuvent se permettre
          de payer une amende de temps en temps, tout en continuant de générer
          des profits colossaux. L’astreinte est une manière efficace
          de&#160;maintenir une pression permanente sur les dirigeants et
          d’orienter leurs décisions dans le sens attendu.
        </Text>
        <Text>
          Nos combats collectifs pour forcer les GAFAM à respecter le RGPD sont
          un travail de longue haleine et nécessitent la collaboration de toutes
          les autorités compétentes. Bien que les choses bougent lentement,
          c’est sans doute un des domaines où il est raisonnable de faire preuve
          d’un certain optimisme.
        </Text>

        <Text type="h3">Un&#160;espoir&#160;: l’interopérabilité</Text>
        <Text>
          Nous poussions l’idée, dès 2018, qu’au lieu de combattre le mal par le
          mal en incitant les plateformes à la censure (sans même prendre en
          compte leur fonctionnement économique), il fallait contraindre
          celles-ci à être <i>interopérables</i> avec d’autres plateformes et à
          respecter la réglementation sur les données personnelles (qui
          freinerait leur influence néfaste sur les débats). Les géants du Web
          distordent nos échanges humains pour des raisons économiques en
          favorisant les propos anxiogènes, caricaturaux, violents ou payés… au
          détriment des autres. Il faut créer un statut juridique spécifique
          pour les encadrer, et leur imposer de renoncer à leur pouvoir de
          contrainte (et donc redevenir de simples hébergeurs) ou de devenir
          neutres. S’ils souhaitent conserver ce pouvoir, alors ils doivent
          devenir «&#160;interopérables&#160;» et implémenter un standard de
          communication ouvert, partagé avec d’autres services. Nous pourrons
          ainsi, sans avoir de compte chez Facebook, communiquer avec nos amis
          qui y sont inscrits.
        </Text>
        <Text>
          En somme, soit la plateforme s’interdit les profitables
          hiérarchisations des contenus et de la censure privée, soit elle
          devient interopérable et permet à d’autres plateformes d’interagir de
          manière complète avec ses utilisateurs.
        </Text>
        <Text>
          Mais n’oublions pas que ces entreprises sont principalement financées
          par la publicité. Ce «&#160;péché originel du Web&#160;», apparu en
          1994 avec une simple bannière statique, s’est très vite retrouvé au
          cœur même du financement de l’information et du modèle économique des
          entreprises de la «&#160;nouvelle économie&#160;» en ligne. Comme une
          normalité, comme s’il n’y avait pas d’autres solutions. La publicité
          des premiers jours, affichage statique et permanent, s’est heurtée à
          une vision néolibérale imposant une rentabilité toujours plus
          importante. Et ses promoteurs se sont vite aperçus qu’ils pourraient
          en tirer davantage de bénéfices si les messages promotionnels
          s’adressaient à des consommateurs potentiels triés&#160;: dès 2003, un
          brevet déposé par les équipes de Google prévoyait de «&#160;générer
          des informations utilisateurs à des fins de publicité ciblée&#160;».
          C’est le début du capitalisme de surveillance.
        </Text>
        <Text>
          Pour optimiser encore le processus de vente, l’idée est de
          personnaliser des techniques de manipulation permettant de modifier le
          comportement des individus qui lui sont soumis. Car non contents
          d’influencer nos comportements de plus en plus efficacement dans le
          seul dessein de nous afficher des réclames permettant de nous vendre
          des biens et services, Big Data et ses capacités supposément
          incroyables tentent des gouvernements peu démocratiques, comme la
          France&#160;: Gérald Darmanin posait ainsi la question, en
          mai&#160;2021&#160;: pourquoi l’État ne pourrait-il pas accéder à
          cette gigantesque quantité d’informations et à l’analyse algorithmique
          allant avec si les GAFAM, eux, y ont accès
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.arretsurimages.net/chroniques/clic-gauche/la-france-un-gafam-comme-les-autres-pour-darmanin"
                >
                  https://www.arretsurimages.net/chroniques/clic-gauche/la-france-un-gafam-comme-les-autres-pour-darmanin
                </AppLink>
              )
            }
          >
            158
          </InfoClick>
          &#160;? Peut-être parce que c’est illégal et que lesdits GAFAM se font
          condamner les uns après les autres&#160;? Mais ne leur donnons pas
          trop d’idées, en plus de coopter le secteur privé, ils sont souvent
          bien vite prêts à changer la loi et la Constitution pour se laisser
          les coudées franches.
        </Text>
        <Text>
          Cette idéologie visant à considérer que ses concitoyens peuvent et
          devraient se soumettre à une surveillance totale et permanente est
          finalement un continuum, allant des caméras dans les rues à la
          surveillance algorithmique de Facebook, l’un alimentant l’autre et
          vice-versa. Avec pour étape suivante de mettre des algorithmes dans
          les caméras.
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter2Part7;
