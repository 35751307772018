import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter3Part1 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Naissance de la technopolice">
      <>
        <Text>
          En mars&#160;2021, la Quadrature publie un article sur les caméras de
          surveillance «&#160;intelligentes&#160;», mais illégales, installées
          devant la gendarmerie de Moirans, une petite commune de l’Isère
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Vidéosurveillance à Moirans en Isère&#160;:</Text>{" "}
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/03/12/une-videosurveillance-peut-en-cacher-une-autre/"
                  >
                    https://www.laquadrature.net/2021/03/12/une-videosurveillance-peut-en-cacher-une-autre/
                  </AppLink>
                </>
              )
            }
          >
            159
          </InfoClick>
          . Quelques semaines plus tôt, en décembre&#160;2020, le Conseil d’État
          interdisait, à notre demande, les drones utilisés par la préfecture de
          police de Paris pour surveiller les manifestations dans les rues de la
          capitale –&#160;après une première interdiction en mai&#160;2020,
          royalement ignorée par le préfet Didier Lallement
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Deuxième interdiction des drones par le Conseil
                    d’État&#160;:
                  </Text>{" "}
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/12/22/interdiction-des-drones-victoire-totale-contre-le-gouvernement/"
                  >
                    https://www.laquadrature.net/2020/12/22/interdiction-des-drones-victoire-totale-contre-le-gouvernement/
                  </AppLink>
                </>
              )
            }
          >
            160
          </InfoClick>
          .
        </Text>
        <Text>
          Si l’un de nous, militant de l’association, s’était endormi au beau
          milieu de l’été 2009, juste après la lutte contre la loi Hadopi, pour
          se réveiller au printemps 2021, un peu ébouriffé et la langue sèche,
          il se serait sans doute posé deux questions&#160;: «&#160;Pourquoi
          cette grosse sieste de douze&#160;ans&#160;? Et pourquoi La Quadrature
          du Net a-t-elle autant changé&#160;?&#160;»
        </Text>
        <Text>
          En ce qui concerne l’hypersomnie, nous n’avons pas de réponse.
          En&#160;revanche, nous pouvons comprendre son étonnement quant au
          positionnement de la Quadrature&#160;: comment l’association connue
          pour sa défense du partage et de la neutralité du Net, ou pour sa
          lutte contre l’exploitation commerciale des données personnelles, se
          retrouve-t-elle à compter les caméras de surveillance dans la rue et à
          contester les ordres d’un préfet de police&#160;?
        </Text>
        <Text>
          C’est l’histoire d’une modification lente, patiente comme l’évolution
          des espèces, et longue comme une prise de conscience. Pendant
          douze&#160;années, la société a changé, et nous avec elle&#160;; si
          vous avez lu les pages précédentes, vous le savez déjà. Prétendre
          s’engager pour «&#160;la défense des libertés à l’ère du
          numérique&#160;», alors que le numérique envahit tous les aspects de
          la vie quotidienne, c’est fatalement sortir d’Internet et embrasser
          tous les aspects de la société moderne. Reste à savoir comment on est
          passé de la lutte contre la censure administrative du Web, légalisée
          par la loi Renseignement (en 2015), à la dénonciation directe d’un
          système de surveillance installé dans les rues d’une petite ville de
          l’Isère (en 2021).
        </Text>
        <Text>
          Raconter cette histoire, c’est remonter aux origines de la campagne
          Technopolice, lancée en septembre&#160;2019.
        </Text>

        <Text type="h3">
          Le&#160;fantasme de&#160;tout savoir pour tout gouverner
        </Text>
        <Text>
          Quand on cherche à reconstituer le cours des événements a posteriori,
          on risque de fixer des moments de bascule un peu artificiels. Comment
          retrouver la première formulation d’une idée&#160;? Puisqu’on ne peut
          pas faire l’archéologie des pensées dans les têtes, qui ne se
          fossilisent pas bien, il faut se résigner à en chercher les premières
          traces écrites&#160;: les dates de publication serviront de date de
          naissance, alors que les idées ont mûri lentement avant d’oser
          s’exprimer.
        </Text>
        <Text>
          Dans le cas de la campagne Technopolice, les historiens ont de la
          chance&#160;: les témoins de sa naissance sont bien vivants et plutôt
          bavards, et ils se souviennent distinctement de l’enchaînement des
          faits. Félix Tréguer, membre fondateur de la Quadrature qu’il a même
          présidée durant deux ans (de 2017 à 2019), est formel&#160;: l’origine
          de la campagne Technopolice se situe le 8&#160;décembre 2017.
        </Text>
        <Text>
          Ce jour-là, le journal <i>Le Monde</i> publie un article de la
          journaliste Claire Legros, intitulé&#160;: «&#160;À Marseille, le Big
          Data au service de la sécurité dans la ville
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Claire Legros, « À Marseille, le Big Data au service de la
                    sécurité dans la ville », <i>Le Monde</i>, 8&#160;décembre
                    2017&#160;:
                  </Text>{" "}
                  <AppLink
                    white
                    href="https://www.lemonde.fr/smart-cities/article/2017/12/08/a-marseille-le-big-data-au-service-de-la-securite-dans-la-ville_5226528_4811534.html"
                  >
                    https://www.lemonde.fr/smart-cities/article/2017/12/08/a-marseille-le-big-data-au-service-de-la-securite-dans-la-ville_5226528_4811534.html
                  </AppLink>
                </>
              )
            }
          >
            161
          </InfoClick>
          &#160;». L’article cristallise de manière saisissante un très vieux
          fantasme politique&#160;: si la police sait tout, elle pourra mieux
          garantir la sécurité des rues. Et de façon plus large, si on
          connaissait mieux le fonctionnement de la société, avec suffisamment
          de «&#160;données&#160;», alors la diriger deviendrait évident, et ne
          serait plus qu’une question de réglages.
        </Text>
        <Text>
          On peut remonter le fil de cette ambition-là très loin dans le temps,
          jusqu’aux Lumières et peut-être avant, avec la découverte de la
          permanence des lois physiques, qui rend les causes calculables et les
          conséquences prévisibles. Le Dieu imaginé par le philosophe Leibniz,
          par exemple, est omniscient, et sa connaissance intime des
          innombrables déterminations, jusqu’aux plus petites, lui permet de
          connaître l’avenir aussi clairement que s’il avait déjà eu lieu. Un
          gouvernant qui aurait ce pouvoir pourrait à la fois satisfaire les
          aspirations véritables de ses sujets et se prémunir contre les
          révoltes, même les plus&#160;individuelles.
        </Text>
        <Text>
          Le fantasme est ravivé par l’apparition des études statistiques, qui
          donnent naissance à l’économétrie et à la sociologie au XIX
          <sup>e</sup>&#160;siècle&#160;: au-delà des comportements individuels,
          il existe des faits de masse que l’on peut mesurer, que l’on peut
          chiffrer à l’échelle de la société, et qui sont autant de possibles
          explications causales à des comportements majoritaires. Le travail du
          sociologue Émile Durkheim sur le suicide
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Émile Durkheim, <i>Le Suicide</i>, étude de sociologie publiée
                  en 1897.
                </Text>
              )
            }
          >
            162
          </InfoClick>{" "}
          est à ce titre souvent cité comme un acte fondateur de la science
          sociale, qui cherche dans les phénomènes décelables dans le grand
          nombre un éclairage sur les comportements apparemment erratiques des
          individus. On voit pourquoi le pouvoir politique veut acquérir ce
          savoir&#160;: si l’on isole les lois qui déterminent les personnes,
          alors on doit pouvoir prédire leur comportement et, pourquoi pas,
          l’orienter, le manipuler, le diriger.
        </Text>
        <Text>
          Au XX
          <sup>e</sup>&#160;siècle, la direction des masses a été confiée au
          cinéma, à la radio et à la propagande, selon des méthodes explicitées
          par exemple dans les ouvrages du propagandiste austro-américain Edward
          Bernays, parmi lesquels <i>Cristallisation de l’opinion publique</i>{" "}
          (1923) ou <i>Propagande </i>(1928). Le développement de la publicité,
          après la Seconde Guerre mondiale, a affiné l’analyse de la psychologie
          des foules, moins pour satisfaire ses attentes que pour les créer de
          toutes pièces. On est passé en quelques décennies de la réclame au
          marketing.
        </Text>
        <Text>
          La compréhension du fonctionnement des «&#160;systèmes
          complexes&#160;» –&#160;une société humaine, une population animale,
          un cerveau, une psychologie&#160;– et de la façon dont des machines
          pourraient imiter des comportements animaux pour devenir plus
          efficaces devient après la Seconde Guerre mondiale un champ d’étude à
          part entière, sous l’impulsion de penseurs comme Norbert Wiener, et
          reçoit le nom de <i>cybernétique</i>, ou «&#160;technique du
          contrôle&#160;». Ces travaux aboutissent à la mise au point de
          concepts formels comme la «&#160;boucle de rétroaction&#160;» (ou{" "}
          <i>feedback</i>), utilisée dans des domaines aussi variés que la
          biologie, la psychologie, la mécanique, l’économie, etc. Cet exercice
          d’abstraction qui cherche des régularités ou des similitudes dans des
          domaines très distants les uns des autres établit un fort parallélisme
          entre le vivant et le mécanique, et entretient de manière explicite le
          fantasme d’un «&#160;pilotage&#160;» du vivant, à l’échelle d’un
          individu ou d’une société entière. L’idée s’est tellement bien
          implantée dans l’imaginaire collectif que la culture pop se l’est
          appropriée&#160;: le cinéma appelle les robots androïdes des{" "}
          <i>cyborgs</i>, un courant de la SF se baptise <i>cyberpunk</i>, et
          John Perry Barlow mentionne Internet par le beau nom de{" "}
          <i>cyberespace</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    John Perry Barlow,{" "}
                    <i>Déclaration d’indépendance du cyberespace</i>,
                    1996&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://fr.wikipedia.org/wiki/D%C3%A9claration_d%27ind%C3%A9pendance_du_cyberespace"
                  >
                    https://fr.wikipedia.org/wiki/D%C3%A9claration_d%27ind%C3%A9pendance_du_cyberespace
                  </AppLink>
                </>
              )
            }
          >
            163
          </InfoClick>
          .
        </Text>
        <Text>
          Maintenant que toutes nos activités quotidiennes produisent des
          «&#160;données&#160;» numériques –&#160;nos déplacements, nos achats,
          nos discussions sur les réseaux sociaux, nos rendez-vous médicaux, et
          même nos conversations téléphoniques intimes&#160;–, alors il devient
          techniquement plausible de connaître non seulement les activités de la
          population mais aussi ses pensées et ses désirs, dans l’ensemble et en
          détail. Avec une bonne analyse de cette énorme masse de données, on
          doit pouvoir lire l’âme humaine. Et si on n’y arrive pas encore, c’est
          forcément parce qu’on s’y prend mal&#160;: soit on ne calcule pas
          assez vite, soit on n’a pas assez de données. Il en faut donc encore
          plus, et toujours plus.
        </Text>

        <Text type="h3">
          Alerte en&#160;provenance de&#160;la&#160;planète Marseille
        </Text>
        <Text>
          On l’a vu, la prolifération des «&#160;traces numériques&#160;» dans
          nos vies profite d’abord aux publicitaires, ou plutôt aux sites Web
          capables de vendre aux publicitaires nos profils de consommateurs,
          avec la garantie que notre attention est bien retenue sur leurs pages
          à longueur de journée –&#160;le fameux «&#160;temps de cerveau
          disponible
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  {" "}
                  Célèbre formule due à Patrick Le Lay, alors dirigeant de TF1,
                  qui résumait en 2004 le travail de sa chaîne en ces
                  termes&#160;: « Ce que nous vendons à Coca-Cola, c’est du
                  temps de cerveau humain disponible. »
                </Text>
              )
            }
          >
            164
          </InfoClick>
          &#160;». C’est à cette activité peu reluisante mais très lucrative que
          les célèbres GAFAM doivent leur immense fortune en totalité (Google,
          Facebook) ou en partie (Microsoft, Apple, Amazon). Mais les pouvoirs
          publics entendent bien, eux aussi, tirer profit du traitement de notre
          vie numérisée, et de l’immense savoir qu’elle renferme.
        </Text>
        <Text>
          Le capitalisme gouverne notre économie et notre façon de travailler,
          de consommer et de vivre, la bourgeoisie capitaliste occupe les postes
          de gouvernement, rien d’étonnant à ce que la logique des entreprises
          se retrouve au sein des ministères, ou des grandes villes.
        </Text>
        <Text>
          «&#160;L’analyse de données numériques peut-elle contribuer à prévenir
          les troubles à l’ordre public&#160;?&#160;», s’interroge donc
          logiquement <i>Le Monde</i>, le 8&#160;décembre 2017, avant de
          présenter le projet de la municipalité marseillaise&#160;:
          «&#160;Alors que de nombreuses collectivités en France misent sur les
          plateformes de données pour optimiser les déplacements et l’empreinte
          énergétique urbaine, la ville de Marseille annonce la création d’un
          outil d’analyse pour “garantir de manière plus efficace la sécurité et
          la tranquillité publique des citoyens”.&#160;»
        </Text>
        <Text>
          Après quoi l’article décrit en détail le fonctionnement idéal du
          premier «&#160;observatoire Big Data de la tranquillité
          publique&#160;» envisagé en France. C’est le nom choisi par la ville
          de Marseille pour désigner ce nouveau dispositif de surveillance, mais
          le costume de Mère-Grand cache mal les canines et les babines du loup.
        </Text>
        <Text>
          L’article du <i>Monde </i>tombe malheureusement dans le panneau&#160;:
          les termes utilisés pour décrire le projet paraissent directement
          empruntés au discours valorisant des communiqués de presse et de la
          communication d’entreprise. On croit lire une plaquette publicitaire,
          là où on s’attend à une mise en perspective, à une mise en situation
          sociale et politique du projet municipal.
        </Text>
        <Text>
          D’emblée, la «&#160;sécurité&#160;», terme rassurant mais sans
          contenu, cache la réalité des pratiques policières. Ni képi ni
          matraque dans le projet marseillais. Il s’agit de croiser innocemment
          des données, dans une démarche à la pureté quasiment scientifique (un
          «&#160;observatoire&#160;»), de laquelle découlera naturellement la
          «&#160;tranquillité&#160;». Si «&#160;la sécurité est la première des
          libertés
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Dans un article de novembre&#160;2015, après les attentats à
                    Paris, Olivier Tesquet dresse un rapide historique de la
                    prospérité de cette triste formule, passée de la bouche de
                    Jean-Marie Le Pen dans les années 1980 à celle de toute
                    personnalité politique qui veut se donner un air de
                    responsabilité aujourd’hui&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.telerama.fr/medias/la-securite-est-la-premiere-des-libertes-de-le-pen-a-valls-la-formule-s-est-imposee-dans-le-debat-politique,134465.php"
                  >
                    https://www.telerama.fr/medias/la-securite-est-la-premiere-des-libertes-de-le-pen-a-valls-la-formule-s-est-imposee-dans-le-debat-politique,134465.php
                  </AppLink>
                </>
              )
            }
          >
            165
          </InfoClick>
          &#160;», alors la tranquillité peut bien devenir un service municipal
          à part entière.
        </Text>
        <Text>
          L’article énumère quelques-unes des sources de données que le grand
          «&#160;outil Big Data de la tranquillité publique
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Outil Big Data de la tranquillité publique »&#160;: terme
                    utilisé dans un document administratif, le Cahier des
                    clauses techniques particulières (CCTP), que la Quadrature
                    s’est procuré et a rendu public en mars&#160;2018&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/files/CCTP_ObservatoireBigData_Marseille.pdf"
                  >
                    https://www.laquadrature.net/files/CCTP_ObservatoireBigData_Marseille.pdf
                  </AppLink>
                </>
              )
            }
          >
            166
          </InfoClick>
          &#160;» se propose d’agréger&#160;: «&#160;mains courantes de la
          police municipale, captations des caméras de surveillance,
          informations relevées par les marins-pompiers ou les agents des
          espaces verts…&#160;». Il faut reconnaître que l’ambition du projet
          peut éblouir&#160;: «&#160;Au-delà de la collecte, l’outil développé
          par Engie Ineo vise à analyser ces informations et à les croiser avec
          d’autres données, comme celles des opérateurs de téléphonie mobile, de
          transport public, de l’AP-HM (Assistance publique-Hôpitaux de
          Marseille), pour “mieux anticiper les risques”. “Nous allons également
          utiliser les données de météo et les grandes tendances des réseaux
          sociaux dans une finalité de sécurité”, explique Caroline Pozmentier,
          adjointe au maire chargée de la sécurité publique, qui évoque un “Big
          Data de la tranquillité publique, premier pilier de la{" "}
          <i>smart city</i> marseillaise”
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Claire Legros, <i>op. cit.</i>
                </Text>
              )
            }
          >
            167
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          La <i>smart city</i>&#160;: le mot est lâché.
        </Text>

        <Text type="h3">Gouverner sur&#160;un&#160;écran</Text>
        <Text>
          La <i>smart city</i> est un projet général de gestion des villes au
          moyen des outils numériques. Elle suppose le fantasme cybernétique de
          la mise en données du monde. Les foules qui prennent les transports en
          commun, les automobilistes qui vont et viennent pour travailler ou
          faire des courses, les livraisons de marchandises, les ordures à
          collecter et à évacuer, les eaux propres et les eaux usées, le gaz et
          l’électricité, sont autant de flux qu’il est nécessaire de connaître
          pour les faciliter, les optimiser, ou au contraire les réduire, en
          tous les cas les contrôler. On voit que le vocabulaire qui gravite
          autour du mot «&#160;flux&#160;» n’est pas celui de la rêverie devant
          les vagues, ni d’une méditation métaphysique face au cours toujours
          fuyant de la rivière (dans laquelle on ne se baigne jamais deux fois).
        </Text>
        <Text>
          Ce sont des concepts et des mots de gestionnaires, de personnes
          soucieuses d’affecter au bon endroit et au bon moment des moyens
          humains et financiers toujours plus réduits. Les politiques publiques
          dépendent évidemment des finances publiques, dont on sait qu’elles
          sont soumises depuis une quarantaine d’années à une logique
          d’économie, d’appauvrissement, de réduction volontaire. Il s’agit donc
          d’être d’autant plus efficace.
        </Text>
        <Text>
          Dans ce contexte général, les forces de police sont un flux à gérer
          comme les autres&#160;: on dispose de «&#160;ressources&#160;»
          limitées (les agents de la police municipale, les agents de la police
          nationale, des véhicules, des horaires de travail) et d’un volume de
          problèmes à «&#160;traiter&#160;» (des faits d’incivilité, de
          délinquance, de criminalité bien sûr, mais aussi des zones d’accidents
          de la route ou d’incendie à sécuriser, des événements publics festifs,
          sportifs ou militants, ou encore des sorties d’école à protéger le
          matin et le soir, etc.). Il est donc évident et nécessaire, pour tout
          gestionnaire municipal avisé, d’adapter ses ressources à ses besoins,
          et de savoir où et quand il doit disposer ses agents de police pour
          être efficace.
        </Text>
        <Text>
          La <i>smart city</i> propose de répondre à cette question, et à toutes
          les autres du même genre, grâce aux «&#160;données&#160;». Et pour
          obtenir des «&#160;données&#160;», il faut des sources de données.
          C’est-à-dire des capteurs de données.
        </Text>
        <Text>
          Pour le traitement des ordures, ce sera la contenance volumique des
          camions de collecte, multipliée par le nombre de rotations et le
          nombre de camions. Pour les eaux propres, ce sera le volume indiqué
          par les compteurs. Pour les eaux sales, la capacité et le niveau de
          remplissage des bassins de collecte et de retraitement. Pour les flux
          d’automobiles, on posera des câbles de comptage aux points de
          convergence, où on comptera les véhicules minute par minute sur un
          enregistrement vidéo continu, etc. Mais la <i>city</i> n’aurait rien
          de <i>smart</i> si elle s’en tenait à ces moyens grossiers et à des
          «&#160;capteurs&#160;» aussi classiques. La <i>smart city</i> mesure
          tout, tout le temps, et pour cela elle doit multiplier les capteurs,
          partout où c’est possible. Dans les immeubles, les portes auront des
          serrures électriques qui s’ouvrent avec des badges ou des codes, ainsi
          on saura à quelle heure les résidents entrent et sortent. Les ordures
          seront collectées dans des conteneurs munis de balances, pour savoir à
          quelle fréquence et en quelle quantité les résidents jettent leurs
          déchets. Les rues et les axes routiers seront filmés en permanence et
          des logiciels de reconnaissance de formes compteront les véhicules
          avec une précision inégalable, et pourront même indiquer le nombre de
          passagers visibles de chaque voiture, ou le nombre de vélos qui se
          mêlent à la circulation motorisée pour savoir si cela justifierait la
          création d’une voie cyclable protégée.
        </Text>
        <Text>
          La <i>smart city</i> exploite toutes les sources de données en
          provenance d’autres institutions qui sont autant de
          «&#160;capteurs&#160;»&#160;: le nombre et l’emplacement des accidents
          fournis par les commissariats de police et les services de secours,
          les blessures et les décès comptés par les hôpitaux, les flux de
          voyageurs constatés par les compagnies de transport en commun (la RATP
          en Île-de-France, les TCL à Lyon, etc.).
        </Text>
        <Text>
          Chaque habitant de la ville, avec son smartphone dans la poche, est
          une source potentielle de données. Cette contribution est souvent
          passive&#160;: on peut suivre les déplacements de chacun grâce aux
          bornes téléphoniques «&#160;accrochées&#160;» par le smartphone
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    On se souviendra par exemple que, dans les premiers jours du
                    premier confinement sanitaire de mars&#160;2020, les
                    opérateurs téléphoniques avaient pu dire que 17 % de la
                    population de l’Île-de-France avait quitté la ville pour se
                    confiner ailleurs&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lemonde.fr/pixels/article/2020/03/26/confinement-plus-d-un-million-de-franciliens-ont-quitte-la-region-parisienne-en-une-semaine_6034568_4408996.html"
                  >
                    https://www.lemonde.fr/pixels/article/2020/03/26/confinement-plus-d-un-million-de-franciliens-ont-quitte-la-region-parisienne-en-une-semaine_6034568_4408996.html
                  </AppLink>
                </>
              )
            }
          >
            168
          </InfoClick>
          , deviner le moyen de locomotion en fonction de la vitesse de
          transition d’une borne à l’autre, et encore plus finement avec les
          bornes wifi publiques de la ville. Certaines galeries marchandes
          disposent de capteurs Bluetooth ou wifi pour identifier les téléphones
          qui passent près des boutiques et savoir lesquels s’arrêtent devant la
          vitrine, ceux qui entrent ou non, après combien de temps d’hésitation…
          tout cela dans le but de proposer des publicités ou des promotions
          ciblées par SMS… Les municipalités aimeront savoir comment leurs
          habitants circulent dans la ville et quels sont leurs circuits, leurs
          habitudes, etc.
        </Text>
        <Text>
          Mais la contribution des habitants de la <i>smart city</i> peut aussi
          être active&#160;: il existe déjà dans de nombreuses villes des
          applications pour signaler des ordures abandonnées, des décharges
          sauvages, des équipements cassés ou –&#160;pourquoi pas&#160;– des
          faits de délinquance. Derrière ces ébauches d’interactivité, on devine
          tout un imaginaire politique où les rapports entre une administration
          locale (la mairie) et ses administrés (les habitants de la commune)
          sont expurgés de tout enjeu politique réel, pour ne garder que des
          relations de client à fournisseur, ou de capteur à unité centrale. Le
          choix politique n’est jamais à venir, jamais discuté, mais toujours
          déjà là.
        </Text>
        <Text>
          Cette vision d’une <i>smart city</i> entièrement technologique
          commence à être sérieusement remise en cause, y compris par ses
          promoteurs en France
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Voir par exemple cette présentation de la notion de «{" "}
                    <i>smart city</i> » sur le site du Centre d’études et
                    d’expertise sur les risques, l’environnement, la mobilité et
                    l’aménagement (Cerema)&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://smart-city.cerema.fr/territoire-intelligent/definition-smart-city"
                  >
                    https://smart-city.cerema.fr/territoire-intelligent/definition-smart-city
                  </AppLink>
                </>
              )
            }
          >
            169
          </InfoClick>
          , qui ont bien perçu le peu d’attrait de leur fantasme
          industriel&#160;: les pouvoirs locaux ont pu constater, par
          expérience, que les «&#160;solutions&#160;» proposées par les
          opérateurs de la surveillance étaient souvent des gadgets trop chers
          pour un résultat insuffisant. On préfère désormais lui accoler
          d’autres fantasmes, comme celui d’une ville «&#160;durable&#160;»,
          capable de trouver l’équilibre entre la surconsommation énergétique
          des équipements numériques et les marges d’économies que ces mêmes
          équipements pourront permettre d’identifier.
        </Text>
        <Text>
          Mais il reste un domaine dans lequel les lignes budgétaires sont
          encore faciles à débloquer, sans susciter le moindre mécontentement
          des administrés, et qui peut même rapporter des voix aux
          élections&#160;: c’est la sécurité.
        </Text>

        <Text type="h3">Réaction politique à&#160;un&#160;sujet technique</Text>
        <Text>
          Dès la parution de l’article du <i>Monde</i>, Twitter a réagi. La
          Quadrature aussi, et parmi nous Félix Tréguer, membre fondateur de
          l’association et habitant de Marseille. Claire Legros, l’autrice de
          l’article, a été d’un fair-play parfait&#160;: le jour même, elle
          interviewait Félix et publiait sa réaction
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  On voit encore la mention de la modification au pied de
                  l’article accessible en ligne.
                </Text>
              )
            }
          >
            170
          </InfoClick>
          .
        </Text>
        <Text>
          Que dit Félix, au nom de la Quadrature&#160;? Rien de révolutionnaire
          ni d’extrémiste, seulement des évidences qu’il fallait énoncer pour ne
          pas laisser penser que le projet de «&#160;l’observatoire Big Data de
          la tranquillité publique&#160;» allait de soi&#160;: «&#160;C’est la
          logique même de ces outils qui doit être interrogée. L’idée selon
          laquelle la technologie va résoudre des problèmes sociaux et qu’en
          investissant des milliers d’euros on arrivera à garantir la sécurité
          représente un leurre. Des études ont montré aux États-Unis à quel
          point ces programmes renforcent des biais tels que la discrimination
          liée à la couleur de peau. On assiste à un saut en avant technologique
          lié au Big Data qui entraîne la possibilité de croiser toutes ces
          données issues de bases diverses. Même si au début on nous vend des
          dispositifs encadrés, on constate une accoutumance à ces systèmes qui
          banalisent la société de surveillance.&#160;»
        </Text>
        <Text>
          Pour étayer ces évidences, il nous faut nous forger une connaissance
          et un avis de première main sur la question. L’association doit
          pouvoir argumenter, prouver, illustrer… Pour cela, il existe un outil
          précieux&#160;: les «&#160;demandes Cada&#160;».
        </Text>
        <Text>
          Depuis le 17&#160;juillet 1978, les citoyens ont un «&#160;droit
          d’accès aux documents administratifs&#160;». Ils peuvent consulter
          «&#160;tous les documents produits ou reçus par une administration
          publique (administrations d’État, collectivités territoriales,
          établissements publics)&#160;», et même par «&#160;les organismes
          privés chargés d’une mission de service public&#160;» en faisant une
          simple demande par courrier. Ce droit est garanti par la Commission
          d’accès aux documents administratifs (Cada), qui contrôle la nature
          des documents pouvant échapper à cette obligation de communication, et
          qui est aussi l’autorité de recours quand les citoyens estiment que
          leur droit de consultation n’a pas été correctement respecté.
        </Text>
        <Text>
          On devrait dire qu’on envoie une «&#160;dada&#160;» –&#160;une
          «&#160;demande d’accès à un document administratif&#160;»&#160;– mais
          dans la langue commune des Garagistes de la Quadrature, c’est devenu
          une «&#160;demande Cada&#160;». Dès qu’il prend connaissance du projet
          de «&#160;l’observatoire Big Data de la tranquillité publique&#160;»,
          Félix Tréguer envoie une «&#160;demande Cada&#160;» à la mairie de
          Marseille. Les documents qu’il reçoit sont publiés dans un article
          intitulé «&#160;La surveillance policière dopée aux Big Data arrive
          près de chez vous&#160;!
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La surveillance policière dopée aux Big Data arrive près
                    de chez vous ! », 20&#160;mars 2018&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2018/03/20/surveillance_big_data_marseille/"
                  >
                    https://www.laquadrature.net/2018/03/20/surveillance_big_data_marseille/
                  </AppLink>
                </>
              )
            }
          >
            171
          </InfoClick>
          &#160;», le 20&#160;mars 2018, et complété par l’arrivée de nouveaux
          documents dès le mois de juin. On sort des discours de déploration,
          trop facilement attaquables, pour entrer dans la description
          documentée des faits. Chacun peut désormais voir de ses propres yeux
          ce qui est&#160;: fin des fantasmes d’épouvante, et commencement de
          l’angoisse lucide.
        </Text>
        <Text>
          Le projet marseillais de «&#160;l’observatoire Big Data de la
          tranquillité publique&#160;» se propose d’agréger des informations
          venues de toutes parts&#160;: des fichiers de la police nationale et
          des mains courantes de la police municipale, qui géolocalise ses
          données, des décisions de justice, des procès-verbaux des pompiers (à
          Marseille, ce sont les marins-pompiers), des informations fournies par
          les transports publics, par les parkings privés, par les caméras de
          surveillance du trafic routier, par les services locaux de la météo,
          évidemment, les flux vidéo des caméras municipales (avec l’objectif
          explicite de disposer à terme de deux mille caméras et de drones), des
          données fournies par l’État et d’autres collectivités locales, des
          données en provenance des hôpitaux, et même des données fournies par
          des sociétés privées (opérateurs téléphoniques par exemple). Sans
          oublier, et ce n’est pas la moindre, la participation des habitants,
          invités à fournir des informations à partir de leur smartphone de
          manière active (envoi de signalements par SMS, de photos ou
          d’enregistrements audio) ou passive (localisation, vitesse de
          déplacement, etc.).
        </Text>
        <Text>
          Voilà qui est clair&#160;: la sardine de l’Observatoire marseillais
          est en réalité une baleine qui veut tout avaler, et toutes les données
          qui passent à portée, plancton ou sable, seront aussitôt englouties.
          Mais dans quel but, ce «&#160;Big Data&#160;» pantagruélique&#160;? En
          vue de quelles espérances, de quelles finalités, de quelles
          observations&#160;? C’est beaucoup moins clair.
        </Text>
        <Text>
          «&#160;Analyser le passé, apprécier le présent et anticiper
          l’avenir&#160;», dit en substance le cahier des charges du projet
          marseillais
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  {" "}
                  <Text>
                    CCTP, consultable sur le site de La Quadrature du Net&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/files/CCTP_ObservatoireBigData_Marseille.pdf"
                  >
                    https://www.laquadrature.net/files/CCTP_ObservatoireBigData_Marseille.pdf
                  </AppLink>
                </>
              )
            }
          >
            172
          </InfoClick>
          . Ses concepteurs se vantent aussi d’une «&#160;approche
          particulièrement exploratoire et créative&#160;»&#160;<i>:</i> en
          d’autres termes, ils ne savent pas ce qu’ils font, mais ils sont prêts
          à tout. Et d’expérience en expérience, de tâtonnement en tâtonnement,
          ils espèrent bien trouver quelque chose.
        </Text>
        <Text>
          Le grand fantasme de l’esprit policier a deux formes spectaculaires
          que le cinéma nous a beaucoup montrées. La première consiste à vouloir
          «&#160;punir tous les criminels, et pour tous les crimes&#160;»
          –&#160;en cela, le regard total sur la ville est sans doute à la fois
          une façon d’assouvir ce désir, et un moyen de laisser entendre à la
          population que rien n’échappera à l’œil qui la surveille. La menace
          vaut comme mesure. C’est la fameuse «&#160;impunité zéro&#160;», ou la
          fameuse «&#160;tolérance zéro&#160;» chère aux discours démagogiques
          dont se moque si brillamment le film <i>Robocop</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  <i>Robocop</i>, film de Paul Verhoeven sorti en 1987&#160;: le
                  fantasme de prévenir le crime ne date pas de 2017 !
                </Text>
              )
            }
          >
            173
          </InfoClick>
          . L’autre rêve, c’est d’anticiper les crimes, comme dans{" "}
          <i>Minority Report</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  <i>Minority Report</i>, film de Steven Spielberg d’après une
                  nouvelle de Philip K.&#160;Dick, 2002.
                </Text>
              )
            }
          >
            174
          </InfoClick>
          .
        </Text>
        <Text>
          Le fantasme de l’avenir calculable, on l’a vu, existe au moins depuis
          Leibniz, mais le dernier demi-siècle, témoin à la fois de
          l’accroissement fulgurant de la puissance de calcul des ordinateurs et
          de la baisse de leur prix, lui a donné une nouvelle vigueur. Le rêve
          devient une idée en trouvant le moyen de s’actualiser. Et les pouvoirs
          publics en 2021 envisagent sérieusement que la police soit dotée des
          moyens d’anticiper la délinquance ou les crimes graves. On a déjà vu
          comment, dans le domaine du renseignement, l’horreur du terrorisme
          était sans cesse rappelée et amplifiée par les politiciens et les
          médias pour justifier les passe-droits et les mesures de surveillance
          de masse. Dans le domaine de la «&#160;sécurité du quotidien&#160;»,
          la «&#160;prévention&#160;» tient ce rôle d’argument massue, qui tue
          toute réflexion. Qui serait assez fou pour rejeter la sagesse selon
          laquelle «&#160;mieux vaut prévenir que guérir&#160;»&#160;?
        </Text>
        <Text>
          La «&#160;police prédictive&#160;» n’est pas une nouveauté ni une idée
          marseillaise&#160;: elle a déjà été envisagée très sérieusement aux
          États-Unis, dans de grandes villes à fort taux de criminalité, comme
          Chicago ou Atlanta. Et&#160;là où le renseignement antiterroriste
          cherche à identifier des personnes ou des réseaux susceptibles de
          passer à l’action violente, les outils de la police prédictive
          s’attachent plutôt à dresser des cartes de l’espace urbain, ce qu’on
          appelle des <i>heat maps</i> («&#160;cartes de chaleur&#160;») où les
          zones à plus forte probabilité de délinquance sont signalées par des
          couleurs plus chaudes (plus rouges) que les zones urbaines moins
          dangereuses et plus froides (plus bleues). Le temps est également pris
          en compte, et les zones de chaleur peuvent se déplacer sur la carte au
          fil des heures du jour et de la nuit. De telles représentations
          cartographiques des risques permettent aux services de police de mieux
          répartir leurs moyens, des agents ou des voitures de patrouille par
          exemple.
        </Text>
        <Text>
          Mais à l’heure où l’on voit Marseille et d’autres villes françaises
          s’emparer du fantasme de la «&#160;police prédictive&#160;», on
          constate que les villes américaines qui l’avaient expérimentée les
          premières renoncent à ces outils, et n’en font plus le cœur de leurs
          investissements en personnel et en argent. On apprend par ailleurs que
          le logiciel PredPol, vendu par la société du même nom depuis 2012, se
          fonde sur un algorithme développé par le sismologue David Marsan, de
          l’université de Savoie à Chambéry
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Isabelle Bellin, « Sécurité publique&#160;: que dire de la
                    police prédictive ? », <i>Data Analytics Post</i>,
                    28&#160;mai 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://dataanalyticspost.com/securite-publique-que-dire-de-la-police-predictive/"
                  >
                    https://dataanalyticspost.com/securite-publique-que-dire-de-la-police-predictive/
                  </AppLink>
                </>
              )
            }
          >
            175
          </InfoClick>
          , sous prétexte que les faits de délinquance se diffuseraient dans la
          ville à la manière des ondes sismiques dans les sols… Les bases
          intellectuelles de ces outils ont à peu près le sérieux de
          l’astrologie. On n’en attendait pas moins de la part d’outils capables
          de prédire que la délinquance surviendra probablement dans les zones
          de forte délinquance.
        </Text>
        <Text>
          Outre leur moralité et leur scientificité très discutables, les outils
          numériques de la «&#160;tranquillité publique&#160;» et de la
          «&#160;police prédictive&#160;» ont un coût, que d’autres perçoivent
          comme un juteux bénéfice. Les caméras, les analyses, les barrières et
          les armes sont fabriquées et vendues par des groupes industriels et
          des entreprises dont la peur et la mise sous surveillance de nos vies
          sont le très profitable fonds de commerce. Et c’est l’autre
          enseignement que donnent les documents obtenus par les «&#160;demandes
          Cada&#160;»&#160;: on voit apparaître les noms, les budgets, et les
          tractations commerciales.
        </Text>
        <Text>
          Mettre au jour des discours politiques qui relèvent autant de
          l’idéologie que du marketing&#160;; suivre d’une ville à l’autre le
          déploiement de dispositifs de surveillance inutiles, fantaisistes et
          coûteux&#160;; repérer les acteurs politiques et industriels de la
          surveillance généralisée&#160;; connaître l’état des systèmes pour
          dénoncer la surveillance de masse en pleine connaissance de
          cause&#160;: c’est l’ambition originelle de la campagne Technopolice
          de La Quadrature du Net, lancée en septembre&#160;2019.
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter3Part1;
