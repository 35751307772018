import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter2Part6 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>();

  return (
    <PagePattern partName="De la démocratie numérique à l’effritement de la démocratie">
      <>
        <Text>
          La Quadrature a toujours eu pour but, dès sa création, de participer
          au débat démocratique de manière constructive et d’être force de
          proposition. Ce qui n’est pas une mince affaire.
        </Text>
        <Text>
          Sous la présidence de Nicolas Sarkozy, tout effort de notre part était
          directement tué dans l’œuf. Chantre de l’«&#160;internet
          civilisé&#160;», il incarnait à peu près tout ce contre quoi la
          Quadrature se battait. Qu’en serait-il sous François Hollande&#160;?
          Au début, nous avions bon espoir. Pendant la campagne présidentielle,
          Philippe Aigrain avait publié <i>Sharing</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="http://www.sharing-thebook.com/">
                  http://www.sharing-thebook.com/
                </AppLink>
              )
            }
          >
            128
          </InfoClick>
          , un guide-manifeste pour un partage sur Internet qui protégerait
          aussi les créateurs. Il avait senti l’équipe de campagne du candidat
          Hollande (dont Aurélie Filipetti, future ministre de la Culture)
          plutôt réceptive à ses propositions, qui étaient aussi les nôtres.
          Mais une nouvelle leçon nous attendait&#160;: celle de la puissance
          des lobbies. Le programme de Hollande promettait d’en finir avec la
          Hadopi, mais au soir de l’élection, il n’en est déjà plus question. En
          quelques jours, tout le monde à la Quadrature prend conscience que
          notre opposition au gouvernement va demeurer la même, quel que soit
          son bord politique.
        </Text>
        <Text type="h3">Une&#160;consultation fantoche sous Hollande</Text>
        <Text>
          En 2015, Axelle Lemaire est à la manœuvre en tant que secrétaire
          d’État au numérique. Elle propose une grande consultation citoyenne
          avec pour objectif de «&#160;coécrire la loi pour une République
          numérique&#160;». Les personnes intéressées par ces sujets ont
          trois&#160;semaines, du 26&#160;septembre au 18&#160;octobre 2015,
          pour faire des propositions, les amender et les voter, avant que le
          gouvernement n’y réponde pour choisir celles qui seront intégrées dans
          le projet de loi. Cette consultation bénéficie d’une forte
          participation de la société civile, dont celles de Wikimédia, de
          l’April et de La Quadrature du Net. Sans nous faire d’illusions en
          entrant dans le jeu de cette consultation, nous proposons
          neuf&#160;contributions (trois&#160;propositions et
          six&#160;amendements à des articles existants). Alors que ces
          propositions reçoivent plus de 95&#160;% de soutien des votants à la
          plateforme, le gouvernement n’en retient que deux dans le projet de
          loi.
        </Text>
        <Text>
          Les propositions de la société civile, pour certaines massivement
          plébiscitées aussi (la communication du code source d’un logiciel
          public
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.republique-numerique.fr/consultations/projet-de-loi-numerique/consultation/consultation/opinions/section-1-ouverture-des-donnees-publiques-1/le-code-source-d-un-logiciel-est-un-document-administratif-communicable"
                >
                  https://www.republique-numerique.fr/consultations/projet-de-loi-numerique/consultation/consultation/opinions/section-1-ouverture-des-donnees-publiques-1/le-code-source-d-un-logiciel-est-un-document-administratif-communicable
                </AppLink>
              )
            }
          >
            129
          </InfoClick>
          , l’utilisation de logiciels libres&#160;&amp;&#160;de GNU/Linux dans
          les écoles, les universités
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.republique-numerique.fr/consultations/projet-de-loi-numerique/consultation/consultation/opinions/section-2-service-public-de-la-donnee-1/utiliser-les-logiciels-libres-gnu-linux-dans-les-ecoles-et-les-universites"
                >
                  https://www.republique-numerique.fr/consultations/projet-de-loi-numerique/consultation/consultation/opinions/section-2-service-public-de-la-donnee-1/utiliser-les-logiciels-libres-gnu-linux-dans-les-ecoles-et-les-universites
                </AppLink>
              )
            }
          >
            130
          </InfoClick>{" "}
          ou les administrations, l’inscription dans la loi du délit de
          non-respect de la neutralité du Net, ou encore, la reconnaissance du
          droit au chiffrement) n’auront pas beaucoup plus de succès. Au mieux,
          des promesses de mise en place dans des chantiers en cours… mais
          surtout, pas trop de législation. Puis le texte, augmenté des quelques
          propositions issues de la consultation (pas forcément les plus
          plébiscitées, d’ailleurs), part en discussion à l’Assemblée nationale.
          En décembre&#160;2015, l’avant-projet de loi est présenté et contient
          quelques avancées notables. L’ouverture des données publiques,
          largement soutenue par la société civile, y est proposée, et l’accès
          ouvert aux publications scientifiques financées par l’argent public
          semble enfin possible, via la reconnaissance d’un droit d’exploitation
          secondaire pour les chercheurs. Droit qui n’aurait probablement pas vu
          le jour sans la pression de la communauté… D’autres éléments du texte
          favorisent une diffusion plus large des informations et de la
          connaissance&#160;: l’inscription du droit à la portabilité des
          données, qui permet à l’utilisateur de ne pas se retrouver enfermé
          dans un écosystème captif et de faire lui-même usage de ses données
          –&#160;encore faut-il qu’un autre service existe où les
          déposer&#160;–, ou encore le maintien de la connexion Internet pour
          les personnes en incapacité de paiement en sont des exemples.
        </Text>
        <Text>
          Mais les textes retenus sont, lecture après lecture, vidés de leur
          substance
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="http://www.nextinpact.com/news/100511-on-a-compare-loi-numerique-aux-avis-consultation-en-ligne.htm"
                >
                  http://www.nextinpact.com/news/100511-on-a-compare-loi-numerique-aux-avis-consultation-en-ligne.htm
                </AppLink>
              )
            }
          >
            131
          </InfoClick>
          . Pour tenter de maintenir des avancées politiques, plusieurs
          organisations, dont la Quadrature, publient une liste d’amendements
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2016/01/06/promouvoir-les-communs/"
                >
                  https://www.laquadrature.net/2016/01/06/promouvoir-les-communs/
                </AppLink>
              )
            }
          >
            132
          </InfoClick>{" "}
          en janvier&#160;2016&#160;: communs numériques, logiciels libres et
          formats ouverts, ou encore liberté de panorama (une exception au droit
          d’auteur permettant de prendre en photo des œuvres protégées par le
          droit d’auteur se trouvant dans l’espace public, souvent des œuvres
          d’art ou d’architecture). En juin&#160;2016, les associations
          regroupées sous la bannière de l’Observatoire des libertés et du
          numérique (OLN) dénoncent le travail parlementaire réalisé par la
          secrétaire d’État et font état d’un bilan décevant des discussions
          autour du texte, alors même que le processus législatif n’est pas
          encore terminé. Elles dénoncent même qu’en l’état le texte introduit
          une notion dangereuse&#160;: un système de détection automatique de
          contenus illicites, mécanisme que la LCEN (loi pour la confiance dans
          l’économie numérique) avait tout juste effleuré en obligeant les
          hébergeurs –&#160;des entités privées&#160;– à retirer promptement les
          contenus «&#160;manifestement illicites&#160;» signalés. Désormais,
          les hébergeurs de contenus devraient mettre en place un système de
          contrôle a priori. Un texte censé apporter des droits positifs
          finirait donc par faire passer un article proprement sécuritaire.
        </Text>
        <Text>
          En juillet, Axelle Lemaire se plaint ouvertement dans une interview à{" "}
          <i>Mediapart</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.mediapart.fr/journal/france/120716/loi-numerique-axelle-lemaire-s-explique-sur-les-arbitrages-perdus-et-gagnes"
                >
                  https://www.mediapart.fr/journal/france/120716/loi-numerique-axelle-lemaire-s-explique-sur-les-arbitrages-perdus-et-gagnes
                </AppLink>
              )
            }
          >
            133
          </InfoClick>{" "}
          du communiqué de l’OLN, arguant que «&#160;certains&#160;»
          souhaiteraient mettre à bas la démocratie représentative en la
          remplaçant par une «&#160;geekocratie&#160;»… après avoir elle-même
          demandé aux geeks de participer à un processus de coconstruction de la
          loi. Nous persistons alors
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2016/07/13/axelle-lemaire-ou-pas/"
                >
                  https://www.laquadrature.net/2016/07/13/axelle-lemaire-ou-pas/
                </AppLink>
              )
            }
          >
            134
          </InfoClick>{" "}
          par un état des lieux du «&#160;bilan catastrophique du gouvernement
          sur le numérique&#160;».
        </Text>
        <Text>
          Ce qui aurait pu être une réconciliation entre le pouvoir politique et
          les spécialistes du numérique tombe dans le calcul politique de bas
          étage. Si bonnes intentions il y a pu avoir au départ, elles ne
          résistent pas au cynisme des professionnels de la politique et du
          pouvoir&#160;: encore une fois, la consultation laisse le sentiment
          d’avoir surtout eu pour but de se donner un vernis de démocratie
          consultative, pour mieux légitimer des choix politiques globalement
          déjà décidés en amont.
        </Text>

        <Text type="h3">
          Comment Macron séduit les&#160;start-up pour lancer sa&#160;campagne
        </Text>
        <Text>
          À l’été 2016, le ministre de l’Économie et des Finances de François
          Hollande quitte son poste pour lancer son mouvement politique. Il sort
          de nulle part, n’a sa carte dans aucun parti politique, n’a jamais été
          élu, et n’a surtout aucune base militante, mais souhaite néanmoins se
          présenter à l’élection présidentielle qui se déroulera moins d’un an
          plus tard. Emmanuel Macron est un homme jeune, qui baigne dans le
          milieu des entrepreneurs et des start-up. Et c’est comme une start-up
          qu’il va gérer sa campagne&#160;: en levant des fonds sur de vagues
          promesses de gains, et en s’entourant de personnes prêtes à suivre le
          projet qui leur a été vendu sans compter leurs heures. Comme il n’a
          pas de programme, seulement une réputation, il laisse ses fans de la
          première heure organiser des débats sur tous les sujets possibles.
          C’est la première fois qu’une équipe de campagne utilise aussi
          massivement les outils collaboratifs en ligne. Cette aptitude à
          accepter ou à aller chercher des méthodes de travail et d’
          <i>empowerment</i> (ou «&#160;capacitation&#160;») des militants, les
          échanges avec la communauté de spécialistes comme celle du logiciel
          libre, renforcent l’idée que vend Macron&#160;: il pourrait être
          vraiment capable de représenter «&#160;le meilleur de tous les
          courants&#160;».
        </Text>
        <Text>
          Des centaines de soirées-débats seront organisées sur tous les sujets,
          des travaux sur le programme, partout en France. Nous sommes invités à
          plusieurs reprises à y participer. Il n’est évidemment pas question
          pour nous de nous afficher avec un candidat plutôt qu’un autre,
          sachant bien l’usage et l’instrumentalisation qui pourraient en être
          ensuite faits. Ce qui n’empêche pas un membre d’aller incognito, dans
          une froide soirée de mi-janvier 2017, prendre la température du groupe
          En Marche du XIV<sup>e</sup>&#160;arrondissement parisien. Le sujet de
          l’échange&#160;: «&#160;Quelle vie privée dans nos sociétés
          connectées&#160;?&#160;»
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://en-marche.fr/evenements/cafe-politique-quelle-vie-privee-dans-nos-societes-connectees"
                >
                  https://en-marche.fr/evenements/cafe-politique-quelle-vie-privee-dans-nos-societes-connectees
                </AppLink>
              )
            }
          >
            135
          </InfoClick>
          . Le public est intéressé et participe. Les questions fusent&#160;:
          «&#160;Est-ce vraiment utile de protéger notre vie
          privée&#160;?&#160;», «&#160;Finalement, face au Big Data,
          l’anonymisation n’est-elle pas vaine&#160;?&#160;», ou encore
          «&#160;Comment éduquer la population face à l’évolution rapide des
          technologies&#160;?&#160;». À cette époque, le programme du candidat
          Macron n’est toujours pas sorti, et ses soutiens ne peuvent donc se
          fonder que sur ses actions au gouvernement, et ses diverses
          déclarations depuis son départ de Bercy fin août&#160;2016. Que
          reste-t-il de ces débats dans le programme du candidat, sorti
          tardivement, le 2&#160;mars 2017, un mois et demi avant le premier
          tour&#160;?
        </Text>
        <Text>
          Probablement un grand sentiment d’amertume et une apathie renouvelée,
          confirmée ou peut-être même décuplée pour cette génération dont de
          nombreuses personnes se prenaient au jeu de la politique, au rêve de
          pouvoir changer les choses pour la première fois. Ce gloubi-boulga
          managérial emprunte aux méthodologies agiles et aux communautés{" "}
          <i>open source</i> leurs valeurs sincères d’égalité et
          d’horizontalité, surfant sur des vocables comme
          «&#160;méritocratie&#160;» et vantant la «&#160;French Tech&#160;»
          pour faire briller les yeux des jeunes cadres et les transformer au
          plus vite en base militante, alors que le management du
          parti-entreprise ne se donne aucune obligation d’écouter sa base. Il
          rappelle également les entreprises qui mettent en place du management
          faussement agile et vidé de sa substance ou font de l’«
          <i>&#160;open source washing&#160;</i>», en profitant de la bonne
          image du logiciel libre sans contribuer aux biens communs
          informationnels. À y réfléchir, à la manœuvre des partis et des
          entreprises, ce sont peut-être les mêmes&#160;!... Un parti ou un
          État&#160;? Finalement tout se gère comme une entreprise.
        </Text>
        <Text>
          Peu importent les dégâts ou le cynisme, ce mode de gouvernance initié
          pendant la campagne se poursuit sous le gouvernement Philippe.
          Plusieurs consultations sont mises en place&#160;: les États généraux
          de la bioéthique, la Convention citoyenne pour le climat, et même le
          très éphémère Collectif citoyen sur la vaccination –&#160;allant
          jusqu’à proposer que trente-cinq&#160;citoyens «&#160;veillent à la
          transparence de la politique vaccinale&#160;» et «&#160;fassent
          remonter les questionnements des Français&#160;»
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.contrepoints.org/2021/10/13/408572-collectif-citoyen-sur-la-vaccination-ne-cherchez-pas-il-nexiste-plus"
                >
                  https://www.contrepoints.org/2021/10/13/408572-collectif-citoyen-sur-la-vaccination-ne-cherchez-pas-il-nexiste-plus
                </AppLink>
              )
            }
          >
            136
          </InfoClick>
          … Une illusion de la participation citoyenne qui n’aura que peu, voire
          aucun impact sur la politique du gouvernement.
        </Text>
        <Text>
          Au niveau numérique
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://en-marche.fr/emmanuel-macron/le-programme/numerique"
                >
                  https://en-marche.fr/emmanuel-macron/le-programme/numerique
                </AppLink>
              )
            }
          >
            137
          </InfoClick>
          , le projet du président est très vague (une «&#160;Europe du
          numérique&#160;»), carrément surréaliste (l’autorisation de
          «&#160;déroger au droit pour permettre l’expérimentation&#160;»),
          voire fort consensuel («&#160;supprimer les zones blanches&#160;» et
          «&#160;fibrer le pays&#160;»). Sur le reste, Emmanuel Macron a
          finalement retourné sa veste («&#160;transparence sur les données
          personnelles&#160;») ou laissé la société civile faire le travail à sa
          place («&#160;renégocier le Privacy Shield&#160;», finalement tombé
          grâce à l’Autrichien Max Schrems).
        </Text>
        <Text>
          Il flatte franchement l’orgueil des entrepreneurs. Mise en avant du
          concept (fumeux) de «&#160;start-up nation&#160;», discours dans les
          incubateurs de start-up à la gloire des «&#160;gens qui
          réussissent&#160;» face à ceux «&#160;qui ne sont rien&#160;», le
          numérique de Macron est celui du clinquant, loin de ceux qui le font
          réellement. Il utilise le milieu geek comme un affichage, un ruban à
          la boutonnière qu’on arbore pour faire beau mais qu’on enlève une fois
          rentré à la maison. En mettant en avant des projets phares, des
          start-up qui réussissent, en les faisant financer à grand renfort
          d’argent public par la banque publique d’investissement, il cache
          finalement tout un pan de l’économie réelle, celle qui fait tourner la
          machine au quotidien. On le verra notamment dans le choix de Microsoft
          pour héberger le Health Data Hub (HDH) –&#160;le grand entrepôt de nos
          données de santé.
        </Text>
        <Text>
          Ces choix du pouvoir politique donnent au numérique un aspect de décor
          en carton-pâte. Les licornes (ces start-up valorisées à plus d’un
          milliard de dollars) comme étendard, un objectif non pas de rendre
          service à la communauté ou de résoudre des problèmes importants, mais
          de devenir milliardaire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.nouvelobs.com/economie/20150107.OBS9413/macron-il-faut-des-jeunes-francais-qui-aient-envie-de-devenir-milliardaires.html"
                >
                  https://www.nouvelobs.com/economie/20150107.OBS9413/macron-il-faut-des-jeunes-francais-qui-aient-envie-de-devenir-milliardaires.html
                </AppLink>
              )
            }
          >
            138
          </InfoClick>
          &#160;: c’est toute une économie du vent qui est mise en avant.
        </Text>
        <Text>
          Comment, dès lors, continuer à travailler avec le politique&#160;?
          Comment espérer un changement de société, ou même des changements à la
          marge, voire, soyons fous, un simple respect des libertés
          fondamentales, quand tout échange avec les politiques finit
          immanquablement dans la démagogie ou le lobbying d’entreprises
          puissantes&#160;? Quand nous passons des mois à expliquer qu’un texte
          est totalement anticonstitutionnel, mais que malgré tout il se trouve
          une majorité de députés et sénateurs pour les voter (loi Avia sur la
          haine en ligne, «&#160;loi Sécurité globale&#160;»…), et que ces
          textes finissent en effet par être censurés, que faire&#160;?
        </Text>
        <Text>
          Même les multiples victoires juridiques de 2021 (dont celles des
          drones, sur lesquelles nous reviendrons) ne réussissent pas à faire
          entendre raison aux politiques. Pire&#160;: attaquer certains textes
          devient un risque d’aboutir à une mauvaise décision et de créer une
          jurisprudence qui pourrait être pire que les textes initiaux. Alors
          que faire&#160;? Mettre en sommeil ce mode d’action pour ne conserver
          que les actions les plus sûres&#160;?
        </Text>
        <Text>
          Et pourtant, quelle que soit la qualité de ses analyses, quelle que
          soit l’énergie que ses membres y mettent, il semble que la seule chose
          qui empêche ces textes illibéraux d’être appliqués soit le droit. Mais
          jusqu’à quand&#160;? Les sujets numériques portés par la Quadrature
          sont en effet au cœur de nombreuses évolutions de la société, et par
          là même des enjeux forts pour les politiques. L’association se
          retrouve en première ligne des tentatives de mainmise de l’État sur un
          réseau qui concentre tant d’espoirs d’émancipation. En somme, plus le
          numérique envahit nos vies, plus la puissance publique s’acharne à en
          reprendre le contrôle, contre la population, effritant d’autant notre
          démocratie.
        </Text>
        <Text>
          En 2017, il n’y a plus de raison de conserver un état d’urgence en
          application depuis deux&#160;ans –&#160;niant de fait cette notion
          d’urgence. Le président Macron, tout juste élu, se retrouve donc à
          faire entrer un état d’exception dans le droit commun, avec la
          bénédiction des parlementaires français. À partir de là, tout discours
          essayant de sortir de cet état de stupeur devient inaudible. Au moment
          où les politiques de Trump, Johnson, Erdogan, Bolsonaro ou encore
          Orban font dans la démagogie d’État, on pouvait encore se penser à
          l’abri en France. Il est certain que c’est désormais de plus en plus
          difficile.
        </Text>
        <Text>
          On arrive au stade où, quelle que soit la nature de l’opposition, quoi
          qu’il soit proposé pour répondre à un problème, que ça vienne de la
          société civile ou directement de parlementaires, la réponse politique
          ne fait désormais plus aucun cas de ce que certains nomment pourtant
          la <i>realpolitik</i>. Fi de l’efficacité, du droit ou même de la
          réalité observable, le positionnement politique devient purement
          idéologique.
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter2Part6;
