import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter2Part2 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Le terrorisme partout">
      <>
        <Text>
          Le 7&#160;janvier 2015, à 11&#160;h&#160;30, une attaque terroriste
          cible le journal <i>Charlie Hebdo</i> à Paris. La France s’arrête,
          stupéfaite, sous le choc. Quatre jours après, quatre millions de
          Français et quarante-quatre chefs d’États entament une marche
          républicaine contre le terrorisme islamiste. Au Parlement, la machine
          s’emballe.
        </Text>
        <Text>
          Deux jours à peine après l’attentat, la Quadrature publie un
          communiqué alertant sur le risque d’instrumentalisation sécuritaire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2015/01/09/charliehebdo-non-a-linstrumentalisation-securitaire/"
                >
                  https://www.laquadrature.net/2015/01/09/charliehebdo-non-a-linstrumentalisation-securitaire/
                </AppLink>
              )
            }
          >
            79
          </InfoClick>
          . Nous mettons en garde le gouvernement contre une récupération de ces
          attentats pour faire passer de nouvelles lois d’exception. Ce
          communiqué parle aux gens&#160;: cinq&#160;mille personnes le lisent
          en une heure, vingt mille&#160;dans les premières 24&#160;heures. Et
          ce d’autant plus que l’association a malheureusement vu juste&#160;:
          un boulevard vient de s’ouvrir pour les lois sécuritaires.
        </Text>
        <Text>
          Pendant que des millions de Français défilent contre le terrorisme et
          pour la liberté d’expression, le gouvernement français notifie la
          Commission européenne qu’il compte déroger aux règles sur la censure
          des réseaux
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.numerama.com/politique/31824-sites-terroristes-le-gouvernement-notifie-son-projet-de-blocage-a-bruxelles.html"
                >
                  https://www.numerama.com/politique/31824-sites-terroristes-le-gouvernement-notifie-son-projet-de-blocage-a-bruxelles.html
                </AppLink>
              )
            }
          >
            80
          </InfoClick>
          . Il prévoit ainsi de mettre en place le blocage de sites Internet
          sans contrôle judiciaire et sous 24&#160;heures, prévu par la
          «&#160;loi Antiterrorisme&#160;» promulguée quelques mois plus tôt, le
          13&#160;novembre 2014. Dans la foulée de l’émotion suscitée par
          l’attentat de <i>Charlie Hebdo</i>, des déclarations plus outrancières
          les unes que les autres sur la responsabilité de la Toile dans le
          danger terroriste se multiplient…
        </Text>
        <Text>
          Alors que la Quadrature alertait depuis plus d’un an sur
          l’instrumentalisation de la menace terroriste pour justifier des
          atteintes majeures aux libertés des citoyens, ce ne sont plus quelques
          décrets contre lesquels les associations de défense des libertés
          doivent s’opposer&#160;: elles font maintenant face à une ribambelle
          de projets de lois, d’amendements, de déclarations, de propositions,
          d’accusations même. Très vite, profitant de la période, le
          gouvernement use abondamment d’un outil d’exception dans son passage
          de loi&#160;: la procédure accélérée prévue par la Constitution
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>Connue sous le nom d’« article&#160;49.3 »</Text>
              )
            }
          >
            81
          </InfoClick>
          .
        </Text>
        <Text type="h3">La&#160;«&#160;loi Renseignement&#160;»</Text>
        <Text>
          En avril&#160;2015, le projet de loi relatif à la prévention d’actes
          de terrorisme et au renseignement, surnommé «&#160;PJL
          renseignement&#160;», est examiné en à peine trois&#160;semaines par
          la Commission des lois. Une gageure pour un texte si complexe. Députés
          et sénateurs n’ont plus le temps de lire ou de comprendre ce qu’ils
          doivent voter. La riposte ne peut pas s’organiser dans ces délais qui
          empêchent la mobilisation citoyenne.
        </Text>
        <Text>
          Nous mettons sur pied avec la LDH, le Syndicat des avocats de France
          et le Syndicat de la magistrature (ces derniers étant pourtant
          rarement d’accord) une conférence de presse commune sur le sujet.
          Il&#160;s’agit du premier événement organisé dans le tout nouveau
          «&#160;Garage&#160;» de la Quadrature, qui vient de déménager dans de
          plus grands locaux, dans le XX<sup>e</sup> arrondissement de Paris.
          Nous y dénonçons le fait que le gouvernement s’apprête à utiliser le
          Big Data pour «&#160;révéler la menace terroriste&#160;». En clair, il
          s’agit de la captation massive des données informatiques et de
          l’installation au cœur des équipements du réseau d’un algorithme
          permettant de les analyser. Cet algorithme, nul ne sait comment il
          fonctionnera exactement&#160;: il est classé <i>secret-défense</i>.
          Les «&#160;boîtes noires&#160;» du gouvernement sont de retour.
        </Text>
        <Text>
          Elles ne sont pas les seules mesures que le gouvernement souhaite
          déployer pour surveiller la population&#160;: <i>IMSI catchers</i>{" "}
          (permettant de repérer les téléphones mobiles et d’intercepter leurs
          communications), utilisation de logiciels espions, pose de micros et
          de caméras dans les domiciles et les véhicules, extension de l’accès
          aux données «&#160;en temps réel&#160;» prévu par la LPM 2013 dont la
          Commission nationale de l’informatique et des libertés (CNIL)
          s’inquiète qu’elle pourrait permettre une «&#160;aspiration massive et
          directe des données par les agents des services concernés&#160;»… De
          nouveau, des pratiques illégales («&#160;a-légales&#160;»,
          souvenez-vous), des mesures mises en œuvre en dehors de tout cadre
          juridique, sont légalisées a posteriori, en tirant avantage de la
          sidération.
        </Text>
        <Text>
          La «&#160;loi Rens&#160;» (oui, c’est un jeu de mots), poussée par le
          Premier ministre Manuel Valls qui veut «&#160;conférer aux services de
          renseignement des moyens à la hauteur de la menace terroriste&#160;»
          en profite pour étendre les missions des services de renseignement.
          Les différentes mesures pourront être mises en place pour des
          objectifs aussi variés que «&#160;l’indépendance nationale&#160;», la
          «&#160;prévention des violences collectives&#160;» ou la défense des
          «&#160;intérêts économiques, industriels et scientifiques majeurs de
          la France&#160;». La «&#160;raison d’État&#160;» débridée…
        </Text>
        <Text>
          La liste complète de ces objectifs, qui compte dix éléments, laisse
          une grande latitude aux services de renseignement, permettant
          d’interpréter par exemple les manifestations contre le nucléaire comme
          allant à l’encontre de la protection des «&#160;intérêts économiques
          essentiels&#160;». Cela n’est évidemment pas un hasard et on est bien
          loin de la «&#160;simple&#160;» prévention du terrorisme, et ce malgré
          des mesures drastiquement attentatoires aux libertés individuelles,
          doublée de l’utilisation de la procédure accélérée au titre de
          «&#160;l’urgence&#160;». On pourrait même croire que le terrorisme
          sert de prétexte pour faire passer une réforme des pouvoirs du
          renseignement et de leur encadrement, si on avait l’esprit un peu
          cynique (ou simplement, l’habitude)…
        </Text>
        <Text>
          Presque comme une réponse préemptive à ces critiques, pour toutes ces
          mesures, la loi propose néanmoins un contrôle. Une autorité
          administrative indépendante&#160;: la Commission nationale de contrôle
          des techniques de renseignement (CNCTR) est créée. Elle est composée
          pour moitié de parlementaires –&#160;plutôt que d’une majorité de
          magistrats&#160;– et dispose de 24&#160;heures pour rendre ses avis,
          son silence valant évidemment acceptation des mesures de
          renseignement. Si jamais ce maigre contrôle venait malgré tout à être
          trop inconvenant, il pourrait être contourné grâce à une procédure
          dite d’«&#160;urgence absolue&#160;», qui permet tout bonnement de
          s’en passer, sur simple appréciation du seul Premier ministre.
        </Text>
        <Text>
          Dans l’ensemble, c’est un incroyable passage en force politique.
        </Text>
        <Text>
          Commence une bataille législative. L’UMP trouve le texte insuffisant,
          mais nécessaire. Le Parti de gauche s’oppose. Les Verts aussi, sans
          savoir que ces dispositions vont être utilisées contre eux quelques
          mois plus tard. En revanche, aucun député socialiste ne s’élève contre
          le texte. Dans les médias, comme auprès des députés de tout bord,
          contactés un à un, nous démontrons notre capacité à avoir une bonne
          lecture politique des textes, et à être une sérieuse source d’analyse.
          Mais de la part du gouvernement, et de Bernard Cazeneuve en
          particulier, ce n’est que mépris et hostilité. Selon ce dernier,
          l’association n’aurait rien compris à la qualité et à la nécessité de
          son texte.
        </Text>
        <Text>
          Quelques jours après notre conférence de presse commune, le débat sur
          la surveillance prend de l’ampleur. Une soirée débat est organisée au
          Numa, espace parisien dédié aux start-up. Côté invités, rien de bien
          surprenant&#160;: quelques personnalités du PS, bons soldats du
          gouvernement, et des <i>usual suspects</i> des droits humains comme
          Amnesty, le Syndicat de la magistrature, la Quadrature, et des
          journalistes comme Marc Rees de <i>Next INpact</i>. Par contre, bien
          plus étonnante est la présence de membres des services de
          renseignement venus (c’est rare) au contact du public pour expliquer
          que «&#160;les boîtes noires, chers jeunes geeks naïfs, ça n’est pas
          ce que vous croyez&#160;». Un moment ambigu par excellence, considéré
          par eux comme une main tendue et perçu par nous, qui sommes habitués à
          discuter avec des parlementaires et des journalistes, comme un coup de
          pression surréaliste. Le contexte de la chasse aux sorcières contre
          les hackers et les lanceurs d’alerte n’aide pas… La Quadrature évolue
          désormais dans une sphère bien différente de celle de ses débuts, qui
          avec le recul semble insouciante.
        </Text>
        <Text>
          Devant l’inefficacité de leurs interventions dans le débat
          parlementaire, les associations se replient sur le volet juridique.
          Sous la pression médiatique et pour couper l’herbe sous le pied des
          détracteurs de la loi Renseignement, le président de la République
          François Hollande saisit lui-même le Conseil constitutionnel. Les
          Exégètes envoient, par le biais de FDN, la Quadrature et la FFDN, un{" "}
          <i>Amicus Curiae</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.lefigaro.fr/secteur/high-tech/2015/06/23/32001-20150623ARTFIG00268-loi-renseignement-les-opposants-tirent-leur-derniere-cartouche.php"
                >
                  https://www.lefigaro.fr/secteur/high-tech/2015/06/23/32001-20150623ARTFIG00268-loi-renseignement-les-opposants-tirent-leur-derniere-cartouche.php
                </AppLink>
              )
            }
          >
            82
          </InfoClick>{" "}
          aux sages du Conseil constitutionnel, un document littéralement
          d’«&#160;ami de la cour&#160;», présentant des informations pour aider
          le Conseil à trancher sur un sujet. Le Conseil constitutionnel valide
          la quasi-totalité des mesures de surveillance prévues, le
          23&#160;juillet 2015, légalisant la surveillance de masse et avalisant
          un recul historique des droits fondamentaux
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2015/07/23/honte-sur-la-france-le-conseil-constitutionnel-valide-largement-la-loi-renseignement"
                >
                  https://www.laquadrature.net/2015/07/23/honte-sur-la-france-le-conseil-constitutionnel-valide-largement-la-loi-renseignement
                </AppLink>
              )
            }
          >
            83
          </InfoClick>
          . Les boîtes noires algorithmiques sont entérinées. Seule la
          surveillance internationale est jugée non conforme à la Constitution.
          Malgré la médiatisation du débat, le «&#160;PJL renseignement&#160;»
          est adopté et devient la loi n<sup>o</sup>&#160;2015-912 du
          24&#160;juillet 2015 relative au renseignement.
        </Text>
        <Text type="h3">
          Les&#160;Exégètes Amateurs passent à&#160;la&#160;vitesse
          de&#160;croisière
        </Text>
        <Text>
          Le petit groupe des Exégètes Amateurs se solidifie. Ils ont attaqué un
          à un les décrets de la loi de programmation militaire (LPM) pour
          s’opposer aux dispositions d’accès aux données de connexion. Ils
          viennent d’attaquer le décret de la LPM par un recours en excès de
          pouvoir devant le Conseil d’État, et de demander le renvoi au Conseil
          constitutionnel d’une question prioritaire de constitutionnalité (ou
          QPC). Cette QPC est un mécanisme qui permet à tout justiciable, dans
          le cadre d’un procès, de saisir le Conseil constitutionnel afin que
          celui-ci s’assure que la disposition législative applicable à son
          affaire ne porte pas atteinte aux droits et libertés garantis par la
          Constitution. Grâce à cette procédure, qui existe en France depuis
          2010, les Exégètes Amateurs espèrent faire annuler d’un coup tous les
          décrets.
        </Text>
        <Text>
          Après le dépôt de cette QPC en avril, les Exégètes continuent de
          s’attaquer au sujet des données de connexion. En effet, l’accès à ces
          données ne peut avoir lieu que si elles existent et ont été
          conservées. C’est la loi pour la confiance dans l’économie numérique
          (LCEN) de 2004 qui encadre la rétention des données de connexion, et
          le décret d’application de 2011 de la même loi qui fixe le délai de
          cette rétention à un an. Autrement dit&#160;: tous les fournisseurs
          d’accès et d’hébergement doivent conserver les données permettant
          l’identification de leurs utilisateurs pendant un an.
        </Text>
        <Text>
          Ces dispositions vont à l’encontre de la jurisprudence de la Cour de
          justice de l’Union européenne (CJUE) d’avril&#160;2014, dite
          «&#160;Digital Rights Ireland&#160;». L’arrêt Digital Rights invalide
          le principe de conservation généralisée des données de connexion, que
          la Cour considère comme contraire à la Charte des droits fondamentaux
          de l’Union européenne
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2015/02/18/decret-lpm-la-quadrature-du-net-depose-un-recours-devant-le-conseil-detat/"
                >
                  https://www.laquadrature.net/2015/02/18/decret-lpm-la-quadrature-du-net-depose-un-recours-devant-le-conseil-detat/
                </AppLink>
              )
            }
          >
            84
          </InfoClick>
          . Oui mais voilà&#160;: on ne peut faire de recours que contre les
          décisions administratives récentes. Or, nous sommes en 2015 et les
          décrets d’application de la LCEN, qui doivent être attaqués comme
          toute décision administrative dans les deux mois suivant leur
          parution, sont déjà bien anciens. Comment faire appliquer une
          jurisprudence nouvelle à des lois anciennes&#160;?
        </Text>
        <Text>
          Les Exégètes trouvent alors une faille. S’appuyant sur l’arrêt Digital
          Rights, ils demandent au gouvernement l’abrogation du décret, rendu
          illégal par la décision de la CJUE. L’administration a ensuite deux
          mois pour répondre, l’absence de réponse valant refus. C’était
          attendu, l’administration ne daigne pas répondre à la demande des
          Exégètes Amateurs. Mais voilà&#160;: un refus est bien une décision
          administrative… que les Exégètes ont donc deux mois pour attaquer
          devant le Conseil d’État
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://exegetes.eu.org/dossiers/abrogationretention/index.html"
                >
                  https://exegetes.eu.org/dossiers/abrogationretention/index.html
                </AppLink>
              )
            }
          >
            85
          </InfoClick>
          &#160;!
        </Text>
        <Text>
          Ils déposent leur demande d’abrogation le 6&#160;mai 2015 et attendent
          patiemment le 6&#160;juillet pour constater l’absence de réponse du
          gouvernement. Pendant ce temps, le Conseil constitutionnel transmet au
          mois de juin la QPC portant sur la LPM
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2015/06/05/premiere-victoire-pour-les-citoyens-contre-la-surveillance-la-loi-de-programmation-militaire-devant/"
                >
                  https://www.laquadrature.net/2015/06/05/premiere-victoire-pour-les-citoyens-contre-la-surveillance-la-loi-de-programmation-militaire-devant/
                </AppLink>
              )
            }
          >
            86
          </InfoClick>
          . La loi Renseignement qui est alors en discussion au Parlement vient
          étendre les dispositions contestées sur l’accès aux données de
          connexion. Et le lendemain du vote de la «&#160;loi Rens&#160;», le
          24&#160;juillet, le Conseil constitutionnel rejette la QPC des
          associations. Le groupe de juristes bénévoles ne se laisse pas
          abattre.
        </Text>
        <Text>
          La procédure contre le décret de la LPM continue devant le Conseil
          d’État (qui rejettera le recours en février&#160;2016), et les
          Exégètes commencent à attaquer les décrets de la loi Renseignement
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://exegetes.eu.org/dossiers/renseignement/index.html"
                >
                  https://exegetes.eu.org/dossiers/renseignement/index.html
                </AppLink>
              )
            }
          >
            87
          </InfoClick>{" "}
          qui paraissent à l’automne, tout en attaquant le refus d’abrogation du
          décret de la LCEN. Ils publient de nouveaux recours chaque mois,
          chacun plus dense que le précédent. En un an, ils vont attaquer une
          douzaine de décrets, poser quelques QPC, permettant la censure de lois
          parlementaires par le Conseil constitutionnel, et entamer des dossiers
          qui feront plus tard jurisprudence. Bien plus tard. La justice est
          lente, d’autant plus lente lorsqu’elle doit remonter d’institution en
          institution jusqu’à la Cour européenne. C’est un long combat qui
          commence pour les juristes, qui les mènera jusque devant la Cour
          européenne des droits de l’homme et, à nouveau, de nombreuses fois,
          devant le Conseil d’État et le Conseil constitutionnel.
        </Text>
        <Text>
          Mais alors que les Exégètes entament leurs démarches les unes après
          les autres, pleins d’espoir dans leurs futures retombées, un autre
          événement vient encore accentuer la pression de l’agenda parlementaire
          antiterroriste.
        </Text>
        <Text type="h3">Les&#160;attentats du&#160;13&#160;novembre 2015</Text>
        <Text>
          Le soir du 13&#160;novembre 2015, une série de fusillades et
          d’attaques-suicides sont perpétrées au Stade de France à Saint-Denis
          et dans de multiples lieux à Paris&#160;: la salle de spectacle du
          Bataclan et plusieurs terrasses des X<sup>e</sup> et XI<sup>e</sup>{" "}
          arrondissements. Les attentats font 130&#160;morts et 413&#160;blessés
          hospitalisés, dont 99 en situation d’urgence absolue. Dans la panique,
          devant la multiplicité des points d’attaque et l’incertitude des
          informations qui arrivent de toutes parts, les Parisiens se
          précipitent dans les commerces et immeubles les plus proches. En
          quelques dizaines de minutes, les rues de Paris se sont vidées et les
          rideaux de fer ont été tirés devant les boutiques. À
          23&#160;h&#160;53, le président de la République François Hollande
          décrète l’état d’urgence sur tout le territoire.
        </Text>
        <Text>
          L’horreur de cette soirée, la panique qu’elle a engendrée, nous
          l’avons vécue de près, comme de nombreux Parisiens. Ce sentiment que
          quelque chose de différent vient de se produire, que quelque chose
          vient de rompre. L’inquiétude pour les proches, les connaissances…
          Sans surprise, comme beaucoup de gens, nous apprendrons au cours des
          jours suivants qu’un proche, une amie d’amie, un cousin, un collègue…
          a vécu les attaques directement. Certains y ayant perdu la vie.
        </Text>
        <Text>
          Le lundi matin, à peine remis, nous publions un communiqué&#160;:
          «&#160;S’associer à la douleur, penser l’avenir&#160;». Et pour penser
          l’avenir, nous, militants des libertés, repensons au passé. En 2001,
          les attentats du 11&#160;septembre ont ouvert la voie au Patriot Act,
          une loi antiterroriste américaine attaquant les libertés individuelles
          comme le droit à un procès équitable, le droit à la vie privée ou le
          droit à la liberté d’expression. Ce long texte réunissait pêle-mêle
          des mesures pour la plupart déjà rédigées en amont qui n’attendaient
          que le bon moment pour être votées. C’est ce que la journaliste Naomi
          Klein a nommé la «&#160;stratégie du choc&#160;»&#160;: l’utilisation
          par les personnes au pouvoir d’un «&#160;choc psychologique&#160;»
          pour renverser la situation à leur avantage. Quatorze&#160;ans plus
          tard, nous le savons, la suite de lois sécuritaires qu’elle a
          combattue depuis sept&#160;ans en est l’amorce&#160;: c’est le même
          destin qui attend la France. «&#160;Face à la déclaration de l’état
          d’urgence et aux annonces faites au cours du week-end, La Quadrature
          du Net demande aux responsables politiques de prendre le temps de la
          réflexion.&#160;»
        </Text>
        <Text>
          Mais au lendemain de cet «&#160;acte de guerre commis par une armée
          terroriste&#160;», comme le qualifie François Hollande, personne n’ose
          rompre les rangs de la solidarité nationale. Depuis un an et demi,
          soit depuis le début de l’été 2014, la Quadrature ne fait pratiquement
          que de l’anti-antiterrorisme. Depuis un an, l’hystérie collective a
          monté en épingle des faits divers d’enfants qui «&#160;ne sont pas
          Charlie&#160;», a jeté une suspicion de sédition sur tous les
          musulmans, a cloué au pilori tous ceux qui ne faisaient pas le jeu de
          l’unité nationale. Le 16&#160;novembre, la Quadrature se sent seule.
        </Text>
        <Text>
          La campagne sur la «&#160;directive Copyright&#160;», qui démarre au
          même moment, est complètement éclipsée par l’actualité médiatique.
          L’antiterrorisme est partout. Nous sommes épuisés et, devant nous, les
          menaces contre les libertés sont plus grandes que jamais.
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter2Part2;
