import React from "react";
import cx from "classnames";

import Styles from "./AppLink.module.scss";
import { Text, TextType } from "src/components";

interface AppLinkProps {
  type?: TextType;
  children?: React.ReactNode;
  href?: string;
  white?: boolean;
}

export const AppLink = ({ type, children, href, white }: AppLinkProps) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={href}
      className={cx(Styles.AppLink, white && Styles.White)}
    >
      <Text type={type}>{children}</Text>
    </a>
  );
};
