import React, { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Introduction from "../bookContent/Introduction";
import Conclusion from "../bookContent/Conclusion";
import Chapter1Part1 from "../bookContent/Chapter1Part1";
import Chapter1Part2 from "../bookContent/Chapter1Part2";
import Chapter1Part3 from "../bookContent/Chapter1Part3";
import Chapter1Part4 from "../bookContent/Chapter1Part4";
import Chapter1Part5 from "../bookContent/Chapter1Part5";
import Chapter1Part6 from "../bookContent/Chapter1Part6";
import Chapter2Part1 from "../bookContent/Chapter2Part1";
import Chapter2Part2 from "../bookContent/Chapter2Part2";
import Chapter2Part3 from "../bookContent/Chapter2Part3";
import Chapter2Part4 from "../bookContent/Chapter2Part4";
import Chapter2Part5 from "../bookContent/Chapter2Part5";
import Chapter2Part6 from "../bookContent/Chapter2Part6";
import Chapter2Part7 from "../bookContent/Chapter2Part7";
import Chapter3Part1 from "../bookContent/Chapter3Part1";
import Chapter3Part2 from "../bookContent/Chapter3Part2";
import Chapter3Part3 from "../bookContent/Chapter3Part3";
import Chapter3Part4 from "../bookContent/Chapter3Part4";
import Chapter3Part5 from "../bookContent/Chapter3Part5";
import Chapter3Part6 from "../bookContent/Chapter3Part6";

interface BookPartType {
  name: string;
  link: string;
  chapter: string;
  part?: string;
  component?: React.ReactNode;
}

export interface UseBookType {
  currentLocation?: string;
  currentChapter?: BookPartType;
  isChapter: boolean;
  book: BookType;
  navigateToBeforePage: () => void;
  navigateToNextPage: () => void;
  isConclusion: boolean;
  isIntroduction: boolean;
}

type BookType = BookPartType[];

const book: BookType = [
  {
    name: "Intro",
    link: "/book/introduction",
    chapter: "Introduction",
    component: <Introduction />,
  },
  {
    name: "I.1",
    link: "/book/I/1",
    chapter: "Partie I - Défendre Internet - (2008-2014)",
    part: "Aux origines de la lutte",
    component: <Chapter1Part1 />,
  },
  {
    name: "I.2",
    link: "/book/I/2",
    chapter: "Partie I - Défendre Internet - (2008-2014)",
    part: "Hadopi, ou l’histoire d’un passage en force",
    component: <Chapter1Part2 />,
  },
  {
    name: "I.3",
    link: "/book/I/3",
    chapter: "Partie I - Défendre Internet - (2008-2014)",
    part: "Le combat contre l’ACTA",
    component: <Chapter1Part3 />,
  },
  {
    name: "I.4",
    link: "/book/I/4",
    chapter: "Partie I - Défendre Internet - (2008-2014)",
    part: "Ces politiques qui n’ont rien compris",
    component: <Chapter1Part4 />,
  },
  {
    name: "I.5",
    link: "/book/I/5",
    chapter: "Partie I - Défendre Internet - (2008-2014)",
    part: "Ces politiques qui veulent tout contrôler",
    component: <Chapter1Part5 />,
  },
  {
    name: "I.6",
    link: "/book/I/6",
    chapter: "Partie I - Défendre Internet - (2008-2014)",
    part: "La fin de l’innocence",
    component: <Chapter1Part6 />,
  },
  {
    name: "II.1",
    link: "/book/II/1",
    chapter:
      "Partie II - De l'obsession (anti)terroriste à la surveillance des géants du Web - (2014-2018)",
    part: "Du prétexte à la panique antiterroriste",
    component: <Chapter2Part1 />,
  },
  {
    name: "II.2",
    link: "/book/II/2",
    chapter:
      "Partie II - De l'obsession (anti)terroriste à la surveillance des géants du Web - (2014-2018)",
    part: "Le terrorisme partout",
    component: <Chapter2Part2 />,
  },
  {
    name: "II.3",
    link: "/book/II/3",
    chapter:
      "Partie II - De l'obsession (anti)terroriste à la surveillance des géants du Web - (2014-2018)",
    part: "Extension des outils antiterroristes",
    component: <Chapter2Part3 />,
  },
  {
    name: "II.4",
    link: "/book/II/4",
    chapter:
      "Partie II - De l'obsession (anti)terroriste à la surveillance des géants du Web - (2014-2018)",
    part: "Protéger nos données : le RGPD en renfort",
    component: <Chapter2Part4 />,
  },
  {
    name: "II.5",
    link: "/book/II/5",
    chapter:
      "Partie II - De l'obsession (anti)terroriste à la surveillance des géants du Web - (2014-2018)",
    part: "Multiplication des fronts : l’heure des choix",
    component: <Chapter2Part5 />,
  },
  {
    name: "II.6",
    link: "/book/II/6",
    chapter:
      "Partie II - De l'obsession (anti)terroriste à la surveillance des géants du Web - (2014-2018)",
    part: "De la démocratie numérique à l’effritement de la démocratie",
    component: <Chapter2Part6 />,
  },
  {
    name: "II.7",
    link: "/book/II/7",
    chapter:
      "Partie II - De l'obsession (anti)terroriste à la surveillance des géants du Web - (2014-2018)",
    part: "La campagne anti-GAFAM",
    component: <Chapter2Part7 />,
  },
  {
    name: "III.1",
    link: "/book/III/1",
    chapter: "Partie III - L’ère de la technopolice - (2018-2022)",
    part: "Naissance de la technopolice",
    component: <Chapter3Part1 />,
  },
  {
    name: "III.2",
    link: "/book/III/2",
    chapter: "Partie III - L’ère de la technopolice - (2018-2022)",
    part: "La technopolice est déjà partout : retour sur un futur sans avenir",
    component: <Chapter3Part2 />,
  },
  {
    name: "III.3",
    link: "/book/III/3",
    chapter: "Partie III - L’ère de la technopolice - (2018-2022)",
    part: "Décentraliser la lutte contre la technopolice",
    component: <Chapter3Part3 />,
  },
  {
    name: "III.4",
    link: "/book/III/4",
    chapter: "Partie III - L’ère de la technopolice - (2018-2022)",
    part: "Quand le numérique menace les libertés",
    component: <Chapter3Part4 />,
  },
  {
    name: "III.5",
    link: "/book/III/5",
    chapter: "Partie III - L’ère de la technopolice - (2018-2022)",
    part: "L’état d’urgence sanitaire, nouvelle stratégie du choc",
    component: <Chapter3Part5 />,
  },
  {
    name: "III.6",
    link: "/book/III/6",
    chapter: "Partie III - L’ère de la technopolice - (2018-2022)",
    part: "Vers un monde de la « sécurité globale » ?",
    component: <Chapter3Part6 />,
  },
  {
    name: "Conclu",
    link: "/book/conclusion",
    chapter: "Conclusion",
    component: <Conclusion />,
  },
];

export const useBook = (): UseBookType => {
  const { chapterId, partNumber } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const currentLocation = useMemo(() => {
    if (chapterId && partNumber) return `${chapterId}.${partNumber}`;
    else if (location.pathname.includes("introduction")) return "Intro";
    else if (location.pathname.includes("conclusion")) return "Conclu";
    return undefined;
  }, [chapterId, location.pathname, partNumber]);

  const currentChapter = useMemo(
    () => book.find((index) => index.name === currentLocation),
    [currentLocation]
  );

  const chapterIndex = book.findIndex(
    (index) => index.name === currentChapter?.name
  );

  const isConclusion = useMemo(
    () => currentChapter?.name === "Conclu",
    [currentChapter?.name]
  );
  const isIntroduction = useMemo(
    () => currentChapter?.name === "Intro",
    [currentChapter?.name]
  );
  const isChapter = useMemo(
    () => !isConclusion && !isIntroduction,
    [isConclusion, isIntroduction]
  );

  const navigateToNextPage = () => {
    const link = isConclusion ? book[0]?.link : book[chapterIndex + 1]?.link;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    link && navigate(link);
  };

  const navigateToBeforePage = () => {
    const link = isIntroduction
      ? book[book.length - 1]?.link
      : book[chapterIndex - 1]?.link;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    link && navigate(link);
  };

  return {
    currentLocation,
    currentChapter,
    isChapter,
    book,
    navigateToBeforePage,
    navigateToNextPage,
    isConclusion,
    isIntroduction,
  };
};
