import React from "react";

import { Text, PagePattern } from "src/components";

const Conclusion = () => {
  return (
    <PagePattern partName="Conclusion">
      <>
        <Text>
          Les risques liés au numérique évoluent à mesure que l’humanité
          l’invente et le découvre. Dans ce contexte en constante mutation, nous
          nous essayons à de nouvelles formes de lutte pour défendre les valeurs
          que la Quadrature incarne. Sans jamais être certains de l’issue des
          batailles.
        </Text>
        <Text>
          Les sujets sont protéiformes. Les attaques viennent autant
          d’entreprises privées, petites ou grandes, françaises ou
          internationales, que des gouvernements. Aucune solution n’est simple
          et évidente. Il nous faut donc trouver comment mobiliser l’opinion
          publique, comment l’embarquer dans nos combats, à la croisée du droit,
          de la technologie et de la politique. Informer et agir, et tenter
          toujours d’être le plus efficace possible, dans les limites de nos
          ressources humaines et financières. La lutte ne pourra jamais être
          menée par quelques militants seulement, elle ne peut se restreindre à
          une seule, ni même à plusieurs associations.
        </Text>
        <Text>
          Augmenter le coût politique d’attaquer les libertés fondamentales est
          l’un de nos objectifs depuis le départ. Mais l’effort que nécessite
          cette stratégie est considérable. La censure, la violation de la
          neutralité du Net, la surveillance des réseaux, la vidéosurveillance
          algorithmique…&#160;: les rendre inacceptables aux yeux de tout un
          chacun est la seule manière d’obtenir une victoire ferme et pérenne.
          Transformer des sujets d’experts en évidences culturelles, c’est
          peut-être l’ultime défi de La&#160;Quadrature du Net.
        </Text>
        <Text>
          Nous continuerons à aller dans les médias, à participer à des
          événements associatifs, à répondre aux invitations partout en France
          et en Europe dès qu’il s’agira de sujets liés aux libertés dans
          l’espace numérique. Mais c’est surtout sur vous que repose ce travail
          de conviction. Pour dépasser nos cercles proches et diffuser nos
          idées, vos idées, dans la société, c’est sur vous que nous comptons.
          En offrant, par exemple, ce livre à vos amis&#160;! En leur expliquant
          le danger inhérent à la publicité ciblée ou à la surveillance
          totale(-itaire) de l’espace public. En rejoignant une association
          locale. Et surtout, en vous rappelant que la force d’une démocratie
          repose moins sur un vote une fois tous les cinq&#160;ans que sur
          l’engagement de citoyennes et de citoyens au quotidien, pour
          contrebalancer le pouvoir d’un État qui peut si facilement dériver.
        </Text>
      </>
    </PagePattern>
  );
};

export default Conclusion;
