import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter2Part1 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Du prétexte à la panique antiterroriste">
      <>
        <Text>
          Quelques mois à peine se sont écoulés depuis les révélations d’Edward
          Snowden sur l’espionnage massif des citoyens, conduit par la NSA
          américaine, quand la loi de programmation militaire (ou LPM pour les
          initiés) arrive au Parlement français. Elle est présentée au Sénat le
          2&#160;août 2013, où elle est discutée au mois d’octobre. Devant
          l’amplification des menaces terroristes qui pèsent sur la France, la
          LPM reprend les propositions du «&#160;Livre blanc sur la défense et
          la sécurité nationale
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.defense.gouv.fr/content/download/206186/2286591/Livre-blanc-sur-la-Defense-et-la-Securite-nationale%202013.pdf"
                >
                  https://www.defense.gouv.fr/content/download/206186/2286591/Livre-blanc-sur-la-Defense-et-la-Securite-nationale%202013.pdf
                </AppLink>
              )
            }
          >
            71
          </InfoClick>
          &#160;» paru le 29&#160;avril 2013 et approuvé par le président de la
          République, François Hollande.
        </Text>
        <Text>
          Parmi ces propositions&#160;figure le renforcement de l’accès aux
          données informatiques par les services de renseignement de la police
          et de la gendarmerie. Il s’agit d’accéder à la fois aux données de
          connexion (logins de connexion, métadonnées d’un e-mail ou de SMS) et
          au contenu des correspondances. Pour la majorité parlementaire, la
          volonté est «&#160;d’harmoniser&#160;» les différents cadres
          existants. Néanmoins, au fur et à mesure des débats, on comprend
          qu’«&#160;harmoniser&#160;» signifie surtout «&#160;étendre
          l’accès&#160;», en permettant aux services de renseignement de
          s’adresser non plus seulement aux fournisseurs d’accès à Internet
          (FAI), mais aussi aux hébergeurs de contenus. La LPM cherche aussi à
          allonger la liste des administrations qui peuvent accéder à ces
          données&#160;: aux Douanes, aux ministères de la Défense et de
          l’Intérieur s’ajoutent désormais ceux de l’Économie ou encore du
          Budget.
        </Text>
        <Text>
          Enfin, elle veut élargir la nature des données concernées, pour y
          inclure par exemple la géolocalisation. Jean-Pierre Sueur, sénateur de
          la majorité et auteur de l’amendement, explique que cela permettra
          d’encadrer des pratiques qui «&#160;existaient sans bases
          juridiques&#160;». En d’autres termes, les services de renseignement
          demandaient et utilisaient déjà ces données, alors que celles-ci
          n’étaient pas explicitement prévues par la législation précédente. Il
          s’agit d’une absence dans la loi. D’une zone grise. L’action n’est
          donc selon eux pas illégale, mais plutôt «&#160;a-légale&#160;». Jolie
          argutie rhétorique, que l’on retrouvera par la suite quand il sera
          question de valider a posteriori des activités illégales de la police
          ou du renseignement.
        </Text>
        <Text type="h3">
          La&#160;démocratie à&#160;l’épreuve des&#160;lois sécuritaires
        </Text>
        <Text>
          Demander des informations à des tiers, voyez-vous, c’est un peu long.
          Alors le gouvernement propose la mise en place d’une collecte
          «&#160;en temps réel&#160;» des données grâce à la
          «&#160;sollicitation&#160;» du réseau. Cette formulation est floue,
          très floue. Techniquement, personne ne sait vraiment à quoi elle
          correspond. Ce flou conduit certains observateurs
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.lemonde.fr/technologies/article/2013/11/26/surveillance-d-internet-inquietudes-autour-de-la-loi-de-programmation-militaire_3518974_651865.html"
                >
                  https://www.lemonde.fr/technologies/article/2013/11/26/surveillance-d-internet-inquietudes-autour-de-la-loi-de-programmation-militaire_3518974_651865.html
                </AppLink>
              )
            }
          >
            72
          </InfoClick>
          , nous y compris, à se demander s’il s’agit d’installer des
          dispositifs d’interception directement sur les équipements du réseau
          (câbles, serveurs, équipements des FAI, antennes de téléphonie…)
          –&#160;comme a pu le faire la NSA dix&#160;ans auparavant en aspirant
          une copie des données transitant par les câbles de fibre optique
          sous-marins dans l’énigmatique «&#160;Room 641A
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.sfgate.com/tech/article/nsa-spying-network-att-folsom-room-641a-13028155.php"
                >
                  https://www.sfgate.com/tech/article/nsa-spying-network-att-folsom-room-641a-13028155.php
                </AppLink>
              )
            }
          >
            73
          </InfoClick>
          &#160;». Ces spéculations inquiétantes présagent des futurs débats sur
          ces dispositifs d’interception, surnommés «&#160;boîtes noires&#160;»,
          car destinés à enregistrer tout ce qui (se) passe sur le réseau. Nous
          y reviendrons.
        </Text>
        <Text>
          De fait, la loi permet la capture en temps réel et sur simple demande
          administrative, donc sans supervision ni mandat judiciaire, des
          informations et des documents traités dans les réseaux concernant tout
          un chacun, et rend permanents des dispositifs qui n’étaient que
          temporaires. Elle passe à l’Assemblée dans une relative indifférence à
          l’automne, puis est adoptée par le Sénat en décembre, malgré notre
          mobilisation, relayée dans certains médias
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <AppLink
                    white
                    href="https://www.lemonde.fr/blog/bugbrother/2013/12/10/tout-ce-que-vous-avez-toujours-voulu-savoir-sur-la-lpm-et-que-vous-avez-ete-nombreux-a-me-demander/"
                  >
                    https://www.lemonde.fr/blog/bugbrother/2013/12/10/tout-ce-que-vous-avez-toujours-voulu-savoir-sur-la-lpm-et-que-vous-avez-ete-nombreux-a-me-demander/
                  </AppLink>
                  <br />
                  <AppLink
                    white
                    href="https://lexpansion.lexpress.fr/high-tech/notre-liberte-sur-internet-est-elle-menacee-par-la-loi-de-programmation-militaire_1327415.html"
                  >
                    https://lexpansion.lexpress.fr/high-tech/notre-liberte-sur-internet-est-elle-menacee-par-la-loi-de-programmation-militaire_1327415.html
                  </AppLink>
                </>
              )
            }
          >
            74
          </InfoClick>
          .
        </Text>
        <Text>
          La seule institution encore capable de s’y opposer est le Conseil
          constitutionnel. Alors que la Quadrature avait gagné ses lettres de
          noblesse du militantisme en s’immisçant dans le débat parlementaire
          via le lobbying citoyen, nous devons désormais lutter pour nos idées
          sur le plan judiciaire, en engageant des procédures. Nous appelons
          ainsi solennellement les parlementaires à déposer une saisine du
          Conseil constitutionnel pour que ce dernier se prononce sur la
          conformité de cette loi à la Constitution. Et nous signons trois jours
          plus tard une lettre ouverte avec la Fédération internationale pour
          les droits humains (FIDH), la Ligue des droits de l’homme (LDH) et
          Reporters sans frontières
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2013/12/13/loi-de-programmation-militaire-les-parlementaires-doivent-saisir-le-conseil-constitutionnel/"
                >
                  https://www.laquadrature.net/2013/12/13/loi-de-programmation-militaire-les-parlementaires-doivent-saisir-le-conseil-constitutionnel/
                </AppLink>
              )
            }
          >
            75
          </InfoClick>
          .
        </Text>
        <Text>
          C’est dans la foulée de ces événements qu’est créé, le 28&#160;janvier
          2014 (journée internationale de la protection des données
          personnelles), l’Observatoire des libertés et du numérique (OLN). Son
          but&#160;? «&#160;Sensibiliser et alerter l’opinion publique sur les
          dérives possibles, utiliser tous les instruments juridiques
          disponibles afin de défendre les droits et les libertés. […] Initier
          et encourager les oppositions à tout projet liberticide. […] Dénoncer,
          d’une part, la prolifération des moyens de surveillance dans tous les
          domaines de la vie privée et socio-économique et, d’autre part, la
          généralisation de la collecte, du stockage, de l’utilisation et de la
          réutilisation indus des données personnelles. [Appeler] à la mise en
          œuvre de dispositifs de contrôle effectifs des fichiers et des
          technologies de surveillance actuelles et à venir ainsi qu’au
          développement de protections effectives des données
          personnelles.&#160;» Vaste programme, qui rappelle furieusement les
          missions que s’est données la Quadrature. C’est donc sans surprise que
          nous rejoignons l’OLN (et nous en faisons toujours partie).
        </Text>
        <Text>
          2014 va être une année charnière pour le paysage politique français.
          Alors que la LPM n’est pas encore mise en place (son décret
          d’application n’arrivera qu’un an plus tard), les choses
          s’accélèrent&#160;: le terrorisme, épouvantail rhétorique dans les
          discours politiques depuis des années, devient plus tangible dans la
          vie des Français et des Européens. Daech proclame en juin&#160;2014 un
          califat entre l’Irak et la Syrie&#160;: l’État islamique. La menace
          terroriste se répand avec un souffle nouveau dans le champ médiatique
          occidental. La France découvre l’existence de djihadistes radicalisés
          sur son territoire, qui partent en Syrie se former et prendre les
          armes.
        </Text>
        <Text>
          Le même mois, Guillaume Larrivé, député UMP, propose dans un rapport
          une batterie de mesures pour stopper ces «&#160;loups
          solitaires&#160;». Ce rapport commence par une section intitulée
          «&#160;Internet est devenu le premier vecteur de la propagande
          djihadiste et le principal moyen de recrutement de terroristes&#160;».
          Cette inquiétante déclaration donne le ton des actions prescrites par
          le rapport, qui vont de la création d’une «&#160;liste noire&#160;» de
          sites à censurer (car faisant l’apologie du terrorisme), à celle d’un
          délit de consultation habituelle de ces sites, en passant par
          l’instauration d’une «&#160;cyberpatrouille&#160;».
        </Text>
        <Text>
          Certes, il serait stupide et contre-productif de nier qu’Internet est
          utilisé efficacement par les mouvements terroristes (de toutes sortes)
          pour diffuser leurs idées et recruter, mais il y a un pas certain à
          dire qu’il est le principal moyen de recrutement des terroristes, un
          pas que le rapport franchit sans hésitation sans pour autant étayer
          son propos par des études. À la place, de multiples témoignages, dont
          ceux de responsables des services de renseignement qui sont –&#160;on
          peut presque les comprendre&#160;– les premiers à demander plus de
          pouvoirs sur Internet. À&#160;la même période, on assiste à une
          réorganisation des services de renseignement français, la Direction
          centrale du renseignement intérieur (DCRI) devenant en avril la
          Direction générale de la sécurité intérieure (DGSI). Qui dit
          «&#160;direction générale&#160;» dit pouvoirs, effectifs et budgets
          étendus. Demander à ceux dont le rôle est de surveiller s’ils
          souhaitent obtenir plus de pouvoirs plutôt que de s’appuyer sur des
          analyses chiffrées de la situation aboutit invariablement à renforcer
          la mainmise des services de renseignement sur Internet.
        </Text>
        <Text>
          Du côté de la Quadrature, le potentiel de détournement de ces mesures
          pour porter atteinte aux libertés fondamentales des citoyens saute aux
          yeux. Au-delà de l’inefficacité technique des mesures de censure,
          inévitablement contournables par les personnes un tant soit peu
          motivées, l’idée d’un délit de consultation de sites terroristes pose
          de réelles questions politiques et éthiques&#160;: qui décide de ce
          qui est terroriste&#160;? Qui en a la légitimité&#160;? Il n’y a pas
          grand débat à rappeler que les terroristes des uns sont les défenseurs
          de la liberté des autres. En&#160;pratique, la qualification de
          «&#160;terroriste&#160;» n’est souvent pas évidente et elle est de
          plus une arme politique puissante –&#160;le traitement des Black
          Panthers en est un exemple. Et la censure&#160;: sur quels critères
          est-elle décidée&#160;? Ceux-ci sont-ils publics&#160;? Peut-on
          vérifier, liste à l’appui, qu’ils sont correctement appliqués&#160;?
          Et si ce n’est pas le cas, comment croire que les décisions ne
          verseront pas dans l’arbitraire&#160;? Quoi qu’il en soit, la
          «&#160;liste noire&#160;» ainsi compilée ne devient-elle pas un
          annuaire, dont l’efficacité est aussi fluctuante que les changements
          d’adresse des sites Web&#160;?
        </Text>
        <Text>
          En outre, ce très orwellien «&#160;délit de consultation de sites
          terroristes&#160;» accorde un pouvoir de conviction quasi magique à
          ces sites, si dangereux que le bon peuple ne devrait même pas pouvoir
          les voir, au risque de tomber sous leur charme. Il simplifie également
          de manière caricaturale les raisons de consulter un site de propagande
          de Daech, en les réduisant à une seule&#160;:
          l’«&#160;auto-radicalisation&#160;». C’est oublier le travail des
          journalistes, des chercheurs, des activistes… Le temps n’est pas à la
          finesse ou à la pensée complexe. Et, bizarrement, personne ne parle de
          couper l’accès au site le plus utilisé par les terroristes pour entrer
          en contact&#160;: Facebook.
        </Text>
        <Text>
          Porté par son rapport, le député Larrivé dépose en avril&#160;2014 à
          l’Assemblée nationale avec les députés Ciotti, Goujon et Marleix une
          proposition de loi «&#160;renforçant la lutte contre l’apologie du
          terrorisme sur Internet
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.assemblee-nationale.fr/14/propositions/pion1907.asp"
                >
                  https://www.assemblee-nationale.fr/14/propositions/pion1907.asp
                </AppLink>
              )
            }
          >
            76
          </InfoClick>
          &#160;». Bernard Cazeneuve, alors ministre de l’Intérieur, reprend
          dans sa proposition de loi une grande partie des préconisations des
          députés, mettant en place un dispositif de blocage de sites
          «&#160;faisant l’apologie du terrorisme&#160;». De nouveau, choqués
          par la mise en place d’une infrastructure de censure qui risque d’être
          réutilisée à d’autres fins, nous lançons une campagne dénonçant la
          manière dont les parlementaires abandonnent les libertés fondamentales
          au nom de l’antiterrorisme, créant un dangereux précédent et des
          risques inacceptables dans un État dit démocratique.
        </Text>
        <Text>
          La «&#160;loi Antiterrorisme&#160;» est néanmoins votée pratiquement
          en l’état, et promulguée le 13&#160;novembre 2014. En parallèle, la
          communauté internationale s’engage contre l’État islamique, à la suite
          de l’intervention militaire en août des États-Unis en Irak. À
          l’automne 2014, le Parlement débat d’un nouveau projet de loi, le
          projet de loi de Larrivé, renforçant les dispositions relatives à la
          lutte contre le terrorisme. Encore.
        </Text>
        <Text type="h3">Un&#160;nouvel outil&#160;: les&#160;tribunaux</Text>
        <Text>
          À la Quadrature, on ne sait plus où donner de la tête. Sur le site de
          l’organisation, le rythme de publication d’analyses politiques sur les
          mesures antiterroristes s’accélère. Les lois sécuritaires se succèdent
          et passent les unes après les autres, dans un sentiment de danger
          imminent qui vient peser sur les débats et neutraliser l’analyse
          rationnelle. Le lobbying parlementaire ne fonctionne plus.
        </Text>
        <Text>
          Malgré tout, nous tentons une nouvelle fois de réinventer nos moyens
          d’action, leitmotiv de la Quadrature depuis sa naissance. Le
          26&#160;décembre 2014, lendemain de Noël, quelque part sur Internet un
          salon de discussion IRC s’anime. Le premier décret d’application de la
          LPM vient d’être publié. Les militants de la Quadrature, mais aussi de
          French Data Network (FDN) et de la Fédération des fournisseurs d’accès
          à Internet associatifs (FFDN) prennent connaissance du texte. C’est un
          groupe hétéroclite de geeks et de juristes, qui travaillent ensemble
          depuis quelques mois déjà sur les dangers que pose la LPM. Ils ont
          participé à l’analyse du projet de loi, aux propositions des
          amendements, à la rédaction des communiqués. Et surtout, ils sont
          prêts. Ce décret, voilà plusieurs mois qu’ils l’attendent. Ils ont
          deux mois à compter de sa sortie pour l’attaquer devant le Conseil
          d’État et ils comptent bien ne pas manquer l’échéance.
        </Text>
        <Text>
          À force de préparation, les geeks se prennent au jeu du juridique et
          apprennent les ressorts des différentes juridictions, les mécanismes
          du droit administratif et, surtout, son vocabulaire. Les juristes,
          eux, apprivoisent IRC et rédigent les procédures en LaTeX, cet obscur
          système de composition de documents, sur des <i>pads</i>{" "}
          auto-hébergés. L’auto-formation bat son plein avec enthousiasme et les
          points de vue se croisent et se complètent.
        </Text>
        <Text>
          En janvier&#160;2015, moins d’un mois plus tard, le groupe de travail
          publie son premier mémoire et se met à attaquer en justice les décrets
          du gouvernement, les uns après les autres. À peine créé, le groupe
          informel est déjà identifié dans les débats à l’Assemblée
          nationale&#160;: le député Jean-Jacques Urvoas (à l’époque pas encore
          condamné pour violation du secret professionnel) les qualifie avec un
          dédain perceptible d’«&#160;exégètes amateurs&#160;», en référence à
          leur travail d’analyse juridique. Être cités dans un rapport de la
          commission des lois
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.assemblee-nationale.fr/14/rapports/r2697.asp"
                >
                  https://www.assemblee-nationale.fr/14/rapports/r2697.asp
                </AppLink>
              )
            }
          >
            77
          </InfoClick>
          , c’est une consécration&#160;! Alors, après quelques mois à
          fonctionner sans nom pour se désigner, quand le groupe informel en
          vient à s’en choisir un, il fait écho à une ancienne tradition
          quadraturienne de réappropriation des sobriquets qui lui sont
          attribués. En avril&#160;2015 naissent les Exégètes Amateurs.
          L’engouement dépasse le petit cercle militant, et des outils sont mis
          en ligne dans le même esprit de dénonciation du texte et des soutiens
          de la LPM
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Voir par exemple </Text>
                  <AppLink white href="https://exegeteamateur.github.io/">
                    https://exegeteamateur.github.io/
                  </AppLink>
                </>
              )
            }
          >
            78
          </InfoClick>
          .
        </Text>
        <Text>
          Après avoir «&#160;hacké le Parlement&#160;», en permettant à tous les
          citoyens de participer au lobbying, voici le «&#160;hack juridique de
          la procédure législative&#160;». Le combat se déplace. Au milieu de
          cette période éprouvante, émerge un nouveau moyen d’action crédible,
          pouvant potentiellement prendre le relais du plaidoyer politique et
          parlementaire. La Quadrature, portée par ce souffle nouveau, est en
          marche contre l’antiterrorisme, et la victoire semble atteignable.
        </Text>
        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter2Part1;
