import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter3Part5 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="L’état d’urgence sanitaire, nouvelle stratégie du choc">
      <>
        <Text>
          En mars&#160;2020, le ciel nous tombe sur la tête. Dans la stupeur du
          confinement décrété le 17&#160;mars, l’association est comme à
          l’arrêt. Chacun chez soi, nous sommes scotchés aux réseaux sociaux, à
          la télé et aux sites de presse pour essayer de comprendre ce qui se
          passe. Et très vite, ce qu’on voit nous pousse à agir –&#160;sans
          sortir de chez nous, et dans le respect des gestes barrières.
        </Text>

        <Text type="h3">L’attaque des&#160;drones</Text>
        <Text>
          Première stupéfaction&#160;: les drones policiers envahissent les
          villes. En quelques jours, ils sont partout. Quelques semaines plus
          tôt, en&#160;décembre&#160;2019 ou en janvier&#160;2020, on voyait sur
          les chaînes d’info des reportages amusés et condescendants sur
          «&#160;la Chine&#160;», pays lointain, exotique et totalitaire, dans
          lequel la population était entièrement confinée sur ordre du pouvoir
          (imaginez ça chez nous&#160;!), dans une spectaculaire tentative
          d’endiguer une épidémie mystérieuse, mortelle et fulgurante. Regardez
          ces images, mesdames et messieurs, c’est incroyable&#160;: des drones
          pilotés par des policiers sillonnent les rues pour rappeler à l’ordre
          les habitants terrorisés, et pour filmer les contrevenants.
        </Text>
        <Text>
          Fin mars&#160;2020, les mêmes reportages sont tournés en France&#160;:
          regardez comment ces sympathiques policiers utilisent un matériel
          ultramoderne et hypertechnologique pour rassurer nos
          concitoyens&#160;! Regardez comme le gouvernement prend bien les
          choses en main, et comme nos rues sont sûres&#160;! Gros plan sur
          l’appareil au sol, plan de coupe sur les visages des policiers, l’air
          soucieux et impliqués, penchés sur l’écran de contrôle du drone, plan
          sur le drone en vol, interview de l’officier qui explique, ton
          responsable et ferme, que c’est un outil moderne et que les
          incivilités doivent être combattues pour le bien de tous. C’est dans
          la boîte.
        </Text>
        <Text>
          Aucune exagération. Le réel s’est caricaturé lui-même, au prix d’un
          retournement de veste hautement acrobatique. Trois éléments sont
          frappants. D’abord, la docilité des grands médias télé, qui assurent
          bénévolement la communication des préfets et du ministère de
          l’Intérieur, sans aucune distance critique. Ensuite, la facilité avec
          laquelle ce qui était présenté il y a peu comme le symbole du
          totalitarisme peut devenir, en changeant seulement la légende qui
          accompagne l’image, le symbole de l’ordre et de la paix&#160;: c’est à
          quoi l’on s’expose, à force de dire que la sécurité est la première
          des libertés. Enfin, le cynisme avec lequel le ministère de
          l’Intérieur pousse son agenda. On pourrait penser à une sorte de
          gesticulation dans le vide, dans une période où il est privé de son
          efficacité –&#160;la police ne sait soigner personne. Mais connaissant
          la hiérarchie policière et le ministère de l’Intérieur, il faut
          envisager aussi une stratégie délibérée, qui n’hésite pas à saisir
          toutes les occasions de rendre la police nécessaire et la surveillance
          sympathique. La motivation d’ordre politique est peut-être renforcée
          aussi par un impératif plus trivial de publicité&#160;: dans une
          logique de privatisation de l’État et de rétrécissement des budgets
          publics, chaque ministère doit se mettre en avant pour gagner des
          arbitrages et augmenter ses dotations.
        </Text>
        <Text>
          Quoi qu’il en soit, l’irruption des drones dans le paysage sécuritaire
          est si frappante que nous publions dès le 4&#160;avril 2020, dans la
          troisième semaine du confinement, un article intitulé «&#160;L’attaque
          des drones
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Covid-19&#160;: l’attaque des drones », 4&#160;avril
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://technopolice.fr/blog/covid-19-lattaque-des-drones/"
                  >
                    https://technopolice.fr/blog/covid-19-lattaque-des-drones/
                  </AppLink>
                </>
              )
            }
          >
            254
          </InfoClick>
          &#160;». Le texte recense d’abord un certain nombre de vols de drones
          médiatisés et documentés, pour donner la mesure de l’ampleur et de la
          gravité des choses&#160;: les reportages télévisés montraient des
          drones équipés de haut-parleurs pour diffuser les consignes de
          confinement dans les rues et «&#160;prévenir&#160;», mais on apprend
          que des personnes qui se promenaient ont été suivies par des drones
          avant d’être verbalisées par la police –&#160;pour les
          «&#160;guérir&#160;»&#160;? L’article replace ensuite cette
          utilisation nouvelle dans une continuité&#160;: les drones sont
          utilisés depuis 2014 au moins, pour surveiller les migrants aux
          frontières, les manifestations, ou les ZAD, mais avec une grande
          discrétion médiatique, dans un brouillard de secret presque militaire.
          Tout se passe comme si la crise sanitaire venait soudain décomplexer
          les policiers, justifier la posture sécuritaire. Enfin, l’article
          constate que l’utilisation des drones de surveillance n’est pas
          encadrée par la loi.
        </Text>
        <Text>
          Bizarrement, le sujet prend. Dans la vacuité de l’actualité, ou dans
          sa grande monotonie, la présence des drones policiers surprend et
          choque l’opinion. Sans doute le contraste entre la débauche de haute
          technologie de surveillance et le dénuement de l’hôpital public a-t-il
          aussi un rôle de révélateur. En tout cas, si l’on en croit les rares
          indicateurs de l’humeur publique que nous pouvions avoir en restant
          confinés chez nous, à savoir les dons de soutien à l’association et
          les e-mails reçus à notre adresse publique (
          <a href="http://contact@laquadrature.net">contact@laquadrature.net</a>
          ), le sujet déchaîne les passions. Beaucoup plus, par exemple, que la
          surveillance des manifestations pendant les quatorze mois de
          «&#160;gilets jaunes&#160;» qu’on vient de traverser. C’est une
          surveillance concrète qu’on peut voir et qui, en plus, parle. On a
          l’impression que tous les commissariats se lâchent et se mettent à
          surveiller à travers leurs robots volants&#160;: surveillance des
          plages, des forêts, des villes, à distance.
        </Text>
        <Text>
          Le 12&#160;avril 2020, après un mois de confinement strict, le
          ministère de l’Intérieur publie un appel d’offres pour l’achat de
          650&#160;drones, pour un montant de 4&#160;millions d’euros. Parmi les
          clauses de détail, on découvre «&#160;180&#160;micro-drones du
          quotidien
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Pourquoi le ministère de l’Intérieur vient-il de commander
                    des drones ? », <i>Libération</i>, 15&#160;avril 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.liberation.fr/checknews/2020/04/15/pourquoi-le-ministere-de-l-interieur-vient-il-de-commander-des-drones_1785166/"
                  >
                    https://www.liberation.fr/checknews/2020/04/15/pourquoi-le-ministere-de-l-interieur-vient-il-de-commander-des-drones_1785166/
                  </AppLink>
                </>
              )
            }
          >
            255
          </InfoClick>
          &#160;». Vous le saviez, vous, qu’il existait des drones de
          surveillance «&#160;du quotidien&#160;»&#160;? «&#160;Devant les
          sénateurs, Christophe Castaner a réfuté tout lien avec le confinement
          mais ne se privera pas de les utiliser dans cette optique
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Le ministère de l’Intérieur a-t-il commandé 650 drones
                    pour aider au respect du confinement ? », LCI, 16&#160;avril
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lci.fr/police/le-ministere-de-l-interieur-a-t-il-commande-650-drones-pour-aider-au-respect-du-confinement-2151170.html"
                  >
                    https://www.lci.fr/police/le-ministere-de-l-interieur-a-t-il-commande-650-drones-pour-aider-au-respect-du-confinement-2151170.html
                  </AppLink>
                </>
              )
            }
          >
            256
          </InfoClick>
          &#160;», précise LCI.
        </Text>
        <Text>
          Une utilisation décomplexée, mais une utilisation illégale.
          Conjointement avec la LDH, nous déposons le 4&#160;mai 2020 un recours
          devant le tribunal administratif de Paris
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Nous attaquons les drones de la police parisienne »,
                    4&#160;mai 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/05/04/nous-attaquons-les-drones-de-la-police-parisienne/"
                  >
                    https://www.laquadrature.net/2020/05/04/nous-attaquons-les-drones-de-la-police-parisienne/
                  </AppLink>
                </>
              )
            }
          >
            257
          </InfoClick>
          , contre l’utilisation des drones par la préfecture de police de
          Paris, sous les ordres du préfet Didier Lallement. Le tribunal
          administratif nous déboute de notre référé-liberté, une procédure qui
          juge en urgence d’une atteinte aux libertés. Qu’à cela ne tienne, nous
          allons devant le Conseil d’État pour une décision sur le fond.
          L’audience se tient le 15&#160;mai, et le Conseil d’État écoute
          longuement notre avocat, celui de la LDH, et le représentant du
          ministère de l’Intérieur. Le 18&#160;mai, l’ordonnance du Conseil
          d’État cloue les drones au sol. Nous avons gagné
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Les goélands abattent leur premier drone », 18&#160;mai
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/05/18/les-goelands-abattent-leur-premier-drone/"
                  >
                    https://www.laquadrature.net/2020/05/18/les-goelands-abattent-leur-premier-drone/
                  </AppLink>
                </>
              )
            }
          >
            258
          </InfoClick>
          .
        </Text>
        <Text>
          Mais le préfet emploie de nouveau les drones au-dessus des
          manifestations parisiennes, dès le «&#160;déconfinement&#160;», en
          toute illégalité. Comme nous sommes encore plus têtus que le préfet,
          nous retournons fin octobre&#160;2020 devant le Conseil d’État
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Drones en manifestation&#160;: la Quadrature
                    contre-attaque », 26&#160;octobre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/10/26/drones-en-manifestation-la-quadrature-contre-attaque/"
                  >
                    https://www.laquadrature.net/2020/10/26/drones-en-manifestation-la-quadrature-contre-attaque/
                  </AppLink>
                </>
              )
            }
          >
            259
          </InfoClick>
          , qui réitère son jugement&#160;: en l’absence d’une nécessité
          démontrée, les drones en manifestation sont interdits
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2020/12/22/interdiction-des-drones-victoire-totale-contre-le-gouvernement/"
                >
                  https://www.laquadrature.net/2020/12/22/interdiction-des-drones-victoire-totale-contre-le-gouvernement/
                </AppLink>
              )
            }
          >
            260
          </InfoClick>
          . Les goélands parisiens, qui attaquaient les drones pendant les
          manifestations, deviennent notre mascotte officielle.
        </Text>

        <Text type="h3">Flambée de&#160;l’épidémie de&#160;surveillance</Text>
        <Text>
          L’ivresse sécuritaire en pleine épidémie ne s’arrête pas aux drones.
          La vidéosurveillance automatisée refait son apparition, avec de
          nouveaux gadgets. Des mairies proposent d’utiliser des caméras
          thermiques pour détecter les personnes fiévreuses, et donc
          probablement infectées et contagieuses, à l’entrée des magasins ou des
          bâtiments recevant du public. Une telle proposition aurait fait sauter
          au plafond en 2019. En&#160;2020, on se contente de souligner
          –&#160;et le lobby industriel le premier&#160;– que ça ne fonctionne
          pas encore très bien. Comme si le principal était de «&#160;faire
          quelque chose&#160;», et de montrer qu’on fait quelque chose
          –&#160;donc, certaines villes mettent des caméras thermiques à
          l’entrée des écoles
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Le Conseil d’État, saisi par la LDH, interdit ces caméras
                    thermiques le 29&#160;juin 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.usine-digitale.fr/article/covid-19-le-conseil-d-etat-interdit-l-usage-des-cameras-thermiques-dans-les-ecoles.N980456"
                  >
                    https://www.usine-digitale.fr/article/covid-19-le-conseil-d-etat-interdit-l-usage-des-cameras-thermiques-dans-les-ecoles.N980456
                  </AppLink>
                </>
              )
            }
          >
            261
          </InfoClick>
          …
        </Text>
        <Text>
          Les entreprises de la surveillance ont elles aussi une imagination
          sans limite et une solide propension à l’opportunisme –&#160;qualités
          entrepreneuriales par excellence, appelées souplesse, réactivité,
          créativité, proactivité, adaptabilité, disruption, ou quelque chose
          comme ça. Prenez par exemple la société Datakalab
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Fiche de la société Datakalab sur le site
                    Technopolice&#160;:{" "}
                  </Text>
                  <AppLink white href="https://technopolice.fr/datakalab/">
                    https://technopolice.fr/datakalab/
                  </AppLink>
                </>
              )
            }
          >
            262
          </InfoClick>
          . Cette petite start-up qui se réclame de la <i>brain-tech</i>{" "}
          (concept audacieux) s’est spécialisée dans la détection des émotions
          par reconnaissance faciale, en visant plutôt des applications
          marketing, comme la mesure de l’efficacité des publicités. Bel exemple
          de capitalisme de surveillance&#160;: en avril&#160;2020, la société
          propose à la ville de Cannes de tester ses logiciels sur les caméras
          municipales, pour voir si les habitants portent bien le masque sur les
          marchés de la ville et dans les bus
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Coronavirus à Cannes&#160;: Surprise ! Dans ces marchés,
                    des caméras vérifient si vous êtes bien masqués »,{" "}
                    <i>20&#160;minutes</i>, 28&#160;avril 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.20minutes.fr/nice/2768871-20200428-coronavirus-cannes-surprise-marches-cameras-verifient-si-bien-masques"
                  >
                    https://www.20minutes.fr/nice/2768871-20200428-coronavirus-cannes-surprise-marches-cameras-verifient-si-bien-masques
                  </AppLink>
                </>
              )
            }
          >
            263
          </InfoClick>
          . Loin de choquer, l’initiative plaît&#160;: en mai&#160;2020, la RATP
          passe un contrat avec Datakalab, pour une expérience d’ampleur à la
          station Châtelet, une des plus fréquentées de Paris. Les logiciels de
          l’entreprise sont couplés aux caméras de surveillance de la régie pour
          vérifier si les voyageurs portent un masque et respectent bien la
          «&#160;distanciation sociale&#160;» dans les couloirs de la station
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La RATP va tester des caméras intelligentes pour mesurer
                    le taux de port du masque dans la station Châtelet »,{" "}
                    <i>Le Monde</i>, 7&#160;mai 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lemonde.fr/pixels/article/2020/05/07/ratp-des-cameras-intelligentes-pour-mesurer-le-taux-de-port-du-masque-dans-la-station-chatelet_6039008_4408996.html"
                  >
                    https://www.lemonde.fr/pixels/article/2020/05/07/ratp-des-cameras-intelligentes-pour-mesurer-le-taux-de-port-du-masque-dans-la-station-chatelet_6039008_4408996.html
                  </AppLink>
                </>
              )
            }
          >
            264
          </InfoClick>
          . Mais cette fois-ci, la CNIL s’interpose. Elle avait laissé faire
          l’expérience cannoise, jugeant que les données étaient suffisamment
          anonymisées pour ne présenter aucun risque pour la vie privée. Et puis
          les personnes qui ne donnaient pas leur consentement pouvaient faire
          «&#160;non&#160;» avec la tête… Mais dans le métro Châtelet, aucune
          possibilité de ne pas donner son consentement&#160;: c’est illégal.
          L’expérience est suspendue en juin&#160;2020
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Trop intrusives, les caméras de détection de masques
                    désactivées à Paris et à Cannes », BFM, 22&#160;juin
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.bfmtv.com/tech/trop-intrusives-les-cameras-de-detection-de-masques-desactivees-a-paris-et-a-cannes_AN-202006220163.html"
                  >
                    https://www.bfmtv.com/tech/trop-intrusives-les-cameras-de-detection-de-masques-desactivees-a-paris-et-a-cannes_AN-202006220163.html
                  </AppLink>
                </>
              )
            }
          >
            265
          </InfoClick>
          .
        </Text>
        <Text>
          L’avis de la CNIL précise&#160;: «&#160;Si des données sensibles sont
          traitées, telles que la captation d’informations personnelles de santé
          ou d’informations biométriques, ou si le droit d’opposition n’est pas
          possible (du fait, par exemple, du “balayage vidéo” de la caméra dans
          une rue), il est nécessaire de mettre en place un cadre légal adapté
          qui respecte l’article&#160;9 et/ou l’article&#160;23 du RGPD
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Avis de la CNIL sur les caméras thermiques&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.cnil.fr/fr/cameras-dites-intelligentes-et-cameras-thermiques-les-points-de-vigilance-de-la-cnil-et-les-regles"
                  >
                    https://www.cnil.fr/fr/cameras-dites-intelligentes-et-cameras-thermiques-les-points-de-vigilance-de-la-cnil-et-les-regles
                  </AppLink>
                </>
              )
            }
          >
            266
          </InfoClick>
          .&#160;» S’il faut une loi, on fait une loi&#160;: le ministère des
          Transports la fait passer un an plus tard, sans que personne ne se
          demande si le fait que tous nos visages soient analysés et décortiqués
          dans les transports a vraiment une utilité, ou si l’argent ne pourrait
          pas servir à autre chose. Mais pour le gouvernement néolibéral, de
          l’argent public investi dans les services publics est perdu, alors que
          l’argent public qui paye des entreprises privées est bien dépensé,
          dans «&#160;l’économie réelle&#160;». Ce n’est pas une blague ni une
          exagération&#160;: le milliardaire Bernard Arnault, soutien d’Emmanuel
          Macron en 2017 et jamais démenti depuis lors, déclarait en 2016 que
          «&#160;les emplois publics ne sont pas de vrais emplois&#160;». Bref.
        </Text>
        <Text>
          Fort heureusement, l’épidémie crée sans cesse de nouveaux débouchés
          pour les maniaques de la surveillance, et autant de «&#160;leviers de
          croissance&#160;». Le confinement strict, avec le télétravail
          obligatoire et la scolarité à distance, ouvre de nouvelles
          perspectives aux surveillants. Comment s’assurer que les élèves sont
          attentifs et ne trichent pas pendant les devoirs surveillés&#160;?
          Comment s’assurer que les salariés travaillent bien autant d’heures
          qu’on leur en paye&#160;?
        </Text>
        <Text>
          On a bien entendu décidé, car «&#160;plus rien ne serait comme
          avant&#160;», de faire confiance aux gens. Les étudiants ont tout
          intérêt à étudier sérieusement, puisqu’il s’agit de leur propre
          éducation et de leur avenir. Il est par ailleurs très facile de
          vérifier si les salariés ont fait ou non le travail qui leur est
          demandé. Ha ha&#160;! C’est une plaisanterie, bien sûr.
        </Text>
        <Text>
          Les entreprises de la surveillance ont aussitôt proposé d’innombrables
          outils astucieux pour surveiller les gens, en utilisant notamment la
          webcam de leur ordinateur de travail. Comme avec les drones, il s’agit
          de maintenir sous surveillance celles et ceux qui sont trop loin pour
          être directement sous l’œil du chef. Pour les étudiants, les start-up
          vendent aux universités la possibilité de surveiller les examens et
          d’éviter la triche (car tout est là)&#160;: une caméra ouverte en
          permanence, parfois un smartphone pour filmer le contrechamp et
          s’assurer que personne n’est présent pour aider l’étudiant, avec
          analyse des sons et des images, et parfois jusqu’à l’analyse de
          l’activité du globe oculaire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Crise sanitaire&#160;: la technologie envahit l’université
                    », 30&#160;avril 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/04/30/crise-sanitaire-la-technopolice-envahit-luniversite/"
                  >
                    https://www.laquadrature.net/2020/04/30/crise-sanitaire-la-technopolice-envahit-luniversite/
                  </AppLink>
                </>
              )
            }
          >
            267
          </InfoClick>
          …
        </Text>
        <Text>
          Derrière toutes ces initiatives disparates, l’idée est au fond la
          même&#160;: dans une crise profonde comme peut l’être une épidémie
          mondiale, il faut afficher son action et promettre une solution rapide
          et facile. L’intelligence artificielle est parfaite pour ça&#160;:
          mystérieuse, moderne et un peu floue, elle permet de promettre qu’on
          va résoudre magiquement les difficultés de fond, et si le résultat
          tarde à venir, c’est qu’il faut aller plus loin et perfectionner les
          outils. Caméras thermiques&#160;: les personnes fiévreuses ne pourront
          plus entrer dans les lieux fermés. Robots qui vérifient la distance
          physique. Robots qui vérifient le port du masque. Utilisation des
          données GPS&#160;: tout à coup, Orange sort du bois et propose
          d’utiliser de nouveau les données de bornage de nos téléphones, pour
          savoir qui respecte ou non le confinement…
        </Text>
        <Text>
          Dans l’idéal, il faudrait pouvoir suivre chaque personne en
          permanence, et savoir qui croise qui, pour tracer le parcours des
          contaminations… C’est ce que propose sans honte Sigfox, une entreprise
          toulousaine d’objets connectés&#160;: fournir des bracelets
          électroniques pour suivre les malades à la trace
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.ladepeche.fr/2020/04/25/coronavirus-la-societe-toulousaine-sigfox-propose-des-bracelets-electroniques-pour-gerer-le-deconfinement,8862285.php"
                >
                  https://www.ladepeche.fr/2020/04/25/coronavirus-la-societe-toulousaine-sigfox-propose-des-bracelets-electroniques-pour-gerer-le-deconfinement,8862285.php
                </AppLink>
              )
            }
          >
            268
          </InfoClick>
          .
        </Text>

        <Text type="h3">Traçage individuel et&#160;relations sociales</Text>
        <Text>
          Ce fantasme ultime, c’est bien sûr l’application StopCovid&#160;:
          développée par l’Institut national de recherche en sciences et
          technologies du numérique (Inria) et disponible le 2&#160;juin 2020,
          elle est voulue et promue par le gouvernement comme solution moderne
          de suivi et de contrôle des contaminations. Chaque personne est
          invitée à l’installer sur son smartphone, et à activer en permanence
          la fonction Bluetooth du téléphone. Ce dispositif de liaison sans fil,
          par ondes radio de courte portée, est relativement sensible à la
          distance. Il est donc utilisé pour identifier, grâce à leur adresse
          matérielle, les téléphones du voisinage. Un système de chiffrement
          permet d’anonymiser ces données&#160;: le logiciel attribue à chaque
          appareil un identifiant qui ne doit pas permettre de retrouver
          l’adresse MAC
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Chaque carte ou chaque puce réseau (Ethernet, wifi, ou
                  Bluetooth) est identifiée par un numéro unique (de
                  6&#160;octets au format hexadécimal) appelé son adresse MAC,
                  ou adresse matérielle, pour la distinguer de l’adresse lP, par
                  exemple, susceptible de changer à chaque nouvelle connexion.
                </Text>
              )
            }
          >
            269
          </InfoClick>{" "}
          ou le numéro de téléphone de l’utilisateur. Ensuite, les personnes qui
          s’avèrent contaminées par le Covid-19 sont censées l’indiquer dans
          leur application. Leur identifiant déclenche aussitôt une alerte à
          destination de toutes les personnes qui les ont «&#160;croisées&#160;»
          suffisamment longtemps et dans une période compatible avec la durée
          d’incubation du virus. Le but&#160;: que chacun puisse s’isoler et se
          tester au plus tôt, quand il peut avoir été contaminé, afin de casser
          la chaîne de la contagion.
        </Text>
        <Text>
          Avant même qu’elle existe, la seule idée de cette application de
          «&#160;traçage&#160;» (le mot «&#160;<i>tracking</i>&#160;» est
          employé par les épidémiologistes dans les médias) déchaîne un débat
          furieux, dans lequel nous prenons notre part. Personne ne nie
          l’utilité de suivre les contagions, et de prévenir les personnes ayant
          été en contact avec une personne malade. Ce qui est contesté, c’est la
          nécessité de passer par une application numérique pour le faire. Ici
          comme ailleurs, on craint que la facilité, au lieu d’être un argument
          en faveur du numérique, soit le centre même du danger. Le sociologue
          Antonio Casilli, par ailleurs membre de La Quadrature du Net, signe le
          25&#160;avril 2020 dans <i>Le Monde</i> une tribune intitulée
          «&#160;StopCovid est un projet désastreux piloté par des apprentis
          sorciers
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « StopCovid est un projet désastreux piloté par des
                    apprentis sorciers », 25&#160;avril 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/04/25/stopcovid-est-un-projet-desastreux-pilote-par-des-apprentis-sorciers/"
                  >
                    https://www.laquadrature.net/2020/04/25/stopcovid-est-un-projet-desastreux-pilote-par-des-apprentis-sorciers/
                  </AppLink>
                </>
              )
            }
          >
            270
          </InfoClick>
          &#160;». En amont du débat parlementaire du 28&#160;avril, la tribune
          invite les députés à rejeter le projet&#160;: «&#160;Les
          parlementaires français seront amenés à voter sur StopCovid,
          l’application mobile de traçage des individus imposée par l’exécutif.
          Nous souhaitons que, par leur vote, ils convainquent ce dernier de
          renoncer à cette idée tant qu’il est encore temps. Non pas de
          l’améliorer, mais d’y renoncer tout court. [...] Cette “solution”
          technologique ne serait qu’une continuation du confinement par
          d’autres moyens. Si, avec ce dernier, nous avons fait l’expérience
          d’une assignation à résidence collective, les applications mobiles de
          surveillance risquent de banaliser le port du bracelet
          électronique.&#160;»
        </Text>
        <Text>
          Le 14&#160;avril 2020, nous avions publié nos «&#160;arguments pour
          rejeter StopCovid
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Nos arguments pour rejeter StopCovid », 14&#160;avril
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/04/14/nos-arguments-pour-rejeter-stopcovid/"
                  >
                    https://www.laquadrature.net/2020/04/14/nos-arguments-pour-rejeter-stopcovid/
                  </AppLink>
                </>
              )
            }
          >
            271
          </InfoClick>
          &#160;». Dans une période aujourd’hui oubliée, où les masques
          manquaient, où les tests existaient à peine, où le vaccin était encore
          un rêve, le côté magique de la solution numérique pouvait séduire. On
          allait pouvoir être averti par SMS de la proximité du virus. Il est
          intéressant de lire a posteriori nos arguments contre l’application,
          car ils sont assez proches de ceux qu’on peut adresser,
          dix-huit&#160;mois plus tard, au «&#160;passe sanitaire&#160;» (2021)
          ou au «&#160;passe vaccinal&#160;» (2022).
        </Text>
        <Text>
          Le plus frappant&#160;: l’installation de l’application risque de
          devenir un prérequis pour accéder aux activités sociales ordinaires
          (prendre un train, voir un spectacle), et risque de créer des
          inégalités réelles dans la population, quand un quart des habitants du
          pays ne possède pas de smartphone. Le reste des arguments tourne
          autour de la faiblesse technique de l’outil (problèmes de
          l’anonymisation, imprécision du Bluetooth, alertes inutiles dans les
          zones densément peuplées, etc.), et surtout sur la très redoutée
          banalisation des outils de contrôle, dont nous avons déjà parlé au
          sujet de la reconnaissance faciale. Le plus pertinent est sans doute
          celui-ci&#160;: l’outil technologique risque de donner aux gens qui
          l’utilisent un faux sentiment de sécurité, alors qu’il est très loin
          d’être infaillible, et d’entraîner un relâchement des gestes de
          protection efficaces (masque, distanciation, aération, etc.). C’est un
          argument qu’on entendra contre le «&#160;passe sanitaire&#160;», qui
          n’a de sanitaire que le nom, étant entendu qu’il ne protège en rien de
          la contamination, mais ne fonctionne de l’aveu même du gouvernement
          que comme incitation à la vaccination par la restriction des libertés.
        </Text>
        <Text>
          La CNIL publie le 27&#160;avril 2020 un avis sur l’application
          StopCovid, que nous trouvons un peu faible. Elle demande au
          gouvernement de démontrer la nécessité de l’outil numérique, alors que
          le traçage des contacts peut être fait par d’autres moyens, par
          exemple en interrogeant les personnes contaminées. Mais la commission
          ne va pas assez loin dans la formulation de cette exigence, et c’est
          le point le plus intéressant de notre article, son affirmation la plus
          forte&#160;: «&#160;L’idée au cœur du droit des libertés fondamentales
          est que, par principe, il est interdit de limiter nos libertés. Elles
          ne peuvent l’être que par exception, et uniquement en démontrant
          qu’une telle limitation est utile à un intérêt supérieur, telle que la
          santé publique dans notre cas. […] Si aucun élément factuel ne prouve
          l’efficacité d’une technique qu’elle reconnaît pourtant comme
          attentatoire aux libertés fondamentales, la mission de la CNIL est de
          déclarer celle-ci illégale
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Analyse de l’avis de la CNIL, 27&#160;avril 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/04/27/la-cnil-sarrete-a-mi-chemin-contre-stopcovid/"
                  >
                    https://www.laquadrature.net/2020/04/27/la-cnil-sarrete-a-mi-chemin-contre-stopcovid/
                  </AppLink>
                </>
              )
            }
          >
            272
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          Un an plus tard, StopCovid est devenu TousAntiCovid, et plus personne
          n’utilise l’application pour le «&#160;contact <i>tracking</i>&#160;».
          En revanche, de nombreuses personnes l’utilisent pour produire le QR
          code de leur «&#160;passe sanitaire&#160;». L’application a donc bien
          confirmé sa vocation policière et administrative, perceptible dès
          l’origine, et bien éloignée de toute préoccupation d’ordre sanitaire
          au sens strict. Après quatre ou cinq vagues de Covid-19, après les
          vagues de contamination par les variants Delta (été et automne 2021)
          puis Omicron (hiver 2021-2022), tout le monde a oublié la notion même
          de traçage des contacts. L’idée paraît vieille et inutile, comme
          beaucoup d’idées sécuritaires nées pendant la crise du printemps 2020
          paraissent aujourd’hui vieilles et inutiles. Les caméras thermiques se
          sont fait oublier. Aucun drone dans les rues ne surveille le port du
          masque ou les distances de sécurité. On ne parle plus nulle part de
          suivre les malades par GPS ou par bornage des téléphones. Quand on
          passe en revue ces vieux fantasmes de 2020, on a l’impression de
          traverser un cimetière de jouets encore neufs. Tant mieux.
        </Text>

        <Text type="h3">Une&#160;frénésie législative</Text>
        <Text>
          Les gadgets passent, mais les lois restent –&#160;hélas. Saisissant le
          prétexte de l’urgence d’agir, le gouvernement de la majorité Macron
          est pris d’une frénésie législative. D’abord, il se donne les moyens,
          très vite, d’une action exceptionnelle, en faisant voter dès le
          23&#160;mars 2020 une loi réglementant l’état d’urgence sanitaire.
          Prorogée plusieurs fois, cette loi est encore en vigueur au moins
          jusqu’au 31&#160;juillet&#160;2022. L’état d’urgence sanitaire
          proprement dit, quant à lui, a duré en France métropolitaine de
          mars&#160;2020 à juin&#160;2021, et il a été réactivé dans les DOM-TOM
          à la fin de l’année&#160;2021 (Martinique, La&#160;Réunion) et début
          2022 (Guadeloupe, Guyane, Mayotte, Saint-Martin et Saint-Barthélemy).
          Cet état d’urgence renforce le pouvoir exécutif, qui peut prendre un
          plus grand nombre de décisions par décret.
        </Text>
        <Text>
          Malgré son importance vitale, l’organisation de la lutte contre
          l’épidémie est très loin d’être en 2020 la seule occupation du
          gouvernement. Édouard Philippe, puis Jean Castex, qui lui succède à
          Matignon, font voter des lois (sécuritaires), entre deux douzaines de
          décrets (sécuritaires). Rapide tour d’horizon.
        </Text>
        <Text>
          D’abord, l’année est marquée par une avalanche de nouveaux fichiers,
          ou d’élargissement des fichiers existants, que nous tentons de
          contester devant les tribunaux. En mars&#160;2020, le ministère de la
          Justice décide d’ouvrir le fichier DataJust&#160;: il regroupera
          toutes les décisions de justice qui comportent une notion de
          dédommagement, ou d’indemnisation des préjudices corporels. C’est un
          ensemble colossal, qui comporte une grande quantité de données
          personnelles, voire très personnelles&#160;: les noms des personnes
          impliquées, bien sûr, mais aussi leur situation financière,
          professionnelle et familiale, ou des indications de santé physique et
          mentale, etc. Pourquoi agréger toutes ces décisions&#160;?
          Apparemment, pour voir s’il ne serait pas possible de confier ces
          millions de jurisprudences et de cas particuliers à un algorithme qui
          en déduirait, par «&#160;apprentissage&#160;», la meilleure décision à
          prendre en fonction des données de la situation&#160;: un conseiller
          ou un juge automatique, en quelque sorte, qui serait statistiquement
          juste, en fonction des décisions prises dans le passé par les juges
          humains. Le ministère est très flou sur ce qu’il attend d’un tel
          algorithme, et se montre bien incapable d’expliquer en vue de quoi il
          aurait besoin d’autant de données personnelles aussi sensibles. Sur la
          base des bafouillements du ministère, dont la CNIL relevait aussi le
          peu de consistance, nous contestons ce fichier devant le Conseil
          d’État. Notre recours est rejeté en décembre&#160;2021.
        </Text>
        <Text>
          En avril&#160;2020, pendant le confinement, le gouvernement élargit
          les finalités du dossier «&#160;Accès au dossier des
          contraventions&#160;» (ADOC), normalement réservé aux infractions
          routières. Les policiers et les gendarmes avaient commencé à s’en
          servir pour enregistrer les amendes pour non-respect des règles du
          confinement&#160;: comme la récidive était punie d’une amende plus
          forte, puis d’une peine de prison, il fallait bien tenir le compte.
          Comment&#160;? En détournant un fichier… Malheureusement pour les
          agents, c’est illégal. Après qu’un prévenu a été relaxé pour
          «&#160;malfaçon juridique&#160;»
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Coronavirus&#160;: un fichier de police détourné pour
                    repérer les récidivistes qui violent le confinement »,{" "}
                    <i>Le Monde</i>, 15&#160;avril 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lemonde.fr/police-justice/article/2020/04/15/un-fichier-de-police-detourne-pour-reperer-les-recidivistes-qui-violent-le-confinement_6036662_1653578.html"
                  >
                    https://www.lemonde.fr/police-justice/article/2020/04/15/un-fichier-de-police-detourne-pour-reperer-les-recidivistes-qui-violent-le-confinement_6036662_1653578.html
                  </AppLink>
                </>
              )
            }
          >
            273
          </InfoClick>
          , le gouvernement prend un décret pour renommer le fichier
          («&#160;Système de contrôle automatisé&#160;» ou SCA) et élargir ses
          finalités à toutes les amendes
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Fichage policier&#160;: recours contre le détournement du
                    fichier du système de contrôle automatisé », 9&#160;novembre
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/11/09/fichage-policier-recours-contre-le-detournement-du-fichier-du-systeme-de-controle-automatise/"
                  >
                    https://www.laquadrature.net/2020/11/09/fichage-policier-recours-contre-le-detournement-du-fichier-du-systeme-de-controle-automatise/
                  </AppLink>
                </>
              )
            }
          >
            274
          </InfoClick>
          . Là encore, le Conseil d’État rejette notre recours en
          décembre&#160;2021.
        </Text>
        <Text>
          Fin 2020, en décembre, trois décrets modifient trois fichiers
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Décrets PASP&#160;: fichage massif des militants
                    politiques », 8&#160;décembre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/12/08/decrets-pasp-fichage-massif-des-militants-politiques/"
                  >
                    https://www.laquadrature.net/2020/12/08/decrets-pasp-fichage-massif-des-militants-politiques/
                  </AppLink>
                </>
              )
            }
          >
            275
          </InfoClick>{" "}
          de «&#160;sécurité publique&#160;», pour y inclure les
          «&#160;habitudes de vie&#160;» et les «&#160;activités en
          ligne&#160;», ainsi que les «&#160;opinions politiques&#160;», là où
          n’étaient prises en compte auparavant que des «&#160;activités
          politiques&#160;». Nous attaquons également ces décrets devant le
          Conseil d’État (et nous sommes toujours en attente de la décision).
        </Text>
        <Text>
          Du côté des lois, c’est l’escalade. D’abord, en septembre, le
          gouvernement français introduit dans un projet de règlement européen
          nommé DSA son sempiternel retrait des contenus en 24&#160;heures
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La loi Avia revient par la porte de l’UE »,
                    22&#160;septembre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/09/22/aviasback/"
                  >
                    https://www.laquadrature.net/2020/09/22/aviasback/
                  </AppLink>
                </>
              )
            }
          >
            276
          </InfoClick>
          , qui vient pourtant d’être censuré par le Conseil constitutionnel
          dans la loi Avia quelques mois plus tôt (juin&#160;2020). En décembre,
          le gouvernement prend le prétexte de sa lutte contre le terrorisme et
          la radicalisation islamiste pour s’en prendre aux associations, dans
          son projet de loi contre le séparatisme. Et surtout, en
          novembre&#160;2020, le gouvernement présente une nouvelle loi de
          police, intitulée en toute franchise&#160;: «&#160;loi pour une
          sécurité globale&#160;». Nous y reviendrons.
        </Text>

        <Text type="h3">
          Le&#160;Health Data Hub&#160;(HDH)&#160;: offensive du&#160;business
          sanitaire
        </Text>
        <Text>
          À l’été 2019, la Quadrature est contactée par un médecin et un
          ingénieur, qui se sont constitués en association sous le nom
          d’InterHop. Ils travaillent tous deux pour l’Assistance
          publique-Hôpitaux de Paris (AP-HP) et s’occupent de la gestion des
          données de cet énorme groupe hospitalier qui rassemble 39 hôpitaux et
          accueille 10&#160;millions de personnes chaque année.
        </Text>
        <Text>
          Cette activité génère une énorme quantité de données, utilisées bien
          sûr pour organiser au quotidien les actes médicaux et la facturation,
          mais qui intéressent aussi beaucoup les praticiens, les entreprises et
          les organismes de santé. Les deux militants sont inquiets&#160;: le
          gouvernement a décidé, à la suite de la publication du rapport Villani
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Donner un sens à l’intelligence artificielle&#160;: pour
                    une stratégie nationale et européenne », rapport de
                    mars&#160;2018, sous la direction du député Cédric
                    Villani&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.vie-publique.fr/rapport/37225-donner-un-sens-lintelligence-artificielle-pour-une-strategie-nation"
                  >
                    https://www.vie-publique.fr/rapport/37225-donner-un-sens-lintelligence-artificielle-pour-une-strategie-nation
                  </AppLink>
                </>
              )
            }
          >
            277
          </InfoClick>
          , de débloquer un budget colossal pour des projets d’intelligence
          artificielle, et de mettre dans le même temps les données de santé
          françaises à disposition des chercheurs et des entreprises qui
          souhaiteraient les utiliser, car il faut «&#160;faire de la France un
          pays leader de l’intelligence artificielle&#160;». Une telle base
          existe déjà depuis 2016 sous le nom de Système national des données de
          santé (SNDS). Mais, afin d’élargir les données collectées et d’en
          faciliter l’accès au secteur privé (GAFAM, «&#160;medtechs&#160;»,
          start-up, assureurs, mutuelles, etc.), un groupement d’intérêt public
          nommé Health Data Hub (HDH) est créé le 24&#160;juillet 2019.
        </Text>
        <Text>
          Le HDH doit regrouper à terme les données de la médecine de ville, des
          pharmacies, du système hospitalier, des laboratoires de biologie
          médicale, du dossier médical partagé, de la médecine du travail, des
          Ehpad ou encore les données des programmes de séquençage de l’ADN
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Pour une liste exhaustive, se reporter à la partie&#160;5 «
                    Patrimoine de données » du rapport de la mission de
                    préfiguration du HDH&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://solidarites-sante.gouv.fr/IMG/pdf/181012_-_rapport_health_data_hub.pdf"
                  >
                    https://solidarites-sante.gouv.fr/IMG/pdf/181012_-_rapport_health_data_hub.pdf
                  </AppLink>
                </>
              )
            }
          >
            278
          </InfoClick>
          . L’accès aux données du SNDS était conditionné à «&#160;des fins de
          recherche, d’étude ou d’évaluation présentant un caractère d’intérêt
          public&#160;». Pour simplifier l’accès au HDH, on enlève les notions
          de recherche, d’étude ou d’évaluation. Il suffit désormais de déclarer
          un traitement «&#160;présentant un caractère d’intérêt public&#160;».
        </Text>
        <Text>
          Avec le Syndicat des jeunes médecins généralistes (SJMG) et le
          Syndicat de la médecine générale, nous rédigeons un courrier commun
          faisant état de nos questionnements sur les modalités de constitution
          des comités éthiques, qui auront à décider des projets qui auront
          accès aux données de santé des Français, ainsi que sur les finalités
          de recherches envisagées, mais aussi sur la solution technique et le
          modèle économique retenus. Le 31&#160;octobre 2019, nous avons
          rendez-vous à la Direction de la recherche, des études, de
          l’évaluation et des statistiques (DREES), une direction de
          l’administration centrale dépendant conjointement des ministères de la
          Santé, du Travail et de l’Économie. Nous sommes reçus par la
          directrice et la sous-directrice du projet, avec un représentant de
          l’April
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Association pour la promotion du logiciel libre&#160;:{" "}
                  </Text>
                  <AppLink white href="https://www.april.org/">
                    https://www.april.org/
                  </AppLink>
                </>
              )
            }
          >
            279
          </InfoClick>{" "}
          et un membre du SJMG. L’entretien dure deux heures. L’échange est
          riche, avec des réponses techniques et argumentées. On en sort plutôt
          rassurés.
        </Text>
        <Text>
          Mais le 19&#160;juin 2020, Jérôme Hourdeaux, journaliste à{" "}
          <i>Mediapart</i>, analyse la délibération de la CNIL du 20&#160;avril
          2020 concernant le projet HDH
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Délibération de la CNIL au format PDF&#160;: </Text>
                  <AppLink
                    white
                    href="https://www.cnil.fr/sites/default/files/atoms/files/deliberation_du_20_avril_2020_portant_avis_sur_projet_darrete_relatif_a_lorganisation_du_systeme_de_sante.pdf"
                  >
                    https://www.cnil.fr/sites/default/files/atoms/files/deliberation_du_20_avril_2020_portant_avis_sur_projet_darrete_relatif_a_lorganisation_du_systeme_de_sante.pdf
                  </AppLink>
                </>
              )
            }
          >
            280
          </InfoClick>
          , depuis son architecture technique jusqu’au contrat d’hébergement
          avec Microsoft
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Données de santé&#160;: le “oui mais” du Conseil d’État au
                    Health Data Hub », <i>Mediapart</i>, 19&#160;juin
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.mediapart.fr/journal/france/190620/donnees-de-sante-le-oui-mais-du-conseil-d-etat-au-health-data-hub"
                  >
                    https://www.mediapart.fr/journal/france/190620/donnees-de-sante-le-oui-mais-du-conseil-d-etat-au-health-data-hub
                  </AppLink>
                </>
              )
            }
          >
            281
          </InfoClick>
          . Il explique que la CNIL s’inquiète&#160;: Microsoft conserverait une
          copie des clés de chiffrement des données, «&#160;ce qui a pour
          conséquence de permettre techniquement à ce dernier d’accéder aux
          données&#160;». Aucun rapport avec les données «&#160;stockées à froid
          et chiffrées, donc inaccessibles&#160;» qu’on nous avait promises lors
          de notre entretien sept&#160;mois plus tôt. On nous avait expliqué
          aussi que le système serait cloisonné, et que les différentes
          technologies nécessaires au fonctionnement du HDH (stockage, machine
          virtuelle, environnement de travail, outils d’analyse) seraient
          réparties entre différents prestataires pour éviter les risques de
          fuite.
        </Text>
        <Text>
          Mais c’est Microsoft qui rafle la mise&#160;! La directrice technique
          du HDH arguant, sans sourciller, que seules les plateformes
          d’hébergement américaines sont capables de proposer des technologies à
          la hauteur pour le stockage et le traitement des données de santé
          françaises. Pourtant, il y a un hic&#160;: Microsoft dépend de la
          législation américaine, passablement permissive concernant l’accès de
          son administration aux données stockées par les entreprises… InterHop
          attaque cette décision d’hébergement devant le Conseil d’État, ce qui
          pousse Olivier Véran, ministre de la Santé, à s’engager à «&#160;faire
          disparaître complètement&#160;» les risques posés par l’hébergement du
          HDH chez Microsoft, et à «&#160;adopter une nouvelle solution
          technique&#160;» sous deux&#160;ans, dans un courrier adressé à
          Marie-Laure Denis, présidente de la CNIL, le 19&#160;novembre 2020.
        </Text>
        <Text>
          Faute de chiffrement fiable, peut-on au moins anonymiser les données
          de santé&#160;? C’est une solution évoquée pour apaiser les esprits,
          mais techniquement inatteignable. Anonymiser de telles données n’a
          aucun sens&#160;: on souhaite pouvoir suivre le parcours d’un malade
          sur une longue période, comparer l’évolution de la santé sur le long
          terme, chaque acte médical doit donc pouvoir être corrélé avec le
          précédent. Cette agglomération est suffisante pour identifier une
          personne unique dans une base de données.
        </Text>
        <Text>
          De manière plus générale, se pose la question de la légitimité de la
          création de ce <i>hub</i>. Dans un pays où les urgences sont
          régulièrement débordées, où les «&#160;déserts médicaux&#160;»
          obligent les patients à parcourir des dizaines de kilomètres pour se
          rendre chez un généraliste, et les secours à perdre des dizaines de
          minutes avant de venir en aide à un accidenté, au moment même où la
          pandémie de Covid-19 fait déborder les services de réanimation, la
          priorité serait-elle de dépenser des centaines de millions d’euros
          pour ouvrir nos données de santé aux start-up de la
          «&#160;biotech&#160;»&#160;?
        </Text>
        <Text>
          Les hôpitaux ont déjà leurs entrepôts de données, et travaillent avec
          des chercheurs et des entreprises pour améliorer les parcours et les
          protocoles de soin. Outre le travail titanesque à réaliser pour
          homogénéiser les données entre des centaines d’hôpitaux et de CHU,
          c’est aussi demander aux responsables de traitement de transférer des
          données obtenues auprès de leurs patients à une entité nationale sur
          laquelle ils n’ont aucune maîtrise.
        </Text>
        <Text>
          Quelle idée sous-tend la centralisation de ces données de santé et
          leur usage par des start-up&#160;? Une croyance en l’absolue vérité
          apportée par la machine
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Health Data Hub&#160;: du fantasme de l’intelligence
                    artificielle à la privatisation de nos données de santé »,
                    17&#160;mars&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/03/17/health-data-hub-du-fantasme-de-lintelligence-artificielle-a-la-privatisation-de-nos-donnees-de-sante/"
                  >
                    https://www.laquadrature.net/2021/03/17/health-data-hub-du-fantasme-de-lintelligence-artificielle-a-la-privatisation-de-nos-donnees-de-sante/
                  </AppLink>
                </>
              )
            }
          >
            282
          </InfoClick>
          . En l’infaillibilité des algorithmes. Lors de la remise du rapport
          Villani intitulé «&#160;Donner un sens à l’intelligence
          artificielle&#160;: pour une stratégie nationale et européenne&#160;»,
          Emmanuel Macron prononce un discours dans lequel il évoque le monde
          calculable de Leibniz, parle d’option «&#160;presque
          prométhéenne&#160;» et, comble de lyrisme, entrevoit dans un avenir
          proche la possibilité «&#160;à travers des machines apprenantes de
          pouvoir parcourir beaucoup plus rapidement les chemins du malheur pour
          choisir le bon chemin beaucoup plus tôt et beaucoup plus rapidement
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Discours du président de la République sur l’intelligence
                    artificielle », 29&#160;mars 2018&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.elysee.fr/emmanuel-macron/2018/03/29/discours-du-president-de-la-republique-sur-lintelligence-artificielle"
                  >
                    https://www.elysee.fr/emmanuel-macron/2018/03/29/discours-du-president-de-la-republique-sur-lintelligence-artificielle
                  </AppLink>
                </>
              )
            }
          >
            283
          </InfoClick>
          &#160;». Battre Dieu en calcul, rien que ça&#160;!
        </Text>
        <Text>
          Le rapport Villani ne recule pas non plus devant le lyrisme, mais sa
          conclusion est beaucoup plus pragmatique. Nous sommes dépassés par la
          puissance des GAFAM (encore une fois, on retrouve cette idée{" "}
          d’impuissance des politiques, écrite noir sur blanc par les politiques
          eux-mêmes), mais il nous reste une carte à jouer pour rester dans la
          course&#160;: fournir les données sectorielles sans lesquelles l’IA
          n’est rien. Et l’organisation du système de santé français permet à
          l’État d’être assis sur une mine d’or. Emmanuel Macron l’avoue
          crûment&#160;: «&#160;Nous avons un véritable avantage, c’est que nous
          possédons un système de santé […] très centralisé, avec des bases de
          données d’une richesse exceptionnelle, notamment celle de
          l’Assurance-maladie et des hôpitaux.&#160;» Puisqu’on n’est pas
          capable de fabriquer le véhicule, fournissons le carburant&#160;!
          C’est la piste nommée «&#160;<i>Hospital as a Platform</i>&#160;» par
          le rapport Villani
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Rapport Villani, page&#160;196&#160;: </Text>
                  <AppLink
                    white
                    href="https://www.aiforhumanity.fr/pdfs/9782111457089_Rapport_Villani_accessible.pdf"
                  >
                    https://www.aiforhumanity.fr/pdfs/9782111457089_Rapport_Villani_accessible.pdf
                  </AppLink>
                </>
              )
            }
          >
            284
          </InfoClick>
          .
        </Text>
        <Text>
          Les personnels de santé créeront les données qui seront vendues demain
          par le HDH. Il est donc évidemment «&#160;nécessaire que les
          professionnels de santé soient sensibilisés et formés pour encoder ces
          informations de manière à les rendre lisibles et réutilisables par la
          machine&#160;»&#160;! Si le personnel résiste, c’est forcément un
          problème de culture&#160;: il sera donc nécessaire de «&#160;procéder
          à la large diffusion d’une culture de la donnée&#160;». Mais la
          «&#160;culture de la donnée&#160;» française et européenne repose sur
          la loi informatique et libertés (France, 1978) et sur le RGPD (Union
          européenne, 2018), qui visent justement à protéger cette donnée, et
          particulièrement la donnée de santé, dite «&#160;sensible&#160;», au
          même titre que l’orientation politique ou sexuelle. Maintenant que le
          contexte technique permet une analyse extrêmement pointue de ces
          données, il faudrait donc cesser de la protéger, parce qu’elle a une
          valeur économique importante&#160;?
        </Text>
        <Text>
          Début 2022, la loi de création du HDH est votée, les décrets sont
          passés, mais il manque encore un arrêté, qui définit en particulier la
          composition du catalogue de données que le HDH pourra exploiter. Il
          manque aussi une autorisation de la CNIL pour utiliser les données du
          SNDS
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Page de présentation du SNDS sur le site de la CNIL&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.cnil.fr/fr/snds-systeme-national-des-donnees-de-sante"
                  >
                    https://www.cnil.fr/fr/snds-systeme-national-des-donnees-de-sante
                  </AppLink>
                </>
              )
            }
          >
            285
          </InfoClick>
          &#160;–&#160;deux éléments cruciaux pour que le projet puisse
          démarrer. Entre-temps, début 2020, une loi est passée en urgence pour
          permettre l’utilisation des données liées au Covid-19, et certains
          projets ont donc pu démarrer sur cette base, dans un cadre dérogatoire
          lié à la situation sanitaire exceptionnelle. Mais légalement, le HDH
          n’a toujours pas d’existence, et ses choix techniques, jusqu’à sa
          justification politique, font toujours débat. La CNIL et la Caisse
          nationale d’assurance-maladie (CNAM)
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « France&#160;: l’Assurance-maladie estime que Microsoft ne
                    peut pas gérer les données de santé », RFI,
                    19&#160;février&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.rfi.fr/fr/france/20210219-france-l-assurance-maladie-estime-que-microsoft-ne-peut-pas-g%C3%A9rer-les-donn%C3%A9es-de-sant%C3%A9"
                  >
                    https://www.rfi.fr/fr/france/20210219-france-l-assurance-maladie-estime-que-microsoft-ne-peut-pas-g%C3%A9rer-les-donn%C3%A9es-de-sant%C3%A9
                  </AppLink>
                </>
              )
            }
          >
            286
          </InfoClick>{" "}
          ont déjà fait part de leur fébrilité sur ce sujet, et la première
          risque de rendre un avis négatif. En pleine campagne présidentielle,
          sur un projet pour lequel Emmanuel Macron s’était largement mouillé,
          sur un thème très «&#160;start-up nation compatible&#160;», il faut
          éviter les risques inutiles. Début janvier&#160;2022, le ministère de
          la Santé retire sa demande d’autorisation à la CNIL
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Face à la controverse, le Health Data Hub retire
                    “temporairement” sa demande d’autorisation pour héberger des
                    données de santé »,
                    <i>Le Quotidien du Médecin</i>,
                    10&#160;janvier&#160;2022&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lequotidiendumedecin.fr/actus-medicales/esante/face-la-controverse-le-health-data-hub-retire-temporairement-sa-demande-dautorisation-pour-heberger"
                  >
                    https://www.lequotidiendumedecin.fr/actus-medicales/esante/face-la-controverse-le-health-data-hub-retire-temporairement-sa-demande-dautorisation-pour-heberger
                  </AppLink>
                </>
              )
            }
          >
            287
          </InfoClick>
          . Le projet de HDH est donc momentanément à l’arrêt.
        </Text>
        <Text>
          Les recherches fondées sur des données publiques, dans la majorité des
          cas récupérées sans que l’usager ne puisse donner un accord libre et
          éclairé, doivent rester sous le contrôle de la puissance publique.
          Cela implique de redonner corps à une recherche publique digne de ce
          nom. Notre système de soin ne doit pas devenir un auxiliaire au
          service d’entreprises privées et de technologies dont la place n’a
          jamais fait l’objet d’un débat public. L’histoire nous a largement
          montré comment les entreprises privées peuvent, dès que l’occasion
          leur est donnée, oublier le bien commun pour verser dans le
          capitalisme sordide
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Nostrum Pharmaceuticals, après avoir multiplié le prix d’un
                    médicament par cinq, se défend en invoquant son « devoir
                    moral de vendre le produit au prix le plus élevé »&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lesechos.fr/industrie-services/pharmacie-sante/etats-unis-un-industriel-augmente-de-400-le-prix-dun-medicament-138722"
                  >
                    https://www.lesechos.fr/industrie-services/pharmacie-sante/etats-unis-un-industriel-augmente-de-400-le-prix-dun-medicament-138722
                  </AppLink>
                  <br />
                  <Text>
                    Novartis, de son côté, a récupéré le résultat d’une
                    recherche financée initialement par le Téléthon, et a décidé
                    de vendre le traitement au prix de 2,1&#160;millions de
                    dollars par patient&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lexpress.fr/actualite/comment-novartis-va-s-enrichir-grace-a-l-argent-du-telethon_2081823.html"
                  >
                    https://www.lexpress.fr/actualite/comment-novartis-va-s-enrichir-grace-a-l-argent-du-telethon_2081823.html
                  </AppLink>
                </>
              )
            }
          >
            288
          </InfoClick>
          .
        </Text>
        <Text>
          La prochaine fois que vous parlerez de décroissance numérique, et
          qu’on vous accusera de vouloir envoyer tout le monde s’éclairer à la
          bougie dans des cavernes, vous pourrez répondre que vous envisagez
          d’abord, et pour commencer&#160;: un monde sans caméras de
          vidéosurveillance automatisée, sans reconnaissance faciale, sans
          profilage de la démarche ni détection des émotions, sans caméras
          thermiques et sans données médicales vendues à des assureurs privés,
          un monde où la police et les services de renseignement ne peuvent pas
          conserver vos données de connexion et de télécommunications pendant un
          an dans un entrepôt de données, ni ajouter votre visage dans des
          fichiers de police quand vous subissez un banal contrôle routier, un
          monde où votre navigation Internet n’est pas suivie de seconde en
          seconde par des multinationales pour vous gaver de publicité, ou pour
          sélectionner ce que vous pouvez lire ou ne pas lire sur les réseaux
          sociaux, un monde où vos données de santé ne servent pas, à votre
          insu, à engraisser des entreprises privées.
        </Text>
        <Text>
          Mais ce n’est pas le monde dans lequel nous vivons. Dans notre monde,
          la surveillance est normale, banale, installée. En un an, on a dû
          s’acclimater aux drones, aux caméras automatiques, aux QR codes pour
          entrer quelque part –&#160;l’année 2020 a inscrit toutes ces
          technologies dans notre quotidien. Et voici qu’arrive une nouvelle loi
          nommée «&#160;Sécurité globale&#160;».
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter3Part5;
