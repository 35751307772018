import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter2Part4 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>();

  return (
    <PagePattern partName="Protéger nos données : le RGPD en renfort">
      <>
        <Text>
          Début 2016, la Quadrature cherche comment reprendre la main sur le
          calendrier. Mais il faut se rendre à l’évidence&#160;: le débat
          parlementaire est de plus en plus verrouillé. Les appels aux députés
          ont toujours moins d’impact, les échanges avec eux sont plus policés
          et relèvent davantage de la stratégie de communication que de
          l’intérêt véritable envers des positions différentes.
        </Text>
        <Text>
          Dans le contexte de l’opposition populaire à la loi Travail, le
          gouvernement se tend, les négociations sont de plus en plus
          compliquées. Le 31&#160;mars, une nouvelle manifestation se termine
          place de la République, où est projeté le film de François Ruffin,{" "}
          <i>Merci Patron</i>. Les manifestants refusent ensuite de partir. Ils
          refusent même de quitter le mois de mars pour passer en avril.
          32&#160;mars, 33&#160;mars&#160;: c’est le début de Nuit debout,
          expérience revendicative et convergente des luttes sociales. Nous
          participons à ce mouvement optimiste en organisant une partie de
          l’infrastructure numérique, en proposant des conférences ou en
          ouvrant, sur la place «&#160;biblio debout&#160;», une bibliothèque
          ouverte et participative. Le lieu se transforme en forum permanent, où
          les assemblées générales sont ouvertes à toutes et à tous et où des
          débats ont lieu à tout moment du jour et de la nuit. Nuit debout
          perdure jusqu’au début de l’été.
        </Text>
        <Text>
          À force de se positionner sur tous les sujets, la Quadrature devient
          incontournable dès que le numérique est en jeu, une position utile
          mais qui a un coût&#160;: il faut toujours plus d’expertise dans des
          domaines différents et toujours plus d’énergie pour suivre, dans
          toutes les directions. Au premier semestre 2016, lors d’un apéro
          proposé aux bénévoles, puis dans un communiqué de presse
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2016/05/17/quadrature_du_net_hors_etat_urgence/"
                >
                  https://www.laquadrature.net/2016/05/17/quadrature_du_net_hors_etat_urgence/
                </AppLink>
              )
            }
          >
            110
          </InfoClick>
          , est présentée la nouvelle position de l’association&#160;:
          «&#160;Face à une représentation politique dont la seule logique est
          sécuritaire, La Quadrature du Net refuse de perdre davantage de temps
          à tenter d’influencer rationnellement ceux qui ne veulent rien
          entendre et choisit de réorienter ses actions.&#160;» L’urgence
          permanente, les propositions irresponsables, relayées par des
          parlementaires dociles au gouvernement, nous conduisent à vouloir
          réorganiser notre action pour moins de suivi législatif français,
          davantage d’actions avec des partenaires européens et plus de
          réflexions, ouvertes à des interventions externes et des compétences
          que nous n’avons pas dans nos équipes. Moins de temps avec le monde
          politique et davantage avec les gens, via plus de sensibilisation de
          fond, plus de participation, de décentralisation de nos actions. D’un
          côté fatigués mais de l’autre portés par ce moment d’engouement
          populaire, nous voulons faire de l’éducation populaire (à l’aide
          d’ateliers, de conférences), pour armer intellectuellement la
          population sur ces sujets. Bref, nous souhaitons tous plus
          d’indépendance face aux agendas imposés&#160;: sortir de l’état
          d’urgence.
        </Text>
        <Text>
          Si cette nouvelle posture nous permet de respirer et de reprendre la
          main sur un certain nombre de dossiers, elle va se révéler difficile à
          tenir. Les ateliers s’essoufflent courant 2017 et disparaissent par
          manque d’investissement de l’équipe, malgré une participation souvent
          importante. Très vite le naturel revient au galop&#160;: nous
          n’arrivons pas à lâcher le débat législatif. Et pour cause, un texte
          qui va se révéler majeur est en train de se mettre en place. Mais
          notre approche envers celui-ci sera cette fois-ci un peu différente.
        </Text>

        <Text type="h3">Une&#160;révolution sans trop révolutionner</Text>
        <Text>
          En mai&#160;2016 est voté l’un des textes européens les plus marquants
          et les plus positifs de ces dernières années&#160;: le RGPD, pour
          «&#160;règlement général sur la protection des données&#160;». Lors de
          la négociation du texte au Parlement européen, les parlementaires en
          charge de le porter ont pourtant subi une campagne de lobbying de la
          part des GAFAM
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  L’acronyme GAFAM désigne les cinq entreprises américaines du
                  secteur de la technologie les plus populaires et cotées en
                  bourse : Google, Apple, Facebook, Amazon et Microsoft.
                </Text>
              )
            }
          >
            111
          </InfoClick>{" "}
          d’une intensité encore jamais vue à l’époque.
        </Text>
        <Text>
          Alors que le développement d’un Internet à vocation de plus en plus
          commerciale a pour corollaire la perte progressive de contrôle sur la
          destination de nos données personnelles, la réglementation existante
          n’est que très peu respectée –&#160;même en France, où la Commission
          nationale de l’informatique et des libertés (CNIL) existe pourtant
          depuis 1978. La faute à une institution qui n’a que peu de pouvoir sur
          les entreprises, et de moins en moins sur l’État.
        </Text>
        <Text>
          Le RGPD prend la relève d’une directive pré-Internet grand public,
          votée en 1995, et vise alors à donner un coup de fouet aux autorités
          de contrôle (nom générique des «&#160;CNIL&#160;» européennes dans le
          règlement) en leur donnant des pouvoirs de sanction supplémentaires,
          allant jusqu’à 20&#160;millions d’euros ou 4&#160;% du chiffre
          d’affaires mondial de l’entreprise visée, ce qui peut revenir à
          plusieurs milliards d’euros pour les géants du numérique. C’est
          pratiquement la seule grande nouveauté du texte.
        </Text>
        <Text>
          La campagne de lobbying réussit dans un premier temps à rendre encore
          plus mou le texte initialement proposé par la Commission européenne.
          Mais l’onde de choc politico-médiatique des révélations Snowden
          modifie le contexte des négociations et ramène finalement le texte
          plus près de sa version initiale&#160;: peu de grandes nouveautés,
          mais quelques avancées considérables. Y figure le
          «&#160;consentement&#160;explicite&#160;», pour lequel nous nous
          battons depuis de nombreuses années et la possibilité de saisir les
          autorités de contrôle par des plaintes collectives. Mais en dehors de
          cela, tous les grands principes de la protection des données
          (définition des données «&#160;sensibles&#160;», conditions de licéité
          de leur collecte et de leur utilisation, loyauté envers le
          consentement, etc.) ainsi que la majorité de nos «&#160;droits&#160;»
          (accès à nos données, possibilités de rectification, etc.) ont été
          repris presque à l’identique de la directive européenne initiale de
          1995. Le règlement crée bien aussi un nouveau droit à la portabilité,
          mais son intérêt pratique reste encore à trouver.
        </Text>
        <Text>
          Malheureusement, le RGPD ne se contente pas d’hériter des forces de la
          directive de 1995, il hérite aussi de sa faille principale&#160;: il
          autorise toute entreprise à collecter et à utiliser des données
          personnelles si elle y trouve un «&#160;intérêt légitime&#160;»
          (économique, structurel…) et que la poursuite de cet intérêt ne porte
          pas une «&#160;atteinte disproportionnée&#160;» aux intérêts des
          personnes concernées. Oui, ce «&#160;critère&#160;» est
          particulièrement flou et tordu, et c’est hélas bien son but&#160;:
          «&#160;Pour l’instant, vous avez le droit de faire ce que vous voulez,
          et on viendra vérifier plus tard, si on a le temps, que vous n’avez
          pas trop dépassé les bornes.&#160;»
        </Text>
        <Text>
          Mais alors, si le RGPD apporte si peu de nouveautés, pourquoi y
          trouver l’espoir de changements majeurs&#160;? Son principal effet, en
          vérité, n’est pas tant d’avoir modifié le droit que d’en permettre
          enfin l’application. Et c’est sur cette partie que cette fois nous
          allons concentrer nos efforts.
        </Text>
        <Text>
          En théorie, depuis longtemps, le droit des données personnelles
          pourrait être une arme puissante pour nous protéger&#160;: l’analyse
          comportementale n’est possible qu’avec notre consentement (qui doit
          désormais être explicite) et, surtout, ce consentement doit être
          libre. C’est ce format de consentement qui est important&#160;: le
          droit des données personnelles prévoit, depuis plusieurs années, que
          notre consentement n’est pas «&#160;valide&#160;» s’il est une
          condition pour accéder à un service. Qu’il n’est pas
          «&#160;libre&#160;» s’il est donné sous la menace de subir une
          conséquence négative. Qu’il n’est pas «&#160;valable&#160;» s’il est
          assimilé à la contrepartie d’un contrat ou à un prix.
        </Text>
        <Text>
          Ce principe est simple&#160;: céder ses données, c’est renoncer à son
          droit fondamental à la protection de la vie privée, à la liberté de
          conscience, à l’intégrité. Or, heureusement, nos principes
          démocratiques s’opposent à la marchandisation de nos libertés
          fondamentales (sans quoi la moindre «&#160;égalité des droits&#160;»
          ne bénéficierait qu’à ceux pouvant se l’acheter).
        </Text>
        <Text>
          Pour résumer, depuis plusieurs années, les modèles économiques de
          Facebook ou Google n’ont, juridiquement, aucune raison
          d’exister&#160;: ils se financent grâce à une analyse comportementale
          de masse à des fins publicitaires. Or cette analyse n’est possible
          qu’avec notre consentement. Nous devrions pouvoir accéder à leur
          service tout en refusant de donner ce consentement, sinon ce
          consentement n’est pas libre&#160;: mais si nous avions véritablement
          cette possibilité, la très grande majorité d’entre nous refuserait
          cette surveillance.
        </Text>
        <Text>
          Leur modèle est illégal et juridiquement absurde depuis longtemps. Le
          seul problème, en vérité, c’est que personne n’avait le pouvoir, ou la
          volonté, de les arrêter. Nous en avons la volonté, et désormais des
          moyens, avec le RGPD. C’est cela, et surtout cela, qui change avec le
          RGPD.
        </Text>
        <Text>
          Au lieu du consentement explicite, les GAFAM veulent absolument un
          vague «&#160;consentement indubitablement donné&#160;». Pour contrer
          l’influence de ce lobbying néfaste, nous rencontrons des eurodéputés
          pour les mettre en garde de ne pas voter «&#160;en faveur de l’intérêt
          des États-Unis&#160;», travaillant de concert avec les autres
          organisations européennes, s’inspirant de l’époque de l’ACTA.
        </Text>
        <Text>
          Le RGPD est adopté le 14&#160;avril 2016. Sa mise en application est
          prévue pour le 25&#160;mai 2018. Ce sont deux longues années que le
          législateur laisse aux entreprises et aux États pour se préparer. Dans
          l’entre-deux, comme pour couronner un texte déjà inattendu, certaines
          décisions de la CNIL semblent montrer qu’une nouvelle ère s’ouvre
          enfin. Les possibilités de sanction ne sont pas encore aussi
          importantes qu’une fois que le RGPD sera entré en vigueur, mais fin
          2017 l’autorité française met en demeure
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2017/12/20/cnil_whatsapp/"
                >
                  https://www.laquadrature.net/2017/12/20/cnil_whatsapp/
                </AppLink>
              )
            }
          >
            112
          </InfoClick>{" "}
          WhatsApp de corriger son système de transfert de données personnelles
          à Facebook. La CNIL considère ce transfert illicite car se fondant sur
          le consentement forcé des utilisateurs, ceux-ci ne pouvant s’y opposer
          qu’en renonçant au service. Le message est fort, et se fonde sur les
          lignes directrices du G29, groupement des CNIL européennes
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Le G29 devient le Comité européen de la protection des données
                  (CEPD) à l’entrée en vigueur du RGPD.
                </Text>
              )
            }
          >
            113
          </InfoClick>
          , en prévision de la sortie prochaine du règlement. Nous nous
          réjouissons de l’analyse faite par la CNIL, car c’est exactement celle
          que la Quadrature défend depuis des années.
        </Text>
        <Text>
          Néanmoins, dans un profond déni de réalité, États et entreprises
          attendront le dernier moment pour prendre le texte en compte.
          L’adaptation en droit français n’est votée qu’en juin&#160;2018, après
          six&#160;mois de négociation. Juste après, fin juillet, la CNIL
          déclare illicites
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2018/07/20/teemo_fidzup/"
                >
                  https://www.laquadrature.net/2018/07/20/teemo_fidzup/
                </AppLink>
              )
            }
          >
            114
          </InfoClick>{" "}
          les activités de deux start-up françaises, Teemo et Fidzup, qui
          géolocalisent des millions de personnes à des fins publicitaires et
          sans leur consentement. Elles ont trois&#160;mois pour cesser ces
          activités. De nouveau un bon signal pour la mise en place effective
          des principes fondateurs du RGPD, et de l’interprétation qui en est
          faite par la Quadrature, et sur le moment nous en venons à voir ces
          deux années comme une période d’accélération prometteuse pour la CNIL.
        </Text>
        <Text>
          Toutefois, en arrière-plan se profile déjà un autre texte qui pourrait
          venir désarmer le RGPD&#160;: le règlement ePrivacy.
        </Text>

        <Text type="h3">
          Le&#160;RGPD mis&#160;à&#160;mal&#160;: la&#160;directive ePrivacy
        </Text>
        <Text>
          Alors que l’Union européenne négocie encore le RGPD, en
          avril&#160;2016, le Parlement européen lance une consultation pour
          mettre à jour une directive de 2002 largement moins médiatisée&#160;:
          ePrivacy.
        </Text>
        <Text>
          Les deux textes sont très liés&#160;: le RGPD réglemente l’usage des
          données personnelles de manière générale, quand ePrivacy précise cet
          usage pour les services de communication. Cela inclut, par exemple, le
          spam ou la gestion des cookies, ces petits fichiers déposés sur votre
          machine qui permettent de conserver des informations sur vous d’une
          visite à l’autre. Le traitement de ces derniers est précisé par la
          directive bien avant l’entrée en application du RGPD&#160;: leur dépôt
          est soumis à un «&#160;consentement valide&#160;» de l’utilisateur,
          tel que défini par le droit supérieur. Lorsque le RGPD entre en
          vigueur, il devient ce «&#160;droit supérieur&#160;», et la définition
          du «&#160;consentement valide&#160;» change&#160;: celui-ci ne peut
          plus être «&#160;déduit&#160;» du simple fait que nous sommes informés
          par un vulgaire «&#160;bandeau cookie&#160;». Notre silence ne vaut
          plus accord, enfin&#160;! Tant que nous ne cliquons pas explicitement
          sur un bouton «&#160;j’accepte&#160;», il est strictement interdit de
          nous pister et de réaliser des profits sur nos données personnelles
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Rappelons de nouveau que notre consentement ne doit pas
                  seulement être « explicite » mais aussi « libre »&#160;: un
                  bouton « je refuse » doit être proposé et le site doit rester
                  accessible même si on ne clique pas sur « j’accepte ».
                </Text>
              )
            }
          >
            115
          </InfoClick>
          . Mais encore faut-il que l’autorité de protection des données (APD)
          de chaque État membre prenne la mesure du changement –&#160;ce qu’elle
          mettra beaucoup de mauvaise volonté à faire en France…
        </Text>
        <Text>
          Malgré l’aspect très technique de cette consultation, la Quadrature
          appelle le public à s’investir dans le débat et publie son analyse
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2017/03/06/ePrivacy_recommandations_LQDN/"
                >
                  https://www.laquadrature.net/2017/03/06/ePrivacy_recommandations_LQDN/
                </AppLink>
              )
            }
          >
            116
          </InfoClick>
          . Elle y explique que le RGPD contient des failles et, les
          technologies de surveillance capitaliste de la population continuant à
          s’améliorer, que cette nouvelle négociation est une occasion pour
          faire une fois de plus reculer les velléités des GAFAM (et des États).
        </Text>
        <Text>
          En octobre&#160;2017, deux commissions du Parlement européen publient
          leurs «&#160;opinions&#160;», des recommandations qui visent
          curieusement à faire d’ePrivacy un texte qui autorise l’analyse de nos
          messages, de nos appels et des sites que nous visitons, et même notre
          géolocalisation, sans notre consentement et à visée commerciale. Cela
          revient ni plus ni moins à vider de son sens le principe de
          consentement explicite pourtant tout juste mis en place par le
          RGPD&#160;! Nous publions un site de campagne pour faire parler du
          texte
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://eprivacy.laquadrature.net/fr/">
                  https://eprivacy.laquadrature.net/fr/
                </AppLink>
              )
            }
          >
            117
          </InfoClick>
          . La commission LIBE («&#160;libertés civiles&#160;») du Parlement
          européen, présidée par une eurodéputée de centre gauche proche des
          idées de la Quadrature, est mandatée pour avoir le dernier mot et
          arrêter la position du Parlement.
        </Text>
        <Text>
          Pour mieux comprendre l’état de ce texte qui pourrait défaire des pans
          entiers du RGPD, nous devons plonger un instant dans les méandres des
          mécanismes de l’Union européenne. En principe, toute nouvelle norme
          créée par l’UE ne peut être adoptée que si le Parlement européen et
          les gouvernements des États membres (qui négocient au sein du Conseil
          de l’UE) se mettent d’accord sur un texte identique –&#160;ce qui peut
          prendre des mois et plusieurs lectures par institution. Pour gagner du
          temps, les règles de procédure du Parlement prévoient ce qui
          suit&#160;: une commission d’examen (constituée d’une soixantaine de
          députés sur les 751) arrête, seule, la position du Parlement et
          dispose d’un mandat pour négocier avec le Conseil de l’UE, au nom de
          l’ensemble du Parlement, afin de trouver un texte commun. Cette
          négociation, sous la supervision de la Commission européenne, est
          appelée «&#160;trilogue&#160;». Elle brille par son absence totale de
          transparence. Lorsque le trilogue aboutit à un consensus, il ne reste
          plus au Parlement et au Conseil qu’à adopter le texte de compromis par
          un vote formel.
        </Text>
        <Text>
          Pour maintenir son mandat de négociation et arriver à un texte commun,
          la présidente de la commission LIBE se retrouve prête à accepter des
          compromis importants, tels que l’exploitation des métadonnées de nos
          communications sans notre consentement. Mais mis sous pression par le
          public et les organisations de la société civile affirmant que le
          résultat de cette négociation serait rejeté en bloc, les députés
          retirent finalement les compromis les plus graves. En fin de compte,
          la commission LIBE finit par voter un texte érodant moins nos libertés
          que prévu, mais qui reste tout de même largement problématique.
        </Text>
        <Text>
          Et ce n’est que le début&#160;: le fameux trilogue doit maintenant
          avoir lieu. Mais une autre pièce se joue, du côté des gouvernements.
          La mise à jour d’ePrivacy contient en effet une partie sur
          l’obligation de conservation généralisée des données de connexion. Or
          plusieurs associations attaquent justement la France sur la
          surveillance de masse induite par une conservation démesurée des
          données de connexion. Les différents membres de l’UE n’étant pas
          d’accord sur la réponse à donner à cette question (l’Italie et la
          France demandant un <i>open bar</i> sur nos données, l’Allemagne étant
          largement plus réservée, et la CJUE souhaitant calmer les velléités de
          surveillance de masse), difficile de trouver une position commune et
          d’aller négocier avec le Parlement.
        </Text>
        <Text>
          Pour compliquer le tout, de nombreuses plaintes étant en cours contre
          les GAFAM (entre autres sur des points gérés par ePrivacy), de
          multiples appels sont lancés pour éviter de modifier la législation
          alors même que les APD n’arrivent pas à appliquer celle déjà en
          vigueur.
        </Text>
        <Text>
          Résultat&#160;? Nous en sommes restés là, et ça n’est pas un mal
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2017/11/06/eprivacy_bilan_pe/"
                >
                  https://www.laquadrature.net/2017/11/06/eprivacy_bilan_pe/
                </AppLink>
              )
            }
          >
            118
          </InfoClick>
          . Les gouvernements doivent entériner une position commune avant de
          pouvoir démarrer les négociations en trilogue. Mais les discussions
          sont toujours engluées en 2022. Ce statu quo nous semble être plutôt
          une bonne situation&#160;: les textes actuels sont relativement
          corrects et, à l’inverse, rien ne nous garantit qu’un futur texte
          permettrait d’améliorer les choses.
        </Text>
        <Text>
          Tout ça pour ça. Parfois, la victoire ressemble plus à un marécage
          qu’à autre chose.
        </Text>

        <Text type="h3">
          Traduire le&#160;droit européen en&#160;droit français&#160;:
          <br />
          la «&#160;loi Protection des&#160;données personnelles&#160;»
        </Text>
        <Text>
          Dès 2016, mais surtout à partir de 2018, les pays de l’Union
          européenne se lancent dans l’adaptation en droit national de deux
          textes&#160;: le RGPD et la directive 2016/680 (aussi appelée
          «&#160;directive Police-Justice&#160;»)
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Un règlement européen est d’application directe dans les
                  législations nationales dès son entrée en vigueur. Une
                  directive européenne doit être transposée dans le droit
                  national dans un certain délai. Dans le cas du RGPD, plusieurs
                  points peuvent néanmoins être adaptés en droit national, par
                  exemple l’âge minimum pour s’inscrire sur un réseau social (à
                  défaut, une valeur est proposée par le RGPD).
                </Text>
              )
            }
          >
            119
          </InfoClick>
          . Le premier impose des obligations à toute personne qui exploite les
          données personnelles d’Européens, sauf si elle le fait dans le cadre
          de la lutte contre les infractions et les menaces à la sécurité
          publique, donc pour le compte de l’État. Le second texte encadre,
          justement, cette lutte contre les infractions, en fixant des principes
          généraux que les États membres doivent traduire dans leur droit
          national en règles précises
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  En théorie, une directive pose des principes généraux qui
                  doivent être transcrits en droit national. Ces dernières
                  années, les directives sont devenues de plus en plus précises,
                  quand les règlements, s’appliquant directement dans tous les
                  États, contiennent de plus en plus de parties à préciser par
                  chaque État.
                </Text>
              )
            }
          >
            120
          </InfoClick>
          . Les États membres ont jusqu’au 6&#160;mai 2018 pour en faire la
          transposition.
        </Text>
        <Text>
          En France, c’est le projet de loi Protection des données personnelles
          qui doit adapter ces deux textes. La Quadrature rencontre la
          rapporteure du projet de loi, Paula Forteza, alors encore membre de
          LREM, et propose en janvier&#160;2018 six&#160;amendements
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2018/01/18/pjl_rgpd_amendements/"
                >
                  https://www.laquadrature.net/2018/01/18/pjl_rgpd_amendements/
                </AppLink>
              )
            }
          >
            121
          </InfoClick>{" "}
          aux parlementaires&#160;:
        </Text>
        <ul>
          <li>
            <Text>
              Faciliter les actions de groupe, en prévoyant que les personnes
              (notamment les entreprises) sanctionnées remboursent à
              l’association les frais engagés pour porter l’action.
            </Text>
          </li>
          <li>
            <Text>
              Exiger que les données soient chiffrées de bout en bout chaque
              fois que cela est possible.
            </Text>
          </li>
          <li>
            <Text>
              Préciser dans la loi le caractère libre du consentement.
            </Text>
          </li>
          <li>
            <Text>
              Concilier de façon cohérente la liberté d’expression et la
              protection des données personnelles, notamment en intégrant la
              jurisprudence de la Cour européenne des droits de l’homme (CEDH)
              qui instaure le critère de «&#160;contribution à un débat
              d’intérêt général&#160;» pour autoriser la publication de données.
            </Text>
          </li>
          <li>
            <Text>
              Renforcer la protection des données sensibles, en corrigeant des
              imprécisions du RGPD, en intégrant des positions de la CNIL.
            </Text>
          </li>
          <li>
            <Text>Corriger la loi Renseignement de 2015.</Text>
          </li>
        </ul>
        <Text>
          En effet, la directive réglemente aussi des sujets portés par la
          fameuse loi Renseignement, qui doit donc être modifiée pour être
          rendue conforme. Une occasion en or pour nous&#160;! Nous proposons
          alors quatre axes d’amélioration, qui sont d’ailleurs tous exigés par
          la directive.
        </Text>
        <Text>
          Premièrement, que les personnes qui font l’objet d’une mesure de
          surveillance en soient informées dès que cette mesure prend fin, ou
          ultérieurement si cela met en péril l’objectif qui a initialement
          motivé la mesure (actuellement, ces personnes n’ont absolument aucune
          façon d’être informées des mesures subies). Deuxièmement, que la
          Commission nationale de contrôle des techniques de renseignement
          (CNCTR) ait accès aux renseignements transmis aux services français
          par des services étrangers, afin de vérifier que ceux-ci sont
          légalement exploités (ce que la CNCTR ne peut pas faire aujourd’hui).
          Troisièmement, que toute personne puisse saisir une juridiction pour
          contester la légalité d’une mesure de surveillance dont elle pense
          faire l’objet (ce que le droit français ne prévoit pas s’agissant des
          mesures de surveillance internationale, menée en particulier par la
          DGSE). Enfin, quatrièmement, que les services français ne puissent
          échanger des renseignements avec d’autres services, français ou
          étrangers, qu’en respectant les mêmes conditions que pour la mise en
          œuvre d’une technique de recueil de renseignement, et sous le contrôle
          de la CNCTR.
        </Text>

        <Text>
          Mais députés et sénateurs ne l’entendent pas de cette oreille et
          refusent de légiférer pour adapter la sacro-sainte loi Renseignement à
          la directive. Incroyablement, cela revient, ni plus ni moins, à
          refuser d’appliquer le droit européen en droit français. Les
          parlementaires seraient-ils partisans du Frexit&#160;?
        </Text>
        <Text>
          Dans les amendements au projet de loi déposés par des députés s’en
          trouve un qui démontre une certaine tentation néolibérale&#160;: celui
          de Bruno Bonnell (LREM) qui souhaite transformer nos données
          personnelles en de simples marchandises
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="http://www.assemblee-nationale.fr/15/amendements/0592/AN/20.asp"
                >
                  http://www.assemblee-nationale.fr/15/amendements/0592/AN/20.asp
                </AppLink>
              )
            }
          >
            122
          </InfoClick>
          , qu’il nous serait possible de céder pour accéder à un service sans
          avoir à payer en monnaie. Heureusement, ni le Parlement européen, ni
          la CNIL, ni l’ensemble des CNIL européennes ne sont dupes, et
          s’opposent clairement à ce que l’accès à des biens ou services puisse
          être conditionné à la cession de données personnelles. Il faut donc y
          voir une tentative désespérée de semer la confusion avant l’arrivée
          prochaine des changements majeurs que laisse espérer le règlement
          européen sur la protection des données. Mais cette idée revenant
          régulièrement, elle mérite qu’on s’y arrête.
        </Text>

        <Text type="h3">
          La&#160;patrimonialisation des&#160;données, fausse bonne idée
        </Text>
        <Text>
          Au printemps 2019, l’un de nous est invité au Luxembourg pour
          participer à un grand débat public, un show politique animé par Audrey
          Pulvar. L’enjeu du débat&#160;? La «&#160;patrimonialisation des
          données&#160;». Face à notre juriste de choc, l’autre débatteur est
          Gaspard Koenig, président du <i>think tank</i> Génération libre, qui
          tente depuis plusieurs années de populariser l’idée que chaque
          individu doit être propriétaire de ses données personnelles, au même
          titre qu’il l’est d’un capital ou d’un bien.
        </Text>
        <Text>
          On voit l’intérêt immédiat de la proposition&#160;: utiliser des
          données personnelles sans consentement, ce serait du vol. Le droit de
          la propriété privée est suffisamment ancien et établi pour que nos
          données personnelles, si elles sont un bien que nous possédons, soient
          dès lors protégées contre les utilisations abusives qui en sont
          faites. Mieux encore&#160;: ce qu’on possède, on peut le vendre avec
          profit. Puisque Google s’enrichit en vendant nos données, on doit
          pouvoir gagner de l’argent en vendant ses données à Google&#160;!
          Hélas, ce rêve ultralibéral brille comme la pyrite&#160;: on dirait de
          l’or, mais c’est un simple caillou.
        </Text>
        <Text>
          Les institutions juridiques en France et en Europe ont toujours refusé
          cette propriété des données personnelles. Elles estiment que la
          propriété privée n’est pas le seul moyen de définir un droit. Quand un
          État prélève un impôt ou assujettit un citoyen à une obligation
          militaire, ce n’est pas au nom d’un droit de propriété. Le fait que
          les parents aient des devoirs et des droits sur l’éducation de leurs
          enfants ne découle pas non plus d’un droit de propriété. On pourrait
          par exemple envisager les données comme une «&#160;production
          incorporelle&#160;» de la personne, au même titre qu’une idée ou
          qu’une œuvre, protégée par le droit d’auteur. Mais le droit européen
          affirme plutôt que les données personnelles sont un{" "}
          <i>prolongement</i>, une <i>partie </i>de la personne&#160;: elles
          constituent un attribut de la personnalité, une émanation immatérielle
          de l’individu, un prolongement incorporel de soi-même. La
          «&#160;personnalisation&#160;», donc, plutôt que la
          «&#160;patrimonialisation&#160;».
        </Text>
        <Text>
          C’est ce qui fonde l’efficacité du RGPD&#160;: même si vous consentez
          au traitement de vos données personnelles par un site Web pour une
          finalité définie, vous gardez un certain nombre de droits sur ces
          données (accès, rectification, opposition, effacement, etc.), ainsi
          que la possibilité de révoquer à tout moment votre consentement. En
          revanche si vous les aviez vendues, vous auriez perdu tous ces droits.
        </Text>
        <Text>
          Par ailleurs, l’approche individualiste de la logique
          «&#160;patrimoniale&#160;» lui fait manquer le véritable enjeu de
          l’exploitation des données, qui est collectif, à l’échelle de la
          société tout entière. D’abord une mise au point&#160;: nous ne devons
          pas protéger nos données personnelles sous prétexte que les sites Web
          nous espionneraient ou nous surveilleraient –&#160;l’enjeu n’est pas
          là. Mais nous devons les protéger parce que les sites Web les
          utilisent pour nous transformer en cibles publicitaires&#160;: les
          traces que nous laissons en naviguant sur le Web permettent aux
          publicitaires de nous inscrire dans une multitude de profils dont nous
          sommes l’intersection unique. Ces pratiques ont transformé le Web en
          vaste espace publicitaire et marchand.
        </Text>
        <Text>
          Que je m’appelle Gérard Martin, cadre dans l’industrie alimentaire
          vivant dans un pavillon au 37 de la rue des Bégonias, n’intéresse pas
          directement les publicitaires en ligne (ils ne sont pas policiers). Ce
          qui les intéresse est anonyme et me relie à mes proches&#160;: si je
          suis célibataire (restaurant, parfum, livraison à domicile) ou en
          couple (voyage, voiture, immobilier), si j’ai des enfants en bas âge
          (couches) ou plus grands (vélo, jeux vidéo), etc.
        </Text>
        <Text>
          Les données personnelles valorisées par les publicitaires m’impliquent
          en tant qu’élément d’un ensemble familial, professionnel ou social qui
          me dépasse. Google ne vend pas aux publicitaires des données
          individuelles, mais des données «&#160;agrégées&#160;». Vous n’êtes
          une personne intéressante que parce que vous êtes à l’intersection
          d’un réseau familial qui lit des mangas, aime tricoter, et vit dans
          telle région avec tel pouvoir d’achat, d’un réseau professionnel qui
          achète des services informatiques ou des grues de chantier, d’un
          réseau social qui vote et revendique des droits. Ce sont des données
          collectives, et la voie de la patrimonialisation des données
          personnelles revient à reconnaître un droit de propriété sur une chose
          qui ne vous appartient pas en propre. C’est d’ailleurs pour avoir
          compris l’importance de cette dimension réticulaire des données
          personnelles que des entreprises comme Google ou Facebook ont
          construit leurs empires sur la publicité ciblée.
        </Text>
        <Text>
          Cette approche permet aux régies publicitaires de modéliser des
          comportements et d’essayer de les influencer grâce à des messages
          publicitaires, qu’il s’agisse d’engendrer un acte économique
          (l’achat), un acte social (prévention médicale, par exemple) ou un
          acte politique&#160;: le vote. On se souvient par exemple de l’affaire
          Cambridge Analytica, qui a mis au jour l’utilisation des données
          fournies par Facebook pour influencer les électeurs lors du référendum
          britannique sur le Brexit (juin&#160;2016) et lors de la
          présidentielle américaine de novembre&#160;2016 (élection de Trump).
          L’exploitation des données personnelles a des conséquences sociales.
          La vie privée est un droit collectif.
        </Text>
        <Text>
          Malheureusement, la CNIL semble avoir assoupli sa position sur la
          patrimonialisation des données. Elle considère aujourd’hui qu’un
          service en ligne peut conditionner son accès soit au paiement d’un
          abonnement, soit au traitement des données personnelles des
          internautes à des fins publicitaires. Autrement dit, la CNIL autorise,
          sous certaines conditions
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  La CNIL exige notamment que le service en question imposant le
                  traitement de données personnelles à des fins publicitaires ou
                  le paiement d’un abonnement n’ait pas une position dominante
                  dans son secteur et que des services alternatifs existent.
                </Text>
              )
            }
          >
            123
          </InfoClick>
          , les services en ligne, notamment le monde de la presse en ligne, à
          considérer que les données de ses utilisateurs valent quelques euros.
        </Text>
        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter2Part4;
