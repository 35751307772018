import React, { useState } from "react";

import {
  AppLink,
  InfoClick,
  Citation,
  Modal,
  Text,
  PagePattern,
} from "src/components";

const Chapter1Part4 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Ces politiques qui n’ont rien compris">
      <>
        <Text>
          Comme la Hadopi 2 avait remplacé la Hadopi en France, l’ACTA
          s’inscrivait dans la lignée des traités anti-piratage américains PIPA
          et SOPA. Bien que certains projets tombent à l’eau, personne n’en
          doute alors&#160;: d’autres suivront.
        </Text>
        <Text>
          L’insistance du pouvoir à vouloir faire passer sous une forme ou une
          autre des textes pourtant régulièrement retoqués trahit un monde fermé
          sur lui-même, qui ne représente plus les citoyens, davantage perméable
          aux lobbies plutôt qu’à la société civile. Du point de vue des
          militants, cet éternel retour des mêmes formules copiées-collées
          énerve, alors que nous avions pourtant l’impression d’avoir gagné.
          Comment relancer sans cesse la machine et l’engouement populaire sur
          les mêmes arguments&#160;? Heureusement, la culture geek est
          résiliente. Elle s’empare de toutes les occasions possibles pour créer
          et rire.
        </Text>
        <Text>
          Et mieux vaut avoir de l’humour et de l’autodérision quand on est sous
          le feu des critiques&#160;! Lors des débats Hadopi, en 2008-2009, les
          députés ont reçu des quantités de messages, argumentés et
          personnalisés, comme ils en avaient rarement vues. Sortant de ses
          gonds, la ministre de la Culture de l’époque, Christine Albanel, a
          carrément accusé l’association de falsifier ces milliers d’e-mails.
          Quelques semaines plus tard, le cabinet de celle qui a vanté les
          mérites du fameux «&#160;pare-feu OpenOffice
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Lors des débats Hadopi, à un député demandant comment au
                    juste les citoyens utilisant des logiciels libres seraient
                    censés sécuriser leur accès à Internet (le défaut de
                    sécurisation étant ce que la loi Hadopi se proposait de
                    punir), la ministre explique maladroitement que des pare-feu
                    gratuits existent, citant la suite bureautique OpenOffice
                    qu’utilise son ministère comme exemple :
                  </Text>
                  <AppLink
                    white
                    href="https://www.assemblee-nationale.fr/13/cri/2008-2009/20090211.asp"
                  >
                    https://www.assemblee-nationale.fr/13/cri/2008-2009/20090211.asp
                  </AppLink>
                </>
              )
            }
          >
            33
          </InfoClick>
          &#160;» a expliqué sans ciller dans une dépêche AFP que La Quadrature
          du Net n’était que «&#160;cinq gus dans un garage qui font des e-mails
          à la chaîne
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <AppLink
                    white
                    href="https://www.numerama.com/magazine/12233-pour-albanel-la-quadrature-du-net-c-est-5-gus-dans-un-garage.html"
                  >
                    https://www.numerama.com/magazine/12233-pour-albanel-la-quadrature-du-net-c-est-5-gus-dans-un-garage.html
                  </AppLink>
                  <br />
                  <AppLink
                    white
                    href="https://www.nextinpact.com/archive/49575-quadrature-gus-garage-internet-depeche.htm"
                  >
                    https://www.nextinpact.com/archive/49575-quadrature-gus-garage-internet-depeche.htm
                  </AppLink>
                  ;
                </>
              )
            }
          >
            34
          </InfoClick>
          &#160;». Bien que cette dépêche ait ensuite été réécrite pour
          supprimer ce passage, l’association s’est dite flattée de tant
          d’attention de la part du ministère&#160;en communiquant ainsi&#160;:
          «&#160;Cela prouve que l’action des nombreux citoyens épris de liberté
          qui contactent leurs députés commence à porter ses fruits. Cela révèle
          la peur de la ministre de se retrouver confrontée aux réalités
          techniques et à l’opinion des citoyens.&#160;»
        </Text>
        <Text>
          Loin de nous formaliser des insultes de la ministre, nous baptisons
          les bureaux de l’association le «&#160;Garage&#160;», tout comme le
          compte Twitter communautaire des bénévoles.
        </Text>

        <Text type="h3">
          Le&#160;<i>peer-to-peer</i>, quèsaco&#160;?
        </Text>
        <Text>
          La question de l’ignorance des réalités techniques se pose,
          effectivement…
        </Text>
        <Text>
          On se souvient de Jacques Chirac, interrompant une présentation des
          fonctionnalités d’Internet à la Bibliothèque nationale de France en
          1996 pour demander ce qu’est une souris. On se rappelle
          l’interrogation (légitime) d’une partie de la presse en 2009, doutant
          du fait que Nicolas Sarkozy ait déjà envoyé un e-mail
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="http://www.politique.net/2009031201-nicolas-sarkozy-a-t-il-deja-envoye-un-e-mail.htm"
                >
                  http://www.politique.net/2009031201-nicolas-sarkozy-a-t-il-deja-envoye-un-e-mail.htm
                </AppLink>
              )
            }
          >
            35
          </InfoClick>
          … Et François Fillon, alors fringant Premier ministre, de déclarer
          sans sourciller qu’il «&#160;est un vrai geek
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.liberation.fr/france/2009/06/18/fillon-ce-vrai-geek_565464/"
                >
                  https://www.liberation.fr/france/2009/06/18/fillon-ce-vrai-geek_565464/
                </AppLink>
              )
            }
          >
            36
          </InfoClick>
          &#160;» car il utilise un iPhone, possède deux Macs et des appareils
          photos numériques. Fichtre&#160;! Depuis, la Hadopi est passée. Mais
          les choses ont-elles changé&#160;?
        </Text>
        <Text>
          Dès le début des années 2000, le <i>peer-to-peer</i> (ou
          «&#160;pair-à-pair&#160;» en français) est sur toutes les lèvres,
          représentant un espace miraculeux donnant accès au cinéma, à la
          musique, et même à des séries télé souvent inédites en France. Il
          permet l’échange direct de données entre les ordinateurs reliés à un
          même réseau comme Internet, sans passer par un serveur central. Mais
          pour la classe politique, le <i>peer-to-peer</i> n’est qu’un excellent
          candidat au titre de fossoyeur de la culture française. Un danger
          manifeste donc, que les décideurs peinent pourtant à définir…
        </Text>
        <Text>
          Des journalistes du site Bakchich.info et de Canal&#160;+ s’exercent à
          une sorte de micro-trottoir sur la moquette des couloirs de
          l’Assemblée
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <AppLink
                    white
                    href="https://www.liberation.fr/ecrans/2009/04/30/peer-to-peer-moi-je-parle-francais-excusez-moi_958546/"
                  >
                    https://www.liberation.fr/ecrans/2009/04/30/peer-to-peer-moi-je-parle-francais-excusez-moi_958546/
                  </AppLink>
                  <br />
                  <AppLink
                    white
                    href="https://www.nextinpact.com/archive/53070-hadopi-p2p-reponses-deputes-bakchich.htm"
                  >
                    https://www.nextinpact.com/archive/53070-hadopi-p2p-reponses-deputes-bakchich.htm
                  </AppLink>
                  <br />
                  <AppLink
                    white
                    href="https://www.dailymotion.com/video/k5717nCr79Ob4tZ833"
                  >
                    https://www.dailymotion.com/video/k5717nCr79Ob4tZ833
                  </AppLink>
                </>
              )
            }
          >
            37
          </InfoClick>
          . Ils interrogent les députés en leur demandant d’expliquer ce qu’est
          le <i>peer-to-peer</i> avec leurs propres mots, voire ce qui le
          différencie du BitTorrent&#160;; BitTorrent étant –&#160;c’est tout le
          piège de la question&#160;– un des multiples types de réseaux{" "}
          <i>peer-to-peer</i>.
        </Text>
        <Text>L’effet comique est immédiat&#160;:</Text>
        <Text>
          Bakchich&#160;: «&#160;Est-ce que vous savez ce qu’est le{" "}
          <i>peer-to-peer</i>&#160;?&#160;»
        </Text>
        <Text>
          Henri Plagnol [député UMP du Val-de-Marne, favorable à la
          Hadopi]&#160;: «&#160;Oui, bien sûr&#160;!&#160;»
        </Text>
        <Text>Bakchich&#160;: «&#160;Alors, expliquez-nous…&#160;»</Text>
        <Text>
          Henri Plagnol&#160;: «&#160;Bah, le <i>peer-to-peer</i>, c’est tout
          simplement pouvoir… euh… s’adresser en direct par… euh, tout ce qui
          est technologie numérique des personnes qui sont dans la même
          situation que vous. Hein, donc c’est un… C’est quelque chose de très
          positif et qu’il faut encourager&#160;!&#160;»
        </Text>
        <Text>Un autre&#160;:</Text>
        <Text>
          Bakchich&#160;: «&#160;Est-ce que vous savez ce qu’est le{" "}
          <i>peer-to-peer</i>&#160;?&#160;»
        </Text>
        <Text>
          Jean-Pierre Grand [député UMP de l’Hérault]&#160;: «&#160;Non.&#160;»
        </Text>
        <Text>
          Bakchich&#160;: «&#160;Non&#160;? Ou le <i>streaming</i>&#160;?&#160;»
        </Text>
        <Text>
          Jean-Pierre Grand&#160;: «&#160;Euh non. Moi je parle français,
          excusez-moi (<i>il s’en va</i>).&#160;»
        </Text>

        <Text>
          Comment prendre au sérieux une classe politique qui montre des
          velléités à contrôler ce qu’elle comprend soit mal, soit pas du
          tout&#160;? Leur insistance à faire passer la loi Hadopi est tournée
          en ridicule jusque sur les plateaux de télévision. Nous y intervenons
          le plus souvent possible, arborant fièrement nos tee-shirts
          «&#160;Hadopi, même pas peur&#160;!&#160;», appelant la population à
          ne pas craindre ce «&#160;gendarme du Net&#160;» qui promet d’être une
          belle usine à gaz. Par exemple, techniquement, la loi est censée faire
          la chasse au «&#160;défaut de sécurisation d’accès à Internet&#160;»,
          un délit aussi vaseux que sa terminologie, que tout le monde se refuse
          à expliquer concrètement, le décret d’application de la loi devant
          alors préciser tout ça «&#160;plus tard&#160;».
        </Text>
        <Text>
          En lisant l’étude publiée par la Hadopi en janvier&#160;2011, on est
          d’ailleurs frappé par la mise en avant du thème de la
          «&#160;sécurité&#160;», primordial puisque le détenteur de la ligne
          est tenu responsable devant la loi des usages «&#160;illicites&#160;»
          qui seraient faits de sa connexion. La Haute Autorité peut ainsi mieux
          coincer les contrevenants&#160;: si votre wifi reste ouvert à tous les
          vents, on ne pourra jamais vous coller une amende pour ces usages avec
          certitude mais vous serez condamné quand même pour «&#160;défaut de
          sécurisation&#160;» de votre connexion à Internet&#160;!
        </Text>
        <Text>
          La sécurisation, c’est simple en théorie, compliqué en pratique. Et
          les recommandations officielles pour être «&#160;sécurisé&#160;» font
          toujours défaut. À l’époque d’ailleurs, certaines personnes ne
          manquent pas de faire remarquer que le ministère de la Défense se
          refuse à utiliser le wifi, ne s’estimant pas en mesure de sécuriser
          suffisamment les accès sans-fil. Mais ne vous inquiétez pas, vous,
          vous y arriverez&#160;!
        </Text>
        <Text>
          La Hadopi en elle-même attire les moqueries à plus d’un titre et n’est
          pas à un paradoxe près&#160;! Morceaux choisis. Elle publie en
          janvier&#160;2011 une étude à l’occasion du MIDEM, le grand raout
          annuel des industries de la musique. Sur cette dernière, est écrit
          noir sur blanc que les personnes qui téléchargent des contenus
          «&#160;illicites&#160;» dépensent aussi en moyenne plus d’argent dans
          des biens culturels «&#160;licites&#160;». Autrement dit, bien loin
          d’être des pirates ou des voleurs, les téléchargeurs sont aussi des
          personnes qui font vivre l’industrie culturelle marchande, et leur
          grand appétit de biens culturels emprunte toutes les voies existantes,
          qu’elles soient légales ou non, payantes ou non
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Rapport du 23 janvier 2011 téléchargeable sur le site de la
                    Hadopi :{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.hadopi.fr/ressources/etudes/etude-presentation-de-letude-hadopi-cannes-lors-du-midem-23-janvier-2011"
                  >
                    https://www.hadopi.fr/ressources/etudes/etude-presentation-de-letude-hadopi-cannes-lors-du-midem-23-janvier-2011
                  </AppLink>
                  <Text>
                    . Voir en particulier la page 9 de la synthèse courte (PDF
                    téléchargeable).
                  </Text>
                </>
              )
            }
          >
            38
          </InfoClick>
          .
        </Text>
        <Text>
          En juin&#160;2011, la soirée de lancement de la campagne de
          communication de la Hadopi –&#160;une campagne chiffrée à
          3&#160;millions d’euros tout de même
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://leplus.nouvelobs.com/contribution/2053-campagne-de-pub-hadopi-une-pur-arnaque.html"
                >
                  https://leplus.nouvelobs.com/contribution/2053-campagne-de-pub-hadopi-une-pur-arnaque.html
                </AppLink>
              )
            }
          >
            39
          </InfoClick>
          &#160;! –&#160;doit créer l’événement et redorer le blason d’une loi
          que tout le monde redoute et que personne ne comprend. Nous prenons la
          direction des beaux quartiers de Paris et nous regroupons devant
          l’espace Pierre-Cardin, choisi pour cette petite sauterie. C’est
          l’occasion pour la Haute Autorité de présenter son label
          «&#160;PUR&#160;» (pour «&#160;promotion des usages
          responsables&#160;») (<i>sic</i>), qui doit être apposé sur les sites
          de téléchargement «&#160;autorisés&#160;». Même la presse se
          moque&#160;: «&#160;Hadopi perdue en naze campagne&#160;», titre{" "}
          <i>Libération</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.liberation.fr/ecrans/2011/06/08/hadopi-perdue-en-naze-campagne_953991"
                >
                  https://www.liberation.fr/ecrans/2011/06/08/hadopi-perdue-en-naze-campagne_953991
                </AppLink>
              )
            }
          >
            40
          </InfoClick>
          .
        </Text>
        <Text>
          Bafouillements, contresens, bêtises en pagaille&#160;: les députés n’y
          connaissent rien et n’y comprennent rien. Le bon mot «&#160;
          <i>peer-to-peer</i>&#160;: le pire du pire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://www.dailymotion.com/video/x94ta5">
                  https://www.dailymotion.com/video/x94ta5
                </AppLink>
              )
            }
          >
            41
          </InfoClick>
          &#160;» tourne, nous rions et sommes nombreux à rire, un peu jaune
          toutefois…
        </Text>
        <Text>
          S’il est possible, ponctuellement, de faire passer de l’intérieur
          quelques idées dans certains cas très exceptionnels, le fossé est
          grand entre les députés –&#160;et la classe politique dans son
          ensemble&#160;– et la population, qui a une expérience concrète et
          positive d’Internet. Même les membres de la garde républicaine nous
          confient, sur le trottoir devant l’Assemblée nationale qu’ils ont pour
          mission de protéger, qu’à la caserne tout le monde utilise les réseaux{" "}
          <i>peer-to-peer</i> pour télécharger les derniers films et séries.
        </Text>
        <Text>
          L’incompréhension entre entreprises et décideurs d’un côté, et milieu
          geek de l’autre, s’est illustrée à l’international par l’histoire d’un
          site Internet d’un genre particulier&#160;: The Pirate Bay. Monté en
          2003 par trois Suédois, le site est mondialement connu pour être le
          plus grand répertoire de liens pour télécharger des contenus via les
          logiciels de torrent.
        </Text>
        <Text>
          Techniquement, c’est un site Web permettant la mise en relation des
          fameux «&#160;pairs&#160;» (c’est-à-dire les ordinateurs des uns et
          des autres connectés à Internet) et le partage de fichiers entre eux.
          Adossé à un <i>tracker</i>, le site public{" "}
          <a href="http://thepiratebay.org">thepiratebay.org</a> sert d’index de
          fichiers torrent. Ces derniers, contrairement à ce que paraissent (ou
          font semblant de) penser les industries du disque, du film et du jeu
          vidéo, ne sont ni des films, ni des albums, ni des jeux. Ils se
          comportent comme des «&#160;tables des matières&#160;» renvoyant à
          d’autres fichiers (le plus souvent des films, des albums, des jeux…).
          Ils ne contiennent rien d’autre qu’une liste comprenant les points de
          rendez-vous (les <i>trackers</i>), le nom des fichiers concernés et
          d’autres informations techniques. Une fois chargés dans le bon
          logiciel, ces fichiers torrent permettent d’être mis en contact avec
          des pairs qui, eux, possèdent tout ou partie des fichiers en question,
          et peuvent en envoyer une copie.
        </Text>
        <Text>
          Cette distinction, assez technique, convenons-en, entre hébergement de
          fichiers sous copyright et mise à disposition d’un «&#160;index
          d’index&#160;» est la base sur laquelle les trois larrons de TPB
          estimaient leur site légal.
        </Text>
        <Text>
          Malgré ce mode de défense assez audacieux, le 31&#160;mai 2006, la
          police suédoise organisa un raid contre The Pirate Bay. Elle saisit du
          matériel informatique dans douze <i>data centers</i> différents. Rendu
          inaccessible, TPB fit la une des médias internationaux, la Motion
          Picture Association of America, qui défend les intérêts des plus
          grands studios hollywoodiens, se gargarisant du succès de l’opération.
        </Text>
        <Text>
          À peine trois jours plus tard, le site resurgit, boosté par sa
          couverture médiatique. Ses visiteurs étaient deux fois plus nombreux
          qu’avant sa fermeture. C’est le pied de nez des partageurs de culture.
          L’<i>effet Streisand</i>, ce phénomène qui se produit lorsque la
          volonté d’empêcher la divulgation d’informations que l’on aimerait
          garder cachées déclenche le résultat inverse, est décuplé. Rien
          n’arrête le partage sur Internet.
        </Text>

        <Text type="h3">
          Se&#160;rencontrer pour mieux lutter&#160;: les&#160;Quadr’apéros
        </Text>
        <Text>
          Début 2011, il devient évident que la Quadrature doit pouvoir compter
          ses soutiens autrement que par les seuls dons. Nos actions demandent
          une forte participation humaine. Pour rassembler le cercle des proches
          et partager un calendrier politique, nous mettons en place le principe
          d’un rendez-vous régulier, mensuel, rassemblant en un même lieu les
          sympathisants.
        </Text>
        <Text>
          Le premier Quadr’apéro se tient ainsi le 25&#160;mars 2011
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://wiki.laquadrature.net/Compte-rendu_du_quadr%27ap%C3%A9ro_du_25_mars_2011"
                >
                  https://wiki.laquadrature.net/Compte-rendu_du_quadr%27ap%C3%A9ro_du_25_mars_2011
                </AppLink>
              )
            }
          >
            42
          </InfoClick>
          , au Loop (pour «&#160;Lieu ouvert ou pas&#160;»), <i>hackerspace</i>{" "}
          parisien situé à l’époque à côté de la place de la République. Le
          programme de la soirée (et de celles à venir)&#160;: se rencontrer,
          discuter autour d’un verre, et faire le point sur l’actualité touchant
          aux sujets de la Quadrature.
        </Text>
        <Text>
          Deux&#160;mois plus tard est organisé un événement de plus grande
          ampleur, sur un week-end&#160;: le Quadrature Communication Camp. Plus
          d’une centaine de bonnes volontés se retrouvent pour deux jours de
          travail, d’échanges et d’ateliers politiques.
        </Text>
        <Text>
          Si le second, exigeant une importante consommation d’énergie
          militante, ne sera pas réitéré sous cette forme, mais à une échelle
          plus petite avec des ateliers sur une soirée, le premier est
          pérennisé, et rassemble toujours autant. Il permet régulièrement à de
          nouveaux visages de croiser les habitués et les membres de
          l’association et d’échanger, de se tenir au courant, voire de
          participer à des actions futures. La Quadrature a trouvé là un axe
          important de communication et d’extension de sa communauté.
          L’événement, très parisien, essaime ponctuellement dans d’autres
          villes.
        </Text>
        <Text>
          Les premiers Quadr’apéros ont lieu dans des <i>hackerspaces</i>, en
          extérieur, ou sont hébergés par une fondation. Et puis, un jour, le
          temps est venu&#160;: nous louons enfin nos propres locaux&#160;! Fini
          le télétravail pour les salariés de la Quadrature. Les ressources
          permettent d’héberger une équipe grandissante. Travail quotidien,
          interviews, Quadr’apéros, ateliers divers, nous avons désormais un
          lieu dédié. L’activité se développe-t-elle parce qu’on a les bons
          outils, ou acquiert-on les outils parce que l’activité se
          développe&#160;? Question classique de l’existence d’Internet&#160;:
          le réseau de réseaux existe-t-il parce qu’il est nécessaire à cette
          étape du développement humain, ou son arrivée transforme-t-elle la
          société qui l’adopte&#160;?
        </Text>
        <Text>
          Nos bureaux ancrent en tout cas l’association dans le réel. Nous
          n’avons d’autre choix que d’aller de l’avant. Au sous-sol, nous
          aménageons un espace avec un grand filet de camouflage militaire.
          C’est parfois là que se déroulent des interviews, dans une ambiance de
          guérilla communicationnelle. Une guérilla que l’on retrouve bien dans
          les premiers temps de l’association. Rien ne doit passer. Aucune
          bourde de nos décideurs ne doit être passée sous silence. Nous ne
          lâchons pas la bride. Évidemment, sortir des communiqués tous les deux
          jours n’est pas tenable sur la durée, et l’équipe va devoir apprendre
          à économiser ses forces. Mais le ton est posé&#160;: les politiques
          doivent compter avec la Quadrature.
        </Text>

        <Text type="h3">Opération Datalove</Text>
        <Text>
          Courant 2012, la Quadrature se positionne pour participer au futur
          débat européen sur le droit d’auteur. C’est l’opération Datalove.
        </Text>
        <Text>
          Nous défendons une réforme positive de celui-ci qui mettrait fin à la
          guerre contre le partage, en prenant en compte les droits du public,
          l’accès à la culture, tout cela dans le cadre des nouvelles pratiques
          culturelles rendues possibles par les technologies numériques.
        </Text>
        <Text>
          L’enjeu est d’ouvrir les yeux des députés européens sur les usages
          réels des artistes, usages souvent en opposition avec le droit et
          pourtant générateurs de brassages culturels, créateurs de nouvelles
          identités artistiques. Dans l’espoir de planter des petites graines
          dans les têtes de ces messieurs-dames du Parlement, nous nous rendons
          à Bruxelles pour leur distribuer des clés USB Datalove, chargées de
          contenu culturel remixé
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://cloud.lqdn.fr/s/EJNneKzm2cfz3nq">
                  https://cloud.lqdn.fr/s/EJNneKzm2cfz3nq
                </AppLink>
              )
            }
          >
            43
          </InfoClick>
          .
        </Text>
        <Text>
          Pour monter l’opération (l’édition de 1&#160;000&#160;clés et le
          voyage en Belgique de plusieurs militants), une campagne de
          financement participatif a été lancée en septembre&#160;; une première
          dans l’histoire de la Quadrature. C’est en réalité un moyen d’intégrer
          toute la communauté dans la stratégie de l’association&#160;: quand
          nous proposons quelque chose qui sort de son activité habituelle, soit
          les soutiens suivent et financent, et nous passons à l’action, soit ce
          n’est pas le cas et nous laissons tomber.
        </Text>
        <Text>
          Ce projet-là est rapidement financé. Deux&#160;mois plus tard, le
          28&#160;novembre, nous sommes six militants à faire le déplacement
          pour remettre les clés Datalove aux députés
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2012/11/28/datalove-sur-cle-usb-pour-appeler-les-eurodeputes-a-reformer-le-droit-dauteur/"
                >
                  https://www.laquadrature.net/2012/11/28/datalove-sur-cle-usb-pour-appeler-les-eurodeputes-a-reformer-le-droit-dauteur/
                </AppLink>
              )
            }
          >
            44
          </InfoClick>
          . Cette campagne est un moment motivant pour nous. Rencontrer les élus
          donne l’occasion d’échanger des propos constructifs. Nous n’avons pas
          peur de nous faire courir après par le service de sécurité, ni de nous
          faire envoyer sur les roses par des députés énervés. La clé USB est un
          cadeau qui, ne valant que quelques euros, présente peu d’effet
          corruptible, mais c’est un bel objet, en forme de vraie clé tatouée
          d’un cœur. Nous l’avons collée sur une feuille cartonnée sur laquelle
          se trouve, en anglais, le texte suivant&#160;:
        </Text>
        <Citation>
          <>
            <Text>
              <i>Chers membres du Parlement européen,</i>
            </Text>
            <Text>
              <i>
                Après le rejet d’ACTA, nous, citoyens, ressentons le besoin de
                partager avec vous ces éléments de culture digitale que nous
                construisons chaque jour sur l’internet libre, ouvert, neutre et
                donc universel.
              </i>
            </Text>
            <Text>
              <i>
                Cette clé USB Datalove a été financée de manière participative
                par des citoyens de toute l’Europe. Vous y trouverez une
                collection de musiques, films et livres qui nous sont chers et
                qui ont été créés avec la même passion du partage, de la
                réutilisation des œuvres culturelles et de la promotion de ces
                pratiques. C’est ainsi que la culture se crée de nos
                jours&#160;!
              </i>
            </Text>
            <Text>
              <i>
                Nous espérons que cela vous aidera à comprendre qu’il est urgent
                que les pratiques culturelles que rendent possibles les
                technologies numériques{" "}
              </i>

              <i>
                soient encouragées plutôt que réprimées, grâce à une profonde
                réforme positive du copyright.
              </i>
            </Text>
            <Text>
              <i>
                «&#160;Toute personne a le droit de prendre part librement à la
                vie culturelle de la communauté, de jouir des arts et de
                participer au progrès scientifique et aux bienfaits qui en
                résultent.&#160;»
              </i>
            </Text>
            <Text>
              <i>
                Article&#160;27.1 de la Déclaration universelle des droits de
                l’homme
              </i>
            </Text>
            <Text>
              <i>
                Bon visionnage, bonne écoute, bonne lecture… et bonne
                réutilisation&#160;!
              </i>
            </Text>
            <Text>
              <i>La Quadrature du Net</i>
            </Text>
          </>
        </Citation>
        <Text>
          Ce projet est un appel du pied aux élus. Nous souhaitons leur prouver
          que leurs actions vont parfois à l’encontre de l’intérêt du public.
        </Text>

        <Text type="h3">Opération Book Scanner</Text>
        <Text>
          Et puis il y a des moments de grâce, où deux mondes se rencontrent,
          comme l’opération <i>Book Scanner</i>, par exemple. Au printemps 2014,
          le ministère de la Culture organise l’événement Silicon Valois,
          «&#160;espace éphémère de travail créatif, de réflexion et de
          rencontres autour des enjeux de la culture et du
          numérique&#160;»&#160;: une fois l’idée lancée, le personnel du
          ministère doit lui donner corps et trouver des innovations techniques
          et culturelles à mettre en avant.
        </Text>
        <Text>
          Un des organisateurs en parle à un membre de la Quadrature qu’il
          connaît par ailleurs. Dans la discussion, on évoque le{" "}
          <i>Book Scanner</i>&#160;: une structure en bois et en verre pour
          caler un livre ouvert, deux appareils photos pour photographier les
          pages, et un PC portable pour traiter les images et publier un PDF du
          livre à la fin
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://diybookscanner.org/">
                  https://diybookscanner.org/
                </AppLink>
              )
            }
          >
            45
          </InfoClick>
          … L’équipe de l’événement adore l’idée&#160;: cet outil à fabriquer
          soi-même sera donc exposé au rez-de-chaussée du ministère de la
          Culture, du 15 au 28&#160;mai 2014
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.bookscanner.fr/le-bookscanner-a-silicon-valois.html"
                >
                  https://www.bookscanner.fr/le-bookscanner-a-silicon-valois.html
                </AppLink>
              )
            }
          >
            46
          </InfoClick>
          . Trois bénévoles se relayent durant deux semaines pour faire la
          démonstration de la numérisation d’un livre, depuis le scannage
          jusqu’au fichier epub. L’œuvre de Victor Hugo va y passer… ainsi que
          le roman de la ministre de l’époque, Aurélie Filippetti,{" "}
          <i>Un homme dans la poche</i>. Réussir à faire la promotion du partage
          aux agents du ministère de la Culture, alors qu’on s’oppose depuis des
          années sur la même idée, c’est une petite victoire qui ne se refuse
          pas…
        </Text>
        <Text>
          Cela prouve qu’en 2014 le dialogue avec nos représentants politiques
          est possible. Nous jouons alors sur notre jeunesse et notre maîtrise
          des nouvelles technologies, si peu comprises de nos élus, pour tenter
          d’insuffler de nouvelles idées. Par la suite, malheureusement, nos
          relations se dégraderont…
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter1Part4;
