import React from "react";

import { Text, PagePattern } from "src/components";

const Introduction = () => {
  return (
    <PagePattern partName="Introduction">
      <>
        <Text>
          La Quadrature du Net défend, depuis sa création, les libertés
          fondamentales dans l’espace numérique.
        </Text>
        <Text>Mais qu’est-ce que cela veut dire, au juste&#160;?</Text>
        <Text>
          Nous ne défendons pas les «&#160;libertés numériques&#160;» ou les{" "}
          <i>digital rights&#160;</i>; les droits et les libertés ne sont pas
          différents qu’on soit dans un espace numérique ou un espace physique.
          La Poste n’a pas plus de légitimité à ouvrir notre courrier que Google
          à fouiller dans le contenu de nos messages. La censure arbitraire ne
          doit pas s’abattre davantage sur un texte publié sur Facebook que sur
          un article dans un journal.
        </Text>
        <Text>
          Si les libertés sont «&#160;fondamentales&#160;», c’est qu’elles
          précèdent le reste et doivent être défendues en tout temps et en tout
          lieu. Au tournant du millénaire, nous avons été un certain nombre à
          constater que dès qu’il s’agit des «&#160;nouvelles technologies de
          l’information et de la communication&#160;», un effet d’émerveillement
          se produit. Un effet tel qu’il peut donner l’illusion que les
          atteintes aux libertés en ligne ne seraient pas dangereuses, ne
          compteraient pas. Rien n’est plus faux, il n’y a pas de distinction
          intrinsèque à faire que l’on soit d’un côté ou de l’autre du clavier,
          de l’écran, en ligne ou hors ligne&#160;: les libertés fondamentales,
          telles que la vie privée, la liberté d’expression, d’association ou le
          droit à un procès équitable, demandent la même exigence pour rester
          entières, valides et protectrices.
        </Text>
        <Text>
          Au début de la Quadrature, il y avait une idée, une utopie&#160;:
          l’horizontalité apportée par Internet permettrait à chacune et à
          chacun de se saisir des outils, de créer, de réutiliser, de
          contribuer, de bâtir un monde à l’image de toutes et de tous. Et,
          portant cette idée, un enthousiasme&#160;: humour geek, mèmes,
          montages, détournements, petits bouts de codes développés sur un coin
          de table pour amuser la galerie ou, mieux, améliorer un peu le monde,
          déjà, à son échelle.
        </Text>
        <Text>
          Au fil des ans, nous avons lutté pour défendre ces libertés, auprès de
          la classe politique –&#160;principalement des députés et
          eurodéputés&#160;– et dans les médias, tentant d’expliquer quand il le
          fallait les risques posés, mais aussi les possibilités offertes par le
          numérique. Nous avons lutté avec et grâce à des gens comme vous et
          nous, qui se sont saisis de ces sujets pour parfois ne plus jamais les
          lâcher et en devenir experts. Plaidoyer citoyen dans les couloirs du
          Parlement, puis contentieux contre l’État, la Quadrature s’est rêvée
          en David contre Goliath, car Internet nous avait appris que la
          connaissance était à portée de clic, que tout pouvait s’apprendre, et
          il nous avait donné faim de tout savoir, de tout faire par nous-mêmes.
        </Text>
        <Text>
          Aux premiers jours, tout était possible, car nous avions conscience
          d’être de celles et ceux qui comprennent les enjeux de la technologie
          dans une société démocratique. Les décisions politiques absurdes
          seraient forcément gommées lorsque nous les pointerions du doigt
          devant les parlementaires, dans les médias ou, au pire, devant la
          justice. La réalité était plus sombre.
        </Text>
        <Text>
          Car ce réseau Internet qui nous a rassemblés et nous a profondément
          changés n’est pas qu’un vecteur de connaissance et de culture. Le
          numérique charrie aussi la publicité ciblée et la surveillance
          généralisée, la concentration du pouvoir et la censure, la
          reproduction et le renforcement des inégalités. Dans un monde où
          l’information est pouvoir, sa concentration met en danger l’exercice
          des droits démocratiques, et les décisions hostiles aux libertés
          fondamentales sont, dans nombre de cas, bel et bien volontaires.
        </Text>
        <Text>
          Quinze&#160;ans après la création de la Quadrature, nous choisissons
          de continuer à opérer dans cet espace à la croisée de la technique, du
          droit et de la politique. Un champ parfois peu accessible, souvent mis
          à mal par des intérêts privés ou des gouvernements peu regardants,
          mais tellement utile et nécessaire.
        </Text>
        <Text>
          Nous avons été qualifiés de gauchistes, de libéraux, d’idéalistes, de
          naïfs…
        </Text>
        <Text>
          Nous avons été qualifiés de citoyennistes, et cela nous convient très
          bien.
        </Text>
        <Text>
          Nous avons été qualifiés de radicaux, et cela nous convient très bien.
        </Text>
        <Text>
          Nous avons été qualifiés de lobbyistes, mais si nous en sommes, c’est
          d’un type bien particulier&#160;: nous ne défendons pas d’intérêts
          particuliers. Nous défendons une vision particulière de l’intérêt
          général. Nous faisons du plaidoyer, pas du lobbying.
        </Text>
        <Text>
          Nous refusons toujours le terme de «&#160;propriété
          intellectuelle&#160;», créé de toutes pièces à la fin des années 1960
          par certains acteurs privés afin de pouvoir utiliser le champ lexical
          du vol pour décrire leurs adversaires.
        </Text>
        <Text>
          Nous soupirons devant l’usage du terme «&#160;contenu&#160;» pour
          décrire les œuvres d’art et les créations de l’esprit, la créativité
          du genre humain réduite à ce qu’on peut mettre dans une boîte, avec
          l’idée que le contenant serait ce qui compte.
        </Text>
        <Text>
          Nous aspirons à un monde où les valeurs originelles d’Internet
          –&#160;partage, ouverture, décentralisation&#160;– seraient de nouveau
          primordiales et sources d’émancipation.
        </Text>
        <Text>
          Ce livre raconte l’histoire d’une organisation qui rêvait d’un
          Internet émancipateur, un idéal mis à mal par les gouvernements et les
          multinationales dès que ces derniers ont pris conscience du pouvoir
          financier et sécuritaire que procuraient ces outils.
        </Text>
        <Text>
          Cette histoire est racontée par quatre militants qui en ont vécu les
          origines, les utopies, les désillusions et les espoirs. Elle ne
          saurait être totalement représentative de celle qu’ont pu vivre les
          dizaines de personnes qui ont contribué, de près ou de loin, à créer
          et à faire vivre cette association.
        </Text>
      </>
    </PagePattern>
  );
};

export default Introduction;
