import React from "react";
import cx from "classnames";

import Styles from "./Button.module.scss";

interface ButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
  variant?: "medium" | "small" | "big";
  subtext?: string;
  className?: string;
}

export const Button = ({
  onClick,
  children,
  variant = "medium",
  subtext,
  className,
}: ButtonProps) => {
  const variantStyle = Styles[variant];

  return (
    <button
      className={cx(Styles.Button, variantStyle, className)}
      onClick={onClick}
    >
      <div className={cx(Styles.Background, variantStyle)} />
      <div className={cx(Styles.Border, variantStyle)} />
      <span className={cx(Styles.Text, variantStyle)}>
        {children}
        <span className={cx(Styles.Subtext, variantStyle)}>{subtext}</span>
      </span>
    </button>
  );
};
