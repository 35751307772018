import React from "react";

import cx from "classnames";
import { Link } from "react-router-dom";

import Styles from "./IndexNavbarItem.module.scss";

import { Text } from "src/components";

const IndexNavbarItem = ({
  text,
  currentLocation,
  onClick,
}: {
  text: { name: string; link: string };
  currentLocation: boolean;
  onClick?: () => void;
}) => {
  const handleClick = () => {
    onClick && onClick();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Link to={text.link} className={Styles.LinkTextIndex} onClick={handleClick}>
      <Text
        type="h3"
        className={cx(
          Styles.IndexText,
          currentLocation && Styles.CurrentLocation
        )}
      >
        {text.name}
      </Text>
    </Link>
  );
};

export default IndexNavbarItem;
