import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Home from "./views/Home/Home";
import Navbar from "./views/Navbar/Navbar";
import Page from "./views/Page/Page";
import Introduction from "./bookContent/Introduction";
import Conclusion from "./bookContent/Conclusion";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="book"
        element={
          <>
            <Navbar />
            <Outlet />
          </>
        }
      >
        <Route path="introduction" element={<Introduction />} />
        <Route path=":chapterId">
          <Route path=":partNumber" element={<Page />} />
        </Route>
        <Route path="conclusion" element={<Conclusion />} />
      </Route>
      <Route path="*" element={<div>not found</div>} />
    </Routes>
  );
}

export default App;

/*
intro
I.1
I.2
I.3
I.4
I.5
I.6
II.1
II.2
II.3
II.4
II.5
II.6
II.7
III.1
III.2
III.3
III.4
III.5
III.6
Conclusion
*/
