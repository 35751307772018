import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useBook, useViewport } from "src/hooks";

import NavbarSmallScreens from "./NavbarSmallScreens/NavbarSmallScreens";
import NavbarBigScreens from "./NavbarBigScreens/NavbarBigScreens";

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const { chapterId, partNumber } = useParams();
  const navigate = useNavigate();
  const {
    isConclusion,
    navigateToNextPage,
    navigateToBeforePage,
    isChapter,
    isIntroduction,
    ...bookProps
  } = useBook();
  const { width: viewportWidth } = useViewport();

  const onClickNextArrow = () => {
    navOpen && !isConclusion && navigateToNextPage();
  };
  const onClickBeforeArrow = () => {
    !isIntroduction && navigateToBeforePage();
  };
  const handleCloseNav = () => navOpen && setNavOpen(false);
  const handleOpenNav = () => !navOpen && setNavOpen(true);

  useEffect(() => {
    if ((!partNumber || !chapterId) && isChapter) {
      return navigate("/", { replace: true });
    }
  }, [chapterId, isChapter, navigate, partNumber]);

  const NavbarComponent =
    viewportWidth >= 1080 ? NavbarBigScreens : NavbarSmallScreens;

  return (
    <NavbarComponent
      isNavOpen={navOpen}
      isConclusion={isConclusion}
      isIntroduction={isIntroduction}
      isChapter={isChapter}
      handleCloseNav={handleCloseNav}
      handleOpenNav={handleOpenNav}
      onClickBeforeArrow={onClickBeforeArrow}
      onClickNextArrow={onClickNextArrow}
      {...bookProps}
    />
  );
};

export default Navbar;
