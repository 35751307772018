import React, { useEffect, useState } from "react";
import cx from "classnames";

import Styles from "./PagePattern.module.scss";

import { Button, Text } from "src/components";
import { useBook } from "src/hooks";
import { ReactComponent as ArrowTop } from "../../assets/arrow-top.svg";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";

interface PagePatternProps {
  children: JSX.Element;
  partName: string;
}

export const PagePattern = ({ children, partName }: PagePatternProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const {
    navigateToBeforePage,
    navigateToNextPage,
    isConclusion,
    isIntroduction,
  } = useBook();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <ArrowTop
        className={cx(
          Styles.ArrowToScroll,
          scrollPosition < 100 && Styles.Hidden
        )}
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
        }
      />
      <div className={Styles.PageContainer}>
        <Button className={Styles.Button}>{partName}</Button>
        {children}
        <div className={Styles.Pagination}>
          {!isIntroduction && (
            <Text
              className={Styles.LinkChangePage}
              onClick={navigateToBeforePage}
            >
              <Arrow className={Styles.ArrowBefore} />
              Page précédente
            </Text>
          )}
          {!isConclusion && (
            <Text
              onClick={navigateToNextPage}
              className={cx(Styles.LinkChangePage, Styles.LinkChangePageNext)}
            >
              Page suivante
              <Arrow className={Styles.ArrowNext} />
            </Text>
          )}
        </div>
      </div>
    </>
  );
};
