import React from "react";

import Styles from "./Citation.module.scss";

interface CitationProps {
  children: JSX.Element;
}

export const Citation = ({ children }: CitationProps) => {
  return <div className={Styles.Container}>{children}</div>;
};
