import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter1Part5 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="La fin de l’innocence">
      <>
        <Text>
          Alors que les luttes autour d’Internet ont toujours été relativement
          bon enfant dans leurs modes d’action, les geeks que nous sommes
          s’aperçoivent d’un changement de paradigme. Nous prenons peu à peu
          conscience que la défense des libertés sur Internet couvre des enjeux
          bien plus importants que nous le pensions. Avec la Hadopi, le risque
          principal auquel s’exposait un utilisateur était de recevoir un
          recommandé et, éventuellement, dans un futur lointain et peu probable,
          de se voir suspendre sa connexion. Mais petit à petit, des figures
          majeures de la «&#160;résistance&#160;» sur Internet se font attaquer
          de manière bien plus violente que ce qu’on aurait pu imaginer, avec
          des conséquences parfois dramatiques. Cette succession de
          «&#160;défaites&#160;» fait entrer les militants dans une nouvelle
          ère, qui laisse derrière elle l’innocence, ou peut-être la naïveté,
          des premières années.
        </Text>

        <Text type="h3">
          Le&#160;renversement de&#160;la&#160;bataille culturelle
        </Text>
        <Text>
          Vous vous souvenez qu’en 2006 la police avait saisi du matériel
          informatique appartenant à The&#160;Pirate Bay. Après le
          resurgissement du site à travers de nombreux miroirs, les geeks
          étaient restés sur la «&#160;victoire&#160;» de l’
          <i>effet Streisand</i>. Mais pendant ce temps, la police suédoise a
          continué son enquête. Le procureur a rédigé un dossier à charge de
          4&#160;000&#160;pages. Le 31&#160;janvier 2008, les cofondateurs sont
          accusés de «&#160;promotion de la violation du droit d’auteur&#160;»,
          et un an plus tard, le 17&#160;avril 2009, ils sont reconnus coupables
          et condamnés à des peines de prison.
        </Text>
        <Text>
          En France, plus personne ne rit des recommandés de la Hadopi.
        </Text>
        <Text>
          Petit à petit, à la Quadrature et tout autour de nous, il y a une
          prise de conscience. Les excités du droit d’auteur, les teigneux de la
          censure, les obnubilés du contrôle des réseaux viennent de faire
          passer la lutte pour le droit au partage dans une autre dimension. Les
          citoyens européens peuvent-ils maintenir le rapport de force&#160;? Le
          jeune Parti pirate, qui défend une réforme du droit d’auteur et lutte
          contre les monopoles privés et les brevets, passe de
          15&#160;000&#160;à 40&#160;000 membres.
        </Text>
        <Text>
          Du côté hacker, les Anonymous font parler d’eux. Ces pirates
          informatiques et activistes politiques («&#160;hacktivistes&#160;»)
          issus du forum anonyme 4chan défendent depuis 2003 la liberté
          d’expression. Pour protester contre la sentence rendue contre les
          cofondateurs de TPB, ils lancent l’opération <i>Baylout</i>, et
          attaquent en DDoS
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  DDoS&#160;: <i>Distributed Denial of Service</i>. Attaque par
                  déni de service distribué. L’attaquant multiplie le nombre de
                  requêtes adressées à un site Internet, jusqu’à ce que,
                  submergé, le serveur ne parvienne plus à répondre aux requêtes
                  reçues. Le site n’est alors plus disponible pour les personnes
                  qui souhaiteraient le consulter.
                </Text>
              )
            }
          >
            58
          </InfoClick>{" "}
          le site de la Fédération internationale de l’industrie phonographique
          (IFPI).
        </Text>
        <Text>
          À la Quadrature, nous dénonçons de notre côté une «&#160;persécution
          politique du partage&#160;». Entre panique et élan révolutionnaire,
          organisation citoyenne et culture du hacking, la résistance
          s’organise.
        </Text>
        <Text>
          Aux États-Unis, cette résistance est notamment incarnée par Aaron
          Swartz. Ce jeune prodige du MIT décide de faire bénéficier le monde de
          son accès universitaire aux articles de recherche publiés sur la base
          de données du JSTOR
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  JSTOR&#160;: contraction de <i>Journal Storage</i>.
                  Bibliothèque numérique dans laquelle sont archivées nombre de
                  publications universitaires et scientifiques, rendues
                  disponibles en consultation payante. Les universités payent
                  souvent des accès JSTOR aux étudiants travaillant sur des
                  projets de recherche.
                </Text>
              )
            }
          >
            59
          </InfoClick>
          . branche ses disques durs dans la salle des machines de l’université
          et entreprend de récupérer chacun des articles de la base. Entre
          septembre&#160;2010 et janvier&#160;2011, il télécharge ainsi presque
          5&#160;millions d’articles, soit la quasi-totalité du JSTOR. Rien ne
          l’empêche de le faire&#160;: il est bien titulaire d’un accès à la
          base, et celle-ci ne prévoit aucune limitation du nombre de
          téléchargement par personne. Il sera pourtant poursuivi par une
          procureure américaine. Au&#160;fur et à mesure de l’enquête, Aaron et
          sa famille se retrouvent harcelés par le FBI. Après deux ans de
          pressions, il se suicide le 11&#160;janvier 2013.
        </Text>
        <Text>
          Aaron était, comme beaucoup de geeks, un idéaliste. Enthousiaste, il
          voulait libérer les données, pour le fun autant que par principe. Cet
          utopiste pointait l’absurdité du copyright apposé sur des articles
          académiques pour lesquels les auteurs n’étaient pas rémunérés
          –&#160;même si le JSTOR demandait des contributions financières pour y
          accéder. En face de lui s’est déployé le formidable bras armé du
          gouvernement fédéral américain&#160;: le FBI et les poursuites
          judiciaires d’une procureure qui requérait trente-cinq&#160;ans de
          prison pour des articles que Swartz n’avait finalement jamais publiés
          et qu’il avait même retournés au JSTOR. Les soldats zélés du copyright
          ont fini par faire un mort.
        </Text>
        <Text>
          Son décès donne lieu à un élan de solidarité international dans le
          milieu hacktiviste. Sur Twitter, plusieurs universitaires décident de
          partager leurs publications en accès libre. TPB publie, sur l’un de
          ses nombreux miroirs, une archive des documents exfiltrés du JSTOR par
          Aaron. Quelques jours plus tard, le site Internet de la{" "}
          <i>Sentencing Commission</i> des États-Unis est remplacé par une vidéo
          dénonçant le traitement de l’activiste par la justice américaine. La
          même vidéo menace, en réponse, de divulguer des informations de
          l’armée américaine, de la <i>Missile Defense Agency</i>, et de la
          NASA. C’est Lauri Love, un hacker suédois affilié à Anonymous, qui
          sera pointé du doigt pour cette action. Il sera arrêté par la National
          Crime Agency anglaise en octobre&#160;2013, à la suite des accusations
          et de la demande d’extradition des États-Unis.
        </Text>
        <Text>
          Quelques années plus tard, alors que l’on suit encore, par
          intermittence, la traque du dernier cofondateur de TPB, Svartholm
          Warg, exilé au Cambodge pour fuir sa sentence, c’est un autre site
          bien connu des amateurs de séries télévisées qui fait parler de lui.
          Le 19&#160;janvier 2012, le site de <i>streaming</i> MegaUpload
          affiche désormais les logos du FBI et du département de la Justice
          américaine, suivi du message&#160;: «&#160;Les noms de domaines liés
          au site MegaUpload.com ont été saisis, par décision de la Cour de
          Justice des États-Unis.&#160;»
        </Text>
        <Text>
          Un quart d’heure après l’annonce, Anonymous est déjà sur le pont et
          lance l’opération #OpMegaUpload. Leur attaque en DDoS vise cette fois
          une quantité impressionnante de cibles&#160;: Universal Music, la
          Recording Industry Association of America, la Motion Picture
          Association of America, l’U.S. Copyright Office, Hadopi, l’Utah Chiefs
          of Police Association, Broadcast Music Incorporated, Warner Music
          Group, le FBI et Sony. En parallèle, des milliers de messages de
          protestation sont publiés sur la page Facebook du FBI.
        </Text>
        <Text>
          Mais la Quadrature et les défenseurs du libre partage n’ont pas le
          temps de s’apitoyer sur leur sort. La censure des contenus culturels,
          poussée par les ayants droit, laisse petit à petit la place à une
          censure plus large contre les contenus désapprouvés par les
          gouvernements. Et alors qu’on discute en France de la légitimité de la
          censure pour lutter contre la pédopornographie, le terrorisme, la
          prostitution ou les discours de haine, à quelques milliers de
          kilomètres de là, la censure totalitaire des réseaux est déjà en
          place.
        </Text>

        <Text type="h3">Les&#160;geeks pris dans la&#160;guerre</Text>
        <Text>
          La nuit est déjà bien avancée en Europe, elle commence tout juste aux
          États-Unis, et quelques geeks insomniaques discutent sur IRC
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  IRC&#160;: <i>Internet Relay Chat</i>. Un réseau distribué de
                  chat anonyme en ligne, sur lequel les gens se reconnaissent
                  par leurs pseudos. En quelque sorte, un ancêtre de MSN ou
                  Slack, mais libre, ouvert, distribué et anonyme.
                </Text>
              )
            }
          >
            60
          </InfoClick>
          . sont membres de Telecomix, un <i>cluster</i> de membres de toute
          l’Europe, sous pseudonymes, qui veut lutter contre les lois sur la
          surveillance.
        </Text>
        <Text>
          Proche de la Quadrature, du Parti pirate suédois et d’autres
          mouvements similaires en Europe, Telecomix s’est engagé à ses débuts
          contre le «&#160;paquet Télécom&#160;» français et la loi suédoise FRA
          (qui autorisait la surveillance de masse de l’ensemble des
          communications électroniques entrant et sortant du pays). Le groupe,
          créé en 2009, met en avant des méthodes qualifiées
          d’«&#160;hacktivistes&#160;». Au centre de leur éthique politique, le{" "}
          <i>datalove</i> (sorte de philosophie selon laquelle les données
          doivent être partagées) et la crypto-anarchie (l’idée que le
          chiffrement systématique des échanges en ligne permet de créer un
          «&#160;bruit&#160;» et de protéger les personnes qui en ont besoin en
          les rendant moins visibles).
        </Text>
        <Text>
          Toujours est-il que même les hacktivistes, parfois, s’ennuient. Ce
          soir-là, ils ont entendu une rumeur sur un blocage d’Internet en
          Syrie. Alors ils lancent, juste pour voir, un petit programme de{" "}
          <i>mapping</i> d’adresses&#160;IP. Cartographier l’internet
          syrien&#160;: l’idée d’une soirée décontractée chez les hackers…
        </Text>
        <Text>
          À l’issue de leurs investigations, ils font un constat surprenant. Le
          trafic Internet entre la Syrie et le reste du monde transite par une
          poignée d’adresses IP. En effet, des serveurs, fournis par la société
          BlueCoat au gouvernement syrien, interceptent tout le trafic entrant
          ou sortant du pays. Ils l’analysent, le surveillent, parfois le
          bloquent. Telecomix vient de découvrir toute une infrastructure de
          surveillance de la population, appliquée au pays tout entier, entre
          les mains d’un gouvernement autoritaire. Comment faire pour alerter
          cette population&#160;?
        </Text>
        <Text>
          Le 15&#160;septembre 2011, la quasi-totalité du trafic Internet syrien
          est redirigée vers une page Web rédigée en anglais et traduite en
          arabe. Son titre&#160;? «&#160;
          <i>Your Internet activity is monitored</i>&#160;» («&#160;Votre
          activité Internet est surveillée&#160;»). Sa signature&#160;?
          «&#160;Nous sommes Telecomix. Nous venons en paix
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://reflets.info/articles/opsyria-quand-internet-ne-laisse-pas-tomber-les-citoyens-syriens"
                >
                  https://reflets.info/articles/opsyria-quand-internet-ne-laisse-pas-tomber-les-citoyens-syriens
                </AppLink>
              )
            }
          >
            61
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          La page renvoie vers une collection d’outils de contournement de la
          surveillance et de la censure, avec un lien vers l’IRC du groupe. Sur
          l’IRC justement commencent à arriver des Syriens surpris qui
          demandent, en arabe, ce qu’il se passe. C’est le début de
          conversations par traductions Google interposées pour expliquer la
          situation, aider à installer des VPN
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>OpSyria –&#160;Itw d’okhin&#160;:</Text>
                  <AppLink
                    white
                    href="https://www.francetvinfo.fr/monde/proche-orient/telecomix-des-hactivistes-au-secours-du-peuple-syrien_62941.html"
                  >
                    https://www.francetvinfo.fr/monde/proche-orient/telecomix-des-hactivistes-au-secours-du-peuple-syrien_62941.html
                  </AppLink>
                </>
              )
            }
          >
            62
          </InfoClick>
          … tout dans l’anonymat le plus total. On ne donne pas son nom, sa
          localisation, ses infos personnelles. Personne ne se connaît, tout le
          monde se méfie, et à raison, des espions se cachent partout.
        </Text>
        <Text>
          Les membres de Telecomix, sans rien avoir vraiment planifié,
          deviennent les fers de lance du soutien des hackers aux révolutions
          des Printemps arabes
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://theworld.org/stories/2012-06-01/battle-hacktivists-anonymous-vs-telecomix"
                >
                  https://theworld.org/stories/2012-06-01/battle-hacktivists-anonymous-vs-telecomix
                </AppLink>
              )
            }
          >
            63
          </InfoClick>
          . French Data Network propose ses serveurs aux Tunisiens, pour
          héberger les vidéos des exactions du régime, censurées localement. En
          Égypte, lorsque le trafic Internet est coupé par le gouvernement,
          Telecomix envoie de vieux modèles de modems capables de se connecter
          au Web via le réseau téléphonique…
        </Text>
        <Text>
          À force de traîner sur les réseaux, Telecomix parvient à identifier
          des machines au comportement particulier. Il s’agit de serveurs
          mettant en œuvre de la <i>Deep Packet Inspection</i>, une technique
          d’analyse en profondeur des informations passant par les équipements
          du réseau. Ces outils déployés par des agences gouvernementales, en
          deux mots, permettent aux gouvernements de surveiller l’activité des
          internautes dans leur pays. Extrêmement dangereux pour la liberté
          d’expression et la démocratie, ils sont développés et vendus par des
          entreprises occidentales, notamment françaises.
        </Text>
        <Text>
          Collaborant avec des journalistes, Telecomix se lance dans
          l’identification de ces sociétés
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://reflets.info/articles/opsyria-syrian-censorship-logs-season-3"
                >
                  https://reflets.info/articles/opsyria-syrian-censorship-logs-season-3
                </AppLink>
              )
            }
          >
            64
          </InfoClick>
          . Amesys, filiale de la française Bull, officie en Égypte, où elle
          collabore avec Hosni Mubarak, le président-dictateur, qui sera, après
          la révolution, condamné à la prison à perpétuité (il risquait la peine
          de mort) pour «&#160;meurtre et tentative de meurtre sur des
          manifestants, abus de pouvoir et de biens sociaux, et atteinte aux
          intérêts de l’État&#160;». À ses côtés, toujours en Égypte, on peut
          lister l’anglaise Gamma, qui fournit son logiciel FinSpy. Ou encore
          l’américaine Blue Coat, qui est également installée en Syrie, à
          Bahreïn, au Qatar, aux Émirats arabes unis, en Chine, en Russie ou
          encore au Venezuela.
        </Text>
        <Text>
          En Iran, c’est Trovicor, de l’allemande Siemens, qui se charge du DPI.
          Au Maroc et aux Émirats, il s’agit de DaVinci, de l’italienne Hacking
          Team. En Syrie et en Libye, on retrouve encore une société française,
          Qosmos. Une autre, française encore, Alcatel, en Birmanie. Oui, la
          liste est longue. Déjà en 2012, l’exportation d’armes numériques se
          porte bien. Le débat est relancé pour savoir si on doit réguler
          l’export d’armes numériques de la même manière que les armes de
          guerre.
        </Text>
        <Text>
          Le jeu du chat et de la souris se poursuit entre les geeks, portés par
          l’élan politique constitué autour de Telecomix, et les gouvernements
          dictatoriaux. Avec les Printemps arabes, c’est l’émulation&#160;: nous
          participons à la libération des peuples. Mais cette participation est
          douloureuse&#160;: les longues nuits passées à conseiller les Syriens
          sur leurs moyens de protection numérique, la récupération des vidéos
          d’assassinat et de torture, la disparition du jour au lendemain de
          contacts dont on soupçonne qu’ils aient été arrêtés et torturés… Tous
          ces éléments contribuent à l’épuisement de la communauté hacktiviste,
          conduisant nombre d’entre eux au burn-out, à la dépression, et,
          parfois, au suicide.
        </Text>
        <Text>
          On pourrait penser que l’exemple de la censure généralisée donné par
          les gouvernements totalitaires en Tunisie ou en Syrie aurait servi
          d’avertissement pour les démocraties occidentales. On pourrait penser
          que les enquêtes identifiant du matériel français utilisé par des
          dictateurs auraient tiré la sonnette d’alarme autour des moyens que
          nos services de renseignement ont déjà à leur disposition. On pourrait
          penser que la nomination de la France dans la liste des «&#160;pays
          sous surveillance&#160;» du rapport 2012 de Reporters sans frontières
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://rsf.org/fr/rapports/entre-surveillance-et-filtrage-la-breche-tenue-des-net-citoyens"
                >
                  https://rsf.org/fr/rapports/entre-surveillance-et-filtrage-la-breche-tenue-des-net-citoyens
                </AppLink>
              )
            }
          >
            65
          </InfoClick>{" "}
          aurait inquiété les citoyens.
        </Text>
        <Text>
          Mais le vrai choc de la surveillance arrive juste un peu plus tard,
          quand un lanceur d’alerte de la NSA contacte WikiLeaks en
          juin&#160;2013. Le nom d’Edward Snowden va bientôt faire la une des
          médias internationaux.
        </Text>

        <Text type="h3">L’émergence des&#160;lanceurs d’alerte</Text>
        <Text>
          WikiLeaks, ONG fondée en 2009 par Julian Assange
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Il nous paraît compliqué de parler de Julian Assange sans
                  aborder les accusations d’agressions sexuelles, pour
                  lesquelles il a été poursuivi par le parquet suédois dès 2010.
                  Bien que ces accusations aient été utilisées politiquement
                  dans le cadre du harcèlement judiciaire international dont il
                  a été victime, nous soulignons que les poursuites ont été
                  abandonnées pour prescription en 2015, faute de jugement, et
                  que cela n’est pas une reconnaissance de non-lieu.
                </Text>
              )
            }
          >
            66
          </InfoClick>
          , pour but de publier des documents classifiés provenant de sources
          anonymes. L’association gagne en notoriété en révélant, en
          avril&#160;2010, des documents confidentiels détaillant les
          agissements de l’armée américaine au cours de sa guerre «&#160;contre
          la terreur
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Du nom donné par l’administration américaine à ses campagnes
                  militaires à la suite des attentats du 11&#160;septembre 2001.
                </Text>
              )
            }
          >
            67
          </InfoClick>
          &#160;», qualifiés de crimes de guerre. Parmi ceux-là, une vidéo de
          2007 d’un raid aérien sur Bagdad en Irak, au cours duquel un soldat en
          hélicoptère ouvre délibérément le feu sur un groupe de civils,
          comprenant des journalistes de l’agence Reuters. Dix-huit personnes
          perdent la vie, dont deux enfants, au cours de trois frappes aériennes
          successives.
        </Text>
        <Text>
          En juillet&#160;2010, WikiLeaks publie les <i>Afghan War Diaries</i>.
          Il s’agit de plus de 90&#160;000&#160;documents et rapports de l’armée
          américaine en Afghanistan datés de 2004 à 2009. Ils révèlent des morts
          civils, des tirs de l’armée américaine contre son camp, et
          l’augmentation des attaques talibanes. <i>Der Spiegel</i>,{" "}
          <i>The New York Times</i> et <i>The Guardian</i>, qui se coordonnent
          pour la publication des premiers articles, affirment dans un
          communiqué qu’ils sont unanimement convaincus de l’intérêt public
          légitime qu’il y a à publier ces documents.
        </Text>
        <Text>
          En novembre&#160;2010, ce sont presque 250&#160;000&#160;télégrammes
          diplomatiques américains qui sont mis au jour (<i>Cablegate</i>).
          Ceux-ci traitent, entre autres, d’affaires d’espionnage, de torture,
          d’ingérence politique, de problèmes de sécurité nucléaire, de ventes
          d’armes, de corruption, et, plus généralement, des opinions et
          connaissances des uns et des autres sur leurs pays voisins.
        </Text>
        <Text>
          Chelsea Manning, ancienne analyste militaire américaine, est très vite
          pointée du doigt par son administration. Elle est accusée d’être à
          l’origine de ces trois fuites et est condamnée en août&#160;2013 à
          trente-cinq&#160;ans de prison. Reconnue responsable de la plus grande
          fuite de documents de l’histoire américaine, elle aura permis de
          révéler les sévices des forces américaines sur des prisonniers d’Abou
          Ghraib en Irak et de Guantanamo à Cuba, et l’utilisation, jusqu’alors
          niée par l’État, de drones d’attaques militaires. La peine prononcée à
          son encontre est plus lourde que celles des militaires reconnus
          coupables des actes de torture qu’elle a dénoncés. Par ailleurs, de
          nombreux rapports font état de ses conditions de détention, décrites
          par le rapporteur spécial des Nations unies sur la torture comme
          «&#160;cruelles, inhumaines et dégradantes&#160;».
        </Text>
        <Text>
          Mais revenons en France, fin 2010. Les documents de WikiLeaks sur le{" "}
          <i>Cablegate</i> sont hébergés sur un serveur de l’hébergeur français
          OVH. Éric Besson est alors ministre chargé de l’Industrie, de
          l’Énergie et de l’Économie numérique, poste qu’il prend peu après
          avoir clôturé le débat sur l’identité nationale pour lequel il avait
          été mandaté par Nicolas Sarkozy. Il demande aux autorités compétentes
          de mettre en œuvre des actions afin que WikiLeaks ne puisse plus être
          hébergé en France. Devant les pressions exercées par le gouvernement,
          Octave Klaba, fondateur d’OVH, devra saisir la justice française. Il
          ne recevra néanmoins aucune notification officielle de contenu
          illicite.
        </Text>
        <Text>
          Quelques mois plus tard, en novembre&#160;2013, vient la condamnation
          de Jeremy Hammond à dix&#160;ans de prison. Hacker américain affilié
          aux Anonymous, il a publié en 2012 avec WikiLeaks des informations
          privées de l’entreprise Stratfor obtenues illégalement. Ces documents
          font état de la surveillance par le gouvernement de groupes
          écologistes en Inde, de militants du mouvement Occupy Wall Street, ou
          encore d’activistes de l’association PETA, qui œuvre pour la défense
          des animaux.
        </Text>
        <Text>
          Cette série de condamnations contre des activistes, couplée à
          l’enfermement de Julian Assange dans l’ambassade d’Équateur à Londres,
          marque un tournant dans la pression judiciaire contre les hackers.
          Alors qu’ils pensaient avoir l’opinion publique avec eux, et que
          celle-ci leur permettait toujours de s’en sortir, les geeks
          déchantent.
        </Text>
        <Text>
          Le 31&#160;juillet 2013, la Quadrature plante les piquets de tente de
          sa Tea House
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  La Tea House se veut un lieu « analogique », qui détonne dans
                  un environnement très numérique. L’objectif est d’en faire un
                  espace de discussion, de pousser les gens à discuter ensemble,
                  autour d’une tasse de thé.
                </Text>
              )
            }
          >
            68
          </InfoClick>{" "}
          au Festival&#160;OHM, aux Pays-Bas, au cœur d’un rassemblement
          international de plus de 3&#160;000&#160;hackers. Dans ce champ au
          milieu de nulle part, de la fibre optique a été tirée et enterrée pour
          subvenir aux besoins de ces accros des données, diffuser en direct les
          conférences qui se succèdent dans les grandes tentes amphithéâtres,
          partager les fichiers sur les réseaux de PirateBox. En face de la Tea
          House, une tente en forme de Tardis
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Tardis&#160;: <i>Time And Relative Dimensions In Space</i>.
                  Référence à la machine à voyager dans le temps et l’espace de
                  la série anglaise <i>Doctor Who</i>.
                </Text>
              )
            }
          >
            69
          </InfoClick>
          , derrière elle, le Noisy Square, considéré comme l’endroit le plus
          militant.
        </Text>
        <Text>
          Si les hacktivistes et les informaticiens se sont toujours côtoyés
          très cordialement, les choses commencent à changer. Les organisateurs
          du camp OHM sont notamment sponsorisés par Fox-IT, une entreprise
          épinglée peu de temps avant par WikiLeaks pour avoir développé un
          outil d’interception des communications et engagé des relations
          commerciales avec des pays comme l’Égypte ou l’Iran. L’entreprise a
          son stand à l’entrée du camp et s’en sert pour recruter.
        </Text>
        <Text>
          Les entreprises ont toujours recruté des informaticiens, et les
          informaticiens ont toujours travaillé dans des grandes entreprises aux
          stratégies plus ou moins éthiques. Mais voilà, depuis les Printemps
          arabes et les révélations de WikiLeaks, les communautés hackers ont
          compris que le manque d’éthique fait des morts.
        </Text>
        <Text>
          D’ailleurs, au sein de la Tea House, assis sur des coussins, autour de
          grandes tables rondes, les geeks discutent des dernières révélations
          publiées par WikiLeaks. À peine un mois auparavant, Edward Snowden,
          ancien employé de la CIA et de la NSA, a lancé l’alerte sur les
          agissements des agences du renseignement américain. On parle de PRISM,
          mais on ne sait pas encore très bien ce que cela recouvre. Les
          États-Unis nous surveillent, mais jusqu’où faut-il pousser la
          paranoïa&#160;? S’ils savaient&#160;! Même alors, on avait
          sous-estimé, et largement, la réalité des compétences de la NSA.
        </Text>
        <Text>
          Julian Assange, fondateur et porte-parole de l’association, participe
          au camp d’OHM, grâce à une connexion en direct depuis l’ambassade
          d’Équateur à Londres. En 2013, il n’est plus possible de parler
          d’informatique sans parler d’éthique. De censure, de surveillance, de
          contrôle des populations.
        </Text>
        <Text>
          La discussion se poursuit en décembre, à un autre rendez-vous
          important de la culture hacker&#160;: le Chaos Communication Congress.
          En&#160;cette trentième édition, le 30C3, Julian Assange (à distance)
          et Jacob Appelbaum, journaliste et figure de proue du réseau Tor
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Tor mettra fin à sa collaboration avec Jacob Appelbaum en
                  juin&#160;2016, à la suite d’allégations d’abus sexuels, qu’il
                  nie. Celles-ci ont eu des répercussions importantes dans la
                  communauté de la sécurité de l’informatique, qui a alors pris
                  conscience des abuseurs potentiels qu’elle peut receler.
                </Text>
              )
            }
          >
            70
          </InfoClick>
          , lancent un appel&#160;: «&#160;
          <i>Sysadmin of the world, unite&#160;!</i>&#160;»
          («&#160;Administrateurs systèmes du monde entier,
          unissons-nous&#160;!&#160;»). Le message est de résister aux sirènes
          des organismes politiques et des entreprises privées de surveillance
          qui viennent recruter les meilleurs informaticiens, jusque dans les
          événements communautaires comme le Congress.
        </Text>
        <Text>
          Mais alors que les révélations tombent au fur et à mesure que les
          journalistes du monde épluchent les documents fournis par Edward
          Snowden&#160;; alors que la situation des pays arabes dégénère sous
          les yeux de Telecomix qui continue à archiver tant bien que mal les
          vidéos d’assassinat et de violences&#160;; alors que jouer à produire
          des <i>effets Streisand</i> peut désormais conduire en prison… Est-il
          encore temps de s’unir&#160;? N’avons-nous pas déjà perdu&#160;?
        </Text>
        <Text>
          En France, le Sénat vient d’adopter le projet de loi relatif à la
          programmation militaire. Envers et contre tout, la Quadrature continue
          tant bien que mal la lutte. Mais en 2013, c’est certain, l’hacktivisme
          a passé un cap. C’est la fin de l’innocence…
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter1Part5;
