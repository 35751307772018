import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter1Part2 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Hadopi, ou l’histoire d’un passage en force">
      <>
        <Text>
          Après l’envoi de ce premier communiqué de presse de lancement, les
          choses s’organisent à la Quadrature. Jérémie Zimmermann et Christophe
          Espern sont à la manœuvre et élaborent les premiers outils de
          communication : logo, canal IRC, mailing list… Le nom de domaine{" "}
          <a href="http://laquadrature.net">laquadrature.net</a> est déposé
          quelques semaines plus tard, le 16 mars 2008, par Benjamin Sonntag,
          nommé à l’infrastructure. Dans la foulée, un wiki est créé.
        </Text>
        <Text>
          Dès le début, toutes les communications de la mailing list sont
          chiffrées. Nous nous appliquons à mettre en œuvre deux principes
          importants : héberger nos propres services pour profiter de la
          capacité d’Internet de permettre à tous d’être un morceau de la Toile,
          et sécuriser les communications entre les bénévoles en utilisant des
          technologies de chiffrement.
        </Text>
        <Text>
          Un financement de 20 000 euros de la Fondation Soros est décroché pour
          couvrir les déplacements et les dépenses diverses de la Quadrature.
          Cet argent est donné sans contrepartie, la Fondation Soros acceptant
          nos conditions de totale indépendance. Quand ce financement passera à
          50 000 euros, les cofondateurs poseront la règle des 30 % : un
          financement ne pourra dépasser 30 % du financement total de
          l’association, pour éviter une dépendance de fait. La Quadrature
          n’ayant pas d’existence juridique, Benjamin Bayart propose que la FDN
          soit destinataire des fonds. Il crée pour ce faire, avec Valentin
          Lacambre et Arnaud Luquin, une émanation de l’association : FDN² (le
          Fonds de défense de la neutralité du Net). C’est cette association qui
          va recevoir les fonds destinés à la Quadrature, mais aussi prendre en
          charge l’embauche des salariés.
        </Text>
        <Text>
          Le travail quotidien de la Quadrature est pris en charge par Jérémie
          Zimmermann et Christophe Espern, qui rendent des comptes aux autres
          membres lors de réunions hebdomadaires et via les canaux de
          discussion. Les activités sont diverses et, comme à l’époque d’EUCD,
          ils doivent multiplier les compétences : développement Web, graphisme,
          rédactionnel, prise de parole, analyse juridique… Ils tiennent à bout
          de bras, par un engagement militant, une association qui, malgré sa
          petite taille, a déjà vocation à influencer le débat public.
        </Text>
        <Text>
          La fuite du projet de loi précurseur de ce qui deviendra la Hadopi
          lance la Quadrature dans le combat du droit d’auteur dans le domaine
          numérique. Ce choix s’inscrit naturellement dans la lignée d’EUCD.info
          contre la DADVSI, ajoutant à la lutte contre les DRM la sauvegarde de
          la culture du mème, du remix et du <i>peer-to-peer</i>, au sein d’un
          mouvement général pour la protection de la liberté d’expression.
        </Text>
        <Text>
          Le travail de l’association balance entre l’analyse juridique pointue,
          qui prend de longues heures de lecture, de compréhension et de
          structuration, la réflexion autour d’idées concrètes et crédibles, et
          la production de mèmes agrémentant les communiqués de presse. Faire
          rire, produire du contenu grinçant, devient notre marque de fabrique.
          Le but : s’attaquer à l’image policée des décideurs qui, le plus
          souvent, déroulent tranquillement leur argumentaire sans opposition,
          et proposer une autre lecture de l’histoire face à celle de la{" "}
          <i>realpolitik</i> de ceux qui sont au pouvoir.
        </Text>
        <Text>
          Pour gagner en visibilité, la Quadrature décide d’investir dans la
          communication. Nous publions communiqué de presse sur communiqué de
          presse, répondons à toutes les sollicitations, et veillons
          particulièrement à notre image dans les médias. Cependant, rares sont
          les reprises de nos textes en dehors des publications spécialisées (
          <i>PC INpact</i>, <i>Numérama</i> ou quelques autres).
        </Text>
        <Text type="h3">La riposte graduée a bon dos</Text>
        <Text>
          Depuis quelques mois, à Bruxelles, au Parlement européen, on discute
          du « paquet Télécom », un ensemble de textes visant à réguler les
          télécommunications. Il comprend notamment deux propositions de
          directives-cadres dont l’objectif est d’amender des directives
          existantes (dont la directive « Vie privée et communications
          électroniques » [<i>E-Privacy</i>]). Chaque texte a son domaine de
          compétences : l’accès aux moyens de communication électroniques, leur
          cadre réglementaire, leur statut de service universel, le sort des
          données à caractère personnel, etc. Ils datent tous du début des
          années 2000 et doivent déjà être refondus pour répondre à la réalité
          des nouvelles pratiques. C’est ce à quoi s’attellent les institutions
          européennes (Parlement, Commission, Conseil et États membres).
        </Text>
        <Text>
          En 2008, la France de Nicolas Sarkozy saisit cette occasion pour
          tenter d’imposer dans ce nouveau « paquet Télécom » une justification
          européenne de la loi Hadopi et de la « riposte graduée » qu’il a tant
          de mal à faire passer dans son propre pays. Il s’agit d’autoriser les
          producteurs de biens culturels à se livrer à des missions de police,
          et les fournisseurs d’accès à sanctionner les internautes sans passer
          par l’autorité judiciaire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Les sanctions seraient pensées en fonction de la gravité des
                  faits et de l’entêtement des contrevenants, les peines allant
                  d’une simple lettre de mise en garde jusqu’à une amende voire
                  à une peine de prison pour les récidivistes, accompagnée d’une
                  coupure de la connexion Internet.
                </Text>
              )
            }
          >
            15
          </InfoClick>
          . Un premier vote au Parlement tacle sévèrement le projet en avril
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Le Parlement européen torpille la riposte graduée ! »,
                    Next INpact, 10 avril 2008 :
                  </Text>
                  <br />
                  <AppLink
                    white
                    href="https://www.nextinpact.com/archive/42915-cult-bono-vote-riposte-graduee.htm"
                  >
                    https://www.nextinpact.com/archive/42915-cult-bono-vote-riposte-graduee.htm
                  </AppLink>
                </>
              )
            }
          >
            16
          </InfoClick>
          . Certains amendements (notamment issu du lobby français du cinéma)
          prônent l’abaissement du niveau de protection des données
          personnelles, d’autres visent à légaliser les <i>spywares</i> des
          industries culturelles, à institutionnaliser leur influence, ou à leur
          permettre de déterminer les technologies sans-fil utilisables demain.
          Le dernier amendement étudié nie l’existence d’un droit à redistribuer
          des œuvres du domaine public ou sous licence libre.
        </Text>
        <Text>
          Le Parlement européen crée une commission chargée de proposer des
          solutions pour développer une industrie culturelle sur Internet.
          Présidée par Viviane Reding, elle confie à Guy Bono, eurodéputé
          français du Parti socialiste, le soin de rédiger un rapport
          d’initiative sur « Les industries culturelles en Europe ». C’est là
          l’occasion d’une prise de position forte : la coupure de l’accès à
          Internet est dite disproportionnée en cas de violation du droit
          d’auteur, car « en contradiction avec les libertés civiques ainsi
          qu’avec les principes de proportionnalité, d’efficacité et de
          dissuasion ». Guy Bono ajoute : « La coupure de l’accès à Internet est
          une sanction aux effets puissants qui pourrait avoir des répercussions
          graves dans une société où l’accès à Internet est un droit impératif
          pour l’inclusion sociale. » Le 10 avril 2008, le rapport est adopté de
          justesse.
        </Text>
        <Text>
          Apprenant à l’automne qu’un amendement est déposé au « paquet Télécom
          » par ce même Guy Bono, sur la base de ce rapport, et conscients de
          l’importance qu’il aura ensuite dans leur combat contre la Hadopi,
          Jérémie et Christophe partent à Bruxelles rencontrer des députés
          européens pour les convaincre de le voter. Le 24 septembre, c’est
          chose faite : le Parlement européen adopte l’amendement 138 (ou «
          amendement Bono ») au « paquet Télécom ». C’est un puissant signal
          envoyé au gouvernement Fillon qui, au sein de sa loi Hadopi, a
          justement prévu cette coupure de l’accès à Internet… La Quadrature
          invite alors « le Premier ministre à prendre acte de ce vote et à ne
          pas déposer devant le Parlement français le projet Olivennes ». Malgré
          cela, le gouvernement fait la sourde oreille, et la France profite de
          sa présidence de l’Union au second semestre 2008 pour vite faire
          retirer cet amendement. La voie se dégage pour la Hadopi
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <AppLink
                    white
                    href="https://www.journaldunet.com/ebusiness/le-net/1032548-la-reponse-graduee-du-rapport-olivennes-perd-son-grade/"
                  >
                    https://www.journaldunet.com/ebusiness/le-net/1032548-la-reponse-graduee-du-rapport-olivennes-perd-son-grade/
                  </AppLink>
                  <br />
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2008/04/10/le-parlement-europeen-rejette-la-riposte-graduee/"
                  >
                    https://www.laquadrature.net/2008/04/10/le-parlement-europeen-rejette-la-riposte-graduee/
                  </AppLink>
                </>
              )
            }
          >
            17
          </InfoClick>
          …
        </Text>
        <Text>
          Les négociations et le travail sur les textes se poursuivent
          néanmoins. L’amendement est rétabli et de nouveau voté par le
          Parlement en mai 2009, mais refusé par le Conseil en juin. Pour
          trancher, un comité de conciliation, composé de ministres des
          vingt-sept États membres, doit se réunir à partir de septembre 2009.
          C’est là que la Quadrature tire la sonnette d’alarme, et publie coup
          sur coup plusieurs articles et plusieurs lettres ouvertes où le thème
          de la neutralité du Net est absolument central.
        </Text>
        <Text>
          Le comité de conciliation achoppe bien entendu sur l’amendement 138,
          maintenu par le Parlement et refusé par le Conseil. Mais de nouveaux
          amendements inquiétants se sont invités dans la discussion au fil des
          lectures. Dans des argumentaires écrits en anglais (à l’usage des
          eurodéputés de toutes origines, et des partenaires militants dans les
          autres pays de l’Union), la Quadrature redoute notamment ceux des
          grands opérateurs américains, dont ATT. Ces opérateurs ont obtenu que
          figurent dans le texte des formulations qui laissent aux opérateurs la
          possibilité unilatérale de « limiter l’accès et/ou l’usage de certains
          services et applications », ou de ralentir le trafic à volonté.
        </Text>
        <Text>
          Le vote final sur le « paquet Télécom » du 24 novembre 2009 ne
          satisfait ainsi pas du tout l’association. Le communiqué publié le
          jour même est un aveu de déception : le texte ne consacre pas
          formellement la neutralité du Net dans l’Union européenne, la version
          finale est nettement en retrait par rapport à l’amendement 138 et
          comporte beaucoup trop de failles dans lesquelles les opérateurs
          pourront s’engouffrer. « L’Union européenne vient de rater une
          occasion historique d’affirmer l’importance cruciale de l’accès libre
          à Internet », écrit Jérémie Zimmermann, porte-parole de La Quadrature
          du Net
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2009/11/24/paquet-telecom-une-occasion-manquee-pour-les-droits-des-citoyens/"
                >
                  https://www.laquadrature.net/2009/11/24/paquet-telecom-une-occasion-manquee-pour-les-droits-des-citoyens/
                </AppLink>
              )
            }
          >
            18
          </InfoClick>
          . La première bataille paraît à demi perdue, mais la guerre est loin
          d’être finie.
        </Text>
        <Text type="h3">Un nouveau cycle pour la Quadrature</Text>
        <Text>
          Fin 2008, après plusieurs années de combat associatif, Christophe
          Espern décide de quitter la Quadrature, laissant Jérémie Zimmermann
          seul à la manœuvre. Devant la lourdeur de cette tâche, les militants
          voient l’avenir de l’association s’assombrir, mais décident de
          rebondir et d’embaucher une personne à temps plein pour épauler
          Jérémie
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Six mois plus tard, à l’été 2009, est embauché Félix Tréguer.
                  Le jeune homme de 22 ans sort alors tout juste de Sciences-po
                  et écrit à l’association pour proposer sa candidature. Il
                  rencontre Jérémie et le courant passe tout de suite : Félix
                  Tréguer est le premier salarié non fondateur de l’association
                  (via FDN2).
                </Text>
              )
            }
          >
            19
          </InfoClick>
          .
        </Text>
        <Text>
          La Quadrature, qui a frôlé la fermeture, continue donc de se
          structurer. Dorénavant, les décisions seront prises collégialement
          avec l’ensemble des cofondateurs : deux cofondateurs valident, sinon
          on ne publie pas.
        </Text>
        <Text>
          Pour permettre à la population de se réattribuer la compétence
          juridique, bien trop longtemps laissée aux seuls juristes, nous
          publions le 9 février 2009 le rapport « Hadopi, “Riposte graduée” :
          une réponse inefficace, inapplicable et dangereuse à un faux problème
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/files/LaQuadratureduNet-Riposte-Graduee_reponse-inefficace-inapplicable-dangereuse-a-un-faux-probleme.pdf"
                >
                  https://www.laquadrature.net/files/LaQuadratureduNet-Riposte-Graduee_reponse-inefficace-inapplicable-dangereuse-a-un-faux-probleme.pdf
                </AppLink>
              )
            }
          >
            20
          </InfoClick>
          ». Ce document de 42 pages comprend une analyse juridique du texte, et
          résume aussi les études sur la consommation de biens culturels,
          démontrant par la même occasion l’erreur originelle ayant amené à
          croire que la surveillance des réseaux <i>peer-to-peer</i> et la
          menace d’amendes allaient « sauver la culture ».
        </Text>
        <Text>
          L’association y dénonce le fait qu’avec la Hadopi les fournisseurs
          d’accès à Internet se retrouvent chargés de surveiller ce que
          téléchargent les internautes et donc d’analyser la nature du trafic ;
          une violation flagrante de la neutralité du Net. Elle rappelle que la
          Commission européenne soutient cette analyse, insistant sur le fait
          que « les moyens de sécurisation des accès proposés par la Hadopi ne
          doivent en aucune manière conduire à imposer, même indirectement, aux
          fournisseurs d’accès une obligation de contrôle des contenus qu’ils
          font transiter, une telle obligation de surveillance étant contraire
          au droit européen. »
        </Text>
        <Text>
          Excellente surprise : ce texte est lu ! Y compris par des gens et dans
          des milieux que l’équipe n’imaginait pas atteindre. Le journal{" "}
          <i>Le Monde</i>, qui ne faisait pourtant pas partie des destinataires
          de notre mailing, rédige un encart dans ses pages « Culture »,
          informant ses lecteurs de l’existence du rapport et en proposant un
          résumé.
        </Text>
        <Text>
          Pour nous, les militants, quelque chose est en train de basculer.
          Quelques jours plus tard, Jérémie Zimmermann fait sa première
          apparition sur un plateau télé dans l’émission « Plein Écran » sur LCI
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Le 21 février 2009 : </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2009/02/22/lci-plein-ecran-du-21022009-piratage-pourquoi-une-loi-de-plus-wattv/"
                  >
                    https://www.laquadrature.net/2009/02/22/lci-plein-ecran-du-21022009-piratage-pourquoi-une-loi-de-plus-wattv/
                  </AppLink>
                </>
              )
            }
          >
            21
          </InfoClick>
          . Il y retrouve Pascal Nègre, alors président multi-casquettes des
          sociétés de gestion des droits SCPP et SCPA et d’Universal Music
          France. Cédric Ingrand, journaliste spécialiste du numérique pour la
          chaîne, salue ses invités, avec sous le bras le fameux rapport de la
          Quadrature, qu’il a lu et annoté. Ce n’est pas un débat qui se déroule
          alors, mais une analyse point par point du rapport, pendant laquelle
          Pascal Nègre fait figure de spectateur
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="http://mediakit.laquadrature.net/formats/12/7.flv"
                >
                  http://mediakit.laquadrature.net/formats/12/7.flv
                </AppLink>
              )
            }
          >
            22
          </InfoClick>
          . Il refusera ensuite d’en discuter et restera un fervent opposant à
          toutes les propositions émanant de l’écosystème anti-Hadopi, que ce
          soit sur les plateaux ou sur les réseaux sociaux.
        </Text>
        <Text>
          Dans la foulée, une idée germe dans l’esprit de Jérémie, de Benjamin
          et du reste de l’équipe. Comment montrer son opposition à la loi
          Hadopi directement sur Internet ? L’opération <i>Blackout</i> voit le
          jour le 25 février 2009, inspirée par une action similaire mise en
          place en Nouvelle-Zélande contre une loi identique tout juste
          repoussée. Elle invite les internautes à peindre leurs sites en noir
          pour indiquer leur opposition au projet de loi, ou même à en couper
          l’accès. C’est la première opération de grande envergure de la
          Quadrature auprès du grand public, et c’est un succès.
        </Text>
        <Text type="h3">Une Hadopi peut en cacher une autre</Text>
        <Text>
          Malgré ce travail d’analyse et son influence croissante dans le débat
          public, la Quadrature ne peut empêcher l’avancée de la loi Hadopi au
          Sénat et à l’Assemblée nationale. En avril 2009, le gouvernement
          décide de passer le texte en « procédure accélérée » : après une seule
          lecture et un vote des deux Chambres, une commission mixte paritaire,
          réunissant des députés et des sénateurs, est chargée de proposer un
          texte commun au Sénat et à l’Assemblée nationale. Cette version,
          validée par le Sénat, est, de manière surprenante, rejetée par
          l’Assemblée nationale le 9 avril au matin à 21 voix contre 15. On
          parle alors des « députés ninjas », cachés derrière les piliers de
          l’hémicycle, arrivés en force au dernier moment pour supplanter en
          nombre les supporters de la mesure.
        </Text>
        <Text>
          « Formidable victoire pour les citoyens ! » déclare alors la
          Quadrature, saluant « la puissance du réseau » qui a permis
          d’influencer les politiques pour en arriver là. Christine Albanel,
          ministre de la Culture, se dit « déterminée à se battre » pour que la
          loi soit finalement adoptée. Et l’affaire ne traîne pas. Faisant
          totalement fi du résultat d’un vote du Parlement, le gouvernement
          décide de représenter le texte le 29 avril. Il est adopté le 12 mai,
          par 296 voix contre 233. Les députés de la majorité auront suivi
          inconditionnellement les ordres de l’exécutif, sans rien connaître du
          dossier
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Ce comportement leur vaudra le surnom de « députés godillots
                    », qui devient le nom d’un site Internet permettant aux
                    citoyens de suivre le travail parlementaire de leurs
                    représentants (ces derniers le prendront très mal).
                  </Text>
                  <br />
                  <AppLink
                    white
                    href="https://www.deputesgodillots.info/depute-2-mois-et-60-deputes-godillots.html"
                  >
                    https://www.deputesgodillots.info/depute-2-mois-et-60-deputes-godillots.html
                  </AppLink>
                </>
              )
            }
          >
            23
          </InfoClick>
          !
        </Text>
        <Text>
          Quelques jours avant, pourtant, à Bruxelles, l’amendement 138 a été
          réintroduit… Poussés par la pression populaire et par le retournement
          de situation au Parlement français, plus de 60 députés décident de
          saisir le Conseil constitutionnel le 19 mai. Le 10 juin, celui-ci
          censure le texte sur la coupure administrative de l’accès à Internet.
          Cette décision est historique en ce qu’elle reconnaît l’accès à
          Internet comme un droit fondamental. Le Conseil constitutionnel impose
          ainsi qu’il n’y ait qu’un juge qui puisse couper un accès Internet : «
          Considérant qu’aux termes de l’article 11 de la Déclaration des droits
          de l’homme et du citoyen de 1789 : “La libre communication des pensées
          et des opinions est un des droits les plus précieux de l’homme : tout
          citoyen peut donc parler, écrire, imprimer librement, sauf à répondre
          de l’abus de cette liberté dans les cas déterminés par la loi” ; qu’en
          l’état actuel des moyens de communication et eu égard au développement
          généralisé des services de communication au public en ligne ainsi qu’à
          l’importance prise par ces services pour la participation à la vie
          démocratique et l’expression des idées et des opinions, ce droit
          implique la liberté d’accéder à ces services
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Décision 2009-580 du Conseil constitutionnel, le 10 juin
                    2009 :
                  </Text>
                  <AppLink
                    white
                    href="https://www.conseil-constitutionnel.fr/decision/2009/2009580DC.htm."
                  >
                    https://www.conseil-constitutionnel.fr/decision/2009/2009580DC.htm.
                  </AppLink>
                </>
              )
            }
          >
            24
          </InfoClick>
          . »
        </Text>
        <Text>
          La liberté d’accès au réseau mondial, devenu bien commun de
          l’humanité, est donc reconnue comme un droit. Cela ouvre pour les
          militants d’immenses perspectives : tout ce qui entrave, empêche,
          diminue, ou conditionne cet accès est désormais une atteinte à un
          droit fondamental reconnu à chaque personne. Pour la Quadrature, c’est
          une victoire considérable.
        </Text>
        <Text>
          Le 23 juin, Christine Albanel est remplacée par Frédéric Mitterrand.
          Mais rien ne change dans la guerre contre les « pirates ». Le 25 juin,
          le gouvernement présente le texte Hadopi 2, censé répondre à la
          censure, deux semaines plus tôt, de la première version. Cette
          nouvelle mouture remet le juge dans la boucle pour la décision finale.
        </Text>
        <Text type="h3">e-G8 : le vieux monde face au cyberespace</Text>
        <Text>
          Une seule situation, une seule image suffit parfois à résumer toute
          une époque politique. En mai 2011, Nicolas Sarkozy, désireux de jouer
          un rôle international de premier plan, et de se réconcilier avec le
          monde du Web après la bataille de la Hadopi, convoque le e-G8, une
          sorte de sommet mondial du numérique qui doit se tenir à Deauville à
          la veille d’un G8 plus classique.
        </Text>
        <Text>
          Facebook, Google, Amazon sont présents, tandis que Free, France
          Télécom ou PriceMinister
          <InfoClick
            onClick={() => setModalOpen(<Text>Aujourd’hui Rakuten.</Text>)}
          >
            25
          </InfoClick>{" "}
          représentent les ambitions françaises. Tout cela ronronne dans
          l’autosatisfaction, jusqu’à une table ronde sur la propriété
          intellectuelle qui rassemble : le PDG du groupe de presse Bertelsmann
          ; Pascal Nègre, patron d’Universal Music France ; le directeur des
          Éditions Gallimard ; Frédéric Mitterrand, ministre de la Culture ; et
          John Perry Barlow, auteur de la fameuse « Déclaration d’indépendance
          du cyberespace ». Ce dernier écoute patiemment la discussion qui se
          tient en français et prend enfin la parole, avec lenteur : « D’abord,
          je vous remercie de m’avoir invité, mais je suis un peu surpris,
          surtout en voyant les autres membres de cette table ronde, parce que
          j’ai l’impression qu’on ne vient pas vraiment de la même planète. Il
          se trouve que je suis le seul ici à vivre de ce que ces messieurs
          appellent “la propriété intellectuelle”. Je ne vois pas ma création et
          mon moyen d’expression comme une “propriété”. Une “propriété”, c’est
          quelque chose qu’on peut me prendre. Si je ne la possède plus,
          quelqu’un d’autre la possède. L’expression, ça ne marche pas comme ça
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Vidéo de l’intervention de John Perry Barlow au e-G8 :
                  </Text>
                  <AppLink
                    white
                    href="https://www.youtube.com/watch?v=JX4ciDBHfNU"
                  >
                    https://www.youtube.com/watch?v=JX4ciDBHfNU
                  </AppLink>
                </>
              )
            }
          >
            26
          </InfoClick>
          . » Ce que la Quadrature s’efforce de crier depuis des mois, sans être
          entendue, il l’affirme calmement, d’une voix posée, et on l’écoute.
          L’ambiance dans la salle est électrique, et les récits dans la presse
          rendent bien ce qui est un coup de tonnerre dans un ciel trop bleu
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « e-G8 : Deux visions du Net irréconciliables ? », 20
                    minutes, 24 mai 2011 :
                  </Text>
                  <AppLink
                    white
                    href="https://www.20minutes.fr/web/729886-20110524-e-g8-deux-visions-net-irreconciliables"
                  >
                    https://www.20minutes.fr/web/729886-20110524-e-g8-deux-visions-net-irreconciliables
                  </AppLink>
                </>
              )
            }
          >
            27
          </InfoClick>
          .
        </Text>
        <Text>
          Bien sûr, la Quadrature est sur le coup. Outre le fait d’avoir créé un
          site de campagne contre le e-G8
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Site de la campagne « Internet vs G8 » :</Text>
                  <AppLink white href="http://g8internet.com">
                    http://g8internet.com
                  </AppLink>
                </>
              )
            }
          >
            28
          </InfoClick>
          , dehors, les militants déroulent une banderole : «
          <i>Governments Corporations United to Control the Net</i>» («
          Gouvernements et grandes entreprises : unis pour le contrôle de
          l’internet »). À l’intérieur du e-G8, Jérémie Zimmermann prend la
          parole : il tient à la main l’étude publiée par la Hadopi, qui montre
          que les consommateurs de contenus téléchargés sont aussi les personnes
          qui consomment le plus de produits payants.
        </Text>
        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter1Part2;
