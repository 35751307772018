import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter3Part6 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Vers un monde de la « sécurité globale » ?">
      <>
        <Text>
          Au printemps 2020, dans la stupeur du premier confinement sanitaire,
          il fut question d’un nouveau monde où «&#160;plus rien ne serait comme
          avant&#160;». Dans ce «&#160;plus rien&#160;», chacun et chacune
          pouvait mettre ce qui lui tenait à cœur. L’écologiste a pensé qu’on
          prendrait un meilleur soin de la planète et des animaux. Le militant
          politique, bercé par la promesse que les travailleurs de la
          «&#160;première ligne&#160;» seraient soutenus «&#160;quoi qu’il en
          coûte&#160;», a pu croire que la politique d’austérité budgétaire pour
          les faibles et de crédit public pour les riches était arrivée à un
          tournant. Chacun a pu rêver tout son soûl, et rencontrer la déception
          le moment venu. Mais les associations qui militent pour le respect des
          droits et des libertés n’ont même pas eu cette chance&#160;: dès les
          premières semaines de mars&#160;2020, les autorités françaises ont
          resserré le poing pour restreindre les libertés.
        </Text>
        <Text>
          On pourrait disserter longtemps des raisons structurelles ou
          opportunistes de ce réflexe sécuritaire. Sur le plan structurel, on
          peut accuser le néolibéralisme, idéologie de la classe dirigeante, qui
          soumet l’État aux besoins des grandes entreprises privées et limite
          son action directe aux&#160;tâches «&#160;régaliennes&#160;»,
          c’est-à-dire le seul maintien de l’ordre&#160;: armée, police,
          justice. On pourrait aussi voir se dessiner une sorte de
          «&#160;division du travail&#160;» au niveau national entre les membres
          de l’Union européenne&#160;: à l’Allemagne désarmée l’industrie
          d’exportation, à la France les techniques sécuritaires et
          militaires&#160;?
        </Text>
        <Text>
          L’essayiste nord-américaine Naomi Klein a théorisé, dans son livre{" "}
          <i>La Stratégie du choc</i>, l’idée qu’un pouvoir, même démocratique,
          n’hésite jamais à tirer parti des périodes de crise pour avancer ses
          positions, à la faveur de la désorganisation ou de l’inattention de la
          société, et à tirer ainsi profit de l’affaiblissement des oppositions
          institutionnelles ou populaires. De ce point de vue, la période de la
          crise sanitaire causée par l’épidémie de Covid-19 a joué à plein le
          rôle de diversion et d’opportunité, aussitôt saisie par Emmanuel
          Macron, la majorité parlementaire et le gouvernement placés sous ses
          ordres. On l’a vu&#160;: l’état d’urgence sanitaire, les drones pour
          surveiller les rues, les outils de suivi «&#160;sanitaire&#160;» des
          populations, les caméras automatisées et les logiciels d’analyse
          d’images pour contrôler les comportements, la multiplication des
          fichiers de police, le traitement policier de l’épidémie avec des
          «&#160;passes&#160;» et des amendes –&#160;tout cela est désormais
          connu.
        </Text>
        <Text>
          Nous avons désormais, à la Quadrature, de très solides raisons de
          penser que cette hypothèse de l’opportunisme, de la «&#160;stratégie
          du choc&#160;» ou de l’effet de surprise, correspond à la réalité. Car
          les lois sécuritaires qui s’enchaînent ne sont ni des caprices ni des
          idées décousues. Derrière l’éparpillement apparent des initiatives, il
          existe en réalité une stratégie concertée. Nul besoin d’invoquer un
          complot secret ou des volontés secrètes, tout est officiel, et le
          ministère de l’Intérieur publie sa&#160;stratégie dans un document
          intitulé le «&#160;Livre blanc de la sécurité intérieure
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Un document à télécharger au format PDF sur ce site&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.interieur.gouv.fr/actualites/actu-du-ministere/livre-blanc-de-securite-interieure"
                  >
                    https://www.interieur.gouv.fr/actualites/actu-du-ministere/livre-blanc-de-securite-interieure
                  </AppLink>
                  <br />
                  <Text>ou sur Vie publique&#160;: </Text>
                  <AppLink
                    white
                    href="https://www.vie-publique.fr/rapport/277185-livre-blanc-de-la-securite-interieure"
                  >
                    https://www.vie-publique.fr/rapport/277185-livre-blanc-de-la-securite-interieure
                  </AppLink>
                </>
              )
            }
          >
            289
          </InfoClick>
          &#160;».
        </Text>

        <Text type="h3">
          «&#160;Livre blanc de&#160;la&#160;sécurité intérieure&#160;»&#160;:
          <br />
          l’avenir est&#160;déjà écrit
        </Text>
        <Text>
          Le «&#160;Livre blanc de la sécurité intérieure&#160;» est rendu
          public le 16&#160;novembre 2020 par Gérald Darmanin, ministre de
          l’Intérieur. Écrit par le ministère, ce long texte de trois cents
          pages veut «&#160;dessiner le pacte de protection et de sécurité des
          Français, plaçant l’humain au cœur de l’action&#160;», ce qui ne veut
          strictement rien dire, pour peu qu’on essaie de dépasser la surface
          sonore des phrases. Le livre blanc «&#160;s’appuie sur une
          concertation large et ouverte&#160;: experts de la sécurité, élus,
          préfets, agents de terrain, chercheurs et universitaires, acteurs de
          la sécurité privée sans oublier les citoyens eux-mêmes avec la
          conférence organisée en janvier&#160;2020&#160;» pour définir en
          réalité son «&#160;pacte de protection&#160;» de manière
          unilatérale&#160;: il s’agit d’un programme établi par les policiers
          pour les policiers
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Loi “Sécurité globale”&#160;: des auditions parlementaires
                    pro-sécuritaires et à sens unique », 28&#160;octobre
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://technopolice.fr/blog/loi-securite-globale-des-auditions-parlementaires-pro-securitaires-et-a-sens-unique/"
                  >
                    https://technopolice.fr/blog/loi-securite-globale-des-auditions-parlementaires-pro-securitaires-et-a-sens-unique/
                  </AppLink>
                </>
              )
            }
          >
            290
          </InfoClick>
          . Lire ce document, c’est comprendre a posteriori la logique qui relie
          les lois sécuritaires qui l’ont précédé, et avoir sous les yeux la
          feuille de route des lois qui suivront. Et en guise «&#160;d’humain au
          cœur de l’action&#160;», on trouvera surtout beaucoup d’équipements et
          de technologies de surveillance à distance.
        </Text>
        <Text>
          Un chapitre entier s’intitule «&#160;Porter le ministère de
          l’Intérieur à la frontière technologique
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  « Livre blanc de la sécurité intérieure » (LBSI),
                  pages&#160;201 à&#160;276.
                </Text>
              )
            }
          >
            291
          </InfoClick>
          &#160;»&#160;: identité numérique, biométrie, IA
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Jusque dans des domaines inattendus, comme ce « [ajouter] des
                  capacités d’intelligence artificielle aux logiciels de prise
                  de plainte afin de mieux catégoriser les contentieux » («
                  Livre blanc de la sécurité intérieure », page&#160;212).
                </Text>
              )
            }
          >
            292
          </InfoClick>
          , analyse des réseaux sociaux
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  « Afin d’améliorer la gestion de l’alerte et la détection
                  rapide de situations dangereuses non signalées aux centres
                  opérationnels, il est possible d’expérimenter l’automatisation
                  de la captation d’informations en sources ouvertes librement
                  accessibles sur les réseaux sociaux » (<i>Id.</i>,
                  page&#160;220).
                </Text>
              )
            }
          >
            293
          </InfoClick>
          , prise de plainte en ligne, plateformes de signalement, système
          d’alertes sur les téléphones des habitants, terminaux mobiles et
          logiciels de saisie pour les agents sur le terrain, «&#160;gilet
          tactique connecté&#160;» et caméra-piéton sur chaque agent, casque et
          véhicule connectés, cartographie numérique, drones, brouillage radio
          pour contrer les attaques de drones civils, développement des outils
          informatiques nécessaires, réseaux internes ou mobiles et serveurs de
          données
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Il est à noter que la question des réseaux informatiques
                  internes à la police est abordée sous l’angle de
                  l’augmentation considérable de la quantité d’images de
                  vidéoprotection, qui demandent une très grande capacité de
                  transfert rapide et de stockage (<i>Id.</i>,
                  pages&#160;239-240).
                </Text>
              )
            }
          >
            294
          </InfoClick>
          , collecte et analyse de données, croisement des fichiers
          («&#160;multi-biométrie&#160;»)… Ce chapitre énorme aborde une
          quantité de sujets vertigineuse qui consacre de manière éclatante le
          lien intime entre police et numérique.
        </Text>
        <Text>
          L’intelligence artificielle, la biométrie et les drones ont droit à
          des passages indiquant les limites légales actuelles de leur
          utilisation, et parfois la nécessité de nouvelles lois pour permettre
          leur plein développement. Travail d’ajustement de la loi aux besoins,
          à quoi s’emploiera tout aussitôt le projet de loi Sécurité globale.
        </Text>
        <Text>
          Le livre blanc parle aussi avec insistance de la constitution d’un
          «&#160;continuum de sécurité&#160;», qui convoque l’imaginaire du dôme
          ou du bouclier, et dans lequel la gendarmerie et la police nationale
          occupent bien entendu la plus grande place, mais avec le renfort
          beaucoup plus important des polices municipales et des sociétés
          privées de sécurité. Ces éléments apparaissent directement dans la loi
          Sécurité globale, dont le texte est présenté au Parlement par deux
          parlementaires de la majorité (LREM) le 20&#160;octobre 2020&#160;:
          Mme&#160;Alice Thourot, avocate et députée de la Drôme, et Jean-Michel
          Fauvergue, député des Pyrénées-Orientales, commissaire de police et
          ancien chef du RAID (de 2013 à 2017). Cette loi en faveur des
          policiers est portée par un policier, pour que tout soit bien clair.
        </Text>
        <Text>
          Livre blanc et loi Sécurité globale, les deux textes sont à lire
          ensemble.
        </Text>

        <Text type="h3">
          Sécurité globale&#160;: ce&#160;que&#160;dit&#160;la&#160;loi
        </Text>
        <Text>
          En octobre&#160;2020, nous recevons un appel de l’attaché
          parlementaire d’une ancienne députée de la majorité&#160;: «&#160;Vous
          avez vu le texte de loi qui est bientôt présenté au Parlement&#160;?
          C’est grave.&#160;» Ça tombe bien, les juristes de l’association ont
          reçu par d’autres voies une première version du texte, et sont
          justement en train de l’analyser. Cette loi attaque sur tous les
          fronts.
        </Text>
        <Text>
          Une «&#160;sécurité globale&#160;», voilà un fantasme inquiétant.
          C’est même littéralement un fantasme totalitaire, celui d’une société
          figée, d’une fin de l’histoire. Et par ailleurs, ce que la
          dénomination recouvre est très pauvre, intellectuellement et
          humainement&#160;: il s’agit d’armer un peu plus la police, de lui
          donner encore des moyens supplémentaires de surveiller, quadriller,
          contrôler les vies dans l’espace public, qui ne doit plus être qu’un
          lieu de passage dans le calme. Le couloir de métro, la galerie
          commerciale et le quartier pavillonnaire comme modèles ultimes de la
          société.
        </Text>
        <Text>
          Nous publions le 29&#160;octobre 2020 une première réaction au projet
          de loi
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Loi “Sécurité globale”&#160;: surveillance généralisée des
                    manifestations », 29&#160;octobre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/10/29/loi-securite-globale-surveillance-generalisee-des-manifestations/"
                  >
                    https://www.laquadrature.net/2020/10/29/loi-securite-globale-surveillance-generalisee-des-manifestations/
                  </AppLink>
                </>
              )
            }
          >
            295
          </InfoClick>
          , en nous focalisant sur l’enjeu du moment&#160;: dans un contexte de
          très forte contestation sociale et de sévère répression des
          manifestations depuis plusieurs années, la loi Sécurité globale
          introduit plusieurs moyens d’augmenter directement la répression des
          manifestants, au sol et dans le ciel. Au sol, avec l’autorisation
          d’envoyer le flux d’images des caméras-piétons en direct vers le poste
          de commandement (PC)&#160;: c’est évidemment pour coordonner
          l’intervention policière, mais c’est aussi se donner la possibilité
          technique d’appliquer aux images des logiciels d’identification
          biométrique pour repérer des meneurs. Dans le ciel, avec
          l’autorisation de faire voler des drones vidéo, dont le flux d’images
          est lui aussi transmis et analysé au PC en direct. Les moyens
          policiers se militarisent, la manifestation est appréhendée comme un
          champ de bataille, et l’approche du maintien de l’ordre comme une
          guerre civile. Contre cette logique insupportable, une forte
          contestation va s’organiser, au Parlement et dans la rue.
        </Text>
        <Text>
          Dès les auditions préalables, nous contestons la partialité des
          rapporteurs du texte, qui laissent de côté le monde associatif pour
          n’écouter que les forces de l’ordre. Plus tard, nous dénonçons aussi
          le comportement de l’un des deux co-rapporteurs du texte, le député
          Fauvergue (LREM), ancien policier d’élite, dont la morgue et
          l’attitude agressive envers l’opposition parlementaire frappent les
          observateurs. Il suffira de l’entendre demander à une députée de
          l’opposition de «&#160;prendre ses gouttes&#160;» pour prendre la
          mesure du personnage
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Sécurité globale&#160;: la police fait la loi »,
                    6&#160;novembre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/11/06/securite-globale-la-police-fait-la-loi/"
                  >
                    https://www.laquadrature.net/2020/11/06/securite-globale-la-police-fait-la-loi/
                  </AppLink>
                </>
              )
            }
          >
            296
          </InfoClick>
          … Sûrs de leur fait et assurés de mener la loi à son terme grâce à la
          majorité à laquelle ils appartiennent, les deux co-rapporteurs
          montrent sans détour qu’il n’y a pas d’autres options&#160;: ce que la
          police a demandé, la police l’obtiendra.
        </Text>
        <Text>
          Rendons grâce à la ténacité d’une poignée de parlementaires
          d’opposition qui ont su tenir bon, tout au long des débats, et porter
          la parole de la contestation démocratique contre les articles les plus
          litigieux et liberticides de cette loi policière. Quels sont ces
          articles&#160;? Sur les seuls sujets de la Quadrature, le numérique et
          la surveillance technologique, ils sont au moins quatre.
        </Text>
        <Text>
          Nous contestons l’article&#160;20, qui donne le droit d’accès aux
          images de vidéosurveillance de l’espace public –&#160;jusqu’ici
          réservées à la police nationale et à la gendarmerie&#160;– aux agents
          des polices municipales, et même à certains agents municipaux de la
          ville de Paris. Nous contestons aussi l’article&#160;20 bis, qui
          élargit les conditions sous lesquelles les copropriétaires d’un
          immeuble peuvent transmettre les images de surveillance des parties
          communes, en direct, à la police, à des fins d’identification
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « PPL Sécurité globale&#160;: la police te surveille jusque
                    dans ton immeuble », 18&#160;novembre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://technopolice.fr/blog/ppl-securite-globale-la-police-te-surveille-jusque-dans-ton-immeuble/"
                  >
                    https://technopolice.fr/blog/ppl-securite-globale-la-police-te-surveille-jusque-dans-ton-immeuble/
                  </AppLink>
                </>
              )
            }
          >
            297
          </InfoClick>
          . De même que l’article&#160;20 ter, qui donne aux agents de la SNCF
          et de la RATP accès aux images de vidéosurveillance de l’espace
          public.
        </Text>
        <Text>
          Nous contestons aussi l’article&#160;21, qui autorise la caméra-piéton
          des policiers et l’envoi du flux d’images au commissariat ou au centre
          de commandement. Là encore, parce que nous souhaitons limiter les
          opportunités de traitement algorithmique des images (reconnaissance
          faciale ou biométrique), pour identifier et appréhender des personnes
          sur la seule foi de leur réputation, en particulier dans un contexte
          de mouvement social ou politique.
        </Text>
        <Text>
          Nous contestons évidemment l’article&#160;22, qui autorise les drones
          policiers non seulement pour des usages de surveillance des biens et
          des sites, mais pour la surveillance des foules et des manifestations,
          alors que ces appareils ont désormais une qualité de vidéo suffisante
          pour identifier des individus et les suivre, avec ou sans traitement
          algorithmique des images
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    En octobre&#160;2020, Mediapart rapporte un fait avéré de
                    militants du collectif hospitalier Inter-Urgences suivis
                    jusqu’à leur domicile par des drones policiers&#160;:
                    Clément Le Foll et Clément Pouré, « Profitant du flou
                    juridique, les drones policiers bourdonnent toujours »,{" "}
                    <i>Mediapart</i>, 26&#160;octobre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.mediapart.fr/journal/france/261020/profitant-du-flou-juridique-les-drones-policiers-bourdonnent-toujours"
                  >
                    https://www.mediapart.fr/journal/france/261020/profitant-du-flou-juridique-les-drones-policiers-bourdonnent-toujours
                  </AppLink>
                </>
              )
            }
          >
            298
          </InfoClick>
          .
        </Text>
        <Text>
          Mais ce qui va embraser le débat public et donner à la contestation de
          la loi Sécurité globale une dimension qui dépasse largement l’audience
          habituelle de la Quadrature, c’est l’article&#160;24. Dans un contexte
          de grande tension sociale, de manifestations hebdomadaires et de
          violences policières filmées et diffusées sur les réseaux sociaux, cet
          article entend interdire la diffusion d’images de policiers en action,
          sous prétexte que ces images pourraient les exposer à des représailles
          violentes dans la vie civile.
        </Text>
        <Text>
          Les policiers veulent pouvoir travailler sans faire courir de risque à
          leur famille, ou même risquer des règlements de compte à titre
          personnel, c’est une chose facile à comprendre et très défendable. La
          protection des agents en dehors du service est incontestable. Les
          rapporteurs de la proposition de loi invoquent bien sûr la mort d’un
          couple de policiers assassinés chez eux, à Magnanville (Yvelines), le
          13&#160;juin 2016, par des terroristes islamistes. Ils omettent de
          dire que ce meurtre terroriste n’a aucun rapport avec la diffusion
          d’images d’opérations de police sur les réseaux sociaux. Les
          rapporteurs et les policiers invoquent aussi les sites de type «&#160;
          <i>cop watch</i>&#160;» («&#160;surveiller les flics&#160;»), qui
          affichent les visages de policiers s’étant livrés à des violences.
          Mais c’est masquer une réalité plus vaste.
        </Text>
        <Text>
          Sans les images des amateurs, filmant ou photographiant des actions de
          police, aurait-on entendu parler de la mort de Cédric Chouviat,
          étouffé en janvier&#160;2020 par trois policiers agenouillés sur sa
          poitrine, après son arrestation quai Branly, à Paris, parce qu’il
          avait téléphoné en conduisant son scooter&#160;? Sans les images
          filmées par des anonymes ou par des professionnels, comment aurait-on
          documenté les impensables violences qui ont marqué la répression du
          mouvement des «&#160;gilets jaunes&#160;»&#160;? En avril&#160;2021,
          le journaliste David Dufresne, qui a documenté aussi précisément que
          possible ces violences de la police, dénombrait 353&#160;manifestants
          blessés à la tête, dont 30&#160;personnes ayant perdu un œil
          (blessures par balles en caoutchouc), et 5&#160;personnes ayant perdu
          une main (blessures par grenades explosives)
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Deux ans après, que sont devenus les “gilets jaunes”
                    mutilés en manifestation ? », France Inter,
                    29&#160;avril&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.franceinter.fr/societe/deux-ans-apres-que-sont-devenus-les-gilets-jaunes-mutiles-en-manifestation/"
                  >
                    https://www.franceinter.fr/societe/deux-ans-apres-que-sont-devenus-les-gilets-jaunes-mutiles-en-manifestation/
                  </AppLink>
                </>
              )
            }
          >
            299
          </InfoClick>
          . Dans de très nombreux cas, ces blessures étaient connues grâce aux
          images tournées au cœur même des manifestations, aussi bien par des
          manifestants que par des journalistes. L’article&#160;24 est aussitôt
          et unanimement accueilli comme une volonté d’étouffer les violences
          policières, de bâillonner les journalistes et les citoyens, et
          d’empêcher l’exercice d’un droit essentiel pour la préservation de la
          démocratie.
        </Text>
        <Text>
          La corporation des journalistes veille jalousement à la protection de
          son métier. Hors de question de travailler avec des images fournies
          par les préfectures, hors de question de ne pas pouvoir travailler au
          cœur même des mouvements sociaux. Très vite, les journalistes
          s’organisent, les indépendants comme les membres de grandes
          rédactions, et donnent au mouvement de protestation, et à l’infâme
          «&#160;Sécurité globale&#160;», toute la publicité que leurs moyens
          leur permettent. Une coordination Stop loi Sécurité globale se met en
          place&#160;: elle regroupe des dizaines de syndicats et
          d’associations, dont La Quadrature du Net, et lance un appel à
          manifester le 28&#160;novembre 2020 devant l’Assemblée nationale, où
          le texte doit être discuté
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Voir la liste des signataires, des organisations et des
                    soutiens sur le site de la coordination Stop loi Sécurité
                    globale&#160;:
                  </Text>
                  <AppLink
                    white
                    href="https://stoploisecuriteglobale.fr/#communique"
                  >
                    https://stoploisecuriteglobale.fr/#communique
                  </AppLink>
                </>
              )
            }
          >
            300
          </InfoClick>
          .
        </Text>
        <Text>
          Cette «&#160;marche des libertés&#160;» laisse à tous les participants
          le souvenir d’un grand bol d’air frais. Après des semaines et des mois
          de confinement, après des mois et des années de manifestations
          réprimées dans la violence et le sang, c’est une fête. La Quadrature
          vient en force, avec un grand masque en forme de caméra de
          surveillance, pour rendre la distribution des tracts plus
          spectaculaire, et ça marche très bien, la caméra est abondamment
          photographiée. C’est aussi le vol inaugural de notre «&#160;filet à
          drones&#160;», un pauvre filet en ficelles, bricolé en vitesse au
          Garage, porté par deux grappes de ballons gonflés à l’hélium.
        </Text>
        <Text>
          Les ballons colorés et le filet dérisoire font rigoler les gens et
          donnent un ton potache à la contestation, mais ils nous servent
          surtout à rappeler sans cesse, de la première à la dernière
          manifestation du mouvement en janvier&#160;2021, que l’article&#160;24
          qui menace la liberté de la presse ne doit surtout pas occulter les
          autres dangers réels cachés dans le texte de loi, dont
          l’article&#160;22 sur les drones, ou les quatre articles sur la
          vidéosurveillance. Les tracts que nous distribuons à chaque manif le
          rappellent aussi, et tout le rôle capital de Benoît, notre
          représentant au sein de la coordination Stop loi Sécurité globale,
          sera de rappeler sans répit aux autres acteurs du mouvement que la loi
          ne se résume pas à son article&#160;24. C’est alors notre plus grande
          crainte&#160;: que le gouvernement ait introduit l’article&#160;24
          comme un chiffon rouge pour détourner l’attention de tous les autres
          pièges cachés dans le texte, et que l’article soit vite supprimé pour
          apaiser les journalistes et enterrer la contestation. Nous savons que
          sans l’appui de la corporation des journalistes et de la caisse de
          résonance des médias, même la lutte contre les drones n’aura pas la
          même audience.
        </Text>
        <Text>
          Heureusement, la mayonnaise militante prend. Chaque pancarte sur la
          vidéosurveillance et les drones brandie pendant les manifs contre
          l’article&#160;24 est une victoire et une joie pour nous.
          L’association boucle même facilement sa campagne de financement
          annuel, fin décembre&#160;2020, grâce à un afflux de dons
          exceptionnel. Nos sujets de travail trouvent un écho dans la société,
          et ni la surveillance de masse, ni la vidéosurveillance généralisée ne
          semblent être devenues des fatalités dans la France de 2020.
        </Text>
        <Text>
          En tout cas, la question de la protection des visages de policiers est
          un point extrêmement sensible pour la hiérarchie policière, et au
          premier chef pour son ministère de tutelle. En septembre&#160;2020,
          nous signons et nous relayons une pétition internationale contre la
          reconnaissance faciale, lancée par l’artiste et militant italien Paolo
          Cirio
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Site Web de Paolo Cirio&#160;: </Text>
                  <AppLink white href="https://paolocirio.net/">
                    https://paolocirio.net/
                  </AppLink>
                </>
              )
            }
          >
            301
          </InfoClick>
          . Son travail s’intéresse depuis longtemps aux technologies
          numériques, au monde qu’elles organisent, aux identités qu’on y tisse,
          aux libertés qu’elles permettent et à celles qu’elles tuent. Il a
          contacté la Quadrature après avoir pris connaissance de notre campagne
          Technopolice, parce qu’il cherchait à mettre sur pied une campagne
          européenne pour l’interdiction de la reconnaissance faciale et des
          techniques de profilage biométrique. Solidaires de son travail, nous
          publions donc sa pétition
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Nous soutenons la pétition pour bannir la reconnaissance
                    faciale en Europe », 22&#160;septembre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/09/22/nous-soutenons-la-petition-pour-bannir-la-reconnaissance-faciale-en-europe/"
                  >
                    https://www.laquadrature.net/2020/09/22/nous-soutenons-la-petition-pour-bannir-la-reconnaissance-faciale-en-europe/
                  </AppLink>
                </>
              )
            }
          >
            302
          </InfoClick>
          . Paolo Cirio avait également imaginé une exposition, une campagne
          d’affichage et un site Web pour sensibiliser à la question de la
          reconnaissance faciale policière. Sur ces affiches, on pouvait voir
          des «&#160;visages&#160;» de policiers casqués et en armure,
          photographiés dans les innombrables manifestations réprimées durant
          les deux dernières années&#160;: un faux champ de texte invitait les
          spectateurs à saisir le nom du policier en dessous de son image floue…
          L’ironie était manifeste et le procédé vieux comme la première
          contestation&#160;: pour montrer la cruauté d’une situation, rien de
          tel que de l’inverser. Mais le ministère de l’Intérieur protège
          l’amour-propre de ses troupes et veut surtout leur montrer qu’il les
          soutiendra jusqu’au bout. M.&#160;Darmanin, ministre médiatique,
          s’empresse donc de menacer sur Twitter l’artiste et le lieu
          d’exposition qui l’accueillait&#160;: «&#160;Paolo Cirio&#160;:
          Insupportable mise au pilori de femmes et d’hommes qui risquent leur
          vie pour nous protéger. Je demande la déprogrammation de
          “l’exposition” et le retrait des photos de son site, sous peine de
          saisir les juridictions compétentes
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Tweet de Gérald Darmanin, le 1<sup>er</sup>&#160;octobre
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://twitter.com/GDarmanin/status/1311688550073724931"
                  >
                    https://twitter.com/GDarmanin/status/1311688550073724931
                  </AppLink>
                </>
              )
            }
          >
            303
          </InfoClick>
          .&#160;» Le centre d’art déprogramma l’exposition, et le site
          disparut. Voilà l’ambiance en France en 2020
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La censure de l’art pour banaliser la surveillance »,
                    8&#160;octobre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/10/08/la-censure-de-lart-pour-banaliser-la-surveillance/"
                  >
                    https://www.laquadrature.net/2020/10/08/la-censure-de-lart-pour-banaliser-la-surveillance/
                  </AppLink>
                </>
              )
            }
          >
            304
          </InfoClick>
          .
        </Text>
        <Text>
          La loi Sécurité globale est adoptée en avril&#160;2021, avec une série
          de modifications obtenues en partie par la pression de la rue&#160;:
          l’article&#160;24 est renuméroté et perd l’essentiel de sa substance
          dangereuse, les drones pourront voler mais avec quelques limitations
          et quelques conditions
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Loi “Sécurité globale” adoptée, résumons »,
                    6&#160;avril&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/04/16/loi-securite-globale-adoptee-resumons/"
                  >
                    https://www.laquadrature.net/2021/04/16/loi-securite-globale-adoptee-resumons/
                  </AppLink>
                </>
              )
            }
          >
            305
          </InfoClick>
          . Mais c’est encore trop, ou trop peu, et comme la loi se retrouve
          devant le Conseil constitutionnel, nous envoyons nos
          «&#160;écritures&#160;», élaborées avec d’autres associations
          (Syndicat des avocats de France, Syndicat de la magistrature, LDH,
          etc.), pour argumenter en faveur de la censure des articles
          liberticides
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Loi “Sécurité globale”&#160;: nos arguments au Conseil
                    constitutionnel », 29&#160;avril&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/04/29/loi-securite-globale-nos-arguments-au-conseil-constitutionnel/"
                  >
                    https://www.laquadrature.net/2021/04/29/loi-securite-globale-nos-arguments-au-conseil-constitutionnel/
                  </AppLink>
                </>
              )
            }
          >
            306
          </InfoClick>
          . Fin mai&#160;2021, le Conseil constitutionnel censure
          l’article&#160;24 (devenu 52) punissant la diffusion des images de
          policiers, interdit la vidéosurveillance continue dans certains
          «&#160;lieux de privation de liberté&#160;» (gardes à vue et centres
          de rétention administrative), ainsi que la surveillance vidéo à partir
          de drones ou d’hélicoptères&#160;: leur nécessité n’est pas démontrée
          et la loi ne leur mettait pas de limites assez claires. Mais le
          Conseil constitutionnel formule ses motifs de censure avec une
          précision qui est souvent, pour un gouvernement têtu, une indication
          très précise de la marche à suivre pour reformuler ses dispositions
          dans la loi suivante.
        </Text>
        <Text>
          Le gouvernement ne perd pas de temps. Dès février&#160;2021, avant
          même l’adoption définitive de la loi Sécurité globale, le ministre de
          l’Intérieur glisse déjà le «&#160;délit d’appel à la haine en
          ligne&#160;» à l’encontre des forces de l’ordre dans l’article&#160;18
          du projet de loi Séparatisme&#160;: l’article&#160;24 affaibli par le
          Sénat est déjà reformulé dans une autre loi
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Avatar de l’article&#160;24, le nouveau délit d’appel à la
                    haine en ligne est adopté avec la loi “séparatisme” »,{" "}
                    <i>Mediapart</i>, 11&#160;février&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.mediapart.fr/journal/france/110221/avatar-de-l-article-24-le-nouveau-delit-d-appel-la-haine-en-ligne-est-adopte-avec-la-loi-separatisme"
                  >
                    https://www.mediapart.fr/journal/france/110221/avatar-de-l-article-24-le-nouveau-delit-d-appel-la-haine-en-ligne-est-adopte-avec-la-loi-separatisme
                  </AppLink>
                </>
              )
            }
          >
            307
          </InfoClick>
          … Passer par la porte et par la fenêtre en même temps, une technique
          de gouvernement&#160;?
        </Text>
        <Text>
          En avril-mai&#160;2021, le gouvernement dépose un nouveau projet de
          loi «&#160;relatif à la prévention d’actes de terrorisme et au
          renseignement&#160;» –&#160;que nous surnommons aussitôt «&#160;loi
          Renseignement 2&#160;»&#160;: elle complète directement la loi
          Renseignement de 2015, en pérennisant dans le droit commun des mesures
          temporaires de l’état d’urgence (facilités de perquisition et de
          détention) ou diverses mesures expérimentales, comme la surveillance
          automatisée du réseau par des «&#160;algorithmes&#160;» surnommés les
          «&#160;boîtes noires&#160;». Mais notre inquiétude est suscitée par
          des articles d’apparence anodine. La saisie du matériel informatique
          des personnes faisant l’objet d’une perquisition par la police (sans
          intervention d’un juge d’instruction), l’obligation faite aux
          administrations et aux services sociaux de fournir des informations
          aux services de renseignement, l’autorisation de larges partages de
          données entre les services de renseignement, l’extension des boîtes
          noires aux adresses URL, la confirmation de la conservation des
          données de surveillance des communications par satellites, plus une
          disposition floue qui nous fait craindre une attaque contre le
          chiffrement des communications
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Loi “renseignement”&#160;: le retour en pire »,
                    27&#160;mai&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/05/27/loi-renseignement-le-retour-en-pire/"
                  >
                    https://www.laquadrature.net/2021/05/27/loi-renseignement-le-retour-en-pire/
                  </AppLink>
                </>
              )
            }
          >
            308
          </InfoClick>
          … Le tout sous couvert de lutte contre le terrorisme, bien évidemment,
          mais également et au passage pour des motifs tels que le
          contre-espionnage économique ou la surveillance des mouvements
          sociaux…
        </Text>
        <Text>
          Passée en procédure d’urgence, la loi est discutée à vitesse grand V,
          nous laissant un sentiment d’épuisement et d’acharnement législatif.
          Le&#160;Parlement, très largement acquis à l’idéologie sécuritaire et
          au caporalisme de parti qui guide les votes de la majorité, n’ose pas
          critiquer les pouvoirs exorbitants donnés aux services de
          renseignement sous couvert de lutte contre le terrorisme, et semble
          croire sur parole le ministre de l’Intérieur et la délégation
          parlementaire au renseignement qui répètent sur tous les tons que
          cette loi est essentielle. Même le Conseil constitutionnel ne voit
          presque rien à y redire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Loi “renseignement 2”&#160;: nos arguments au Conseil
                    constitutionnel », 28&#160;juillet&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/07/28/loi-renseignement-2-nos-arguments-au-conseil-constitutionnel/"
                  >
                    https://www.laquadrature.net/2021/07/28/loi-renseignement-2-nos-arguments-au-conseil-constitutionnel/
                  </AppLink>
                </>
              )
            }
          >
            309
          </InfoClick>
          . Il est bien loin le temps où la contestation de la loi Renseignement
          de 2015 avait été l’occasion de manifestations de rue…
        </Text>

        <Text type="h3">
          «&#160;Loi&#160;Séparatisme&#160;» et&#160;«&#160;loi
          Audiovisuelle&#160;»
          <br />
          &#160;(printemps-été 2021)
        </Text>
        <Text>
          Du côté de la régulation du Web, on doit affronter deux textes de loi
          en même temps&#160;: la loi Audiovisuelle et la loi Séparatisme.
        </Text>
        <Text>
          La première, loi «&#160;communication audiovisuelle et souveraineté
          culturelle&#160;», supprime enfin la Hadopi –&#160;mais pour augmenter
          et confier ses pouvoirs au CSA, qui devient une nouvelle entité nommée
          l’Arcom, avec des pouvoirs de régulation et de censure du Web
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Point d’étape des lois “renseignement”, “séparatisme” et
                    “anti-piratage” », 30&#160;juin&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/06/30/point-detape-des-lois-renseignement-separatisme-et-anti-piratage/"
                  >
                    https://www.laquadrature.net/2021/06/30/point-detape-des-lois-renseignement-separatisme-et-anti-piratage/
                  </AppLink>
                </>
              )
            }
          >
            310
          </InfoClick>
          .
        </Text>
        <Text>
          La deuxième, loi sur le séparatisme, devenue loi sur «&#160;le respect
          des principes de la République&#160;», grand fourre-tout de mesures
          pour détecter ou contrer la radicalisation islamiste, est un prétexte
          pour mélanger des mesures restrictives des libertés associatives et
          des mesures de régulation du Web. Son article&#160;18 reprend les
          éléments de l’article&#160;24 de la loi Sécurité globale
          (l’interdiction de diffuser des images de policiers identifiables).
          Son article&#160;19 autorise la fermeture administrative des sites
          «&#160;miroirs&#160;» d’un premier site censuré par un juge pour
          «&#160;haine&#160;», mesure qu’on retrouve également dans la loi
          Audiovisuelle pour les sites accusés de «&#160;piratage&#160;». Dans
          les deux cas, la nouvelle Arcom a autorité et gagne de nouveaux
          pouvoirs.
        </Text>
        <Text>
          On compte au moins deux leçons à retenir de ces lois. Première
          leçon&#160;: il s’agit de donner toujours plus de pouvoir à
          l’administration plutôt qu’à un juge sur Internet. L’idée étant que le
          juge ne sera pas assez rapide, ni assez efficace, et qu’il faut
          laisser un peu de marge de manœuvre à l’administration pour réguler
          Internet. L’Arcom pourra saisir le juge, proposer des mesures aux
          plateformes, et infliger d’énormes sanctions.
        </Text>
        <Text>
          Deuxième leçon&#160;: ces lois prétendent s’attaquer aux problèmes
          d’Internet, à la surpuissance des plateformes, mais ne font en réalité
          que proposer de plates mesures de modération, de transparence,
          d’obligations de moyens qui ne remettent jamais en cause le modèle
          économique sur lequel se fondent ces plateformes. La publicité ciblée,
          l’économie des données, la captation, la succion même de nos données
          personnelles pour en faire un business, sont obstinément ignorées. Le
          RGPD, qui donne précisément un moyen de pression parfait sur
          l’exploitation non consentie des données personnelles, n’est
          délibérément pas appliqué, ou exploité.
        </Text>

        <Text type="h3">
          «&#160;Loi Drones 2&#160;»,&#160;la&#160;voiture-balai sécuritaire
          (septembre&#160;2021)
        </Text>
        <Text>
          En juillet&#160;2021, trois&#160;mois à peine après la censure
          partielle de la loi Sécurité globale par le Conseil constitutionnel,
          le gouvernement lance déjà, en procédure accélérée, la loi
          «&#160;responsabilité pénale et sécurité intérieure&#160;», que nous
          surnommons aussitôt «&#160;Drones 2&#160;». Voiture-balai de toutes
          les lois sécuritaires qui l’ont précédée en moins de deux ans, cette
          loi ornithorynque essaie de reformuler bon nombre de mesures qui ont
          été censurées. Elle propose en particulier de légaliser les drones
          policiers, en suivant les recommandations données par la dernière
          décision du Conseil constitutionnel à ce sujet. Nous ne perdons pas
          l’occasion de moquer l’entêtement du gouvernement, et de rappeler que
          les drones ont déjà été interdits quatre fois en deux ans
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Les drones reviennent, nous aussi »,
                    14&#160;septembre&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/09/14/les-drones-reviennent-nous-aussi/"
                  >
                    https://www.laquadrature.net/2021/09/14/les-drones-reviennent-nous-aussi/
                  </AppLink>
                </>
              )
            }
          >
            311
          </InfoClick>
          …
        </Text>
        <Text>
          Malgré le comique de répétition, le rire est amer. Devons-nous, de
          guerre lasse, nous résoudre à considérer que notre seul et dernier
          espace de démocratie sera la saisine du Conseil constitutionnel,
          organe totalement opaque&#160;? Doit-on être content que face à la
          mascarade parlementaire, totalement paralysée par la double majorité
          gouvernementale et présidentielle, le seul contre-pouvoir
          institutionnel soit… le Conseil constitutionnel&#160;? Si en plus il
          suffit ensuite au gouvernement de ressortir une nouvelle loi quelques
          mois après…
        </Text>

        <Text type="h3">
          Une&#160;«&#160;Union&#160;» à&#160;la&#160;carte&#160;?
        </Text>
        <Text>
          L’attitude du gouvernement français à l’égard de ses engagements
          européens est aussi élastique que sa conception de la démocratie. En
          même temps que les trois lois dont il vient d’être question, la France
          mène au sein de l’Union européenne des négociations pour faire avancer
          deux textes importants. D’une part le <i>Digital Service Act</i>{" "}
          (DSA), un texte énorme qui encadrera les services en ligne, et
          notamment les plateformes géantes. D’autre part un règlement pour
          encadrer l’utilisation de l’intelligence artificielle dans les pays de
          l’Union. Le gouvernement français pousse dans ces deux textes des
          propositions sécuritaires et policières identiques à celles qu’il
          cherche à nous imposer dans son espace domestique. Mieux encore, il
          utilise le cadre européen pour tenter de contourner les blocages
          constitutionnels qu’il rencontre en France et tout bonnement imposer
          au niveau de l’Union européenne ce qui ne passe pas en France.
        </Text>
        <Text>
          Cette volonté était déjà particulièrement visible au moment de la loi
          Avia «&#160;contre la haine&#160;», en 2019. Le délai de censure des
          contenus «&#160;haineux&#160;» en 24&#160;heures est aggravé, à la fin
          de la discussion de la loi, par un tour de passe-passe législatif, par
          l’introduction soudaine d’un délai d’une heure seulement pour la
          suppression des contenus «&#160;terroristes&#160;». Cette mesure est
          sévèrement censurée par le Conseil constitutionnel. Nous pensions,
          peut-être par naïveté, qu’une telle gifle suffirait pour convaincre le
          gouvernement de l’inanité de la mesure. C’était bien mal estimer son
          audace.
        </Text>
        <Text>
          La censure des contenus haineux en 24&#160;heures, confiée aux robots
          et aux modérateurs des grandes plateformes, se retrouve formulée à
          l’identique dans le cadre des travaux sur le DSA. La censure des
          contenus terroristes se retrouve quant à elle dans le «&#160;règlement
          Terro&#160;» («&#160;de lutte contre les contenus terroristes&#160;»),
          à l’initiative de la France. Et la mesure repoussée en France fait
          désormais son chemin dans le texte européen, jusqu’à son adoption
          finale en mai&#160;2021
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Règlement de censure terroriste adopté&#160;: résumons »,
                    7&#160;mai&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/05/07/reglement-de-censure-terroriste-adopte-resumons/"
                  >
                    https://www.laquadrature.net/2021/05/07/reglement-de-censure-terroriste-adopte-resumons/
                  </AppLink>
                </>
              )
            }
          >
            312
          </InfoClick>
          …
        </Text>
        <Text>
          C’est, pour nous, une énorme défaite&#160;: dans la lignée de ce qui
          avait été prévu en France (et appliqué) depuis plusieurs années, la
          police peut forcer n’importe quel acteur de l’internet (à quelques
          exceptions) à retirer en une heure un contenu qu’elle considère comme
          «&#160;terroriste&#160;». Le délai d’une heure laisse évidemment, et
          c’est central ici, prévoir l’utilisation grandissante de la censure
          automatique, dont les outils sont développés depuis plusieurs années
          par Facebook, Google, etc.
        </Text>
        <Text>
          La France n’hésite donc pas à instrumentaliser l’Union européenne pour
          imposer le cadre juridique dont elle a besoin pour imposer sa
          politique sécuritaire domestique. Mais à l’inverse, quand le cadre
          européen lui déplaît…
        </Text>

        <Text type="h3">Le&#160;droit contre les&#160;droits</Text>
        <Text>
          Vous vous rappelez sans doute notre contentieux porté devant la CJUE,
          au sujet de la durée de conservation des données de connexion. Après
          la très solennelle audience d’octobre&#160;2019
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Voir </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/10/11/recours-contre-le-renseignement-compte-rendu-de-notre-audience-devant-la-cour-de-justice-de-lunion-europeenne/"
                  >
                    https://www.laquadrature.net/2019/10/11/recours-contre-le-renseignement-compte-rendu-de-notre-audience-devant-la-cour-de-justice-de-lunion-europeenne/
                  </AppLink>
                </>
              )
            }
          >
            313
          </InfoClick>
          , la Cour délibère longuement. Elle rend sa décision le 6&#160;octobre
          2020&#160;: «&#160;Une défaite victorieuse&#160;», annonce notre
          communiqué de réaction à chaud
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Surveillance&#160;: une défaite victorieuse »,
                    6&#160;octobre 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/10/06/surveillance-une-defaite-victorieuse/"
                  >
                    https://www.laquadrature.net/2020/10/06/surveillance-une-defaite-victorieuse/
                  </AppLink>
                </>
              )
            }
          >
            314
          </InfoClick>
          . Victoire, car la Cour affirme bien que la France ne peut plus
          imposer cette conservation généralisée des données de connexion, mais
          défaite parce qu’elle fait apparaître un certain nombre de régimes
          d’exception importants. Nous publions un article qui résume bien
          l’ambivalence de nos sentiments&#160;: bien qu’elle réaffirme des
          principes juridiques plus protecteurs que le droit français, la
          décision de la Cour laisse assez de possibilités d’exception pour que
          nous soyons inquiets pour la suite.
        </Text>
        <Text>
          Munis des réponses de la CJUE, nous nous tournons alors vers le
          Conseil d’État. À son tour, il doit en tirer des conclusions sur la
          mise en conformité de la loi française. Confiance toute relative
          cependant, puisque nous jugeons tout de même nécessaire de rappeler
          publiquement, début avril&#160;2021, la gravité de l’enjeu
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Jugement imminent contre la surveillance de masse »,
                    7&#160;avril&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/04/07/jugement-imminent-contre-la-surveillance-de-masse/"
                  >
                    https://www.laquadrature.net/2021/04/07/jugement-imminent-contre-la-surveillance-de-masse/
                  </AppLink>
                </>
              )
            }
          >
            315
          </InfoClick>
          . Le verdict tombe le 21&#160;avril&#160;2021, et cette fois-ci le
          titre de notre communiqué n’est d’aucune ambiguïté&#160;: «&#160;Le
          Conseil d’État valide durablement la surveillance de masse&#160;».
          C’est une déception et une défaite énormes pour nous. Nous le savions,
          il existait des failles dans l’avis de la CJUE, et les petits malins
          du Conseil d’État, prêts à tout pour soutenir la nécessité d’État de
          surveiller la population, s’y sont engouffrés&#160;: «&#160;Le Conseil
          d’État autorise la conservation généralisée des données de connexion
          en dehors des situations exceptionnelles d’état d’urgence sécuritaire,
          contrairement à ce qu’exigeait la Cour de justice de l’UE dans sa
          décision du 6&#160;octobre 2020 contre la France. Pour arriver à une
          conclusion aussi brutale, le Conseil d’État a réinterprété la notion
          de “sécurité nationale” pour l’étendre très largement au-delà de la
          lutte contre le terrorisme et y inclure par exemple l’espionnage
          économique, le trafic de stupéfiants ou l’organisation de
          manifestations non déclarées
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Le Conseil d’État valide durablement la surveillance de
                    masse », 21&#160;avril&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2021/04/21/le-conseil-detat-valide-durablement-la-surveillance-de-masse/"
                  >
                    https://www.laquadrature.net/2021/04/21/le-conseil-detat-valide-durablement-la-surveillance-de-masse/
                  </AppLink>
                </>
              )
            }
          >
            316
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          La collecte massive et indistincte des données de connexion n’est
          justifiée que dans le contexte d’une crise exceptionnelle, dit la
          CJUE&#160;? Fort bien, dit le Conseil d’État, il se trouve que la
          France est en état d’alerte pour plusieurs raisons… La pirouette
          cynique plaira tellement que nous entendrons même des parlementaires
          de droite, de LR à LREM, se réjouir de l’audace et de l’habileté du
          conseiller d’État qui a dirigé les opérations. N’en doutons
          plus&#160;: l’État français préfère ouvertement l’exercice cynique du
          droit au respect des droits de ses citoyens.
        </Text>

        <Text type="h3">
          Pegasus et&#160;les&#160;«&#160;pas de&#160;loup&#160;»
        </Text>
        <Text>
          Lors des révélations Snowden de 2013, nous avions assisté, un peu
          ébahis, aux contorsions gênées de nos gouvernants, dont les
          communications avaient pourtant été captées et enregistrées par les
          services de «&#160;l’allié&#160;» américain. On avait vite compris que
          la France, ayant de toute évidence le même type d’activité, ne pouvait
          se permettre d’élever l’affaire au rang d’incident diplomatique.
        </Text>
        <Text>
          En juillet&#160;2021, Amnesty International révèle, conjointement avec
          le consortium international de journalistes Forbidden Stories
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Le site Web de Forbidden Stories&#160;: </Text>
                  <AppLink white href="https://forbiddenstories.org/fr/">
                    https://forbiddenstories.org/fr/
                  </AppLink>
                </>
              )
            }
          >
            317
          </InfoClick>
          , que des dizaines de smartphones appartenant à des personnalités
          politiques ou médiatiques du monde entier ont été infectés et
          surveillés au moyen du logiciel espion Pegasus, commercialisé par la
          société israélienne NSO Group
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Projet Pegasus&#160;: des révélations chocs sur un
                    logiciel espion israélien », Amnesty International,
                    8&#160;juillet&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.amnesty.fr/actualites/surveillance-revelations-sur-le-logiciel-espion-israelien-pegasus-nso-group"
                  >
                    https://www.amnesty.fr/actualites/surveillance-revelations-sur-le-logiciel-espion-israelien-pegasus-nso-group
                  </AppLink>
                </>
              )
            }
          >
            318
          </InfoClick>
          .
        </Text>
        <Text>
          Un détail compte pour apprécier le sel de l’histoire&#160;: NSO Group
          vend ses outils d’espionnage exclusivement à des États. Autre détail
          savoureux&#160;: on retrouve des traces de Pegasus dans les téléphones
          de plusieurs ministres et journalistes français. Et, cette fois
          encore, comme en 2013&#160;: aucune réaction officielle.
        </Text>
        <Text>
          Fin novembre&#160;2021, un article publié par <i>Le Monde</i> lève un
          coin du voile. Un article publié aux États-Unis vient d’affirmer que
          la France était sur le point de signer un contrat avec NSO Group quand
          le scandale a éclaté. Le palais de l’Élysée dément aussitôt, en
          reconnaissant toutefois que l’entreprise avait longtemps et avec
          insistance courtisé les services français, jusqu’en 2020, et jusqu’au
          refus catégorique de la France.
        </Text>
        <Text>
          Pourquoi ce «&#160;non&#160;»&#160;? Principalement en raison de la
          grande porosité du personnel de NSO Group avec les services israéliens
          et états-uniens. Mais, précise l’article du <i>Monde&#160;</i>:
          «&#160;Les services de police et de justice ont cependant obtenu que
          des moyens financiers soient débloqués pour doter les services de
          renseignement et de justice de l’ensemble des outils nécessaires à un
          piratage à distance des téléphones. […] À ce jour, les capacités
          techniques en la matière demeurent loin de celles de Pegasus, et
          depuis plusieurs années les autorités progressent à pas de loup sur ce
          sujet miné. Au sein de l’État, on craint d’ailleurs que le scandale
          autour de Pegasus ne vienne compromettre le développement de cet outil
          que beaucoup aimeraient voir rejoindre l’arsenal des enquêteurs et des
          espions français
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Malgré les approches de NSO Group, la France a choisi à la
                    fin de 2020 de ne pas acheter le logiciel espion Pegasus »,{" "}
                    <i>Le Monde</i>, 26&#160;novembre&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lemonde.fr/pixels/article/2021/11/26/malgre-les-approches-de-nso-group-la-france-a-choisi-a-la-fin-de-2020-de-ne-pas-acheter-le-logiciel-espion-pegasus_6103783_4408996.html"
                  >
                    https://www.lemonde.fr/pixels/article/2021/11/26/malgre-les-approches-de-nso-group-la-france-a-choisi-a-la-fin-de-2020-de-ne-pas-acheter-le-logiciel-espion-pegasus_6103783_4408996.html
                  </AppLink>
                </>
              )
            }
          >
            319
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          Dès que les services de renseignement français auront développé leur
          propre outil, les smartphones des habitants du pays seront aussitôt,
          et dans la plus parfaite légalité, à portée d’écoute, quoi qu’il en
          coûte.
        </Text>

        <Text>
          Si l’effondrement démocratique a largement démarré sous la présidence
          de Nicolas Sarkozy, et a pu se prolonger sous celle de François
          Hollande, le quinquennat d’Emmanuel Macron est néanmoins bien
          lourd&#160;: extension des pouvoirs des services de renseignement,
          nouveaux fichiers de police et bases de données massives, accélération
          du pouvoir de censure de l’administration, nombreux partenariats avec
          des entreprises sécuritaires pour démultiplier la surveillance sur
          Internet ou dans nos rues, utilisation massive de la reconnaissance
          faciale policière… Il aura directement contribué au basculement,
          toujours plus rapide, toujours plus profond, vers une société
          sécuritaire reposant sur la surveillance et la censure, qu’elle soit
          d’origine étatique ou privée, les deux étant ici souvent mêlées.
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter3Part6;
