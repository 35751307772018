import React from "react";
import { Link } from "react-router-dom";

import cx from "classnames";

import Styles from "./NavbarSmallScreens.module.scss";

import { Text } from "src/components";
import { ReactComponent as Arrow } from "src/assets/arrow.svg";
import { UseBookType } from "src/hooks";

import IndexNavbar from "../IndexNavbar/IndexNavbar";

const NavbarSmallScreens = ({
  currentChapter,
  isChapter,
  currentLocation,
  isConclusion,
  isIntroduction,
  onClickBeforeArrow,
  onClickNextArrow,
  handleCloseNav,
  handleOpenNav,
  isNavOpen,
}: Partial<UseBookType> & {
  isNavOpen?: boolean;
  onClickNextArrow: () => void;
  onClickBeforeArrow: () => void;
  handleCloseNav: () => void;
  handleOpenNav: () => void;
}) => {
  return (
    <div
      onMouseLeave={handleCloseNav}
      className={Styles.NavbarAndIndexContainer}
    >
      <div
        className={cx(Styles.NavbarContainer, isNavOpen && Styles.NavOpen)}
        onMouseEnter={handleOpenNav}
        onClick={handleOpenNav}
      >
        <Link to="/" className={Styles.TitleLink}>
          <Text type="h2" className={Styles.Title}>
            Internet et Libertés
          </Text>
        </Link>
        {!isNavOpen && (
          <Text className={Styles.CurrentLocation} type="h3">
            {currentLocation}
          </Text>
        )}
        {isNavOpen && (
          <div className={Styles.TitleTextContainer}>
            <Arrow
              onClick={onClickBeforeArrow}
              className={cx(
                Styles.Arrows,
                Styles.ArrowLeft,
                isIntroduction && Styles.ArrowHidden
              )}
            />
            <div className={Styles.TitleText}>
              <Text className={Styles.BigTexts} type="h1">
                {isChapter ? currentLocation : currentChapter?.chapter}
              </Text>
              {isChapter && (
                <div className={Styles.ChapterPart}>
                  <Text type="h3" className={Styles.ChapterNameText}>
                    {currentChapter?.chapter}
                  </Text>
                  <Text type="subtitle">
                    {currentLocation} - {currentChapter?.part}
                  </Text>
                </div>
              )}
            </div>
            <Arrow
              onClick={onClickNextArrow}
              className={cx(
                Styles.Arrows,
                Styles.ArrowRight,
                isConclusion && Styles.ArrowHidden
              )}
            />
          </div>
        )}
      </div>
      {isNavOpen && <IndexNavbar onClickItem={handleCloseNav} small />}
      {isNavOpen && (
        <div
          className={Styles.NavbarFantom}
          onClick={handleCloseNav}
          onMouseEnter={handleCloseNav}
        />
      )}
    </div>
  );
};

export default NavbarSmallScreens;
