import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter3Part4 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Quand le numérique menace les libertés">
      <>
        <Text>
          L’histoire de la Quadrature est peut-être –&#160;et c’est sans doute
          le fil de ce livre&#160;– celle de la prise de conscience douloureuse
          que le numérique et le Web, qu’on avait crus émancipateurs,
          progressistes et humanistes par nature, dans leur conception même, se
          sont retournés contre nous comme outils de surveillance, de censure,
          de manipulation des personnes et de gavage publicitaire. Ce n’est pas
          l’histoire d’une naïveté&#160;: le Web mondial a aussi donné à
          l’humanité une nouvelle puissance et une nouvelle conscience
          d’elle-même, sur ce point, les rêveurs ne se sont pas trompés. Le
          réseau mondial a bel et bien bouleversé l’éducation, les échanges
          savants et les échanges quotidiens, révolutionné l’organisation
          politique en donnant un moyen d’expression aux sans-voix, et changé
          mille aspects de nos vies pour le meilleur.
        </Text>
        <Text>
          Mais ce que nous n’avions pas envisagé –&#160;parce que, selon nous,
          ce n’était désirable pour personne&#160;–, c’est que le pouvoir
          économique et politique retournerait cet outil révolutionnaire contre
          ceux qui l’utilisent, pour perpétuer l’ordre existant. On pense en
          particulier à l’utilisation des réseaux (Web et téléphoniques) pour
          contrôler, contraindre, et surveiller les populations&#160;: à la fois
          surveiller tout le monde, et surveiller chacun avec une finesse
          inégalée jusqu’à présent. Ce que l’ère numérique offre de nouveau sur
          le plan historique, c’est aussi un contrôle politique sans précédent
          dans l’histoire. Le numérique est à ce titre vecteur de menaces très
          concrètes sur les libertés publiques, et la période 2018-2019 est
          généreuse en exemples. On l’a vu, la technopolice fait son trou et
          oblige la Quadrature à quitter sa «&#160;zone de confort&#160;» et son
          champ d’étude habituel. Mais même dans le champ bien familier des
          libertés sur Internet, nous faisons face à de nouveaux assauts, et à
          de nouvelles reculades.
        </Text>

        <Text type="h3">
          «&#160;Loi <i>Fake news</i>&#160;»&#160;: la&#160;censure
          qui&#160;fait pschitt
        </Text>
        <Text>
          Si on devait classer ces mesures par degré croissant de nocivité, on
          commencerait sans doute par la loi «&#160;contre les <i>fake news</i>
          &#160;», tellement mal fichue qu’elle en devient presque rigolote.
          Pour être honnête, il faut bien reconnaître que la Quadrature ne lui a
          pas consacré beaucoup de temps ni d’énergie, tant il paraissait
          évident dès le départ que le projet s’écroulerait sur lui-même. Malgré
          tout, cette loi dit évidemment quelque chose de l’état d’esprit des
          gouvernants qui l’ont imaginée, et des moyens qu’ils se donnent.
        </Text>
        <Text>
          En janvier&#160;2018, dans ses vœux à la presse
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Vœux à la presse, 3&#160;janvier 2018&#160;: </Text>
                  <AppLink
                    white
                    href="https://www.elysee.fr/emmanuel-macron/2018/01/03/voeux-du-president-de-la-republique-emmanuel-macron-a-la-presse"
                  >
                    https://www.elysee.fr/emmanuel-macron/2018/01/03/voeux-du-president-de-la-republique-emmanuel-macron-a-la-presse
                  </AppLink>
                </>
              )
            }
          >
            228
          </InfoClick>
          , le président de la République Emmanuel Macron dresse un
          constat&#160;: «&#160;Au-delà même des tentations illibérales, c’est
          le modèle du métier de journaliste qui est aujourd’hui remis en cause
          ou, pour le dire plus justement, dévoyé car nous vivons l’irruption
          dans le champ médiatique des fausses nouvelles, les <i>fake news</i>,
          comme on le dit dans le monde anglo-saxon, et des médias qui les
          propagent.&#160;»
        </Text>
        <Text>
          À quoi pense-t-il&#160;? Difficile de le dire avec précision…
          Peut-être visait-il l’apparition dans le paysage francophone de médias
          russes financés par les partisans de Poutine (la chaîne RT France est
          lancée en décembre&#160;2017)&#160;: «&#160;Entre le complotisme et le
          populisme, le combat est en effet commun, il est de saper toute
          confiance dans le jeu démocratique, d’y faire apercevoir un jeu de
          dupe, une valse de faux-semblants et c’est vous, c’est nous qui sommes
          visés par cette stratégie au profit d’une propagande déterminée. Cette
          montée des fausses nouvelles est aujourd’hui […] utilisée par des
          puissances qui s’amusent en quelque sorte des faiblesses de la
          démocratie, de son ouverture extrême, de son incapacité à trier, à
          hiérarchiser, à reconnaître au fond une forme d’autorité.&#160;»
        </Text>
        <Text>
          Peut-être craint-il de voir arriver en France, alors que Donald Trump
          est président des États-Unis depuis un an déjà, les méthodes
          médiatiques qui ont porté le promoteur immobilier new-yorkais au poste
          d’homme le plus puissant de monde, ou celles qui ont amené la majorité
          des Britanniques à voter en faveur du «&#160;Brexit&#160;»&#160;:
          «&#160;Entre ces machines à répandre les fausses nouvelles et les
          médias professionnels, la porosité menace. Des barrières ont été
          érigées mais les campagnes présidentielles d’à peu près toutes les
          démocraties contemporaines ont montré la faiblesse de celles-ci et
          notre incapacité collective à apporter des réponses qui sont à la
          hauteur aujourd’hui des menaces.&#160;»
        </Text>
        <Text>
          En tout cas, Donald Trump est bien à l’origine de l’expression{" "}
          <i>fake news.</i> Sa campagne électorale, dans la deuxième moitié de
          l’année 2015, a consisté en grande partie à balancer dans le débat
          public des énormités qui polarisaient les discussions et
          monopolisaient l’attention médiatique. Et il avait pris l’habitude de
          répondre, quand la presse lui objectait un fait vérifiable, un
          chiffre, ou un raisonnement&#160;:{" "}
          <i>«&#160;This is fake news&#160;» </i>(une fausse information, une
          invention, un mensonge). À un reporter de CNN qui le contredisait en
          conférence de presse, il lança même&#160;:{" "}
          <i>«&#160;You are fake news&#160;!&#160;»</i> Trump revendiquait sa
          propre lecture du monde, allant jusqu’à parler de «&#160;vérité
          alternative&#160;», fondée même sur des «&#160;faits
          alternatifs&#160;». Adieu la vérité objective, au revoir la
          possibilité d’un monde commun, il ne reste plus que des vérités de
          points de vue, et la bataille électorale n’est plus qu’une compétition
          d’affirmations sans arbitre et sans arbitrage possible. Créé par un
          menteur professionnel pour désigner les propos de ses détracteurs, le
          terme a rapidement changé de sens et, quand Emmanuel Macron l’utilise
          à son tour un an plus tard, c’est pour désigner, à l’inverse, les
          mensonges des propagandistes et des affabulateurs cyniques, et pour
          défendre la presse <i>mainstream</i> qui était l’ennemi désigné de
          Trump. Et d’après vous, comment circulent ces fausses nouvelles qui
          menacent la vraie presse et la démocratie libérale&#160;? Par
          Internet.
        </Text>
        <Text>
          Le discours d’Emmanuel Macron ne se limite donc pas à dénoncer un
          danger, ni à exhorter les journalistes à lutter avec leurs propres
          moyens contre les <i>fake news</i>. Il annonce aussi une prochaine loi
          pour réglementer «&#160;les plateformes Internet&#160;»&#160;:
          «&#160;C’est pourquoi j’ai décidé que nous allions faire évoluer notre
          dispositif juridique pour protéger la vie démocratique de ces fausses
          nouvelles. Un texte de loi sera prochainement déposé à ce sujet. En
          période électorale, sur les plateformes Internet, les contenus
          n’auront plus tout à fait les mêmes règles. [...] Les plateformes se
          verront ainsi imposer des obligations de transparence accrue sur tous
          les contenus sponsorisés afin de rendre publique l’identité des
          annonceurs et de ceux qui les contrôlent, mais aussi de limiter les
          montants consacrés à ces contenus. [...] En cas de propagation d’une
          fausse nouvelle, il sera possible de saisir le juge à travers une
          nouvelle action en référé permettant le cas échéant de supprimer le
          contenu mis en cause, de déréférencer le site, de fermer le compte
          utilisateur concerné, voire de bloquer l’accès au site
          Internet.&#160;»
        </Text>
        <Text>
          Nous retrouvons de vieilles lunes&#160;: la bonne vieille
          incrimination du Web et la bonne vieille censure sont toujours là.
          Défraîchies, décaties, déplumées, mais toujours debout. Elles vont
          servir encore une fois. Quand les causes sont lointaines,
          attaquons-nous aux effets proches…
        </Text>
        <Text>
          Le titre de l’article que nous publions le lendemain du discours à la
          presse, le 4&#160;janvier 2018, annonce la couleur&#160;:
          «&#160;Derrière l’effet d’annonce, Macron esquive le vrai débat
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « <i>Fake news</i>&#160;: derrière l’effet d’annonce, Macron
                    esquive le vrai débat », 4&#160;janvier 2018&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2018/01/04/macron_fake_news-2/"
                  >
                    https://www.laquadrature.net/2018/01/04/macron_fake_news-2/
                  </AppLink>
                </>
              )
            }
          >
            229
          </InfoClick>
          &#160;». Quel est-il, le vrai débat&#160;? Selon nous, Macron se
          trompe en chargeant les plateformes géantes (YouTube, Facebook,
          Twitter, etc.) de faire le ménage parmi les sources de désinformation
          dont elles ne seraient que le lieu de passage et les premières
          victimes. En réalité, dit notre article, le régime de la
          désinformation, du clash, de la polarisation aux extrêmes et de
          l’invective permanente est précisément créé par l’économie de ces
          plateformes. Elles cherchent à retenir l’attention des visiteurs, leur
          soumettent donc de préférence les contenus polémiques qui suscitent le
          plus grand «&#160;engagement&#160;», et retiennent les gens dans les
          filets des réseaux sociaux, sous perfusion de publicités et de
          contenus «&#160;sponsorisés&#160;» (qu’ils soient marchands ou
          politiques). Donald Trump et les <i>fake news</i> sont les enfants
          monstrueux de l’économie de l’attention et du ciblage publicitaire.
        </Text>
        <Text>
          Par ailleurs, souligne l’article, les lois sur la presse de 1881 et la
          loi pour la confiance dans l’économie numérique de 2004 (LCEN) donnent
          déjà à la police et aux juges tous les outils nécessaires pour faire
          supprimer un contenu illicite ou dommageable.
        </Text>
        <Text>
          La «&#160;loi <i>Fake news</i>&#160;» promise par Emmanuel Macron
          s’intitule en réalité «&#160;loi relative à la lutte contre la
          manipulation de l’information&#160;». Elle est adoptée en
          novembre&#160;2018, et ne s’applique que dans une période de
          trois&#160;mois avant chaque élection à valeur nationale. En 2019,
          après la campagne des élections européennes, les juges n’ont été
          saisis qu’une seule fois sur le fondement de cette loi&#160;: c’était
          à l’initiative de deux parlementaires qui voulaient démontrer son
          inanité
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Inefficace ou mal comprise, la loi contre les{" "}
                    <i>fake news</i> toujours en question », France 24,
                    19&#160;juin 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.france24.com/fr/20190619-france-loi-fake-news-efficacite-promulgation-lrem-macron-fausses-nouvelles-csa"
                  >
                    https://www.france24.com/fr/20190619-france-loi-fake-news-efficacite-promulgation-lrem-macron-fausses-nouvelles-csa
                  </AppLink>
                </>
              )
            }
          >
            230
          </InfoClick>
          …
        </Text>

        <Text type="h3">
          Loi&#160;Avia «&#160;contre la&#160;haine en&#160;ligne&#160;»
          (février-décembre 2019)
        </Text>
        <Text>
          Plus sérieuse et plus grave, dans l’ordre des atteintes à la liberté
          en ligne, la loi Avia «&#160;contre la haine&#160;» nous occupe en
          revanche pendant une bonne partie de l’année 2019. C’est pour nous une
          année forte en émotions (en particulier l’angoisse), puisque nous
          travaillons sur la campagne Technopolice et sur le règlement européen
          antiterroriste, dans lequel la France en particulier pousse des
          dispositions liberticides très fortes, comme la censure de sites Web
          en une heure et sans juge.
        </Text>
        <Text>
          Le 14&#160;février 2019, Cédric O (secrétaire d’État au numérique),
          Marlène Schiappa (secrétaire d’État pour l’égalité femmes-hommes) et
          Lætitia Avia (députée de la majorité LREM) tiennent une conférence de
          presse à Bercy pour présenter leur stratégie de lutte «&#160;contre la
          haine en ligne&#160;». Nous sommes présents dans la salle, ce qui nous
          permet de publier une réaction le jour même&#160;: «&#160;Mahjoubi et
          Schiappa croient lutter contre la haine en méprisant le droit européen
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Mahjoubi et Schiappa croient lutter contre la haine en
                    méprisant le droit européen », 14&#160;février 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/02/14/mahjoubi-et-schiappa-croient-lutter-contre-la-haine-en-meprisant-le-droit-europeen/"
                  >
                    https://www.laquadrature.net/2019/02/14/mahjoubi-et-schiappa-croient-lutter-contre-la-haine-en-meprisant-le-droit-europeen/
                  </AppLink>
                </>
              )
            }
          >
            231
          </InfoClick>
          &#160;». Un titre sévère, mais juste.
        </Text>
        <Text>
          Que proposent les stratèges de la «&#160;lutte contre la
          haine&#160;»&#160;? Leurs solutions contre les discours de haine sont
          avant tout, voire exclusivement, technologiques&#160;: un bouton pour
          signaler rapidement les nouveaux contenus illicites, des robots pour
          les bloquer en masse, et des obligations de censure qui reposent sur
          les hébergeurs.
        </Text>
        <Text>
          D’abord, cette stratégie recycle l’existant. Le «&#160;bouton&#160;»
          pour signaler les contenus haineux est prévu depuis 2004 par la loi
          pour la confiance dans l’économie numérique (LCEN). Le non-respect de
          cette mesure est même puni d’un an de prison et de
          75&#160;000&#160;euros d’amende. Mais depuis sa promulgation, les
          gouvernements n’ont jamais jugé opportun de la faire appliquer.
          Pourquoi&#160;? Sans doute parce que les politiques savent –&#160;les
          acteurs du domaine le leur ont expliqué&#160;– que cette mesure est
          inapplicable&#160;: les hébergeurs n’auraient pas les moyens matériels
          et humains de traiter les signalements que cela générerait. Sortir la
          mesure de la cave, c’est donc faire preuve d’une absence de réalisme,
          ou d’une solide démagogie. D’autres moyens existent, par ailleurs,
          comme la plateforme d’harmonisation, d’analyse, de recoupement et
          d’orientation des signalements (Pharos), qui dépend de la police
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Plateforme Pharos de signalement des contenus illicites sur
                    Internet&#160;: « Vous pouvez signaler les faits de&#160;:
                    pédophilie et pédopornographie, expression du racisme, de
                    l’antisémitisme et de la xénophobie, incitation à la haine
                    raciale, ethnique et religieuse, terrorisme et apologie du
                    terrorisme, escroquerie et arnaque financières utilisant
                    Internet », voir{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.interieur.gouv.fr/A-votre-service/Ma-securite/Conseils-pratiques/Sur-internet/Signaler-un-contenu-suspect-ou-illicite-avec-PHAROS"
                  >
                    https://www.interieur.gouv.fr/A-votre-service/Ma-securite/Conseils-pratiques/Sur-internet/Signaler-un-contenu-suspect-ou-illicite-avec-PHAROS
                  </AppLink>
                </>
              )
            }
          >
            232
          </InfoClick>
          .
        </Text>
        <Text>
          Ensuite, les stratèges chantent les louanges des filtres automatiques
          mis en place sur des plateformes comme Facebook et YouTube (Google),
          capables de supprimer en masse des contenus illicites, soit parce
          qu’ils ont déjà été repérés (une vidéo ou une photo par exemple), soit
          parce qu’ils contiennent des éléments facilement repérables (certains
          mots par exemple). Ces «&#160;robots&#160;» logiciels actifs sur les
          serveurs ont été développés à l’origine pour reconnaître les œuvres
          protégées par des droits d’auteur et les supprimer (des films ou des
          morceaux de musique). Sur YouTube, où ils fonctionnent
          quotidiennement, ils bloquent des vidéos en nombre et avec de nombreux
          «&#160;dégâts collatéraux&#160;»&#160;: on a vu par exemple une vidéo
          diffusée sur YouTube se trouver brutalement censurée, parce qu’une
          chaîne de télévision en avait parlé et diffusé des extraits&#160;;
          mais les émissions de la chaîne en question étant elles aussi
          protégées par les robots, la vidéo d’origine était perçue par ceux-ci
          comme un piratage de l’émission de télévision…
        </Text>
        <Text>
          Par ailleurs, le fait de confier la surveillance et la censure du Web
          aux GAFAM ne cesse de nous étonner. Et pourtant, c’est dans la suite
          logique de ce que nous constatons depuis plusieurs années déjà, et qui
          est à l’œuvre aussi dans le règlement européen contre la propagande
          terroriste&#160;: demander à quelques interlocuteurs fiables et
          reconnus d’effectuer le travail, quand bien même il s’agirait de
          grandes entreprises privées américaines. Pour des gouvernants
          pragmatiques, il est raisonnable de prendre en compte les forces en
          présence et le Web tel qu’il est. Pour nous, qui voulons le
          décentraliser et l’arracher des mains des GAFAM, c’est un aveu
          d’échec.
        </Text>
        <Text>
          Le «&#160;règlement Terro&#160;» demande aux hébergeurs de retirer les
          contenus terroristes en une heure. La loi Avia demande aux hébergeurs
          de retirer les contenus haineux en 24&#160;heures. C’est plus long,
          mais ce n’est pas mieux. Seules de très grosses structures ont les
          moyens techniques et humains de répondre à de telles requêtes dans un
          délai aussi court. Seuls les GAFAM peuvent le faire, les petits
          deviendront leurs clients et leur délégueront le travail, ou leur
          achèteront leurs outils. Comme le manquement est sévèrement puni
          (l’amende pouvant s’élever à 4&#160;% du chiffre d’affaires annuel),
          les GAFAM appliqueront par prudence un filtrage préalable sévère, qui
          se répercutera aussitôt chez les petits qu’ils fournissent en
          instruments de censure. Résultat&#160;: un Web uniformisé, édulcoré,
          sans saveur et censuré.
        </Text>
        <Text>
          Contre cette volonté de confier la censure des contenus aux grandes
          plateformes, nous avons un allié de circonstance inattendu&#160;: le
          ministère de la Justice. Dans une circulaire publiée en
          avril&#160;2019
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="http://www.justice.gouv.fr/bo/2019/20190430/JUSD1910196C.pdf"
                >
                  http://www.justice.gouv.fr/bo/2019/20190430/JUSD1910196C.pdf
                </AppLink>
              )
            }
          >
            233
          </InfoClick>
          , il appelle en effet à recourir davantage au juge pour lutter contre
          la haine en ligne et dénonce, l’«&#160;usage abusif&#160;» pouvant
          être fait des «&#160;dispositions permettant d’engager la
          responsabilité des acteurs d’Internet&#160;». Alors que le
          gouvernement propose avec la&#160;loi Avia de contourner le juge pour
          gagner en «&#160;efficacité&#160;», la circulaire ministérielle
          constate que les procureurs saisissent bien trop peu la justice dans
          ces affaires…
        </Text>
        <Text>
          Enfin, nos stratèges nationaux réactivent le fantasme de «&#160;la fin
          de l’anonymat sur Internet&#160;». On peut leur objecter que
          l’anonymat n’existe pas&#160;: les hébergeurs connaissent les adresses
          IP des internautes, et les FAI connaissent leur nom. Mais les mesures
          qu’ils proposent sont en contradiction directe avec le droit
          européen&#160;: depuis des années, la France impose aux fournisseurs
          d’accès à Internet (FAI), aux hébergeurs et aux opérateurs
          téléphoniques de conserver les données de connexion de tous les
          utilisateurs pendant un an, alors que le droit européen limite cette
          conservation de données à la fois en étendue, et dans le temps.
          Ce&#160;point est d’ailleurs à l’origine de l’un de nos plus
          importants contentieux.
        </Text>

        <Text type="h3">
          La&#160;conservation illégale des&#160;données de&#160;connexion
        </Text>
        <Text>
          L’histoire commence en 2015, avec l’adoption de la loi Renseignement.
          Dans un contexte de terrorisme exacerbé, entre l’assassinat de la
          rédaction de <i>Charlie Hebdo</i> et les attentats coordonnés du
          13&#160;novembre 2015, elle donne de larges pouvoirs de surveillance
          aux services de renseignement, et leur ouvre en grand l’accès aux
          données de connexion collectées par les opérateurs télécoms français
          (opérateurs téléphoniques et FAI). Par ailleurs, la loi française
          oblige depuis 2011 les opérateurs à conserver pendant une durée d’un
          an toutes les «&#160;données de connexion&#160;» de leurs
          utilisateurs, ce qu’on appelle aussi les «&#160;métadonnées&#160;».
          Non pas le contenu de vos conversations, mais leur description
          technique&#160;: tel abonné a envoyé à telle abonnée un SMS de tant de
          caractères à telle heure, telle abonnée a téléphoné à tel abonné entre
          telle heure et telle heure, etc. Concernant le Web, les opérateurs et
          les hébergeurs sont obligés de conserver l’historique des pages
          consultées, à partir de quelle adresse IP, etc. Or, la loi européenne
          n’autorise pas une telle collecte de données, ni en masse, ni pour une
          telle durée. Et non, ce n’est évidemment pas parce que l’Union
          européenne, pas plus que la Quadrature, voudrait protéger les
          terroristes –&#160;l’idée est absurde et inutilement insultante pour
          l’intelligence de celui qui la profère.
        </Text>
        <Text>
          La Cour de justice de l’Union européenne (CJUE) avait invalidé en 2014
          une directive européenne de 2006 précisément pour cette raison&#160;:
          elle autorisait une collecte de données trop large, trop peu ciblée,
          et disproportionnée dans son atteinte à la vie privée et à la liberté
          d’expression des citoyens, en regard de sa finalité avouée. Il faut
          lire en creux les reproches formulés par la CJUE&#160;: «&#160;[…]
          ladite directive ne requiert aucune relation entre les données dont la
          conservation est prévue et une menace pour la sécurité publique et,
          notamment, elle n’est pas limitée à une conservation portant soit sur
          des données afférentes à une période temporelle et/ou une zone
          géographique déterminée et/ou sur un cercle de personnes données
          susceptibles d’être mêlées d’une manière ou d’une autre à une
          infraction grave, soit sur des personnes qui pourraient, pour d’autres
          motifs, contribuer, par la conservation de leurs données, à la
          prévention, à la détection ou à la poursuite d’infractions
          graves.&#160;»
        </Text>
        <Text>
          En d’autres termes, surveiller tout le monde pour attraper des
          criminels, c’est illégal. Il faut cibler les gens qu’on surveille,
          dans l’espace et dans le temps, et motiver la surveillance&#160;:
          «&#160;Les dérogations à la protection des données à caractère
          personnel et les limitations de celle-ci doivent s’opérer dans les
          limites du strict nécessaire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>CJUE, arrêt du 8&#160;avril 2014&#160;: </Text>
                  <AppLink
                    white
                    href="https://curia.europa.eu/juris/document/document.jsf?text=&amp;docid=150642&amp;pageIndex=0&amp;doclang=fr&amp;mode=lst&amp;dir=&amp;occ=first&amp;part=1&amp;cid=147682"
                  >
                    https://curia.europa.eu/juris/document/document.jsf?text=&amp;docid=150642&amp;pageIndex=0&amp;doclang=fr&amp;mode=lst&amp;dir=&amp;occ=first&amp;part=1&amp;cid=147682
                  </AppLink>
                </>
              )
            }
          >
            234
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          À nos yeux, la collecte et la conservation des données de connexion de
          toute la population française pendant un an tombent, pour des raisons
          similaires, dans la même illégalité. Nous attaquons donc devant le
          Conseil d’État (d’une part) les décrets d’applications de la loi
          Renseignement, et (d’autre part) la conservation des données, sur la
          base du jugement de la CJUE. Et nous demandons au Conseil d’État de
          demander formellement à la CJUE si l’obligation française de
          conservation des données est conforme ou non au droit européen.
        </Text>
        <Text>
          Tout cela est très lent. Nous déposons nos recours en
          septembre&#160;2015. Le Conseil d’État les examine en audience en
          juillet&#160;2018
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Demain, la surveillance française devant le Conseil d’État
                    ! », 10&#160;juillet 2018&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2018/07/10/audience_renseignement/"
                  >
                    https://www.laquadrature.net/2018/07/10/audience_renseignement/
                  </AppLink>
                </>
              )
            }
          >
            235
          </InfoClick>
          , et renvoie enfin la question à la CJUE. L’audience devant la CJUE se
          tient les 9 et 10&#160;septembre 2019
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Recours contre le renseignement&#160;: compte rendu de
                    notre audience devant la Cour de justice de l’Union
                    européenne », 11&#160;octobre 2019&#160;:
                  </Text>
                  <br />
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/10/11/recours-contre-le-renseignement-compte-rendu-de-notre-audience-devant-la-cour-de-justice-de-lunion-europeenne/"
                  >
                    https://www.laquadrature.net/2019/10/11/recours-contre-le-renseignement-compte-rendu-de-notre-audience-devant-la-cour-de-justice-de-lunion-europeenne/
                  </AppLink>
                </>
              )
            }
          >
            236
          </InfoClick>
          . On fait le voyage pour accompagner et soutenir Alexis Fitzjean,
          avocat et membre de la Quadrature, qui défend brillamment notre
          recours devant les juges européens. Un grand moment collectif. On n’a
          jamais plaidé aussi haut, et après autant d’attente, sur une affaire
          aussi grosse&#160;: la Quadrature contre Premier ministre, garde des
          Sceaux, ministre de la Justice, ministre de l’Intérieur et ministre
          des Armées, ça n’arrive pas tous les jours. La cour rendra sa décision
          en octobre&#160;2020 –&#160;nous y reviendrons.
        </Text>

        <Text type="h3">
          Élaboration difficile de&#160;la&#160;loi&#160;Avia
        </Text>
        <Text>
          Mais revenons à la loi «&#160;contre la haine en ligne&#160;»&#160;!
          La proposition de loi est présentée le 20&#160;mars 2019 par Lætitia
          Avia. Elle est soutenue par un certain nombre d’associations d’aide
          aux victimes de discriminations (SOS Racisme, SOS Homophobie, Licra,
          etc.), qui souhaitent évidemment et à bon droit que le harcèlement en
          ligne soit réprimé, mais sans se poser les mêmes questions que nous, à
          la Quadrature.
        </Text>
        <Text>
          Nous faisions pourtant partie de la quinzaine d’associations qui ont
          été auditionnées par les rédacteurs et les rédactrices de la loi. On a
          dit ce qu’on sait, et ce qu’on n’a jamais cessé de dire&#160;: le
          danger de la censure automatisée, le danger de la censure qui
          contourne les juges et confie le pouvoir à la police et aux
          entreprises privées, le danger de la censure administrative et
          policière qui peut être instrumentalisée par le pouvoir en place pour
          exercer une censure politique, etc. On nous a ri au nez&#160;: il
          s’agit seulement de supprimer des propos antisémites, homophobes, et
          de protéger des enfants harcelés. Oui, répondons-nous, mais pour cela
          il existe déjà des instruments juridiques et policiers, des juges, des
          procédures, et il n’est pas nécessaire de ratisser le Web avec des
          robots censeurs confiés à l’appréciation de Facebook et de Google.
          Rien n’y fait, tout se retrouve tel quel dans la loi Avia.
        </Text>
        <Text>
          Pendant des mois, nous publions des articles et des tribunes pour
          dénoncer l’absurdité et les dangers de ces mesures. Nous dénonçons la
          possibilité d’une censure politique aux ordres du gouvernement en
          place&#160;: un risque réel alors que nous avons l’exemple d’une
          demande policière ridicule pour supprimer une caricature de Macron
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Une loi contre la haine anti-Macron ? », 9&#160;mai
                    2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/05/09/une-loi-contre-la-haine-anti-macron/"
                  >
                    https://www.laquadrature.net/2019/05/09/une-loi-contre-la-haine-anti-macron/
                  </AppLink>
                </>
              )
            }
          >
            237
          </InfoClick>
          , et qu’on se trouve en plein mouvement des «&#160;gilets
          jaunes&#160;» que le gouvernement et les chaînes d’information en
          continu assimilent volontiers à des terroristes. On rappelle les
          échecs des filtres des GAFAM&#160;: de l’aveu même de Facebook, la
          vidéo de la tuerie islamophobe de Christchurch, en Nouvelle-Zélande,
          le 15&#160;mars 2019, avait été partagée 1,5&#160;million de fois, et
          les robots filtreurs de Facebook en avaient laissé passer
          300&#160;000&#160;copies… Sous le régime de la loi Avia, il aurait
          quasiment fallu fermer Facebook dans les 24&#160;heures
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Analyse juridique de la loi “contre la haine en ligne” »,
                    17&#160;juin 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/06/17/analyse-juridique-de-la-loi-contre-la-haine-en-ligne/"
                  >
                    https://www.laquadrature.net/2019/06/17/analyse-juridique-de-la-loi-contre-la-haine-en-ligne/
                  </AppLink>
                </>
              )
            }
          >
            238
          </InfoClick>
          .
        </Text>
        <Text>
          On produit des analyses juridiques, adaptées après chaque vote, pour
          donner des arguments de travail aux parlementaires. On dit et on redit
          ce que tous les professionnels du secteur expliquent&#160;: les
          algorithmes de recommandation des plateformes (ceux de Facebook et
          YouTube en tête) conduisent les internautes de contenu polémique en
          contenu plus polémique encore, car l’économie publicitaire de ces
          entreprises exige que les gens restent, le plus longtemps possible,
          présents sur la page, captifs de la plateforme. Vouloir légiférer
          contre la haine en ligne sans prendre en compte la nocivité sociale de
          ce modèle de rentabilité exclusivement économique, c’est fournir un
          travail inutile.
        </Text>
        <Text>
          Dernière aberration&#160;: la loi Avia prévoit une autorité de
          contrôle, et plutôt que la CNIL ou un organisme ad hoc qui aurait
          l’intelligence et la connaissance de la réalité du Web, elle désigne
          le bon vieux Conseil supérieur de l’audiovisuel (CSA), rebaptisé pour
          l’occasion en Arcom&#160;: Autorité de régulation de la communication
          audiovisuelle et numérique. Pourquoi est-ce un problème&#160;? Parce
          que le CSA a l’habitude de parler avec des chaînes émettrices et
          centralisées
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La loi “haine” va transformer Internet en télévision », 1
                    <sup>er</sup>&#160;juillet 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/07/01/la-loi-haine-va-transformer-internet-en-tv/"
                  >
                    https://www.laquadrature.net/2019/07/01/la-loi-haine-va-transformer-internet-en-tv/
                  </AppLink>
                </>
              )
            }
          >
            239
          </InfoClick>
          . Aborder le Web sous cet angle, c’est forcément retomber sur les
          GAFAM et les plateformes géantes comme seuls interlocuteurs possibles
          pour «&#160;réguler le Web
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Sur ce sujet, voir l’article de Benjamin Bayart publié en
                    octobre&#160;2018, « Intermédiaires techniques&#160;: un
                    éléphant, ce n’est pas une souris en plus gros »&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2018/10/10/intermediaires-techniques-un-elephant-ce-nest-pas-une-souris-en-plus-gros/"
                  >
                    https://www.laquadrature.net/2018/10/10/intermediaires-techniques-un-elephant-ce-nest-pas-une-souris-en-plus-gros/
                  </AppLink>
                </>
              )
            }
          >
            240
          </InfoClick>
          &#160;». Or, contre la «&#160;mise en silo&#160;» et contre la
          centralisation du Web par des plateformes toxiques, nous voyons
          d’autres solutions que le renforcement du centralisme… La première de
          ces solutions s’appelle «&#160;l’interopérabilité&#160;».
        </Text>

        <Text type="h3">
          L’interopérabilité contre la&#160;mise en&#160;silo du&#160;Web
        </Text>
        <Text>
          Que reproche-t-on aux grandes plateformes comme Facebook ou
          YouTube&#160;? Principalement de monopoliser l’attention et le trafic
          du Web, au détriment de sa diversité et de sa résistance, en employant
          des moyens déloyaux et toxiques pour les personnes et pour les
          sociétés. Rien que ça. Les algorithmes de recommandations, conçus pour
          proposer les contenus à plus fort «&#160;engagement&#160;» émotionnel,
          c’est-à-dire concrètement les plus polémiques et les plus choquants,
          favorisent les discours politiques d’antagonisme plutôt que
          d’adhésion. Le&#160;pistage et&#160;le ciblage publicitaires enferment
          aussi les utilisateurs dans la confirmation en spirale de leur propre
          point de vue. Sur Instagram, les&#160;jeunes gens sont confrontés de
          manière obsessionnelle à des représentations irréalistes du corps et
          du bonheur matériel, et sont surexposés à&#160;la dépression et à la
          haine d’eux-mêmes. Voilà pour la toxicité sociale.
        </Text>
        <Text>
          Mais le Web lui-même pâtit de cette nouvelle centralisation. On n’y
          circule plus en suivant des liens, dans une errance d’affinités qui
          traverse la Toile et trace des randonnées, des escapades, des points
          de fuite et de découverte, mais en rayonnant toujours depuis le centre
          qu’est devenu le réseau social, dans un va-et-vient permanent entre
          cet unique foyer et de minuscules portions du reste du Web. La plupart
          des réseaux sociaux «&#160;encapsulent&#160;» même les sites liés dans
          leur propre interface, de telle sorte qu’on ne quitte parfois même
          plus la plateforme pour aller voir une vidéo ou lire un article de
          presse.
        </Text>
        <Text>
          Ce phénomène a «&#160;recentralisé&#160;» le Web, pourtant imaginé sur
          la logique inverse de la navigation. Les internautes que nous sommes
          se retrouvent «&#160;mis en silo&#160;» dans leur réseau social
          d’élection, et exploités par celui-ci comme une matière première qui
          sécrète des données de ciblage et ingurgite en retour de la publicité,
          avec tous les effets néfastes dont on a déjà parlé. Les plateformes
          ont su, avec des inventions de design et des fonctions astucieuses,
          préempter la culture communautaire du Web, et, renonçant peu à peu aux
          standards techniques qui permettaient de communiquer d’une plateforme
          à l’autre, ont enfermé leurs utilisateurs
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Une idée développée dans notre article « L’interopérabilité
                    contre la haine », 12&#160;juin 2019&#160;:
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/06/12/interoperabilite-contre-haine/"
                  >
                    https://www.laquadrature.net/2019/06/12/interoperabilite-contre-haine/
                  </AppLink>
                </>
              )
            }
          >
            241
          </InfoClick>
          . Il est très difficile de quitter une plateforme sans perdre le
          contact avec les personnes rencontrées, souvent sous pseudonyme, par
          l’intermédiaire de la plateforme. C’est un renoncement qui coûte, et
          qui retient les utilisateurs.
        </Text>
        <Text>
          Comment abattre ces murs&#160;? Comment ouvrir ces enclos&#160;? En
          obligeant les grandes plateformes à l’interopérabilité. Il s’agit
          d’une obligation technique&#160;: les contenus publiés sur la
          plateforme A doivent être lisibles sur la plateforme B. Comment une
          telle chose est-elle possible&#160;? Il faut que les deux plateformes
          utilisent les mêmes standards techniques de publication, et que leurs
          API
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Une interface de programmation d’information, ou API, est un
                  logiciel dont le rôle consiste à traduire des instructions
                  entre deux interlocuteurs&#160;: entre un matériel et un
                  logiciel, par exemple, ou entre deux logiciels. Les
                  plateformes peuvent s’entendre sur une API qui définit des
                  fonctions communes, ou définir un standard commun dont chaque
                  nouvel opérateur pourra s’emparer pour dialoguer avec tous les
                  autres.
                </Text>
              )
            }
          >
            242
          </InfoClick>{" "}
          soient ouvertes. La chose n’a rien d’extraordinaire&#160;: si un
          utilisateur de Gmail peut envoyer un e-mail à un utilisateur de
          Protonmail, c’est bien parce que les deux services utilisent un
          protocole unifié.
        </Text>
        <Text>
          Non seulement la chose est possible, mais elle existe déjà. Le
          standard ActivityPub, par exemple, permet depuis 2018 de construire
          des réseaux sociaux interopérables, parce que tout le monde utilise
          des critères communs pour mettre en forme les informations
          publiées&#160;: si votre voisin diffuse ses données sous la même forme
          que vous, vous pouvez très facilement les publier dans votre
          interface. Des services distincts, des «&#160;instances&#160;»,
          peuvent donc coexister et entrer en relation sans que l’un dévore
          l’autre, ou se renferme sur lui-même (et ses utilisateurs avec lui).
          Par exemple, le réseau Mastodon, jumeau de Twitter dans son apparence
          et dans ses fonctions, est fondé sur ActivityPub, et fonctionne par
          «&#160;instances&#160;»&#160;: chaque service peut choisir de se
          «&#160;fédérer&#160;» ou non avec d’autres instances pour échanger des
          messages et mettre ses utilisateurs en relation. Si Twitter devenait
          interopérable et adoptait aussi ActivityPub, les utilisateurs de
          Mastodon pourraient échanger avec eux.
        </Text>
        <Text>
          Cette logique de la construction de nouveaux univers par fédération
          d’instances est très forte, on parle même de «&#160;fédivers&#160;»
          (ou <i>fediverse</i> en anglais). La rupture ou l’adhésion entre les
          instances n’est plus une chose imposée, mais un choix électif et
          politique&#160;: une instance peut à tout moment rompre le lien
          d’échange avec une autre, si elle considère par exemple que ses
          membres ont un comportement hostile.
        </Text>
        <Text>
          Le reproche qui nous est souvent fait, quand nous faisons la promotion
          de ces scénarios d’interopérabilité, c’est de favoriser «&#160;le
          communautarisme&#160;». Laissons de côté les insinuations misérables
          «&#160;d’islamo-gauchisme&#160;», ou de tout ce que le mot
          «&#160;communautarisme&#160;» peut recouvrir comme non-dit xénophobe,
          et intéressons-nous sérieusement au problème.
        </Text>
        <Text>
          Oui, c’est vrai, une instance aura tendance à se former et à se
          regrouper autour d’un intérêt commun, au détriment des autres. Mais
          cela ne suffit pas à définir un «&#160;communautarisme&#160;»
          quelconque, ou alors toutes les associations de pétanque et de
          philatélie sont «&#160;communautaristes&#160;». Le choix de couper les
          liens avec une autre instance signifie-t-il un refus du dialogue, de
          l’échange, un péril pour la démocratie et une atteinte à la liberté
          d’expression&#160;? Là encore, il faut arrêter de se bercer de mots.
          La liberté d’expression est un droit garanti par l’État, qui s’engage
          à ne pas empêcher l’expression des idées, mis à part quelques
          exceptions bien connues d’incitations délibérées à la haine (racisme,
          homophobie, etc.). Mais la liberté d’expression n’a jamais été, et
          n’est en aucun cas une obligation de s’infliger la fréquentation de
          personnes désagréables ou d’écouter leurs discours, et encore moins de
          subir leur harcèlement.
        </Text>
        <Text>
          Sur Twitter, par exemple, tout le monde se croise dans le même espace
          –&#160;homosexuels et homophobes, racistes et personnes racisées, etc.
          –&#160;et les «&#160;raids&#160;» des uns chez les autres sont des
          phénomènes quotidiens, dans une ambiance d’hostilité et de surenchère
          permanentes dont on a déjà vu qu’elles sont la fatalité logique et le
          carburant internes des plateformes unifiées. Si les uns et les autres
          discutent sur des instances distinctes, la fréquentation n’est plus
          imposée. Et si une instance a une attitude hostile envers une autre,
          elle peut être «&#160;bloquée&#160;». À l’inverse, rien n’empêche une
          personne d’en suivre une autre sur une autre instance, même non
          fédérée, à condition qu’elle ne soit pas «&#160;bloquée&#160;». En
          d’autres termes&#160;: chacun choisit avec qui il veut parler, et ne
          subit plus, ni une audience infinie et potentiellement hostile, ni
          l’arbitraire algorithmique des recommandations et des contenus
          imposés.
        </Text>
        <Text>
          Au moment de la préparation de la loi Avia, lors de notre audition,
          nous avons parlé de l’interopérabilité pour casser les logiques de
          harcèlement en ligne. Mme&#160;Avia l’a refusée, en évoquant une
          solution lâche. Elle tenait beaucoup à l’image d’une cour
          d’école&#160;: si un enfant est harcelé pendant la récréation, est-il
          responsable de conseiller aux parents de changer d’école&#160;? Ce à
          quoi nous répondons&#160;: est-il responsable de laisser l’enfant dans
          une école qui organise et couvre le harcèlement scolaire&#160;?
        </Text>
        <Text>
          Il est possible d’ouvrir les plateformes, et il est temps de le faire.
          C’est une idée que nous poussons autant que possible partout où l’on
          nous consulte, en rédigeant des amendements en ce sens chaque fois que
          les lois le permettent. Nous avons d’ailleurs réuni plus de
          soixante-quinze&#160;associations et structures du logiciel libre
          autour d’une lettre ouverte pour promouvoir l’interopérabilité
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2019/05/21/pour-linteroperabilite-des-geants-du-web-lettre-commune-de-45-organisations/"
                >
                  https://www.laquadrature.net/2019/05/21/pour-linteroperabilite-des-geants-du-web-lettre-commune-de-45-organisations/
                </AppLink>
              )
            }
          >
            243
          </InfoClick>
          .
        </Text>
        <Text>
          L’idée a fini par être discutée au niveau européen
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2020/09/08/lunion-europeenne-doit-imposer-linteroperabilite-aux-geants-du-web/"
                >
                  https://www.laquadrature.net/2020/09/08/lunion-europeenne-doit-imposer-linteroperabilite-aux-geants-du-web/
                </AppLink>
              )
            }
          >
            244
          </InfoClick>{" "}
          en 2022, dans le <i>Digital Services Act</i> (DSA) et le{" "}
          <i>Digital Markets Act</i> (DMA). La Commission et le Parlement ont
          introduit une obligation d’interopérabilité pour les réseaux sociaux
          et les messageries interpersonnelles. Mais pour Cédric O, secrétaire
          d’État français au numérique, ce choix est <i>«&#160;</i>excessivement
          agressif pour le modèle économique des grandes plateformes&#160;». Au
          premier semestre 2022, profitant de la présidence française du Conseil
          de l’Union européenne, la France s’oppose alors à toute obligation, et
          la négociation aboutit à l’abandon de l’interopérabilité pour les
          réseaux sociaux et réduit à presque rien l’obligation faite aux
          messageries. Deux pas en avant, un pas et demi en arrière.
        </Text>

        <Text type="h3">
          Aggravation et&#160;censure de&#160;la&#160;loi&#160;Avia
        </Text>
        <Text>
          En France, le texte de la loi Avia évolue au fil des discussions dans
          les deux assemblées, par le jeu des amendements. Mais de notre point
          de vue, c’est pour aller toujours vers le pire. Et le jeu
          parlementaire de la navette entre l’Assemblée nationale (acquise par
          définition à la majorité gouvernementale) et le Sénat (dominé par
          l’opposition de droite) ne nous épargne pas en matière de
          retournements et de coups de théâtre. Le 11&#160;décembre 2019
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Première victoire contre la loi “haine” »,
                    11&#160;décembre 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/12/11/premiere-victoire-contre-la-loi-haine/"
                  >
                    https://www.laquadrature.net/2019/12/11/premiere-victoire-contre-la-loi-haine/
                  </AppLink>
                </>
              )
            }
          >
            245
          </InfoClick>
          , le Sénat supprime dans l’article 1<sup>er</sup> l’obligation de
          retrait des contenus signalés en 24&#160;heures. Le 18&#160;décembre,
          le même Sénat réintroduit cette mesure dans l’article&#160;2
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Loi “haine”&#160;: la trahison du Sénat »,
                    18&#160;décembre 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/12/18/loi-haine-la-trahison-du-senat/"
                  >
                    https://www.laquadrature.net/2019/12/18/loi-haine-la-trahison-du-senat/
                  </AppLink>
                </>
              )
            }
          >
            246
          </InfoClick>
          … En janvier, on titre même un de nos articles avec les mots
          «&#160;coup d’État
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Coup d’État sur la loi “haine” », 22&#160;janvier
                    2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/01/22/coup-detat-sur-la-loi-haine/"
                  >
                    https://www.laquadrature.net/2020/01/22/coup-detat-sur-la-loi-haine/
                  </AppLink>
                </>
              )
            }
          >
            247
          </InfoClick>
          &#160;». Le gouvernement vient d’introduire de force dans le projet de
          loi «&#160;contre la haine&#160;» la pire mesure du règlement Terro
          qu’il soutient par ailleurs sur le plan européen, c’est-à-dire le
          retrait en une&#160;heure des propos faisant l’apologie du terrorisme.
          On est si loin de l’intention première du texte (la rapporteure nous
          parlait des propos homophobes et du cyberharcèlement en milieu
          scolaire&#160;!) que les bras nous en tombent. On peut parler de
          «&#160;cavalier législatif&#160;»
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Un cavalier législatif est un article de loi qui introduit des
                  dispositions qui n’ont rien à voir avec le sujet traité par le
                  projet de loi.
                </Text>
              )
            }
          >
            248
          </InfoClick>
          .
        </Text>
        <Text>
          Au bout du compte, le texte adopté en mai&#160;2020 est à nos yeux
          entièrement mauvais
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Vote final de la loi “haine” », 11&#160;mai 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/05/11/vote-final-de-la-loi-haine/"
                  >
                    https://www.laquadrature.net/2020/05/11/vote-final-de-la-loi-haine/
                  </AppLink>
                </>
              )
            }
          >
            249
          </InfoClick>
          . Des sénateurs ayant demandé l’intervention du Conseil
          constitutionnel, nous ajoutons une contribution écrite avec nos amis
          de <a href="http://Franciliens.net">Franciliens.net</a> (fournisseur
          d’accès associatif
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Site Web de l’association&#160;: </Text>
                  <AppLink white href="https://www.franciliens.net/">
                    https://www.franciliens.net/
                  </AppLink>
                </>
              )
            }
          >
            250
          </InfoClick>
          ), concernés au premier chef par les mesures de censure express. En
          juin&#160;2020, les sages censurent la quasi-intégralité de la loi
          Avia, vue comme une atteinte majeure à la liberté d’expression. Nous
          ne cachons pas notre joie. Ce n’est pas faute d’avoir largement
          prévenu les législateurs…
        </Text>

        <Text type="h3">Le&#160;fisc surveille les&#160;réseaux sociaux</Text>
        <Text>
          C’est acquis, le Web est devenu pour les pouvoirs publics un lieu à
          surveiller, mais aussi un moyen de surveillance. La fin de l’année
          2019 en offre une illustration nouvelle, avec un article étonnant de
          la loi de finance 2020 –&#160;votée chaque année pour définir les
          ressources et les dépenses de l’État durant l’année à venir&#160;– qui
          autorise les services du fisc
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>Pour trois&#160;ans, à titre d’expérimentation.</Text>
              )
            }
          >
            251
          </InfoClick>{" "}
          à analyser les informations postées par les contribuables sur
          les&#160;réseaux sociaux, dans le but de découvrir des incohérences
          entre un niveau de revenus déclarés et un train de vie affiché…
        </Text>
        <Text>
          Avez-vous déclaré la construction de la piscine au bord de laquelle
          vous vous affichez en ligne en maillot de bain&#160;? De façon plus
          curieuse, les informations publiées sur les réseaux sociaux et sur les
          sites de vente en ligne seraient collectées et analysées par des
          algorithmes de traitement en masse. Des algorithmes capables
          d’identifier une vie de nabab&#160;? Non bien sûr, mais capables de
          reconnaître la vente de cigarettes de contrebande ou de vêtements de
          contrefaçon, par exemple, qui intéressent beaucoup le fisc et les
          douanes
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Le Parlement doit rejeter le flicage fiscal des réseaux
                    sociaux », 5&#160;novembre 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/11/05/le-parlement-doit-rejeter-le-flicage-fiscal-des-reseaux-sociaux/"
                  >
                    https://www.laquadrature.net/2019/11/05/le-parlement-doit-rejeter-le-flicage-fiscal-des-reseaux-sociaux/
                  </AppLink>
                </>
              )
            }
          >
            252
          </InfoClick>
          . Le législateur évoque aussi la possibilité d’identifier, grâce à la
          reconnaissance des paysages et des décors urbains, le véritable lieu
          d’habitation d’une personne qui aurait déclaré sa résidence principale
          à l’étranger pour des raisons fiscales.
        </Text>
        <Text>
          Bref, vos photos de restaurant en terrasse postées sur Instagram
          intéressent les services des impôts
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    On reproche à un outil similaire utilisé aux Pays-Bas
                    d’avoir été détourné de son usage premier pour discriminer
                    les populations les plus pauvres&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.letemps.ch/monde/paysbas-batissent-un-surveillance-pauvres"
                  >
                    https://www.letemps.ch/monde/paysbas-batissent-un-surveillance-pauvres
                  </AppLink>
                </>
              )
            }
          >
            253
          </InfoClick>
          . Nous déposons un recours devant le Conseil constitutionnel, mais
          peine perdue&#160;: l’article de loi est validé. L’épisode confirme
          donc que la surveillance des citoyens par l’intermédiaire des outils
          numériques est une source inépuisable d’inspiration pour les
          gouvernements, et semble ne pas poser plus de problème aux yeux du
          législateur.
        </Text>
        <Text>
          Cette fin d’année 2019 et le commencement de 2020 sont d’autant plus
          difficiles à la Quadrature que les mauvaises nouvelles s’accumulent.
          D’abord, la «&#160;directive Copyright&#160;» est adoptée par le
          Parlement européen en mars&#160;2019, alors que nous sommes accaparés
          par le travail sur le règlement Terro. Première défaite. Les articles
          les plus mauvais sont adoptés, et les défenseurs du partage sont
          amers. Par ailleurs, le règlement Terro est adopté une première fois
          par le Parlement européen pendant l’été 2019, avec toutes les
          dispositions que nous combattions. Deuxième défaite. C’est dur.
        </Text>
        <Text>
          Ce double échec est très mal reçu par bon nombre de nos soutiens
          «&#160;historiques&#160;», de l’époque de la lutte contre la loi
          Hadopi, qui le prennent comme une trahison de leurs combats anciens.
          S’opposer à l’envahissement sécuritaire n’est visiblement pas aussi
          essentiel à leurs yeux, ou les touche moins directement. En tout cas,
          la période est délicate pour le groupe&#160;: engueulades avec les
          amis, coups bas sur les réseaux sociaux, départs de l’association. On
          lance la campagne contre la technopolice avec le doute au cœur, et
          dans une ambiance de grande solitude face à la montée d’un monde
          sécuritaire auquel le numérique collabore de toute sa puissance.
        </Text>
        <Text>
          Pendant ce temps, en Chine, une pneumopathie fulgurante touche
          plusieurs personnes dans la ville de Wuhan. Et déjà l’année 2020 nous
          fonce dessus à toute vitesse.
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter3Part4;
