import React from "react";
import cx from "classnames";

import Styles from "./IndexNavbar.module.scss";

import { useBook } from "src/hooks";
import IndexNavbarItem from "../IndexNavbarItem/IndexNavbarItem";

const IndexNavbar = ({
  small,
  onClickItem,
}: {
  small?: boolean;
  onClickItem?: () => void;
}) => {
  const { currentLocation, book } = useBook();

  return (
    <div className={cx(Styles.IndexContainer, small && Styles.Small)}>
      <div className={Styles.Index}>
        {book.map((index) => (
          <IndexNavbarItem
            key={index.name}
            text={index}
            currentLocation={currentLocation === index.name}
            onClick={onClickItem}
          />
        ))}
      </div>
    </div>
  );
};

export default IndexNavbar;
