import React, { HTMLProps } from "react";
import cx from "classnames";

import Styles from "./Text.module.scss";

export type TextType = "h1" | "h2" | "h3" | "h4" | "body" | "subtitle";
interface TextProps
  extends HTMLProps<HTMLParagraphElement | HTMLHeadingElement> {
  children?: React.ReactNode;
  type?: TextType;
  className?: string;
}

export const Text = ({
  children,
  type = "body",
  className,
  ...props
}: TextProps) => {
  const style = cx(Styles[type], className);
  switch (type) {
    case "h1":
      return (
        <h1 className={style} {...props}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={style} {...props}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 className={style} {...props}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 className={style} {...props}>
          {children}
        </h4>
      );
    default:
      return (
        <p className={style} {...props}>
          {children}
        </p>
      );
  }
};
