import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter1Part1 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>();

  return (
    <PagePattern partName="Aux origines de la lutte">
      <>
        <Text>
          La technique et l’informatique sont fortement liées aux questions
          sociales. Augmentation de la productivité, déplacement ou confirmation
          de pouvoir, gains financiers… : elles ont entraîné dans l’histoire
          nombre de bouleversements fondamentaux, avec leurs lots de résistances
          et d’opposants. Dès 1811, déjà, en pleine révolution industrielle,
          alors que la production textile se mécanisait en Angleterre, les
          artisans tondeurs et tricoteurs, dits luddites, se révoltaient contre
          les manufacturiers en organisant la destruction délibérée des
          machines. Plus proche de nous, en France, au début des années 1980, le
          Comité pour la liquidation ou la destruction des ordinateurs (CLODO)
          s’attaquait aux outils informatiques que ses membres, anonymes,
          considéraient comme des instruments de répression et de contrôle, en
          incendiant des bureaux d’entreprises. En Allemagne, le Chaos Computer
          Club (ou CCC), rassemblement de hackers, parvint à politiser, dès
          1984, les sujets numériques (vie privée, cybersécurité…) auprès du
          gouvernement. Quelle que soit leur virulence, ces mouvements sont
          toujours issus des rangs des créateurs et des premiers utilisateurs
          des outils concernés, qui cherchent naturellement à en influencer
          l’usage.
        </Text>
        <Text>
          Le développement continu des technologies impose une adaptation
          régulière des méthodes et des outils utilisés par leurs opposants. Si
          les premiers groupes évoqués ci-dessus risquaient la peine de mort, la
          société reconnaît désormais la légitimité d’un certain niveau de
          contestation. Il faut dire que la numérisation du monde a transformé
          la nature de leur combat : que détruire, lorsque les machines peuvent
          se trouver n’importe où sur la planète, et que leur contenu est
          reproduit à l’infini, rendant la suppression d’une donnée pratiquement
          impossible ?
        </Text>
        <Text>
          Le CCC, par exemple, s’est constitué alors qu’Internet était encore
          confidentiel, aux mains d’une minorité de personnes et d’organisations
          (entreprises, États…). Dix ans après naissait le Web, bientôt
          démocratisé et popularisé grâce à l’ADSL et à sa connexion constante,
          aux forfaits sans limite de consommation de données, et aux
          smartphones qui actent le passage à la mobilité. Constitution de
          monopoles géants, numérisation des services publics… : le contexte a
          évolué à vitesse grand V, tout comme les risques dus au numérique dans
          son ensemble. Aujourd’hui, ce ne sont plus seulement les travailleurs
          et les spécialistes qui sont touchés, mais toute la société.
        </Text>
        <Text type="h3">Internet : les premiers pas</Text>
        <Text>
          Quand, à la fin des années 1990, Internet commence à percer aux dépens
          du Minitel, de nouvelles questions se posent : extraterritorialité,
          responsabilité juridique des acteurs techniques, service public de
          l’accès au réseau, origine et choix de l’investissement pour cet
          accès…
        </Text>
        <Text>
          Ces réflexions quant au « terrain politique » d’Internet rappellent
          celles qui, vingt ans plus tôt, ont émergé au lancement des radios
          libres : quel droit de diffusion ? Quels droits d’auteur ? Quid de
          l’anonymat ? Comment transformer un outil d’émancipation en un système
          majoritairement commercial ?… Dans les années 1970, de nombreuses
          personnes testèrent cet espace de liberté radiophonique inédit, avant
          de rapidement déchanter lorsque le financement publicitaire fit
          irruption.
        </Text>
        <Text>
          Lancé en 1980 par les PTT, service public, le Minitel proposait des
          services relativement contrôlés. Leur mise à disposition était soumise
          à autorisation ministérielle, le paiement pour y accéder apparaissait
          directement sur la facture téléphonique. France Télécom y prélevait sa
          part, et toutes les communications passaient par son réseau.
        </Text>
        <Text>
          Malgré toutes ces contraintes, c’est à partir d’un Minitel que Laurent
          Chemla, entrepreneur, pirata en 1986 le serveur de Café Grand-Mère, et
          se retrouva inculpé pour « vol d’énergie » – le délit de piratage
          informatique n’existant pas encore
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <AppLink
                    white
                    href="https://www.cairn.info/revue-mouvements-2014-3-page-122.htm"
                  >
                    https://www.cairn.info/revue-mouvements-2014-3-page-122.htm
                  </AppLink>
                  <br />
                  <AppLink
                    white
                    href=" http://www.confessions-voleur.net/confessions/node1.html"
                  >
                    http://www.confessions-voleur.net/confessions/node1.html{" "}
                  </AppLink>
                </>
              )
            }
          >
            1
          </InfoClick>
          . De ce premier contact avec le droit naissent les premières
          réflexions sur la manière dont ce dernier s’articule avec les réseaux
          et l’informatique. Laurent Chemla participe d’ailleurs à la création
          de l’Association des utilisateurs d’Internet (AUI), fin 1995
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  À ses côtés, on retrouve des noms connus du logiciel libre :
                  Meryem Marzouki, Stéphane Bortzmeyer…
                </Text>
              )
            }
          >
            2
          </InfoClick>
          . Ses objectifs, alors que la majorité de la population n’a pas encore
          entendu parler de la Toile, sont déjà de défendre et de chercher à
          obtenir des libertés sur Internet.
        </Text>
        <Text>
          Fin 1995, le gouvernement Juppé tente de faire passer une réforme des
          retraites supprimant les régimes spéciaux. Les conséquences sociales
          ne se font pas attendre : une grève géante est déclenchée dans tout le
          pays. Pendant un mois, l’Île-de-France est totalement bloquée par
          l’absence de trains et de métros. La Poste suit largement le
          mouvement. À cette époque, toute la communication passe par le
          courrier. Les postiers manquent ainsi, en ne le distribuant plus,
          d’étouffer leur propre mouvement contestataire. Internet est déjà
          disponible, mais très peu connu du grand public. Les militants se
          voient donc contraints d’apprendre à échanger des e-mails, à créer et
          à animer des forums, et même à utiliser les
          <i> newsgroups</i>, des forums de discussion spécialisés organisés
          hiérarchiquement.
        </Text>
        <Text>
          Au beau milieu des années 1990, celles et ceux qui utilisent Internet
          l’imaginent comme un espace de libertés tel qu’il n’en a jamais existé
          avant. Un espace dans lequel, pour la première fois, les individus
          peuvent vraiment jouir d’une réelle liberté d’expression. L’imprimerie
          a permis au peuple de lire, Internet va-t-il lui permettre d’écrire ?
          Quoi qu’il en soit, le monde politique et les lobbies de la propriété
          intellectuelle voient d’un très mauvais œil l’accès toujours plus
          étendu du grand public à ce réseau de communication sur lequel ils
          n’ont que très peu de contrôle…
        </Text>
        <Text type="h3">Quand la législation s’en mêle</Text>
        <Text>
          En 1996 est voté aux États-Unis le <i>Telecommunications Act</i> (la
          loi sur les télécommunications). Parallèlement, un traité sur le droit
          d’auteurdans l’environnement numérique est signé par plusieurs
          dizaines de pays sous l’égide de l’Organisation mondiale de la
          propriété intellectuelle (OMPI), organisme des Nations unies
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Le WCT, qui compte aujourd’hui 50 signataires :</Text>
                  <br />
                  <AppLink
                    white
                    href="https://www.wipo.int/treaties/fr/ip/wct/summary_wct.html"
                  >
                    https://www.wipo.int/treaties/fr/ip/wct/summary_wct.html
                  </AppLink>
                </>
              )
            }
          >
            3
          </InfoClick>
          .
        </Text>
        <Text>
          Le premier provoque une vive réaction parmi les intellectuels
          américains, à l’instar de John Perry Barlow, parolier de l’influent
          groupe de rock américain Grateful Dead et cofondateur de l’Electronic
          Frontier Foundation, qui rédige sa « Déclaration d’indépendance du
          cyberespace »
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.eff.org/fr/cyberspace-independence"
                >
                  https://www.eff.org/fr/cyberspace-independence
                </AppLink>
              )
            }
          >
            4
          </InfoClick>
          , au forum de Davos. Ce texte lyrique, épique, témoigne de l’utopie,
          du rêve qu’ont les geeks de l’époque d’un Internet totalement
          autonome. Derrière cette première pierre, la volonté est d’affirmer
          que les gouvernements ne peuvent prétendre à s’approprier la Toile.
        </Text>
        <Text>
          Le second traité, plus technique, entraîne quant à lui la levée de
          boucliers de la Free Software Foundation (FSF), une organisation
          américaine promouvant les logiciels libres, créée par Richard
          Stallman. Elle se dresse contre ce qui deviendra en 1998 le
          <i> Digital Millennium Copyright Act</i> (DMCA). Ce texte – dont on
          entend encore parler aujourd’hui à travers les fameuses «requêtes DMCA
          », que reçoivent quotidiennement des hébergeurs de contenus pour leur
          demander de supprimer ceux considérés comme violant la propriété
          intellectuelle – vise notamment à entériner et à protéger les{" "}
          <i>Digital Rights Management </i>(DRM). Les DRM sont des outils de
          contrôle du contenu. Ils limitent l’usage des œuvres légalement
          acquises par les acheteurs selon le bon vouloir de leur propriétaire.
          Imaginez un livre qui disparaît définitivement de votre étagère une
          fois qu’il a été lu trois fois ! Dans le droit européen, ces traités
          seront transcrits au sein de la directive EUCD 2001/29/CE.
        </Text>
        <Text>
          Mais revenons en France. En 1996, toujours sous le gouvernement Juppé,
          on débat à l’Assemblée nationale de la loi de réglementation des
          télécommunications. L’amendement Fillon est présenté. Alors ministre
          délégué à la Poste, aux Télécommunications et à l’Espace, il promet de
          prendre en compte les avis des professionnels du secteur du numérique,
          et demande à son cabinet de commander un rapport. Dans le cadre de sa
          rédaction est lancée la première consultation du public en ligne
          d’initiative gouvernementale en France. Elle est organisée par
          l’Internet Society France, branche française tout juste créée d’une
          association internationale visant à promouvoir le réseau Internet. Une
          belle idée en théorie, mais un coup d’épée dans l’eau, car en réalité
          l’amendement Fillon est déposé le jour même de l’ouverture de la
          fameuse consultation !
        </Text>
        <Text>
          François Fillon souhaite, avec ce texte, transférer la responsabilité
          des contenus disponibles sur Internet au Comité supérieur de la
          télématique, émanation du Conseil supérieur de l’audiovisuel (CSA). Ce
          comité aurait pour mission de rendre des « recommandations » sur les
          services accessibles via un fournisseur d’accès à Internet (dit FAI,
          un <i>access provider</i>, selon les termes de François Fillon). Les
          FAI deviendraient donc pénalement responsables de la fourniture d’un
          service non validé. La responsabilité des contenus leur serait alors
          transférée et ils se retrouveraient de fait à censurer Internet pour
          ne livrer qu’une série de services autorisés par l’administration. La
          tentative de transformation d’Internet en réseau minitel de seconde
          génération fait rire les connaisseurs du réseau. Malgré les tentatives
          de François Fillon, qui va jusqu’à défendre son amendement sur… Usenet
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://groups.google.com/g/fr.soc.divers/c/GKmAu8w1M9w?pli=1"
                >
                  https://groups.google.com/g/fr.soc.divers/c/GKmAu8w1M9w?pli=1
                </AppLink>
              )
            }
          >
            5
          </InfoClick>
          , un réseau de forums populaire à l’époque parmi les utilisateurs, le
          Conseil constitutionnel sonne la fin de la récréation et censure les
          dispositions imposant aux FAI une responsabilité sur les contenus
          circulant sur leur réseau
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <AppLink
                    white
                    href="https://ecosphere.wordpress.com/2017/01/08/retour-sur-lamendement-fillon-de-1996/"
                  >
                    https://ecosphere.wordpress.com/2017/01/08/retour-sur-lamendement-fillon-de-1996/
                  </AppLink>
                  <br />
                  <AppLink
                    white
                    href="https://www.conseil-constitutionnel.fr/decision/1996/96378DC.htm"
                  >
                    https://www.conseil-constitutionnel.fr/decision/1996/96378DC.htm
                  </AppLink>
                </>
              )
            }
          >
            6
          </InfoClick>
          .
        </Text>
        <Text>
          Aux débuts d’Internet, les FAI ne sont pas les seuls dans le viseur
          des autorités. L’« affaire Estelle Hallyday », en 1998, lance les
          hostilités sur le rôle des hébergeurs. Des photos dénudées de la
          mannequin, issues de la presse people, sont scannées puis déposées sur
          un site Web qui se trouve sur les serveurs de l’hébergeur (gratuit)
          AlternB. Une plainte en référé est alors déposée contre ce dernier,
          qui est finalement condamné à verser des dommages et intérêts à la
          plaignante, pour de sulfureux clichés publiés sur… un des 45 000 sites
          environ hébergés sur ses serveurs.
        </Text>
        <Text>
          Imaginez que La Poste soit tenue pour responsable du contenu des
          lettres et des colis qu’elle transporte. Elle se verrait obligée de
          les ouvrir tous pour prendre la décision de les transporter ou non,
          et, bien sûr, elle en laisserait une bonne partie sur le carreau!
          Certains contenus (la pédopornographie, par exemple) seraient faciles
          à détecter, mais pour tout un tas d’autres (« Cette poudre, est-ce de
          la farine ou de l’anthrax ? », « Cette arme est-elle factice ? »), ce
          serait bien plus compliqué. De la même manière, rendre responsables
          les hébergeurs des contenus déposés sur leurs serveurs revient à
          donner à des opérateurs privés un rôle d’enquêteurs et de justiciers.
        </Text>
        <Text>
          La condamnation de Valentin Lacambre, président d’AlternB, ouvre une
          brèche importante permettant à n’importe qui d’attaquer un hébergeur
          pour les données qu’il stocke. Très médiatisée, elle instaure un
          précédent juridique de censure, et politise toute une génération qui
          comprend que l’ignorance technique du pouvoir judiciaire met en danger
          ce nouvel outil prometteur qu’est Internet.
        </Text>
        <Text type="h3">
          Droits d’auteur <i>vs</i> partage
        </Text>
        <Text>
          Les ingénieurs travaillant sur la technologie Internet ne sont pas les
          seuls à être confrontés aux décisions politiques qui réglementent leur
          domaine de compétence contre leurs intérêts.
        </Text>
        <Text>
          En mai 2004, ce sont les développeurs de logiciels qui sont à leur
          tour menacés. Le Conseil européen (qui réunit les chefs d’États et de
          gouvernements des États membres) vote un texte en contradiction avec
          les positions habituelles du Parlement européen sur les brevets
          logiciels. Il autorise les entreprises à protéger des morceaux de code
          informatique. Les partisans de cette mesure affirment qu’elle poussera
          à l’innovation : une personne pouvant protéger sa création prendra
          selon eux plus de risques que si tout le monde peut la copier. Ses
          opposants prévoient au contraire que les grandes entreprises, qui
          disposent de plus gros moyens, bloqueront l’innovation, en déposant
          des brevets qui empêcheront tout nouvel entrant de pénétrer le marché
          du développement informatique.
        </Text>
        <Text>
          Cette attaque contre le métier de développeur mobilise toute une
          communauté qui n’a pourtant pas de culture syndicale
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="http://www.journaldunet.com/solutions/0702/070202-enquete-syndicalisme-informatique/2.shtml"
                >
                  http://www.journaldunet.com/solutions/0702/070202-enquete-syndicalisme-informatique/2.shtml
                </AppLink>
              )
            }
          >
            7
          </InfoClick>
          . Des relations se nouent, à travers les forums, les événements, les
          mailing lists, les plus motivés analysent les textes de loi, imaginent
          des solutions, réfléchissent à des actions…
        </Text>
        <Text>
          En 2004, c’est la Fédération Informatique et Libertés, rassemblant des
          associations (telles Globenet et Acrimed), des ONG (Privacy
          International) et des particuliers engagés dans la défense de la vie
          privée et de la liberté d’expression, qui monte au front et s’élève
          contre la loi pour la confiance dans l’économie numérique, abrégée en
          LCEN
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="http://www.journaldunet.com/solutions/0702/070202-enquete-syndicalisme-informatique/2.shtml"
                >
                  http://www.journaldunet.com/solutions/0702/070202-enquete-syndicalisme-informatique/2.shtml
                </AppLink>
              )
            }
          >
            8
          </InfoClick>
          .
        </Text>
        <Text>
          Les mesures s’enchaînent en France et, à la rentrée 2005, est présenté
          le projet de loi sur le droit d’auteur et les droits voisins dans la
          société de l’information, surnommé DADVSI, visant à renforcer la lutte
          contre la contrefaçon sur Internet. Il est la transposition de la
          directive européenne EUCD 2001/29/CE « sur l’harmonisation de certains
          aspects du droit d’auteur et des droits voisins dans la société de
          l’information ». Il en reprend les grandes lignes, soit: la mise en
          place de dispositifs de lutte contre le partage d’œuvres sur Internet
          et l’interdiction de diffusion de logiciels permettant de casser les
          dispositifs techniques de protection du droit d’auteur (les DRM).
        </Text>
        <Text>
          Ces deux propositions vont à l’encontre de l’idée que l’on se fait à
          l’époque de l’usage d’Internet : une ouverture sur le monde, des
          échanges, des découvertes, un accès illimité à la connaissance et à la
          culture – comme en témoigne la mobilisation des artistes en faveur de
          la « licence globale
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  Pour répondre à la crainte du « vol de musique », une alliance
                  « Public-artistes » est lancée par des sociétés de gestion des
                  droits d’auteur et des associations de consommateurs.
                  Musiciens, photographes, dessinateurs, producteurs, ou encore
                  plasticiens se mobilisent en faveur d’une autorisation d’accès
                  aux contenus culturels en ligne et d’échanges de ces contenus
                  à des fins non commerciales, en contrepartie d’une
                  rémunération versée aux artistes à l’occasion du paiement de
                  l’abonnement Internet.
                </Text>
              )
            }
          >
            9
          </InfoClick>
          ».
        </Text>
        <Text>
          Elles montrent à quel point le pouvoir politique ambitionne de changer
          le visage du réseau pour le mettre à disposition de la consommation,
          et non de l’échange de culture. C’est une remise en cause des
          promesses qu’il apporte pourtant, et sur lesquelles les fournisseurs
          d’accès, France Télécom y compris, surfent pour vendre leurs
          abonnements.
        </Text>
        <Text>
          Le 2 décembre 2005, une pétition est lancée par Loïc Dachary,
          fondateur de la Free Software Foundation France (FSF France), contre
          ce projet de loi DADVSI. Elle recueille les signatures de « 173 628
          particuliers et près de 1 000 organisations – dont plus de 230
          entreprises menacées », selon le site Web EUCD.info, ayant pour but
          d’informer les internautes sur les conséquences de la directive EUCD
          et sa transposition dans le droit français. Cette pétition en ligne,
          qui est la première en France à dépasser les 100 000 signatures,
          prouve que quelques militants peuvent parvenir à mobiliser l’opinion
          sur des sujets pourtant relativement techniques. En parallèle,
          certains d’entre eux entrent en contact avec des députés et des
          sénateurs. Il en est, parmi ces derniers, qui tentent sincèrement de
          comprendre les enjeux et convient des spécialistes du numérique pour
          se faire expliquer les conséquences du texte DADVSI. Ce type d’échange
          perdurera lors des débats sur la loi Hadopi et bien après.
        </Text>
        <Text>
          Christophe Espern, membre de la FSF France, qui a rapidement pris les
          rênes de l’initiative EUCD.info, cherche du soutien. Quand Jérémie
          Zimmermann, administrateur de l’April, une association qui défend les
          logiciels libres, apprend l’existence de cette initiative, il décide
          de lui apporter son aide pour cette campagne. La stratégie est alors
          fondée sur une guérilla législative visant à faire admettre aux
          députés deux idées principales : le bien-fondé de l’usage du logiciel
          libre, et l’obligation d’interopérabilité des logiciels.
        </Text>
        <Text>
          Ensemble, ils mobilisent d’autres militants qui tentent de diffuser
          plus largement la pétition et de faire parler d’elle dans les médias,
          sortent des statistiques, rédigent des discours qui sont lus dans
          l’hémicycle par des parlementaires convaincus de la légitimité de ces
          propositions, ou encore proposent par leur truchement des amendements
          – pour certains adoptés en séance. Malgré des revirements au Sénat et
          face au Conseil constitutionnel, ils arrivent à imposer dans le texte
          DADVSI l’obligation d’interopérabilité, qui va permettre, malgré les
          DRM, de pouvoir lire des fichiers protégés avec n’importe quel
          logiciel. Une petite bombe, qui est prévue pour tuer le principe même
          des verrous techniques.
        </Text>
        <Text>
          Le bilan de cette campagne est mi-figue mi-raisin. Quelques belles
          victoires arrachées (bien qu’elles ne soient valables qu’en France),
          dont celle d’avoir forcé la tenue d’un vrai débat dans l’hémicycle sur
          le logiciel libre et les limites du droit d’auteur, mais les
          participants sont exsangues et ont travaillé des mois sans aucun
          revenu. Ils se sont toutefois fait connaître du public, à travers les
          forums et les médias, se constituant au passage un fichier de presse,
          et une base de soutiens solides qui leur sera bien utile pour la
          suite.
        </Text>
        <Text>
          En 2007, Benjamin Bayart coorganise les 8<sup>e</sup> Rencontres
          mondiales du logiciel libre (RMLL), à Amiens. Il est alors président
          de French Data Network (FDN), plus ancien fournisseur d’accès à
          Internet associatif (FAI), qui a pour devise de pouvoir « faire son
          Internet soi-même ».
        </Text>
        <Text>
          Voyant les services en ligne se concentrer entre les mains de quelques
          géants, que ce soit pour la recherche en ligne, la rédaction d’e-mails
          ou tout autre échange d’informations, il présente sa conférence «
          Internet libre ou Minitel 2.0 ?
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.fdn.fr/actions/confs/Internet-libre-ou-minitel-2-0/"
                >
                  https://www.fdn.fr/actions/confs/Internet-libre-ou-minitel-2-0/
                </AppLink>
              )
            }
          >
            10
          </InfoClick>
          ». Elle sera un important outil de communication permettant à de
          nombreuses personnes de prendre conscience que le beau jouet
          technologique décentralisé est en train d’échapper à ses créateurs, à
          celles et ceux qui voient toujours en lui un outil d’émancipation des
          peuples. Par exemple, Orange, la marque issue de France Télécom et qui
          possède alors de fait un quasi-monopole sur le réseau téléphonique et
          Internet en France, empêche (techniquement et commercialement) toutes
          velléités d’émancipation
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.silicon.fr/adsl-france-telecom-encore-condamne-par-la-justice-europeenne-20134.html"
                >
                  https://www.silicon.fr/adsl-france-telecom-encore-condamne-par-la-justice-europeenne-20134.html
                </AppLink>
              )
            }
          >
            11
          </InfoClick>
          . La vidéo de la conférence enregistrée ce soir-là fera des centaines
          de milliers de vues, et continue à être diffusée aujourd’hui.
        </Text>
        <Text type="h3">Naissance de La Quadrature du Net</Text>
        <Text>
          2007 est aussi l’année de l’élection de Nicolas Sarkozy. Très vite,
          dès le mois de novembre, le nouveau président de la République désigne
          Internet comme un ennemi, un territoire à conquérir, à « civiliser
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.numerama.com/magazine/5692-quand-nicolas-sarkozy-oppose-l-Internet-au-monde-civilise.html"
                >
                  https://www.numerama.com/magazine/5692-quand-nicolas-sarkozy-oppose-l-Internet-au-monde-civilise.html
                </AppLink>
              )
            }
          >
            12
          </InfoClick>{" "}
          ». Il passe ainsi en quelques mois seulement du discours de campagne
          proposant d’aider les industries culturelles à évoluer sur la Toile
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.vie-publique.fr/discours/166383-declaration-de-m-nicolas-sarkozy-president-de-lump-et-candidat-lel"
                >
                  https://www.vie-publique.fr/discours/166383-declaration-de-m-nicolas-sarkozy-president-de-lump-et-candidat-lel
                </AppLink>
              )
            }
          >
            13
          </InfoClick>
          à une déclaration de guerre. Denis Olivennes, alors patron de la Fnac,
          se voit missionné pour produire un rapport sur « Le développement et
          la protection des œuvres culturelles sur les nouveaux réseaux », qu’il
          remet à la ministre de la Culture, Christine Albanel. Ce rapport
          préfigure ce que sera la future loi Hadopi.
        </Text>
        <Text>
          En entendant le président, Jérémie Zimmermann et Christophe Espern
          sont furieux. Ils décident de contre-attaquer en lançant une nouvelle
          initiative. Mais cette fois, elle n’est pas dédiée à un projet de loi
          particulier. Le défaut du précédent projet était de porter le nom
          d’une loi particulière, et donc d’être «jetable»: EUCD.info a disparu
          une fois la loi DADVSI entérinée. Il faut donc trouver un nom qui
          traverse les années.
        </Text>
        <Text>Ce sera « La Quadrature du Net ».</Text>
        <Text>
          « La quadrature du cercle est le symbole d’un problème qu’on ne peut
          pas résoudre, alors que, pendant de nombreuses années, beaucoup de
          gens étaient persuadés du contraire. Les politiques pensent
          aujourd’hui pouvoir “civiliser Internet”, mais ils se heurtent au réel
          de l’infinie capacité du réseau », explique Jérémie Zimmermann.
        </Text>
        <Text>
          Début mars 2008, après plusieurs mois de réflexion, Jérémie Zimmermann
          et Christophe Espern contactent plusieurs de leurs amis militants pour
          leur proposer de rejoindre l’association. Il s’agit de Philippe
          Aigrain, Gérald Sédrati-Dinet et Benjamin Sonntag.
        </Text>
        <Text>
          Philippe Aigrain, chercheur en informatique et entrepreneur, a
          participé à l’aventure des radios libres dans les années 1970, avant
          d’explorer les potentialités démocratiques d’Internet et de devenir un
          ardent défenseur des logiciels libres et des biens communs. Ses
          productions (livres, tribunes) et positions politiques font de lui une
          figure de la communauté. Gérald Sédrati-Dinet, dit Gibus, est un
          ingénieur en logiciel libre. Expert des brevets logiciels et de leurs
          dangers, en particulier du brevet unitaire, il a été le vice-président
          de la Foundation for a Free Information Infrastructure (FFII).
          Benjamin Sonntag est un militant engagé, entrepreneur du Web et du
          logiciel libre.
        </Text>
        <Text>
          Débattant de l’organisation de la toute nouvelle association, tous
          prennent l’engagement qu’elle soit une force de proposition autant
          qu’une force d’opposition. Ils souhaitent qu’elle fasse le pont entre
          l’éthique des logiciels libres, l’éthique des hackers, la connaissance
          des technologies et des processus politiques et législatifs, et
          qu’elle aille stopper les processus politiques à la racine, en
          particulier à Bruxelles.
        </Text>
        <Text type="h3">Les principes fondateurs de La Quadrature du Net</Text>
        <ul>
          <li>
            <Text>
              Se réapproprier collectivement la connaissance, étudier les
              dossiers, mais en se faisant plaisir.
            </Text>
          </li>
          <li>
            <Text>
              Utiliser la technologie et Internet pour aider à l’émancipation du
              public, en inventant des outils qui améliorent la participation,
              créent du collectif (on dit aujourd’hui
              <i> empowerment</i> ou « capacitation »).
            </Text>
          </li>
          <li>
            <Text>
              Réinventer ses propres pratiques politiques, en se sentant libre
              de tester de nouvelles approches du militantisme, telles que la
              guérilla juridico-politique et parlementaire ou le contentieux –
              soit le fait d’attaquer directement les lois et les décisions
              politiques devant la juridiction dédiée, même en n’ayant aucune
              chance de réussite, juste pour communiquer, maintenir la pression,
              ou même troller les politiques.
            </Text>
          </li>
        </ul>
        <Text>
          La Quadrature du Net reçoit alors, trois mois avant sa présentation en
          conseil des ministres, de la part d’une source anonyme, la fuite de
          l’ensemble du projet Hadopi, du nom de l’organisme de régulation qu’il
          propose de créer : la Haute Autorité pour la diffusion des œuvres et
          la protection des droits sur Internet. C’est le début du combat
          fondateur de l’association, son premier dossier. Nous publions donc
          dès mars 2008 notre premier communiqué de presse signé « La Quadrature
          du Net »
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://archive.wikiwix.com/cache/index2.php?url=http%3A%2F%2Fwww.laquadrature.net%2Ffr%2Fnode%2F39#federation=archive.wikiwix.com"
                >
                  https://archive.wikiwix.com/cache/index2.php?url=http%3A%2F%2Fwww.laquadrature.net%2Ffr%2Fnode%2F39#federation=archive.wikiwix.com
                </AppLink>
              )
            }
          >
            14
          </InfoClick>
          .
        </Text>
        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter1Part1;
