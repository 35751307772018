import React, { useState } from "react";

import Image from "src/assets/ACTANIC.png";
import Image2 from "src/assets/LQDN-20120222_NO_to_ACTA_v1-3-1.png";
import Image3 from "src/assets/LQDN-20120222_NO_to_ACTA_v1-3-2.png";
import Image4 from "src/assets/manif anti-ACTA.png";
import Image5 from "src/assets/STOP_ACTA_pedalo.png";
import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter1Part3 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Le combat contre l’ACTA">
      <>
        <Text>
          Début 2012, en plein hiver, plusieurs milliers de Polonais sont dans
          la rue, par –10°C. Ils manifestent. L’objet de leurs craintes ? Un
          obscur traité commercial multilatéral, l’Accord commercial
          anti-contrefaçon, ou <i>Anti-Counterfeiting Trade Agreement </i>:
          l’ACTA. Un traité international qui n’a de commercial que le nom.
        </Text>
        <Text>
          Comment un traité sur le « renforcement des droits de la propriété
          intellectuelle » peut-il susciter une telle mobilisation populaire,
          amener Google et Wikipédia à afficher un bandeau noir sur leur
          plateforme, et créer des remous jusqu’en plein cœur de la vie
          politique états-unienne ?
        </Text>
        <Text type="h3">Stratégie du vampire contre culture du secret</Text>
        <Text>
          La première fois que nous entendons parler de l’ACTA, c’est lors de la
          fuite d’un document de discussion, « trouvé sur une photocopieuse » du
          Parlement européen, puis publié par WikiLeaks en 2008. Rapidement,
          d’autres suivent, dans la presse ou ailleurs, et cela ne fait plus de
          doute : un traité d’une importance majeure est dans les tuyaux,
          scandaleusement négocié en dehors du processus démocratique.
        </Text>
        <Text>
          Nous ne sommes pas les seuls, à la Quadrature, à nous méfier : en mars
          2010, le Parlement européen lui-même dénonce par une déclaration
          officielle « des négociations commerciales qui sortent du cadre
          décisionnel normal de l’Union
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.europarl.europa.eu/doceo/document/TA-7-2010-0058_FR.html"
                >
                  https://www.europarl.europa.eu/doceo/document/TA-7-2010-0058_FR.html
                </AppLink>
              )
            }
          >
            29
          </InfoClick>
          ».
        </Text>
        <Text>
          Effectivement, des représentants non élus de plusieurs États – pour la
          France, issus du ministère des Finances, pour les États-Unis du
          ministère du Commerce – se réunissent discrètement depuis des mois
          dans des hôtels, à Genève, à Washington ou encore à Rabat, pour
          défendre leurs intérêts. Leurs intérêts, ce sont ceux de leurs
          ministères, c’est-à-dire majoritairement ceux des industries du
          copyright, pas ceux de leurs citoyens.
        </Text>
        <Text>
          Face à cette culture du secret, de l’ombre, de l’absence caractérisée
          de transparence, place à la lumière, à la « stratégie du vampire » !
          La Quadrature décide, avec d’autres organisations, d’exposer au grand
          jour ces négociations secrètes et de braquer les projecteurs sur les
          protagonistes français (hauts fonctionnaires habitués à la discrétion,
          qui nous en voudront beaucoup de les avoir ainsi nommés – ou en tout
          cas assez pour nous faire un procès).
        </Text>
        <img
          style={{ width: "100%", height: "auto" }}
          src={Image5}
          alt={`Flyer à destination des membres du parlement européen : "VOTE AGAINST ACTA"`}
        />
        <Text>
          Avec Act Up, nous décidons fin juin 2010 d’aller rendre une petite
          visite aux conspirateurs d’ACTA. Le neuvième round de leurs
          négociations se déroule au cœur des montagnes suisses, dans un hôtel 5
          étoiles qui donne directement sur le lac de Lucerne. Comment les
          atteindre et leur rappeler l’existence d’autres partis non invités à
          la table ? Nous apercevons au loin un loueur de pédalos, à 10 francs
          suisses de l’heure. Banco : quelques heures plus tard, nous pédalons
          sous le soleil, nous nous plaçons sous les fenêtres de l’hôtel et
          sortons nos banderoles et nos mégaphones. Personne ne sort, personne
          ne vient nous chasser, c’est en quelque sorte un coup d’épée dans
          l’eau. Mais une fois les pédalos rendus, une bonne douche de prise,
          nous nous dirigeons vers le centre-ville et investissons une terrasse
          pour boire quelques verres, quand arrivent… tous les négociateurs de
          l’ACTA, sortis de leur hôtel pour prendre un verre eux aussi. Après
          quelques sarcasmes, la discussion s’engage entre les deux partis et
          nous voilà invités à expliquer nos arguments à ces interlocuteurs de
          l’ACTA, qui se présentent comme tout à fait ouverts aux critiques des
          associations.
        </Text>
        <Text>
          Nous ne ratons pas cette occasion et demandons le lendemain, dans une
          salle du luxueux hôtel, à avoir la confirmation des fuites dont nous
          disposons. La sentence tombe : le texte prévoit bien de transférer des
          pouvoirs de sanction à des acteurs privés en sanctionnant pénalement
          ces derniers. Ambiance glaciale.
        </Text>
        <Text>
          C’est le début d’une lutte contre un projet pernicieux, né sur des
          coins de table. Nous allons dépenser notre énergie sans compter et
          espérer que les étoiles finissent par s’aligner.
        </Text>
        <Text type="h3">La vérité sur l’ACTA</Text>
        <Text>
          L’ACTA, c’est le fourre-tout supranational des tenants (pour ne pas
          dire des extrémistes) de la « propriété intellectuelle », ce concept
          juridiquement pratique mais intellectuellement bancal, car il réunit
          un éventail d’éléments sans réel lien les uns avec les autres : les
          brevets, le droit des marques, le droit d’auteur, les indications
          géographiques. Quel lien rigoureux existe-t-il entre le droit
          régissant l’appellation d’origine contrôlée du cantal AOP, la
          production de médicaments génériques et le copier/coller de photos sur
          Internet ? À peu près aucun, mais le vocable « propriété » a une
          conséquence fort pratique : la possibilité de décrire celles et ceux
          qui ne la respectent pas comme des pirates ou des voleurs. Pour les
          défenseurs de cette vision conservatrice, télécharger, c’est voler.
        </Text>
        <Text>
          L’un des principaux enjeux du traité est d’instaurer une protection
          extrêmement agressive du copyright. D’une part, l’ACTA souhaite placer
          une responsabilité pénale sur les intermédiaires techniques des
          échanges d’œuvres sur Internet (comme les fournisseurs d’accès), y
          compris à but non marchand. La répercussion immédiate semble évidente
          : pour éviter le moindre risque, les intermédiaires techniques
          surveilleront leur réseau et leurs utilisateurs de très près,
          censurant à tout va sans chercher de nuance, leur responsabilité
          pénale étant en jeu. Un responsable de FAI peut ainsi payer une amende
          forfaitaire parce qu’un internaute a envoyé un MP3 à un autre. Et
          cette caricature, publiée par un dessinateur sur son propre blog,
          droit à la parodie ou infraction au copyright ? Dans le doute, coupons
          l’accès ! En résumé, l’ACTA transforme les intermédiaires techniques
          en police et justice privées du copyright.
        </Text>
        <Text>
          D’autre part, il s’agit d’inscrire, dans le texte comme dans les
          têtes, l’idée selon laquelle chaque téléchargement d’œuvre correspond
          à une vente perdue. En ce sens, le partage d’œuvres dans son ensemble
          est un manque à gagner colossal. Les représentants des gros ayants
          droit (c’est-à-dire les majors) n’ont jamais réussi à défendre
          correctement cette position devant un juge, et pour cause : elle est
          parfaitement fantaisiste. Elle fait fi des nombreuses études qui
          montrent qu’une fois les différents effets économiques pris en compte,
          l’impact du partage à but non marchand pour les artistes est soit
          légèrement positif, soit neutre. Si l’on ne peut prouver juridiquement
          qu’un téléchargement équivaut à une vente perdue, reste à l’inscrire
          dans le droit. C’est tout l’objectif des industries culturelles.
        </Text>
        <Text>
          Dans le contexte de l’ACTA, il ne semble pas exagéré d’évoquer un
          certain « colonialisme » des œuvres de l’esprit. À l’exception du
          Maroc, la liste des pays participant aux négociations le reflète :
          Australie, Canada, Corée du Sud, États-Unis, Japon, Nouvelle-Zélande,
          Singapour, et bien sûr l’Union européenne qui, embarquant d’un coup
          vingt-sept pays, pèse d’un poids tout particulier sur l’accord, sa
          composition et la formulation de ses différents articles.
        </Text>
        <Text>
          Force est de constater que l’ACTA vise à protéger à tout prix les
          intérêts des pays occidentaux (aux puissantes économies de services
          fondées sur le travail intellectuel) aux dépens des autres nations.
          Quelle hypocrisie, alors que l’histoire nous enseigne qu’ils n’ont
          eux-mêmes aucunement respecté les droits d’auteur et les brevets
          lorsqu’ils étaient en train de se développer aux XVIII
          <sup>e</sup> et XIX
          <sup>e</sup> siècles. Désormais chantres de la propriété
          intellectuelle, ils demandent la main sur le cœur que l’on ait une
          pensée pour les pauvres artistes et inventeurs… Les États-Unis en
          tête… Eux qui, au XVIII
          <sup>e</sup> siècle, reproduisaient sans vergogne les œuvres d’auteurs
          anglais, oubliant tout copyright. Charles Dickens en fit les frais de
          son vivant, allant jusqu’à s’attirer les foudres de ses fans
          américains lors d’une tournée littéraire où il profita de sa notoriété
          pour attirer l’attention sur ce problème et montrer son mécontentement
          clair et explicite
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  En retour, la Grande-Bretagne ne respectait pas le copyright
                  des auteurs américains, dont Edgar Allan Poe.
                </Text>
              )
            }
          >
            30
          </InfoClick>
          .
        </Text>
        <Text>
          Que ce même pays tente désormais de « copyrighter » tout ce qu’il peut
          et d’en tirer profit aurait de quoi faire sourire si ça n’était pas
          doublé d’une agressive injonction à un respect total et absolu de tous
          droits de propriété intellectuelle pour les pays encore en
          développement. On comprend mieux, dans ce contexte, pourquoi la Chine
          n’est pas à la table des négociations… Étant l’une des principales
          cibles de l’ACTA, elle n’a aucun intérêt à en faire partie. Et après
          tout, autant commencer par un noyau dur d’États signataires pour
          ensuite mieux faire pression sur d’autres.
        </Text>

        <Text type="h3">Hanter les couloirs du Parlement européen</Text>
        <Text>
          Pour contrer ce texte dangereux et inacceptable, la stratégie de la
          Quadrature est simple : aller au contact, au Parlement. Mais encore
          faut-il y entrer… Depuis plusieurs mois, nous rencontrons
          régulièrement des députés qui soutiennent la position de
          l’association, en particulier au sein des Verts. De précieuses aides
          pour pénétrer là où tout se joue.
        </Text>
        <Text>
          Quand nous arrivons dans le hall du Parlement, nous appelons un ou
          plusieurs députés, selon notre nombre, et leur demandons de nous
          ajouter sur la liste des invités. Reste à attendre qu’un assistant
          parlementaire vienne nous chercher à l’accueil. Le règlement prévoit
          que nous sommes alors sous sa responsabilité. En réalité, une fois
          entrés, nous partons « faire notre vie » dans les étages du bâtiment.
        </Text>
        <Text>
          Distribuer des flyers à l’entrée des sessions des commissions,
          travailler les amendements avec nos alliés, parcourir les couloirs,
          frapper à toutes les portes pour diffuser nos arguments auprès des
          députés : autant d’actions qui pourraient mettre celles et ceux qui
          nous ont permis d’entrer en porte-à-faux. Résultat, dès que nous
          apercevons l’ombre d’un vigile, nous déguerpissons et nous changeons
          d’étage.
        </Text>
        <img
          style={{ width: "100%", height: "auto" }}
          src={Image}
          alt={`Flyer à destination des membres du parlement européen : "VOTE AGAINST ACTA"`}
        />
        <Text>
          Rapidement, nous connaissons cet immeuble comme notre poche, tout
          comme les gens qui y travaillent. Besoin d’un accès wifi ? Le service
          informatique nous fournit les codes. Des affiches pour décorer en
          douce les couloirs et les ascenseurs ? Le service de reprographie nous
          les imprime, en A2 quadrichromie. Et le règlement intérieur ? Qu’à
          cela ne tienne, la Quadrature est organisée. Notre efficacité nous
          permet de sortir de l’hémicycle après avoir suivi un vote concernant
          notre sujet de préoccupation, de finaliser dans la foulée un
          communiqué préparé la veille au soir avec les éléments du jour, de le
          faire imprimer, et de distribuer à la sortie des députés un texte
          dénonçant ce qu’ils viennent justement de voter, à leur grande
          stupeur.
        </Text>

        <Text type="h3">
          De la bulle bruxelloise à l’activisme européen, et au-delà
        </Text>
        <Text>
          Parler aux parlementaires, la Quadrature commence à savoir faire. Mais
          comment expliquer succinctement un sujet aussi complexe qu’ACTA aux
          non-spécialistes, qui n’ont pas forcément envie de lire un texte sur
          un sujet aussi aride ? Coup de chance, un soutien de la Quadrature,
          Benoît Musereau, nous contacte : il est graphiste et nous propose de
          réaliser une petite vidéo sur ACTA et ses dangers. Nous nous mettons
          au travail, allant jusqu’à enregistrer nous-même la voix off (en
          anglais, pour maximiser la portée du message), dans la grande
          tradition du « fait maison » de la Quadrature !
        </Text>
        <Text>
          Le 28 octobre 2011, trois vidéos sont prêtes et mises en ligne sur
          notre site, hébergées sur les serveurs d’Octopuce ; des miroirs sont
          ajoutés sur YouTube et Dailymotion. La première dénonce ACTA en un peu
          plus de deux minutes, les deux autres se concentrent sur les dangers
          du traité pour Internet et pour les brevets et les semences.
        </Text>
        <iframe title="La Quadrature du Net - NO to ACTA (full version) FR"
            width="560"
            height="315"
            src="https://video.lqdn.fr/videos/embed/2c70bb9b-8ebf-4f94-84c3-6f0abada3d13"
            sandbox="allow-same-origin allow-scripts allow-popups">
        </iframe>
        <Text>
          Les vidéos peinent à sortir des cercles militants. Néanmoins, une
          surprise nous attend. Quelques jours plus tard, alors qu’il est au
          Parlement européen, Jérémie se retrouve assis à côté de Peter Sunde
          alias Brokep de The Pirate Bay (TPB), plateforme de partage{" "}
          <i>peer-to-peer</i> de renommée internationale. Il lui montre la
          vidéo. Et c’est ainsi qu’elle se retrouve sur la page d’accueil de
          TPB. Il va sans dire qu’en 2011 le taux de fréquentation de leur site
          est infiniment supérieur à celui de{" "}
          <a className="url" href="http://laquadrature.net">
            laquadrature.net
          </a>
          . En quelques minutes, Octopuce voit ses serveurs exploser, nous
          décidant à renvoyer les internautes vers le miroir YouTube. Notre
          vidéo finira à plus de 2 millions de vues rien que sur YouTube, un
          chiffre assez incroyable à l’époque. Quant aux sous-titres,
          initialement uniquement disponibles en français et en anglais, ils
          sont rapidement traduits en plus d’une dizaine de langues par des
          bénévoles. La communauté Internet se mobilise et nous restons émus
          devant une telle réponse.
        </Text>
        <Text>
          Une autre composante fondamentale de la campagne contre l’ACTA est la
          collaboration européenne des militants. Alors qu’à l’échelle
          internationale les signatures du traité se multiplient (États-Unis,
          Australie, Canada, Corée du Sud, Japon…), en Europe, rien n’est encore
          joué. Face au danger, les différentes organisations de défense des
          libertés fondamentales dans l’espace numérique font plus que jamais
          front commun. Aux côtés de la Quadrature, nous comptons désormais Bits
          of Freedom aux Pays-Bas,{" "}
          <a className="url" href="http://Netzpolitik.org">
            Netzpolitik.org
          </a>{" "}
          puis Digitale Gesellschaft en Allemagne, Panoptykon Foundation en
          Pologne, ou encore Open Rights Group au Royaume-Uni. European Digital
          Rights (EDRi), à Bruxelles, coordonne ce réseau d’organisations.
          Chacune d’elles agit dans son pays pour empêcher le gouvernement
          national de signer l’accord, mais participe également à l’action
          collective pour éviter une signature des vingt-sept États de l’UE.
          Nous dialoguons avec nos collègues européens, étudions les nouvelles
          fuites, discutons des prochaines étapes du processus de négociation,
          élaborons une stratégie commune…
        </Text>
        <img
          style={{ width: "100%", height: "auto"}}
          src={Image4}
          alt={`Manifestation anti-ACTA à Paris`}
        />
        <Text>
          Mais au-delà des mouvements activistes, le combat passe à une
          dimension supérieure, celui de la mobilisation de l’opinion. Elle est
          d’abord portée par des milliers de Polonais, qui défilent dans les
          rues de Varsovie et de Cracovie en janvier 2012 pour protester contre
          la signature d’ACTA, à valeur hautement symbolique. Encouragés par ce
          mouvement qui prend forme sous nos yeux, nous mettons en ligne le mois
          suivant un tract intitulé « NO to ACTA ! », en français et en anglais.
          En moins de deux semaines, celui-ci est traduit spontanément dans
          toute l’Europe et même au-delà, en vingt et une langues
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/files/flyers/LQDN-20120222_NO_to_ACTA_v1-3.pdf"
                >
                  https://www.laquadrature.net/files/flyers/LQDN-20120222_NO_to_ACTA_v1-3.pdf
                </AppLink>
              )
            }
          >
            31
          </InfoClick>
          .
        </Text>
        <img
          style={{ width: "45%", height: "auto", marginRight: 5}}
          src={Image2}
          alt={`Flyer à destination des membres du parlement européen : "NO to ACTA !"`}
        />
        <img
          style={{ width: "45%", height: "auto" }}
          src={Image3}
          alt={`Flyer à destination des membres du parlement européen : "NO to ACTA !"`}
        />
        <Text>
          Aux États-Unis, la campagne contre ACTA prend la forme d’un combat
          contre ses déclinaisons locales, les propositions de loi{" "}
          <i>Stop Online Piracy Act </i>(SOPA) et{" "}
          <i>Protect Intellectual Property Act</i> (PIPA). Une opération{" "}
          <i>blackout</i> est organisée par l’ONG Fight for the Future, à
          laquelle participeront près de 115 000 sites, dont Wikipédia, Reddit,
          Tumblr ou WordPress. Les actions vont de l’affichage d’un simple
          bandeau au blocage complet du site, en passant par une pleine page
          insérée avant d’y avoir accès. Même Twitter et Google arborent un
          bandeau ou un logo modifié pour indiquer leur participation à
          l’opération
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://upload.wikimedia.org/wikipedia/commons/2/2b/Google_Doodle_Censored_2.png"
                >
                  https://upload.wikimedia.org/wikipedia/commons/2/2b/Google_Doodle_Censored_2.png
                </AppLink>
              )
            }
          >
            32
          </InfoClick>
          .
        </Text>
        <Text>
          Cette opération est un succès au fort impact médiatique. Elle change
          la trajectoire des propositions de loi. Le <i>New York Times</i> va
          même jusqu’à parler de passage à « l’âge adulte politique du secteur
          des technologies ». Une incroyable collaboration internationale se met
          en place, nous préparant à la dernière ligne droite.
        </Text>

        <Text type="h3">Le coup de grâce</Text>
        <Text>
          Le 2 juillet 2012, les membres de la Quadrature retournent au
          Parlement. Le vote de l’ACTA est proche, alors même que le texte est
          en train d’être étudié à la Cour de justice de l’UE. La question est
          de savoir s’il est compatible avec la législation européenne ; la
          réponse est attendue dans plusieurs mois. Pourquoi donc voter ce texte
          maintenant ? Curieux calendrier.
        </Text>
        <Text>
          Et c’est reparti pour un tour des bureaux ! Dans l’un des premiers,
          une attachée parlementaire est au téléphone. Lorsqu’elle raccroche,
          elle nous demande d’approcher. Nous nous présentons : « Bonjour, nous
          sommes bénévoles de La Quadrature du Net, nous venons vous parler du
          vote de demain, sur l’ACTA. » Son sourire disparaît à mesure que nous
          parlons, elle nous coupe la parole, se lève et vient vers nous en
          faisant de grands gestes : « Dehors ! Dehors ! C’est à cause de vous
          que nous recevons des milliers d’e-mails et que le téléphone n’arrête
          pas de sonner ! Je ne veux plus entendre parler de vous ! » Mi-amusés,
          mi-surpris, nous reculons sur le palier, alors qu’elle nous referme la
          porte au nez. Nous regardons sur notre liste quel est le député de
          cette attachée parlementaire que nous avons tant choquée. C’est
          Jean-Marie Cavada.
        </Text>
        <Text>
          À côté, nous tombons sur une députée allemande. Après la présentation
          de notre exposé, la députée nous remercie, nous confie qu’elle est
          plutôt pour l’ACTA, convaincue qu’il est dans l’intérêt de l’Europe de
          signer ce traité, mais annonce qu’elle s’abstiendra, dans l’attente de
          la décision de la Cour de justice. Nous sortons de cet entretien sur
          un petit nuage. Nous avons discuté avec une opposante, une députée
          européenne, avec qui nous avons échangé des arguments pendant une
          dizaine de minutes. C’est donc possible !
        </Text>
        <Text>
          Ce jour-là, nous en rencontrons des dizaines d’autres. On nous écoute,
          on nous congédie d’un geste… Nous ne saurons jamais combien de députés
          nous avons réussi à convaincre… Le 4 juillet, le texte est rejeté. La
          Quadrature est là, au balcon, assistant au vote. Pas d’explosion de
          joie ou de déception dans la grande salle du Parlement. Ce n’est qu’un
          vote parmi d’autres, et seul un bruissement se fait entendre. La
          séance se poursuit, comme si les défenseurs de l’ACTA, négociant dans
          l’ombre depuis tant d’années, ne s’étaient pas pris un mur de plein
          fouet.
        </Text>
        <Text>
          Nous rejoignons la cafétéria, où nous pouvons enfin nous laisser aller
          à afficher notre joie. Nos alliés passent nous voir, et tout le monde
          s’auto-congratule après cette longue et dure bataille politique.
        </Text>
        <Text>
          Mais si la victoire, réelle et durement acquise, n’est que relative,
          c’est que l’ACTA s’inscrit dans une perspective plus globale…
        </Text>
        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter1Part3;
