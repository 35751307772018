import React from "react";

import Styles from "./InfoClick.module.scss";

import { ReactComponent as NoteMark } from "src/assets/noteMark.svg";

interface InfoClickProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const InfoClick = ({ onClick }: InfoClickProps) => {
  return (
    <span onClick={onClick}>
      <NoteMark className={Styles.NoteMark} />
    </span>
  );
};
