import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter3Part3 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Décentraliser la lutte contre la technopolice">
      <>
        <Text>
          Les expérimentations de surveillance automatisée sont nombreuses, et
          dans tout le pays. Les pratiques sont déjà là, cachées sans l’être,
          invisibles seulement parce que personne ne semble s’en soucier. Après
          des décennies d’idéologie sécuritaire dominante et décomplexée, et
          après des années de menace terroriste bien réelle, la surveillance du
          quotidien paraît être devenue une banalité. Les villes qui surveillent
          et les entreprises qui les équipent ont même souvent assez de candeur
          pour s’en vanter. L’époque semble trouver évident le besoin de
          surveiller les gens.
        </Text>
        <Text>
          À la Quadrature, nous avons une sensibilité particulière à la
          question. Depuis des années, on voit la surveillance progresser et
          s’immiscer partout, des outils magnifiques être détournés de leur
          finalité première et devenir des instruments d’espionnage et de
          coercition. Qu’il s’agisse de la façon dont les entreprises privées se
          sont emparées du Web pour en faire une gigantesque machine
          publicitaire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    L’essayiste américaine Shoshana Zuboff parle même de «
                    capitalisme de surveillance »&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.zulma.fr/livre/lage-du-capitalisme-de-surveillance/"
                  >
                    https://www.zulma.fr/livre/lage-du-capitalisme-de-surveillance/
                  </AppLink>
                </>
              )
            }
          >
            192
          </InfoClick>
          , ou de la façon dont la police et les services de renseignement
          cherchent à faire légaliser l’enregistrement et l’exploitation de
          toutes nos activités numériques, la logique à l’œuvre est la
          même&#160;: collecter nos traces et les croiser, cerner nos faits et
          gestes, deviner nos pensées, et nous classer dans des comportements
          prévisibles, qu’il s’agisse de nos pulsions d’achat ou de nos
          revendications politiques.
        </Text>
        <Text>
          Avec un pas de recul, le tableau est terriblement noir&#160;: nos vies
          numérisées –&#160;et elles le sont très largement, que nous
          l’admettions ou non, à partir du moment où nous avons un smartphone
          dans la poche ou un ordinateur à la maison&#160;–, nos vies connectées
          sont tout bonnement des vies sous surveillance.
        </Text>

        <Text type="h3">La&#160;fierté de&#160;surveiller</Text>
        <Text>
          Le techno-flicage est une industrie comme une autre, il a ses
          entreprises, son marché, et il a ses catalogues&#160;: par exemple, on
          recommande aux esprits curieux la lecture du catalogue de l’AN2V. Ce
          sigle est celui de l’Association nationale de la vidéoprotection
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Site Web de l’AN2V&#160;: </Text>
                  <AppLink white href="https://an2v.org/">
                    https://an2v.org/
                  </AppLink>
                </>
              )
            }
          >
            193
          </InfoClick>
          , un groupement d’intérêt qui rassemble des «&#160;communes
          vidéosurveillées&#160;» et des dizaines d’entreprises locales,
          nationales ou internationales, toutes spécialisées dans la fabrication
          ou la vente d’équipements de surveillance&#160;: caméras, capteurs,
          drones, logiciels, etc. En lisant ce beau catalogue où s’échangent les
          «&#160;bonnes pratiques&#160;» (de surveillance) et les «&#160;retours
          d’expériences&#160;» (de surveillance), les entreprises mettent en
          avant leurs réussites, avec l’appui complice des mairies (leurs
          clientes), pour convaincre de nouvelles mairies (et de futures
          clientes). On se vante, on se fait mousser, on met son plus beau
          costume, on fait de belles phrases et de belles photos. On fait du
          lobbying. Mais pour qui veut savoir comment il est surveillé, pour qui
          se demande qui surveille et comment, c’est une mine d’or à ciel
          ouvert.
        </Text>
        <Text>
          On y apprend par exemple que Christian Estrosi, maire de Nice et
          paladin de la <i>safe city</i>, aurait testé la vidéosurveillance
          automatisée dans sa ville, avec la reconnaissance des émotions. Il est
          fier d’être aussi le tout premier à tester la reconnaissance faciale
          sur la voie publique, lors du carnaval de Nice, en février&#160;2019
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Nice va tester la reconnaissance faciale lors du Carnaval
                    »,
                    <i>Le Point</i>, 19&#160;février 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lepoint.fr/societe/nice-va-tester-la-reconnaissance-faciale-lors-du-carnaval-19-02-2019-2294405_23.php"
                  >
                    https://www.lepoint.fr/societe/nice-va-tester-la-reconnaissance-faciale-lors-du-carnaval-19-02-2019-2294405_23.php
                  </AppLink>
                </>
              )
            }
          >
            194
          </InfoClick>
          &#160;: ce test grandeur nature est présenté comme parfaitement légal,
          même la CNIL serait d’accord&#160;! Pas de chance, c’est un gros
          mensonge. Médiatisé une première fois lors du carnaval, le test
          connaît une deuxième couverture médiatique, plus importante et moins
          favorable, en août&#160;2019, lorsque la CNIL s’alarme de l’illégalité
          de l’opération, pour laquelle elle n’a en réalité pas été consultée,
          et qu’elle n’a absolument jamais autorisée.
        </Text>
        <Text>
          Le dispositif du test niçois est très simple. On soumet à un logiciel
          nommé AnyVision, développé par une entreprise monégasque (qui est à
          l’initiative de l’opération), une cinquantaine de photos fournies par
          des volontaires (la plupart sont des employés de la mairie)&#160;: le
          logiciel est chargé de retrouver les cinquante cobayes dans les images
          de vidéosurveillance de la ville, au moment où la foule du carnaval
          envahit les rues. Pour corser l’affaire, et mettre en valeur la
          qualité du logiciel, certaines photos datent même de «&#160;plusieurs
          décennies
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Reconnaissance faciale&#160;: la CNIL tique sur le bilan
                    de l’expérience niçoise », <i>Le Monde</i>, 28&#160;août
                    2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lemonde.fr/pixels/article/2019/08/28/reconnaissance-faciale-la-cnil-tique-sur-le-bilan-de-l-experience-nicoise_5503769_4408996.html"
                  >
                    https://www.lemonde.fr/pixels/article/2019/08/28/reconnaissance-faciale-la-cnil-tique-sur-le-bilan-de-l-experience-nicoise_5503769_4408996.html
                  </AppLink>
                </>
              )
            }
          >
            195
          </InfoClick>
          &#160;», et la reconnaissance faciale doit donc prendre en compte le
          vieillissement probable des protagonistes. D’après la ville et
          l’entreprise, l’opération est un succès total, avec une reconnaissance
          réussie à 100&#160;%. Le microclimat de Nice serait donc très
          favorable au développement des palmiers, des orangers, et de la
          reconnaissance faciale.
        </Text>
        <Text>
          Mais la CNIL n’a pas beaucoup apprécié d’être citée comme caution de
          l’expérience, alors qu’elle n’avait pas émis d’avis sur le projet. Son
          communiqué d’août&#160;2019 douche l’enthousiasme niçois. Trop tard,
          le mal est fait&#160;: Estrosi a pu se poser en précurseur de la
          surveillance et en personnalité forte, à qui les opérations en dehors
          du cadre légal ne font pas peur.
        </Text>
        <Text>
          La région PACA, avec ses élus de droite volontiers démagos qui misent
          tout sur la sécurité, est un paradis de la technopolice. À peine
          a-t-on laissé Nice et son carnaval de la surveillance, qu’on y revient
          pour une autre expérimentation, cette fois-ci à l’initiative de la
          région. Nous sommes alertés par des militants syndicaux de l’Éducation
          nationale&#160;: la région, là aussi avec l’appui de Christian
          Estrosi, veut installer aux portes de deux lycées, à Marseille et à
          Nice, un portique de reconnaissance faciale pour filtrer l’entrée des
          élèves. Ceux-ci auront un badge sur lequel sera enregistrée leur
          photo&#160;: ils devront se présenter devant la caméra, et si le
          visage ne correspond pas à l’image du badge, le portail ne s’ouvre
          pas.
        </Text>
        <Text>
          Cette fois, il n’est pas trop tard pour agir. Les portiques sont déjà
          en cours d’installation –&#160;des parents d’élèves nous envoient même
          des photos de l’avancée des travaux&#160;–, mais il est encore temps
          d’attaquer la décision de la région devant le tribunal administratif.
          C’est ce que nous faisons en février&#160;2019, avec la Ligue des
          droits de l’homme (LDH), la CGT Éduc’action des Alpes-Maritimes et la
          Fédération des conseils de parents d’élèves des écoles publiques des
          Alpes-Maritimes. L’argumentaire juridique s’appuie principalement sur
          le RGPD et la façon dont il encadre le recours à la biométrie&#160;:
          dans le cas présent, l’usage de la reconnaissance faciale paraît
          nettement disproportionné par rapport à la finalité visée
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Reconnaissance faciale&#160;: un recours pour faire
                    barrage à la surveillance biométrique », 19&#160;février
                    2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/02/19/reconnaissance-faciale-un-recours-pour-faire-barrage-a-la-surveillance-biometrique/"
                  >
                    https://www.laquadrature.net/2019/02/19/reconnaissance-faciale-un-recours-pour-faire-barrage-a-la-surveillance-biometrique/
                  </AppLink>
                </>
              )
            }
          >
            196
          </InfoClick>
          . L’affaire traîne un peu. En octobre&#160;2019, la CNIL vient en
          renfort, en publiant un communiqué qui arrive aux mêmes conclusions
          que nous&#160;: «&#160;Après un examen attentif du projet, la CNIL a
          considéré que le dispositif projeté est contraire aux grands principes
          de proportionnalité et de minimisation des données posés par le RGPD
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Communiqué de la CNIL le 29&#160;octobre 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.cnil.fr/fr/experimentation-de-la-reconnaissance-faciale-dans-deux-lycees-la-cnil-precise-sa-position"
                  >
                    https://www.cnil.fr/fr/experimentation-de-la-reconnaissance-faciale-dans-deux-lycees-la-cnil-precise-sa-position
                  </AppLink>
                </>
              )
            }
          >
            197
          </InfoClick>
          .&#160;» Le tribunal administratif de Marseille nous donne enfin
          raison, en février&#160;2020, un an après le dépôt du recours
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Première victoire en justice contre la reconnaissance
                    faciale », 27&#160;février 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/02/27/premiere-victoire-en-france-devant-la-justice-contre-la-reconnaissance-faciale/"
                  >
                    https://www.laquadrature.net/2020/02/27/premiere-victoire-en-france-devant-la-justice-contre-la-reconnaissance-faciale/
                  </AppLink>
                </>
              )
            }
          >
            198
          </InfoClick>
          . La reconnaissance faciale à l’entrée des lycées est illégale, et les
          portiques de Marseille et de Nice n’entreront jamais en service.
        </Text>
        <Text>
          Cette victoire nous galvanise&#160;! Nous pouvons gagner et faire
          arrêter des projets&#160;! Non, la surveillance n’est pas une
          fatalité. Elle est encore en grande partie illégale, elle devrait être
          un scandale démocratique, et nous n’allons pas la laisser s’installer
          sans rien faire…
        </Text>

        <Text type="h3">
          Des&#160;micros dans les&#160;rues de&#160;Saint-Étienne
        </Text>
        <Text>
          Dès l’affaire de «&#160;l’observatoire Big Data de la tranquillité
          publique&#160;», à Marseille en décembre&#160;2019, Félix Tréguer a
          l’intuition que l’exemple marseillais n’est pas un cas isolé, une
          exception ou une aberration, mais plutôt le sommet émergé d’un
          iceberg. Il faudrait plonger pour en voir plus. Pour cela, les
          «&#160;demandes Cada&#160;» sont un outil merveilleux auquel il
          devient urgent de recourir.
        </Text>
        <Text>
          Les mairies à qui on les adresse peuvent avoir de nombreuses raisons
          de ne pas vouloir nous répondre positivement. Elles peuvent jouer sur
          les exceptions prévues par la loi&#160;: les documents sont «&#160;en
          cours d’édition&#160;», ou concernent une affaire «&#160;en
          cours&#160;», ou leur publication constitue un risque pour la sécurité
          publique, etc. Parfois, les mairies ne savent même pas ce qu’elles
          achètent ou possèdent&#160;: il est arrivé plus d’une fois, nous avons
          pu le constater, qu’une mairie renouvelle son matériel ou passe un
          contrat global de vidéosurveillance sans savoir qu’un logiciel de
          vidéosurveillance automatisée (VSA) lui était vendu en même temps. Et
          bien sûr, certaines autorités locales ne jouent pas le jeu. Non,
          l’expression n’est pas juste&#160;: certaines autorités locales ne
          respectent pas la loi. Elles ont deux mois pour instruire la demande
          et répondre. Passé ce délai, nous pouvons nous tourner vers la Cada
          elle-même, l’autorité chargée de veiller à l’application de la loi.
          Mais ces recours sont très longs –&#160;trop longs.
        </Text>
        <Text>
          Malgré tous les obstacles et les lenteurs de bonne ou de mauvaise
          volonté, nous recevons des réponses à nos demandes. Qu’espère-t-on
          obtenir&#160;? Des délibérations, des cahiers des charges, des appels
          d’offres, des devis, des contrats, bref, tout ce qui peut décrire avec
          la plus grande précision possible les systèmes vendus aux communes, et
          leurs conditions réelles d’utilisation. Au fur et à mesure de
          l’avancée de la campagne Technopolice, les informations nouvelles sont
          de plus en plus rares&#160;: on commence à connaître les outils et les
          entreprises qui les vendent. Mais ça ne veut pas dire pour autant que
          les informations ordinaires ne sont pas pertinentes&#160;: leur
          banalité dit quelque chose de la réalité qui s’installe, de la
          banalité de la surveillance, et de la surenchère commerciale qui
          l’aggrave d’année en année. Et au milieu de cette masse, on trouve
          quelques pépites, quelques divines surprises.
        </Text>
        <Text>
          La meilleure de toutes est sans doute venue de Saint-Étienne. Alerté
          par des articles de presse publiés en mars&#160;2019
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Des micros dans les rues de Saint-Étienne pour assurer la
                    sécurité des habitants », <i>Le Parisien</i>, 1<sup>er</sup>
                    &#160;mars 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.leparisien.fr/faits-divers/des-micros-dans-les-rues-de-saint-etienne-pour-assurer-la-securite-des-habitants-01-03-2019-8022531.php"
                  >
                    https://www.leparisien.fr/faits-divers/des-micros-dans-les-rues-de-saint-etienne-pour-assurer-la-securite-des-habitants-01-03-2019-8022531.php
                  </AppLink>
                </>
              )
            }
          >
            199
          </InfoClick>
          , un petit groupe de la Quadrature adresse à la mairie de la ville une
          «&#160;demande Cada&#160;» au sujet d’un projet étrange&#160;:
          l’installation de cinquante&#160;micros, dans un quartier pauvre de la
          ville, pour identifier les sons susceptibles de déclencher une
          intervention de police.
        </Text>
        <Text>
          La réponse arrive dans les temps, début avril, et elle est
          merveilleuse&#160;: soit par ignorance, soit par zèle, la personne
          chargée de notre dossier a tout donné. Plus de deux cents&#160;pages
          sur les rendez-vous de la mairie avec l’entreprise Serenicity, les
          projets abandonnés, les comptes rendus de réunions… Une vraie
          transparence&#160;! Le 15&#160;avril 2019, nous publions tout,
          documents bruts et analyses, sur le site (alors encore embryonnaire)
          de la campagne Technopolice
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Mouchards et drones à Saint-Étienne&#160;: le maire veut
                    étouffer le débat », 15&#160;avril 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://technopolice.fr/blog/mouchards-et-drones-a-saint-etienne-le-maire-veut-etouffer-le-debat/"
                  >
                    https://technopolice.fr/blog/mouchards-et-drones-a-saint-etienne-le-maire-veut-etouffer-le-debat/
                  </AppLink>
                </>
              )
            }
          >
            200
          </InfoClick>
          . On découvre que les micros sont les premiers maillons d’une chaîne.
          Les fameux «&#160;capteurs sonores&#160;» enregistreraient des
          «&#160;anormalités sonores&#160;» (dont la liste est si
          impressionnante –&#160;coups de feu, cris, perceuse, meuleuse,
          klaxons, coups de sifflet, bombes aérosols, crépitements…&#160;– qu’on
          se demande quels bruits n’en font pas partie), déclenchant aussitôt
          une alerte auprès de la police, qui décide alors s’il convient ou non
          d’intervenir.
        </Text>
        <Text>
          Mais ce n’est pas tout&#160;! On apprend ensuite que le projet initial
          comportait une seconde phase, qui donnait la part belle aux drones
          pour «&#160;lever le doute&#160;» des alertes et, éventuellement,
          suivre à la trace les fuyards. Elle s’appuyait aussi sur le
          développement d’une application, permettant aux habitants de signaler
          «&#160;un problème&#160;». Drones et application sont pour l’instant
          abandonnés, du fait d’une «&#160;législation trop stricte&#160;», mais
          jusqu’à quand&#160;?
        </Text>
        <Text>
          Non, vous ne rêvez pas&#160;: c’est une robotisation totale de
          l’espace public, avec disparition de l’humain, comme si la personne se
          déplaçant dans les rues n’était pas seulement suspecte mais dangereuse
          par nature. Les quartiers pauvres considérés comme des zones de
          non-droit, des territoires à réprimer, ce n’est pas une exagération de
          militant ou un fantasme d’opprimé imaginaire&#160;: c’est une réalité
          pratique, une façon de faire, un état de fait dont les personnes
          sérieuses parlent entre elles sur le ton de l’évidence, en
          costume-cravate et en uniforme, une calculette à la main. Nous
          laissons à l’appréciation des lecteurs et des lectrices la difficulté
          de savoir dans quel quartier de la ville, de la banlieue ou du centre
          de décision qui lui envoie des drones, l’humanité semble se retirer le
          plus violemment.
        </Text>
        <Text>
          En même temps que nous publions l’article et les documents
          généreusement fournis par la mairie de Saint-Étienne, nous adressons
          un courrier à la CNIL, car les articles de presse qui évoquent le
          projet confirment tous son accord préalable, mis en avant par la
          mairie de Saint-Étienne pour se couvrir et rassurer tout le monde. En
          mai&#160;2019, la ville annonce qu’elle repousse la mise en place de
          l’expérience
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « L’installation très polémique de capteurs sonores est
                    repoussée à Saint-Étienne », France Bleu, 8&#160;mai
                    2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.francebleu.fr/infos/societe/l-installation-de-capteurs-sonores-est-repoussee-a-saint-etienne-1557289707"
                  >
                    https://www.francebleu.fr/infos/societe/l-installation-de-capteurs-sonores-est-repoussee-a-saint-etienne-1557289707
                  </AppLink>
                </>
              )
            }
          >
            201
          </InfoClick>
          . Le 25&#160;octobre 2019, Marie-Laure Denis, toute nouvelle
          présidente de la CNIL, adresse à la ville un courrier très sec
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Des micros dans la rue&#160;: la CNIL tire les oreilles
                    (intelligentes) de Saint-Étienne », <i>Télérama</i>,
                    29&#160;octobre 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.telerama.fr/medias/la-cnil-tire-les-oreilles-intelligentes-de-saint-etienne,n6492439.php"
                  >
                    https://www.telerama.fr/medias/la-cnil-tire-les-oreilles-intelligentes-de-saint-etienne,n6492439.php
                  </AppLink>
                </>
              )
            }
          >
            202
          </InfoClick>
          &#160;: «&#160;Je vous avertis qu’à défaut d’un cadre légal spécifique
          et adapté […], le traitement de données à caractère personnel en
          question ne saurait être mis en œuvre de façon licite.&#160;»
        </Text>
        <Text>
          Ce «&#160;je vous avertis&#160;» n’est pas une menace de cour de
          récréation&#160;: il s’agit bel et bien d’un avertissement à valeur
          légale, un des pouvoirs dont la CNIL est investie
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Au sujet des pouvoirs de contrôle et de sanction de la
                    CNIL&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.cnil.fr/fr/mission-4-controler-et-sanctionner"
                  >
                    https://www.cnil.fr/fr/mission-4-controler-et-sanctionner
                  </AppLink>
                </>
              )
            }
          >
            203
          </InfoClick>
          . Le projet de micros policiers est aussitôt abandonné
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Saint-Étienne&#160;: La ville se fait tirer les oreilles
                    et renonce à installer des micros dans ses rues »,{" "}
                    <i>20&#160;minutes</i>, 30&#160;octobre 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.20minutes.fr/societe/2640019-20191030-saint-etienne-ville-fait-tirer-oreilles-renonce-installer-micros-rues"
                  >
                    https://www.20minutes.fr/societe/2640019-20191030-saint-etienne-ville-fait-tirer-oreilles-renonce-installer-micros-rues
                  </AppLink>
                </>
              )
            }
          >
            204
          </InfoClick>
          .
        </Text>

        <Text type="h3">Décentraliser la&#160;lutte</Text>
        <Text>
          Valenciennes qui se lance dans la vidéosurveillance automatisée avec
          les outils du Chinois Huawei, Toulouse qui s’acoquine avec IBM, des
          drones à Istres, des capteurs sonores à Saint-Étienne ou à Strasbourg,
          un «&#160;observatoire Big Data de la tranquillité publique&#160;» à
          Marseille, de la détection d’émotions dans le tramway de Nice, une
          société israélienne (Briefcam) qui affirme être présente dans
          cent&#160;villes de France avec son logiciel «&#160;d’aide à la
          vision&#160;»&#160;!… Au fur et à mesure de notre avancée dans le
          nouveau monde de la technopolice, l’évidence s’impose&#160;: les
          expériences partent dans tous les sens, rien n’est vraiment centralisé
          ou valable à l’échelle nationale.
        </Text>
        <Text>
          Le financement est parfois national, en partie grâce à des fonds
          dédiés du ministère de l’Intérieur, et le discours politique
          sécuritaire qui sous-tend l’ensemble est bien entendu national, tout
          comme l’idéologie triomphante de l’alliance entre la technologie,
          l’entreprise privée et la sécurité. Mais l’essentiel de la mise en
          œuvre semble se passer de gré à gré entre des communes et des
          entreprises. Loin d’un grand plan national et gouvernemental concerté,
          on assiste à un fourmillement de petits projets locaux. Il va donc
          falloir se livrer à un travail de fourmi pour documenter et analyser
          ce que les communes inventent, ici ou là, avec l’aide et l’incitation
          très active des entreprises de la surveillance.
        </Text>
        <Text>
          Beaucoup de régions, de départements, de villes grandes ou petites,
          mettent à jour leur système de vidéosurveillance, et les entreprises
          les embarquent alors dans leur logique «&#160;mieux-disante&#160;» de
          technique dernier cri. Certaines sont en relation avec des entreprises
          locales, des start-up hébergées dans des pépinières d’entreprises
          subventionnées, avec qui elles échangent régulièrement, et qui se
          trouvent être des partenaires «&#160;naturelles&#160;» quand vient le
          jour de tester leurs outils&#160;: ainsi les micros de Saint-Étienne
          étaient-ils installés par la société stéphanoise Serenicity, créée par
          le propriétaire et dirigeant de la maison stéphanoise Vernay-Carron,
          qui fabrique des armes de chasse depuis deux cents&#160;ans
          («&#160;L’art de la chasse, l’amour de la nature&#160;») et s’est
          rendue célèbre en fabriquant le fameux Flash-Ball ou lanceur de balle
          de défense (LBD), popularisé depuis 2016 par la police nationale. Du
          côté d’Orléans, une autre expérience de micros dans les rues
          «&#160;sensibles&#160;» est menée durant l’hiver 2021-2022 par une
          entreprise locale nommée Sensivic, intégrée dans un groupe baptisé
          Lorias, et hébergée par un incubateur numérique appelé Le Lab’O, le
          tout étant financé en partie par la région, la commune, et le
          département. La pomme ne tombe jamais loin de l’arbre.
        </Text>
        <Text>
          D’autres communes travaillent en revanche avec de grandes sociétés
          internationales comme Thales (armement) ou Engie Ineo (cybersécurité
          et vidéosurveillance), ou encore Huawei, IBM, etc.
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Pour en savoir plus sur les entreprises de la
                    technopolice&#160;:{" "}
                  </Text>
                  <AppLink white href="https://technopolice.fr/entreprises/">
                    https://technopolice.fr/entreprises/
                  </AppLink>
                </>
              )
            }
          >
            205
          </InfoClick>
          . Mais le point commun entre toutes ces initiatives locales semble
          être la nullité politique. Quand l’heure est à l’abandon des
          politiques publiques, sous la double injonction idéologique de la
          «&#160;réduction de la dette&#160;» et de la meilleure performance
          indiscutable de l’entreprise privée, les personnalités politiques
          –&#160;à tous les étages, nationaux, régionaux, et municipaux&#160;–
          investissent toute leur autorité et leur capacité d’action dans le
          seul domaine qui semble encore relever de façon légitime du bien
          public&#160;: «&#160;la sécurité&#160;». Les maires paraissent
          résignés à l’idée de n’être réélus que pour leur seule et unique
          capacité à mettre partout et toujours plus de police et de matériel de
          surveillance.
        </Text>
        <Text>
          Devant la grande uniformité de ce désir de surveillance, et devant la
          grande dispersion de ses formes locales, le constat s’impose à la
          Quadrature&#160;: on ne pourra pas tout faire tout seul, il faut que
          d’autres s’y mettent aussi.
        </Text>

        <Text>
          Et des autres, il y en a plein&#160;! À Saint-Étienne, par exemple,
          les militants locaux se sont d’abord réunis dans un groupe appelé
          Serenicity-Google, car ils refusaient aussi l’implantation d’un
          «&#160;atelier Google&#160;» dans la ville, avant de se renommer Halte
          au contrôle numérique. À Dijon, Rennes, Amiens, Toulouse ou Lyon, des
          militants bien implantés et très au fait de ce qui se passe dans leur
          ville nous contactent pour parler de ces sujets. L’enjeu pour la
          Quadrature devient donc, très naturellement (parce que c’est dans sa
          logique depuis sa création), de se concentrer sur la fabrication des
          outils qui permettront aux groupes locaux de se rencontrer, et de
          partager des informations, des méthodes et de la motivation. On ne
          peut pas tout faire, mais on peut aider les autres à le faire
          ensemble.
        </Text>
        <Text>
          Il suffit souvent de mettre en lumière des luttes qui existent déjà.
          Par exemple, il est impossible de parler de technopolice sans parler
          du projet{" "}
          <a href="http://sous-surveillance.net">sous-surveillance.net</a>, qui
          s’est longtemps chargé de cartographier dans toute la France les
          caméras de surveillance, chaque ville ayant sa propre page.
        </Text>
        <Text>
          Nous nous sommes inspirés de ce travail car, en lançant le tour de
          France du délire numérique et sécuritaire, la toute première chose
          qu’on imaginait, c’était une carte. Comme dans les albums d’Astérix,
          mais avec les projets sécuritaires de Thales et de Huawei à la place
          des camps romains de Petibonum et de Babaorum. Chacun peut cliquer sur
          le nom de sa ville et avoir aussitôt un résumé clair de la situation,
          avec des liens vers des fiches plus détaillées sur les projets, les
          acteurs locaux et les entreprises, les techniques utilisées, et tous
          les documents bruts obtenus par «&#160;demande Cada&#160;»
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://data.technopolice.fr/fr/">
                  https://data.technopolice.fr/fr/
                </AppLink>
              )
            }
          >
            206
          </InfoClick>
          .
        </Text>
        <Text>
          La «&#160;technocarte
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://carte.technopolice.fr/">
                  https://carte.technopolice.fr/
                </AppLink>
              )
            }
          >
            207
          </InfoClick>
          &#160;» est un outil pratique pour les visiteurs du site, un point
          d’entrée accessible à toutes les curiosités –&#160;simple passant,
          militant, journaliste, chercheur&#160;– mais c’est aussi devenu un des
          aspects les plus spectaculaires de la campagne Technopolice pour
          celles et ceux qui l’alimentent au quotidien&#160;: «&#160;C’est un
          travail assez flippant, où l’on se rend compte du côté toile
          d’araignée, presque gangrenant de ces expérimentations et de ces
          techniques de surveillance&#160;», explique par exemple Martin Drago,
          un des piliers de la campagne, juriste à La Quadrature du Net de 2018
          à 2021. L’infographie qui trace les liens de travail entre les
          entreprises et les collectivités locales renforce encore l’image de la
          toile d’araignée
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://graphcommons.com/graphs/a0367840-d2f9-4f61-8b98-92f7955441c9"
                >
                  https://graphcommons.com/graphs/a0367840-d2f9-4f61-8b98-92f7955441c9
                </AppLink>
              )
            }
          >
            208
          </InfoClick>
          …
        </Text>
        <Text>
          Mais si la carte est un outil de navigation, de consultation des
          informations, le plus difficile est en amont, quand il s’agit de
          rassembler les énergies, de fouiller dans la presse et dans les sites
          spécialisés, dans la communication des entreprises et des communes, de
          lancer des «&#160;demandes Cada&#160;», d’organiser le travail pour
          éviter les doublons et les recherches inutiles, de dépouiller les
          documents reçus, de les analyser, de les synthétiser, puis de publier
          les fiches, d’écrire des articles, etc. Derrière la carte, il y a le
          travail collectif des bénévoles de la campagne Technopolice.
        </Text>
        <Text>
          L’outil premier, par lequel les gens se rencontrent, c’est un forum
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://forum.technopolice.fr/">
                  https://forum.technopolice.fr/
                </AppLink>
              )
            }
          >
            209
          </InfoClick>
          &#160;: ouvert à toutes et à tous, consultable par tout le monde, pour
          essayer de mutualiser les différentes luttes, échanger, s’informer
          aussi, se tenir au courant. On est loin des réseaux sociaux
          sophistiqués et des applications que le Web des GAFAM multiplie depuis
          bientôt quinze&#160;ans. Retour au Web <i>low tech</i> à
          l’ancienne&#160;: un forum, c’est ouvert, c’est simple, et c’est
          costaud. Tout le monde peut s’inscrire très vite, avec une adresse
          e-mail et un pseudonyme, et tout aussitôt lire, répondre, participer.
          Les messages sont classés par grandes catégories, «&#160;Projets et
          villes technopolicières&#160;» (l’entrée géographique),
          «&#160;Technologies et industries de surveillance&#160;» (l’entrée par
          les entreprises et par les techniques mises en œuvre&#160;:
          vidéosurveillance, reconnaissance faciale, etc.), ou «&#160;Stratégies
          et formats de mobilisation&#160;» (comment lutter ensemble). Un espace
          est prévu pour les nouveaux venus qui veulent se présenter et lire les
          présentations des autres participants, et même une rubrique pour les
          discussions libres, qui n’entrent dans aucune catégorie ou qui
          n’auraient même aucun rapport avec le travail en cours. Tout l’intérêt
          du forum est de rester ouvert et transparent, pour que chacun puisse
          venir se renseigner, et trouver le plus vite possible une place, un
          rôle, une réponse ou quelque chose à faire pour aider.
        </Text>
        <Text>
          Le site fournit aussi des packs de communication, des flyers, des
          affiches et des images à imprimer chez soi. Tous les textes écrits par
          la Quadrature ou le site Technopolice, ainsi que les images, sont
          publiés sous licence libre CC by-SA
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>Termes de la licence libre CC by-SA&#160;: </Text>
                  <AppLink
                    white
                    href="https://creativecommons.org/licenses/by-sa/2.0/fr/"
                  >
                    https://creativecommons.org/licenses/by-sa/2.0/fr/
                  </AppLink>
                </>
              )
            }
          >
            210
          </InfoClick>
          , qui permet la libre reproduction à la condition de créditer la
          source.
        </Text>
        <Text>
          Le plus important, du point de vue de l’action efficace, se trouve
          sans doute dans la rubrique «&#160;Se mobiliser
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://technopolice.fr/semobiliser/">
                  https://technopolice.fr/semobiliser/
                </AppLink>
              )
            }
          >
            211
          </InfoClick>
          &#160;». Elle renvoie vers le forum, vers l’espace de rédaction
          collective des fiches de synthèse (un ensemble de pads baptisé le
          Carré
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink white href="https://carre.technopolice.fr/">
                  https://carre.technopolice.fr/
                </AppLink>
              )
            }
          >
            212
          </InfoClick>
          ), vers la base de données des documents collectés, mais aussi, et
          surtout, vers la page des guides juridiques
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://technopolice.fr/semobiliser/guidesjuridiques/"
                >
                  https://technopolice.fr/semobiliser/guidesjuridiques/
                </AppLink>
              )
            }
          >
            213
          </InfoClick>
          . Ici, les bénévoles qui souhaitent contribuer à la campagne et
          documenter les pratiques de technopolice dans leur commune peuvent
          apprendre la base de la méthode de travail. D’abord, ils trouveront un
          guide pratique de la «&#160;demande Cada&#160;»
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://technopolice.fr/blog/guide-se-renseigner-sur-la-surveillance-dans-sa-ville/"
                >
                  https://technopolice.fr/blog/guide-se-renseigner-sur-la-surveillance-dans-sa-ville/
                </AppLink>
              )
            }
          >
            214
          </InfoClick>
          &#160;: quels documents demander, à qui, et comment. L’explication est
          très simple, même les novices peuvent se lancer le jour même. Enfin,
          la page des guides juridiques renvoie vers les textes de plusieurs
          recours en justice que nous avons écrits pour contester des
          dispositifs de technopolice&#160;: bien que ce soit un exercice qui
          s’adresse plutôt à des juristes, tout le monde est invité à s’emparer
          des moyens que la loi donne aux citoyens pour faire valoir leurs
          droits et défendre leurs libertés.
        </Text>
        <Text>
          La campagne Technopolice est lancée de façon très officielle en
          septembre&#160;2019. Une soirée est organisée à Nice, la ville où
          Christian Estrosi a voulu, sans grand succès&#160;:
        </Text>
        <ul>
          <li>
            <Text>
              lancer «&#160;Reporty&#160;», une application de délation civique
              pour que les habitants de la ville dénoncent, avec la caméra de
              leur smartphone, les faits dont ils sont les témoins
              <InfoClick
                onClick={() =>
                  setModalOpen(
                    <>
                      <Text>
                        « La <i>smart city</i> policière se répand comme une
                        traînée de poudre », 6&#160;juillet 2018&#160;:{" "}
                      </Text>
                      <AppLink
                        white
                        href="https://www.laquadrature.net/2018/07/06/nice-smart-city-surveillance/"
                      >
                        https://www.laquadrature.net/2018/07/06/nice-smart-city-surveillance/
                      </AppLink>
                    </>
                  )
                }
              >
                215
              </InfoClick>
              ,
            </Text>
          </li>
          <li>
            <Text>
              tester la reconnaissance faciale dans la foule du carnaval de la
              ville,
            </Text>
          </li>
          <li>
            <Text>
              envisager la détection des émotions dans les transports publics de
              la ville «&#160;en vue de détecter d’éventuels mouvements de
              panique et de stress
              <InfoClick
                onClick={() =>
                  setModalOpen(
                    <>
                      <Text>
                        « Un logiciel pour décoder les émotions des usagers du
                        tramway de Nice », France Bleu, 4&#160;janvier
                        2019&#160;:{" "}
                      </Text>
                      <AppLink
                        white
                        href="https://www.francebleu.fr/infos/societe/un-logiciel-pour-decoder-les-emotions-des-usagers-du-tramway-de-nice-1546621455"
                      >
                        https://www.francebleu.fr/infos/societe/un-logiciel-pour-decoder-les-emotions-des-usagers-du-tramway-de-nice-1546621455
                      </AppLink>
                    </>
                  )
                }
              >
                216
              </InfoClick>
              &#160;» ou des passages à l’acte violents,
            </Text>
          </li>
          <li>
            <Text>
              obliger les lycéens à se soumettre à la reconnaissance faciale
              pour entrer dans leur lycée.
            </Text>
          </li>
        </ul>
        <Text>
          Un tel talent pour la surveillance et la surenchère technologique
          méritait au minimum un satisfecit, et désignait Nice comme la
          meilleure ville technopolicière de France. À tout seigneur tout
          honneur, la conférence de presse de lancement a donc lieu à Nice, avec
          les associations en compagnie desquelles nous déposons un recours au
          tribunal administratif contre l’expérience de reconnaissance à
          l’entrée des lycées. Un recours gagné, comme on l’a vu plus haut.
        </Text>
        <Text>
          Avec un peu de recul, deux années après le lancement du site et de la
          campagne officielle, force est de reconnaître que notre idéal d’une
          lutte décentralisée, réseautée et disséminée dans toute la France est
          encore loin d’être atteint. Nous chapeautons encore le site et
          abattons 95&#160;% du travail d’information, d’analyse, de
          cartographie, ou de communication. Mais il est difficile de mesurer
          combien nos analyses et nos cartographies sont diffusées ou reprises.
          Des discussions sur le forum se font sans nous, des échanges et des
          groupes locaux aussi. Et un mouvement Technopolice autonome s’est créé
          en Belgique&#160;! <i>Last but not least</i>, le terme
          «&#160;technopolice&#160;» s’est fait une place dans la presse et dans
          le débat public, ce qui n’est pas la moindre des victoires…
        </Text>

        <Text type="h3">«&#160;Ah non,&#160;pas&#160;vous&#160;!&#160;»</Text>
        <Text>
          Deux&#160;mois après le lancement de la campagne, fin
          novembre&#160;2019, nous partons joyeusement, sous une pluie fine et
          glaciale, pour une soirée de tractage devant Paris Expo (un grand
          centre de congrès situé porte de Versailles à Paris), où se tient le
          102<sup>e</sup>&#160;congrès de l’Association des maires de France.
          Depuis plusieurs mois nous voyons la reconnaissance faciale envisagée
          partout en France, en même temps que de grandes villes et des
          collectivités locales aux États-Unis prennent, avec un temps d’avance,
          des décisions démocratiques pour interdire la reconnaissance faciale
          policière sur leur territoire (c’est par exemple le cas de l’État de
          Californie, en octobre&#160;2019
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La Californie interdit la reconnaissance faciale sur les
                    caméras des policiers », <i>Le Journal de Montréal</i>,
                    10&#160;octobre 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.journaldemontreal.com/2019/10/10/la-californie-interdit-la-reconnaissance-faciale-sur-les-cameras-des-policiers"
                  >
                    https://www.journaldemontreal.com/2019/10/10/la-californie-interdit-la-reconnaissance-faciale-sur-les-cameras-des-policiers
                  </AppLink>
                </>
              )
            }
          >
            217
          </InfoClick>
          ). Le tract que nous avons préparé pour les «&#160;maires de
          France&#160;» est une invitation très nette, d’abord à mener des
          politiques municipales plus intelligentes, plus sociales et moins
          sécuritaires, et ensuite à prendre publiquement position contre
          l’utilisation de la reconnaissance faciale policière.
        </Text>
        <Text>
          Ce jeudi 21&#160;novembre, la nuit est tombée tôt et la pluie est
          têtue. C’est le dernier jour du congrès annuel des maires, les plus
          pressés partent déjà attraper le train du retour, d’autres arrivent en
          bavardant, après avoir pris un dernier verre au buffet de clôture
          offert par l’organisation du congrès. À toutes et à tous nous tendons
          notre petit tract orange ou vert le plus joyeusement possible&#160;:
          «&#160;La Quadrature du Net&#160;! Refusez la reconnaissance
          faciale&#160;!&#160;» Beaucoup prennent le petit papier, quelques-uns
          le refusent avec un air pressé, comme on l’a tous fait au moins une
          fois devant les démarcheurs de rue, avec parfois un rapide échange de
          plaisanteries, «&#160;merci et bonne soirée&#160;», et enfin, bien
          sûr, quelquefois la discussion s’engage.
        </Text>
        <Text>
          Un maire nous demande ce que nous avons contre la reconnaissance
          faciale, qui aide à lutter contre le terrorisme. Plusieurs affirment
          que la vidéosurveillance est souvent utile et donc parfaitement
          nécessaire. On répond avec autant de conviction mais avec la certitude
          inverse, on cite des chiffres, des études, en essayant d’entrouvrir
          une vision plus large de la société qu’on est en train de construire.
          Un maire d’une commune rurale explique, désolé, à l’abri d’un auvent,
          qu’il a dû faire installer trois caméras autour de la salle des fêtes
          de son village sous la pression des assureurs&#160;: après un
          cambriolage, les voleurs ayant emporté le matériel de la sono et de
          l’éclairage, l’assurance du bâtiment était soumise à l’installation
          préalable des caméras. Un autre élu estime qu’avec la reconnaissance
          faciale on pourra enfin savoir qui pisse dans les rues. D’autres,
          enfin, plutôt des femmes d’ailleurs, manifestent un intérêt réel pour
          nos idées, et veulent savoir comment faire pour exprimer leur refus de
          la surveillance automatisée. Plusieurs nous demandent comment nous
          contacter plus tard.
        </Text>
        <Text>
          Les discussions sont passionnantes, parce que dans cette coupe
          sociologique mince –&#160;les maires des communes de France encore
          présents à la clôture d’un congrès parisien un soir pluvieux de
          novembre&#160;–, on voit déjà tout un éventail de discours et de
          positions, on constate la prégnance de l’idéologie sécuritaire
          dominante, le ton d’évidence incontestable sur lequel elle s’exprime,
          et le peu de place laissé dans les imaginaires à la possibilité d’une
          société qui ne serait pas celle de la surveillance généralisée.
        </Text>
        <Text>
          Il fait froid, on n’a presque plus de tracts, on envisage de lever le
          camp. Arrive un petit groupe pressé, au milieu duquel se trouve Gaël
          Perdriau, le maire de Saint-Étienne. On lui tend un tract, sa réponse
          fuse&#160;: «&#160;Ah non, pas vous&#160;!&#160;» L’annulation des
          micros a dû lui rester en travers de la gorge.
        </Text>

        <Text type="h3">
          Les&#160;cent visages de&#160;la&#160;reconnaissance faciale
        </Text>
        <Text>
          L’année 2019 fut pour nous celle de la reconnaissance faciale. La
          technologie est partout, au point qu’on publie un article sur le
          sujet, en dehors de toute actualité précise, simplement pour poser
          quelques définitions et quelques pistes de réflexion sur le sujet
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Le vrai visage de la reconnaissance faciale »,
                    21&#160;juin 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/06/21/le-vrai-visage-de-la-reconnaissance-faciale/"
                  >
                    https://www.laquadrature.net/2019/06/21/le-vrai-visage-de-la-reconnaissance-faciale/
                  </AppLink>
                </>
              )
            }
          >
            218
          </InfoClick>
          &#160;: distinction entre identification et authentification,
          nécessité de distinguer les différentes finalités des usages, saut
          anthropologique d’une société où le visage trahit la personne et la
          livre au pouvoir policier –&#160;on esquisse, on défriche, on
          découvre.
        </Text>
        <Text>
          Pendant ce temps, autour de nous, la mise en place de la technologie
          s’accélère, dans un contexte social très tendu. Chaque samedi, les
          «&#160;gilets jaunes&#160;» manifestent dans la France entière, et la
          majorité gouvernementale semble trouver secondaire que la police
          réprime durement les manifestations, au prix de mains ou d’yeux
          arrachés, quand la réalité de ces blessures atroces n’est pas tout
          simplement occultée par les grands médias, ou niée par les ministres
          et le chef de l’État lui-même. Dans un contexte où les luttes sociales
          sont criminalisées et matées par les armes (des armes «&#160;non
          létales&#160;», bien entendu), nous voyons la reconnaissance faciale
          s’imposer dans des domaines aussi divers que la vidéosurveillance, le
          ciblage publicitaire, les démarches administratives ou les contrôles
          policiers de routine. On découvre ce qui n’était d’ailleurs pas caché,
          parce que personne ne s’en était jamais offusqué&#160;: depuis 2012,
          les policiers peuvent utiliser les images de vidéosurveillance, les
          images des réseaux sociaux, ou les photos qu’ils prennent lors des
          manifestations ou des contrôles, et les comparer par reconnaissance
          faciale avec les photos enregistrées dans le TAJ.
        </Text>
        <Text>
          Le TAJ, pour «&#160;traitement des antécédents judiciaires&#160;», est
          un énorme fichier de police dans lequel sont entassées, pêle-mêle,
          toutes les personnes avec qui la police a eu affaire durant ses
          enquêtes&#160;: mis en cause, complices éventuels, et bien sûr
          victimes. Si les policiers appliquaient la loi, ils devraient effacer
          de ce fichier les personnes dont la mise en cause n’a pas donné lieu à
          une condamnation judiciaire. Malheureusement, les procureurs le
          reconnaissent, personne ne veille à effacer ces données. Les photos et
          les noms s’accumulent donc dans le fichier pour vingt&#160;ans
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Voir la fiche de la CNIL sur le fichier TAJ&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.cnil.fr/fr/taj-traitement-dantecedents-judiciaires"
                  >
                    https://www.cnil.fr/fr/taj-traitement-dantecedents-judiciaires
                  </AppLink>
                </>
              )
            }
          >
            219
          </InfoClick>
          . En 2018, le fichier TAJ comptait 18&#160;millions de fiches et
          8&#160;millions de photos
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Chiffres tirés d’un rapport parlementaire « d’information
                    sur les fichiers mis à la disposition des forces de police
                    », octobre&#160;2018, consultable ici&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="http://www.assemblee-nationale.fr/dyn/15/rapports/cion_lois/l15b1335_rapport-information.pdf"
                  >
                    http://www.assemblee-nationale.fr/dyn/15/rapports/cion_lois/l15b1335_rapport-information.pdf
                  </AppLink>
                </>
              )
            }
          >
            220
          </InfoClick>
          . Or, la reconnaissance faciale est autorisée au sein du fichier TAJ
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Pour tout savoir sur la naissance, la composition et
                    l’utilisation du fichier TAJ, lisez notre article du
                    18&#160;novembre 2019, « La reconnaissance faciale des
                    manifestant.es est déjà autorisée »&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/11/18/la-reconnaissance-faciale-des-manifestants-est-deja-autorisee/"
                  >
                    https://www.laquadrature.net/2019/11/18/la-reconnaissance-faciale-des-manifestants-est-deja-autorisee/
                  </AppLink>
                </>
              )
            }
          >
            221
          </InfoClick>
          .
        </Text>
        <Text>
          En 2019, les policiers envoient 375&#160;000&#160;requêtes de
          reconnaissance faciale dans le TAJ. Plus de mille fois par jour, le
          fichier est interrogé pour tenter d’identifier des personnes dont on
          ne connaît que le visage sur une photo. Des délinquants&#160;?
          Sûrement. Des personnes qui subissent un banal contrôle routier&#160;?
          C’est fort probable. Des manifestants dans la rue&#160;? C’est
          possible. C’est pourquoi nous publions en novembre&#160;2019 un
          article qui saisit justement la question sous cet angle&#160;:
          «&#160;La reconnaissance faciale des manifestants est déjà
          autorisée&#160;».
        </Text>
        <Text>
          Il n’existe (pour l’heure) aucune loi rendant explicitement possible
          la reconnaissance faciale des manifestants, mais cet article démontre
          pourtant, point par point, que la chose est techniquement et surtout
          légalement possible. Comment&#160;? D’abord, comme on l’a vu, la
          reconnaissance faciale est une des fonctions disponibles du TAJ.
          Ensuite, parce que les policiers peuvent accéder depuis 2007 au
          fichier TES (pour «&#160;titres électroniques sécurisés&#160;»), qui
          rassemble depuis 2005 toutes les personnes ayant obtenu une carte
          d’identité ou un passeport biométriques obligatoires, et conserve
          depuis 2008 toutes les photos d’identité numérisées qui sont imprimées
          sur les pièces d’identité. Voilà qui ajoute quelques dizaines de
          millions de visages au grand vivier disponible pour la reconnaissance
          faciale de masse… L’article se termine en annonçant que nous avons
          envoyé au gouvernement une demande d’abrogation des fonctions de
          reconnaissance faciale dans le TAJ, qui serait ensuite formulée, en
          cas de refus, sous forme de recours devant le Conseil d’État. Sans
          surprise, le recours est déposé en août&#160;2020
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « Nous attaquons la reconnaissance faciale dans le TAJ »,
                    7&#160;août 2020&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2020/08/07/nous-attaquons-la-reconnaissance-faciale-dans-le-taj/"
                  >
                    https://www.laquadrature.net/2020/08/07/nous-attaquons-la-reconnaissance-faciale-dans-le-taj/
                  </AppLink>
                </>
              )
            }
          >
            222
          </InfoClick>
          . En&#160;janvier&#160;2022, le Conseil d’État n’a toujours pas rendu
          sa décision.
        </Text>
        <Text>
          Autre porte d’entrée de la reconnaissance faciale&#160;: l’application
          Alicem, pour «&#160;application de lecture de l’identité d’un citoyen
          en mobilité&#160;». Si vous ne la connaissez pas, c’est normal. Pour
          aller vite&#160;: les agences d’État ont développé, dans la logique du
          «&#160;guichet numérique&#160;», un système d’identifiant unique pour
          les services publics en ligne, nommé France Connect. Sur cette base
          s’appuie un «&#160;projet d’application pour prouver son identité en
          ligne&#160;» et cette application prototype s’appelait Alicem. Mais
          pour créer votre profil vérifié, l’application vous imposait de
          scanner la puce RFID de votre pièce d’identité numérique (qui contient
          votre photo d’identité), puis de vous photographier et de vous filmer
          avec votre smartphone, pour analyser les images par comparaison
          faciale.
        </Text>
        <Text>
          De notre point de vue, ce genre de gadget banalise la reconnaissance
          faciale en laissant croire qu’elle est efficace, moderne et sûre, et
          en lui donnant un rôle positif. Comment des personnes qui ont utilisé
          la reconnaissance faciale sur elles-mêmes de manière inoffensive et
          même rassurante pourraient-elles refuser ensuite de s’y soumettre dans
          la rue, quand des caméras «&#160;intelligentes&#160;» les dévisageront
          et les identifieront plusieurs fois par jour&#160;? En
          juillet&#160;2019, nous attaquons donc l’application Alicem devant le
          Conseil d’État
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « La Quadrature du Net attaque l’application Alicem, contre
                    la généralisation de la reconnaissance faciale »,
                    17&#160;juillet 2019&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.laquadrature.net/2019/07/17/la-quadrature-du-net-attaque-lapplication-alicem-contre-la-generalisation-de-la-reconnaissance-faciale/"
                  >
                    https://www.laquadrature.net/2019/07/17/la-quadrature-du-net-attaque-lapplication-alicem-contre-la-generalisation-de-la-reconnaissance-faciale/
                  </AppLink>
                </>
              )
            }
          >
            223
          </InfoClick>
          . Le droit précise bien que la biométrie doit être minimisée et
          utilisée seulement en cas de nécessité, c’est-à-dire lorsque aucune
          autre possibilité pratique ne permet d’atteindre le même but. Mais
          Alicem ne permet pas aux personnes qui veulent créer une identité
          numérique de le faire sans se soumettre à la reconnaissance faciale.
          Même la CNIL est d’accord avec nous et publie un avis opposé à
          l’expérimentation gouvernementale&#160;: «&#160;Le consentement au
          traitement des données biométriques ne peut être regardé comme libre
          et comme étant par suite susceptible de lever l’interdiction posée par
          l’article&#160;9.1 du RGPD.&#160;» Malgré cela et comme souvent, le
          gouvernement n’a pas modifié son décret en le publiant. Il s’agit donc
          bien ici de normaliser la reconnaissance faciale comme outil
          d’identification, en passant outre la seule condition qui devrait être
          acceptable pour son utilisation&#160;: notre consentement libre et
          explicite. Le Conseil d’État rejette sèchement notre recours en
          novembre&#160;2020, et nous concède seulement la nécessité de proposer
          un autre moyen d’identification que la reconnaissance faciale… qui
          semble pour l’instant enterrée dans le projet Alicem.
        </Text>
        <Text>
          Mais la reconnaissance faciale, on vous le dit, est partout
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Voir l’article « Tentative d’état des lieux de la
                    reconnaissance faciale en France en 2021 »,
                    21&#160;juin&#160;2021&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://technopolice.fr/blog/tentative-detat-des-lieux-de-la-reconnaissance-faciale-en-france-en-2021/"
                  >
                    https://technopolice.fr/blog/tentative-detat-des-lieux-de-la-reconnaissance-faciale-en-france-en-2021/
                  </AppLink>
                </>
              )
            }
          >
            224
          </InfoClick>
          &#160;: si vous passez par un aéroport dans les semaines qui viennent,
          on vous proposera peut-être de passer par un portique de
          reconnaissance faciale, mais seulement avec votre consentement
          explicite –&#160;et vous pourrez refuser. Il existe deux projets
          expérimentaux, PARAFE (pour les voyageurs hors espace Schengen) et
          MONA (pour les voyages à l’intérieur de l’Union européenne), et tous
          deux sont menés par Idemia, une société française spécialiste des
          techniques de l’identité biométrique. La CNIL a validé les deux
          expérimentations, parce qu’elles sont soumises au consentement
          explicite des participants. Mais comme avec Alicem, nous craignons que
          la banalisation de ce type de gadgets ludiques, qui
          «&#160;fluidifient&#160;» la vie des personnes les plus aisées, ne
          masque dans les imaginaires la réalité d’une reconnaissance faciale de
          surveillance, massive, subie et beaucoup moins «&#160;cool&#160;».
        </Text>

        <Text type="h3">Drones policiers&#160;: un&#160;œil&#160;volant</Text>
        <Text>
          Autre découverte notable de la campagne Technopolice en 2019&#160;:
          des drones policiers volent déjà au-dessus de nos têtes. On l’a appris
          à la faveur d’une «&#160;demande Cada&#160;» adressée à la ville
          d’Istres, dans les Bouches-du-Rhône, à la suite de la lecture d’un
          article paru dans <i>Le&#160;Monde</i>
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    « À Istres, la mairie équipe la police de drones pour
                    compléter le dispositif de vidéosurveillance »,{" "}
                    <i>Le Monde</i>, 6&#160;avril 2018&#160;:{" "}
                  </Text>
                  <AppLink
                    white
                    href="https://www.lemonde.fr/la-foire-du-drone/article/2018/04/06/a-istres-des-drones-pour-la-videosurveillance_5281508_5037916.html"
                  >
                    https://www.lemonde.fr/la-foire-du-drone/article/2018/04/06/a-istres-des-drones-pour-la-videosurveillance_5281508_5037916.html
                  </AppLink>
                </>
              )
            }
          >
            225
          </InfoClick>
          . Dans les documents obtenus, la ville reconnaît sans difficulté que
          les deux drones vidéo de très haute définition qu’elle a achetés ont
          servi, pour 73&#160;% de leur temps de vol, à surveiller des
          manifestations, en complément des quatre-vingt-dix&#160;caméras fixes
          déjà installées dans les rues de la ville
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Voir la fiche de la ville sur le site Technopolice&#160;:{" "}
                  </Text>
                  <AppLink white href="https://technopolice.fr/istres/">
                    https://technopolice.fr/istres/
                  </AppLink>
                </>
              )
            }
          >
            226
          </InfoClick>
          …
        </Text>
        <Text>
          C’est un point capital des tendances actuelles de la
          technopolice&#160;: la multiplication des caméras. En même temps que
          l’intelligence artificielle pour analyser les images se développe et
          s’implante partout, les sources d’images se multiplient dans toutes
          les directions. On installe toujours plus de caméras fixes de
          vidéosurveillance, mais aussi des caméras personnelles portées par les
          policiers et les gendarmes, et plus haut, les drones. Voilà pourquoi
          nous n’hésitons pas à parler de contrôle total de l’espace
          public&#160;: des caméras partout, et qui peuvent se déplacer, et une
          intelligence artificielle au sol qui peut tout analyser, et même
          appliquer de la reconnaissance faciale. Voilà l’état des lieux à la
          fin de 2019. L’année 2020, à la faveur de la crise sanitaire,
          accélérera cette tendance d’une manière stupéfiante. Mais comme disait
          Rudyard Kipling, c’est une autre histoire…
        </Text>

        <Text type="h3">Organiser les&#160;fuites</Text>
        <Text>
          Les «&#160;demandes Cada&#160;» sont une source généreuse de documents
          et d’informations. Mais elles ont l’inconvénient de ne concerner que
          les administrations publiques. Que se passe-t-il, que se prépare-t-il
          dans le secret des entreprises&#160;? Que manigancent les partis et
          les états-majors politiques&#160;? Pour le savoir, il faut bénéficier
          de fuites, d’indiscrétions, de confidences. On peut apprendre beaucoup
          de choses en fréquentant les personnes chargées de prendre les
          décisions, c’est un travail de journaliste. Mais comment accueillir
          les informations vraiment sensibles&#160;? Si&#160;une personne
          employée dans une entreprise de la surveillance, ou dans un ministère,
          entendait parler d’une chose vraiment grave, oserait-elle la rendre
          publique&#160;? Le risque peut retenir les plus lucides, surtout quand
          on voit le sort réservé dans la loi, à dessein, aux lanceurs et aux
          lanceuses d’alerte. C’est pourquoi nous avons mis en place un dépôt
          sécurisé pour que les personnes en possession de documents sensibles
          puissent venir les déposer de manière anonyme
          <InfoClick
            onClick={() =>
              setModalOpen(
                <>
                  <Text>
                    Aucun besoin d’être ingénieur en informatique, une page du
                    site Technopolice explique pas à pas comment faire :{" "}
                  </Text>
                  <AppLink white href="https://technopolice.fr/leak/">
                    https://technopolice.fr/leak/
                  </AppLink>
                </>
              )
            }
          >
            227
          </InfoClick>
          .
        </Text>

        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter3Part3;
