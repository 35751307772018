import React, { useState } from "react";

import { AppLink, InfoClick, Modal, Text, PagePattern } from "src/components";

const Chapter2Part3 = () => {
  const [isModalOpen, setModalOpen] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <PagePattern partName="Extension des outils antiterroristes">
      <>
        <Text>
          S’il y a une idée qui revient souvent dans les analyses de la
          Quadrature, c’est celle du danger de mettre en place une
          infrastructure, qu’elle soit technique ou légale, en jurant qu’elle ne
          servira que dans un but précis, et noble, bien évidemment. Certains
          esprits chagrins diraient que c’est une marotte, mais l’histoire nous
          a donné un nombre déprimant d’exemples de détournement de ce type.
          Ainsi, la collecte de données dans un but parfois justifié à l’origine
          peut dériver trois, cinq, dix, vingt&#160;ans plus tard. De manière
          complètement légale.
        </Text>
        <Text>
          Le Fichier national automatisé des empreintes génétiques (FNAEG) en
          est un exemple incroyable (et révoltant). Ce fichier, créé en 1998 et
          censé servir à la lutte contre les délinquants sexuels, comptait moins
          de 5&#160;000&#160;personnes en 2002. En 2020, il en compte plus de
          4,8&#160;millions, soit environ 8&#160;% de la population
          française&#160;! Cette augmentation presque exponentielle s’explique
          par l’extension continue du périmètre du fichage génétique en France,
          dû à de multiples lois successives. C’est ce qui conduit à des
          situations ubuesques, comme celle de 2006, quand des lycéens mis en
          garde à vue après avoir participé aux manifestations contre le contrat
          première embauche (CPE) ont été obligés de se soumettre à des
          prélèvements ADN
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://section-ldh-toulon.net/deux-ex-lyceennes-anti-CPE-vont.html"
                >
                  https://section-ldh-toulon.net/deux-ex-lyceennes-anti-CPE-vont.html
                </AppLink>
              )
            }
          >
            88
          </InfoClick>
          … De même que des enfants à la suite d’un vol de tamagotchis
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.lefigaro.fr/actualite/2007/05/16/01001-20070516ARTFIG90039-prelevements_de_salive_le_front_du_refus_s_organise.php"
                >
                  https://www.lefigaro.fr/actualite/2007/05/16/01001-20070516ARTFIG90039-prelevements_de_salive_le_front_du_refus_s_organise.php
                </AppLink>
              )
            }
          >
            89
          </InfoClick>
          &#160;! On est loin du but originel du fichier.
        </Text>
        <Text>
          En 2021, le FNAEG contient plus d’un tiers des Français
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.nextinpact.com/article/48209/plus-dun-tiers-francais-sont-fiches-dans-fnaeg"
                >
                  https://www.nextinpact.com/article/48209/plus-dun-tiers-francais-sont-fiches-dans-fnaeg
                </AppLink>
              )
            }
          >
            90
          </InfoClick>{" "}
          si l’on compte les personnes directement identifiées et les parentèles
          (parents, frères et sœurs) indirectement identifiables (les parentèles
          multiplient le nombre de personnes identifiables par cinq). Bien que
          plus de 80&#160;% des personnes y soient enregistrées en tant que
          simples «&#160;mis en cause&#160;» (donc présumés innocents), leurs
          empreintes sont conservées pendant quarante&#160;ans. Un véritable
          «&#160;fichier des honnêtes gens&#160;».
        </Text>
        <Text>
          Ce phénomène est connu et étudié. En anglais, il a un nom
          descriptif&#160;: on parle de «<i>&#160;mission creep</i>&#160;» (ou
          «&#160;<i>scope creep&#160;</i>»), cette idée que la mission (ou le
          périmètre) d’origine s’étire jusqu’à couvrir des zones bien plus
          larges que celles initialement prévues. Au niveau européen,
          l’expression est régulièrement employée pour décrire des lois qui
          commencent par un cadre donné et l’étendent, l’étendent, jusqu’à
          englober des choses qui n’ont aucun rapport avec le premier cadre, que
          ce soit par tactique, par bêtise ou par hubris.
        </Text>
        <Text>
          Cette «&#160;dérive des missions&#160;», en bon français, se combine
          souvent avec un autre phénomène que l’on redoute à la
          Quadrature&#160;: <i>l’effet cliquet</i>. L’effet cliquet, c’est cette
          (quasi-)impossibilité de retour en arrière, une fois un certain stade
          passé. En 2015, un nouvel exemple de ces phénomènes va nous être donné
          en France.
        </Text>
        <Text type="h3">L’état d’urgence, c’est maintenant</Text>
        <Text>
          Le 13&#160;novembre 2015, au soir des attaques-suicides et de la prise
          d’otage du Bataclan, et alors que celle-ci est encore en cours,
          François Hollande décrète l’état d’urgence, pour une durée déterminée
          de douze jours
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000031473404/"
                >
                  https://www.legifrance.gouv.fr/loda/id/JORFTEXT000031473404/
                </AppLink>
              )
            }
          >
            91
          </InfoClick>
          . Trois jours plus tard, le président de la République annonce devant
          le Congrès une ribambelle de mesures qu’il souhaite dérouler dans les
          prochains mois&#160;: prolongation de l’état d’urgence,
          intensification des opérations françaises en Syrie, révision de la
          Constitution pour «&#160;agir contre le terrorisme de guerre&#160;»,
          création de postes supplémentaires dans les forces de sécurité…
        </Text>
        <Text>
          La Conférence de Paris sur le climat doit avoir lieu quelques semaines
          plus tard, du 30&#160;novembre au 12&#160;décembre 2015 au Bourget, et
          à cette occasion de nombreux militants écologistes ont prévu des
          actions à Paris, en France et en Europe pour porter leurs
          revendications. Dès le 18&#160;novembre, au prétexte de la nécessité
          de renforcer la sécurité sur la voie publique, le gouvernement annonce
          l’interdiction de la Marche mondiale pour le climat prévue pour le
          29&#160;novembre.
        </Text>
        <Text>
          Quelques jours après, l’état d’urgence est consacré et étendu pour
          trois&#160;mois par la loi du 20&#160;novembre
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000031500831/"
                >
                  https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000031500831/
                </AppLink>
              )
            }
          >
            92
          </InfoClick>
          , qui autorise notamment l’assignation à résidence des personnes dont
          le «&#160;comportement constitue une menace pour la sécurité et
          l’ordre publics&#160;». Il sera renouvelé six&#160;fois&#160;!
        </Text>
        <Text>
          Très vite, cette loi d’exception censée protéger la France des
          attentats terroristes est mise en application pour freiner les
          militants écologistes. Dans la semaine qui suit, des activistes,
          impliqués dans les mouvements de contestation autour du projet
          d’aéroport de Notre-Dame-des-Landes, font l’objet de violentes
          perquisitions en Dordogne, à Ivry-sur-Seine, à Rennes, à Rouen, à
          Lyon, parfois sous des prétextes flous, voire franchement fallacieux.
          Le 28&#160;novembre, le ministre de l’Intérieur Bernard Cazeneuve
          annonce l’assignation à résidence de vingt-quatre militants, jusqu’au
          12&#160;décembre, date de fin de la COP21
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.lemonde.fr/societe/article/2015/11/27/les-militants-de-la-cop21-cible-de-l-etat-d-urgence_4818885_3224.html"
                >
                  https://www.lemonde.fr/societe/article/2015/11/27/les-militants-de-la-cop21-cible-de-l-etat-d-urgence_4818885_3224.html
                </AppLink>
              )
            }
          >
            93
          </InfoClick>
          .
        </Text>
        <Text>
          Malgré les interdictions, les perquisitions, les assignations,
          plusieurs milliers de manifestants se réunissent le 29&#160;novembre
          sur la place de la République à Paris, où devait initialement avoir
          lieu la Marche mondiale pour le climat
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.liberation.fr/france/2015/11/29/cop-21-malgre-l-interdiction-manifestation-tendue-en-cours-place-de-la-republique-a-paris_1417000/"
                >
                  https://www.liberation.fr/france/2015/11/29/cop-21-malgre-l-interdiction-manifestation-tendue-en-cours-place-de-la-republique-a-paris_1417000/
                </AppLink>
              )
            }
          >
            94
          </InfoClick>
          . Certains sont venus de loin. Un groupe de zadistes de
          Notre-Dame-des-Landes est présent, tout comme des militants du NPA ou
          des membres de la Brigade activiste des clowns. On compte aussi de
          nombreuses personnes simplement concernées par le sujet
          –&#160;enfants, adolescents, personnes âgées… Le rassemblement démarre
          dans une bonne ambiance. Mais toutes les artères autour de la place de
          la République sont bloquées par la police, à une exception près. La
          manifestation ne peut que faire le tour de la place. La tension monte
          très vite. Les policiers qui encadrent la place font, aléatoirement,
          quelques pas en avant, créant confusion et inquiétude chez les
          manifestants qui, pour la plupart, discutent climat et état d’urgence
          en petits groupes. Rapidement, la place se recouvre d’un épais nuage
          de gaz lacrymogène. Les gens, parfois des personnes âgées ou des
          parents avec leurs enfants, que la police a laissés entrer sans
          sourciller sur la place, courent d’un côté à l’autre entraînant des
          mouvements de foule. La police charge une fois, deux fois, trois fois.
          Les manifestants et les policiers piétinent les bougies et les fleurs
          qui avaient été rassemblées en un mémorial aux victimes des attentats.
          À l’issue de cette journée mouvementée, la préfecture de police aura
          procédé à 341&#160;interpellations et 316&#160;mises en garde à vue,
          dont seules deux aboutiront à une condamnation, dont une pour refus de
          donner ses empreintes digitales.
        </Text>
        <Text>
          La première manifestation sous le régime de l’état d’urgence donne le
          ton de la montée de la répression policière permise par l’arsenal
          antiterroriste. Désormais, les manifestations seront des
          confrontations. Et concernant le niveau de violence, c’est encore ce
          qu’un haut responsable des forces de l’ordre confiait au{" "}
          <i>Monde diplomatique</i> il y a quelques années qui l’explique le
          mieux&#160;: «&#160;C’est nous, l’institution, qui fixons le niveau de
          violence de départ. Plus la nôtre est haute, plus celle des
          manifestants l’est aussi
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.monde-diplomatique.fr/2019/01/BONELLI/59444"
                >
                  https://www.monde-diplomatique.fr/2019/01/BONELLI/59444
                </AppLink>
              )
            }
          >
            95
          </InfoClick>
          .&#160;»
        </Text>
        <Text>
          Celle-ci existe dans un cadre, et le cadre ne cesse de s’étendre.
          François Hollande le confirmera d’ailleurs dans une série d’entretiens
          publiés l’année suivante dans le livre{" "}
          <i>Un président ne devrait pas dire ça</i>&#160;: «&#160;C’est vrai,
          l’état d’urgence a servi à sécuriser la COP21, ce qu’on n’aurait pas
          pu faire autrement […]. Imaginons qu’il n’y ait pas eu les attentats,
          on n’aurait pas pu interpeller les zadistes pour les empêcher de venir
          manifester.&#160;»
        </Text>
        <Text>
          Pour réagir à la mise en place et à l’utilisation abusive de l’état
          d’urgence, de manière presque réflexe, nous ouvrons un <i>pad</i>, un
          document collaboratif en ligne accessible à toutes et à tous, puis une
          page sur le wiki de l’association, pour recenser les atteintes aux
          libertés. Ne serait-ce que dans les quinze premiers jours de l’état
          d’urgence, entre le 14 et le 30&#160;novembre 2015, près de
          150&#160;abus sont documentés et sourcés sur la page. Au total, l’état
          d’urgence donne lieu à plus de 7&#160;000&#160;mesures
          administratives&#160;: perquisitions, assignations à résidence,
          interdictions de séjour ou de manifestation. Une majorité d’entre
          elles ne feront l’objet d’aucune suite judiciaire
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.lemonde.fr/idees/article/2017/10/11/conseil-d-etat-quand-les-recours-n-aboutissent-pas-ou-peu_5199605_3232.html"
                >
                  https://www.lemonde.fr/idees/article/2017/10/11/conseil-d-etat-quand-les-recours-n-aboutissent-pas-ou-peu_5199605_3232.html
                </AppLink>
              )
            }
          >
            96
          </InfoClick>
          .
        </Text>
        <Text>
          Comme la Quadrature et ses alliés l’avaient prévu, l’État utilise les
          largesses permises par la mesure d’exception pour s’attaquer à
          d’autres cibles, en particulier aux opposants politiques
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.arretsurimages.net/articles/etat-durgence-cop-21-laveu-de-hollande-que-personne-na-releve"
                >
                  https://www.arretsurimages.net/articles/etat-durgence-cop-21-laveu-de-hollande-que-personne-na-releve
                </AppLink>
              )
            }
          >
            97
          </InfoClick>
          . Sur le wiki, les contributeurs inexpérimentés ajoutent tant bien que
          mal leurs pierres à l’édifice, tandis que des wikipédiens chevronnés
          viennent régulièrement «&#160;jardiner&#160;» la page pour organiser,
          ranger et mettre en forme les contributions. L’outil tourne finalement
          sans la Quadrature, dans un enthousiasme collaboratif et décentralisé.
        </Text>

        <Text type="h3">La&#160;machine à&#160;fantasmes technologique</Text>
        <Text>
          La Quadrature n’est pas la seule à vouloir mettre en place des outils,
          mais tous n’auront pas la même vocation. Après les attentats du
          13&#160;novembre 2015, les politiques tentent une fois de plus de
          jouer la carte de la solidarité nationale, du «&#160;tous
          ensemble&#160;», comme l’avait déjà été la marche du 11&#160;janvier
          faisant suite aux attentats de <i>Charlie Hebdo</i>. Anne Hidalgo,
          maire de Paris, lance une grande campagne d’affichage «&#160;
          <i>Fluctuat nec mergitur</i>&#160;». Sur les systèmes d’affichage de
          publicité JC Decaux, sur la tour Eiffel, en <i>street art</i>, sur le
          mémorial de la place de la République, la devise est partout. Cette
          campagne est accompagnée d’initiatives comme cette «&#160;carte
          citoyenne&#160;» qui permettrait aux Parisiens de participer à la vie
          de leur cité. La devise se décline encore avec l’organisation d’un
          hackathon «&#160;<i>Nec mergitur</i>&#160;» en janvier&#160;2016, en
          partenariat avec la mairie, la préfecture de police de Paris, le
          ministère de l’Intérieur et d’autres services de l’État. L’événement a
          pour objectif d’«&#160;accompagner la police et les services de l’État
          dans leur mission de prévention, d’alerte et de gestion des crises, en
          leur proposant des outils innovants et nouveaux&#160;». Les promoteurs
          de l’événement avaient, à l’époque, déjà proposé des idées
          d’applications à l’idéologie pour le moins orientée. Par exemple, une
          application permettant de détecter si votre voisin est radicalisé. Le
          temps est à la suspicion et à la délation automatisée. Charmant.
        </Text>
        <Text>
          Voyant cela, nous contactons les organisateurs en amont de
          l’événement, pour leur rappeler que le développement d’outils
          numériques peut être positif, et non effectué uniquement dans
          l’objectif de désigner des boucs émissaires. On nous suggère de
          participer au débat, et même de faire partie du comité de déontologie
          de l’événement, qui n’a pas de pouvoir de contrainte. Ce qu’après
          discussions nous refusons. Une sage décision…
        </Text>
        <Text>
          Des journaux titrent sur une mobilisation du monde de l’innovation
          «&#160;contre le terrorisme&#160;», ou «&#160;pour la sécurité&#160;».
          Le résultat est à l’avenant&#160;: le premier groupe de travail du
          hackathon a pour thème «&#160;Prévenir la radicalisation, concevoir et
          diffuser les contre-discours&#160;» et le prix spécial du jury est
          décerné à un «&#160;outil d’identification de niveau du risque de
          radicalisation d’un individu à travers ses différents profils sur les
          réseaux sociaux et l’analyse du contenu de ses messages&#160;». Du
          monde de la tech au gouvernement, on est en pleine période de fantasme
          de détection des «&#160;terroristes&#160;» par des algorithmes et
          «&#160;signaux faibles&#160;»&#160;! Une autre application propose la
          «&#160;détection automatisée de rumeurs sur les réseaux sociaux et
          diffusion de contre-discours&#160;». Heureusement, des outils plus
          positifs voient le jour, comme des systèmes d’amélioration de
          l’organisation des secours, ou la mise en contact par appli entre une
          victime et des secouristes dans une zone déterminée
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://web.archive.org/web/20160624210105/http://necmergitur.paris/"
                >
                  https://web.archive.org/web/20160624210105/http://necmergitur.paris/
                </AppLink>
              )
            }
          >
            98
          </InfoClick>
          .
        </Text>
        <Text>
          Participer à des événements de ce type est-il la mission de la
          Quadrature&#160;? Sert-elle alors de faire-valoir ou permet-elle
          d’infléchir une politique&#160;? Cette question est récurrente dans
          les décisions que doit prendre l’organisation. La tactique des
          décideurs est toujours la même&#160;: donner à leurs opposants un
          comité soigneusement étudié sans aucun pouvoir réel. Aujourd’hui, nous
          en sommes convaincus. En 2015, dans ces circonstances si
          particulières, alors que notre positionnement se fondait sur le
          respect des libertés fondamentales –&#160;même en temps d’attaques
          terroristes&#160;–, nous avions un temps espéré pouvoir infléchir la
          ligne sécuritaire, mais toute confiance a depuis été rompue.
        </Text>
        <Text>
          Pendant ce temps, le gouvernement continue ses propositions de lois
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2016/05/27/Sale-prin-temps-pour-les-libertes/"
                >
                  https://www.laquadrature.net/2016/05/27/Sale-prin-temps-pour-les-libertes/
                </AppLink>
              )
            }
          >
            99
          </InfoClick>
          . La Quadrature alerte sur la banalisation des mesures d’exception,
          qui viennent entériner dans le droit commun les restrictions de l’état
          d’urgence
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.laquadrature.net/2016/03/17/crime-organise-terrorisme-etat-urgence-libertes-danger/"
                >
                  https://www.laquadrature.net/2016/03/17/crime-organise-terrorisme-etat-urgence-libertes-danger/
                </AppLink>
              )
            }
          >
            100
          </InfoClick>
          . Mais rien n’arrête la course folle à l’antiterrorisme&#160;! La loi
          relative à la lutte contre les incivilités et les actes terroristes
          (c’est large, n’est-ce pas&#160;?), passée en procédure accélérée, est
          promulguée le 22&#160;mars 2016. Elle autorise notamment les agents
          des réseaux de transports publics (RATP et SNCF) à procéder à des
          palpations de sécurité et à des fouilles de bagages. Cette loi
          débattue au Parlement à partir de décembre a été déposée par le
          gouvernement en octobre&#160;2015
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.assemblee-nationale.fr/dyn/14/dossiers/lutte_atteintes_securite_publique"
                >
                  https://www.assemblee-nationale.fr/dyn/14/dossiers/lutte_atteintes_securite_publique
                </AppLink>
              )
            }
          >
            101
          </InfoClick>
          . Un mois avant les attentats de novembre&#160;! Le 3&#160;juin 2016
          est promulguée la loi relative à la lutte contre le crime organisé et
          le terrorisme, déposée en février, toujours en procédure accélérée
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.assemblee-nationale.fr/dyn/14/dossiers/lutte_crime_organise_terrorisme"
                >
                  https://www.assemblee-nationale.fr/dyn/14/dossiers/lutte_crime_organise_terrorisme
                </AppLink>
              )
            }
          >
            102
          </InfoClick>
          . Elle autorise les perquisitions de domiciles de nuit, l’utilisation
          élargie des <i>IMSI catcher</i> et le renforcement des contrôles
          d’accès aux lieux accueillant de grands événements. Petit à petit,
          l’une après l’autre, les lois adaptent dans le droit commun des
          mesures jusque-là réservées à l’état d’urgence. Cet état exceptionnel,
          et surtout temporaire, qui vient d’être renouvelé pour la troisième
          fois, afin de sécuriser les manifestations sportives de l’été (l’Euro
          de football et le Tour de France).
        </Text>
        <Text>
          Le gouvernement continue également ses initiatives de
          «&#160;solutionnisme technologique
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  L’historien des sciences Evgeny Morozov développe la notion de
                  « solutionnisme technologique » dans son ouvrage{" "}
                  <i>
                    Pour tout résoudre cliquez ici. L’aberration du
                    solutionnisme technologique
                  </i>{" "}
                  (FYP Éditions, 2014). « Derrière ce mot se cache l’idée que,
                  pour ses thuriféraires, tout aspect de la société n’est en
                  fait qu’un problème à résoudre&#160;: la sécurité, le
                  transport, la santé, l’éducation, la politique,
                  l’alimentation… Tout doit être fait pour gommer les défauts du
                  système, atteindre la perfection, augmenter l’efficacité. […]
                  Bref, le solutionniste possède un marteau (le Web, Internet,
                  de puissants ordinateurs…) et tout ressemble à un clou. La
                  réflexion sur les questions de société disparaît devant
                  l’attrait de la nouveauté. » (« Contre le&#160;solutionnisme
                  numérique », <i>Le Monde, </i>24&#160;octobre 2014.)
                </Text>
              )
            }
          >
            103
          </InfoClick>
          &#160;». À la veille de l’Euro, une nouvelle application est
          créée&#160;: «&#160;Alerte attentat&#160;». Elle vient renforcer le
          dispositif SAIP (pour «&#160;système d’alerte et d’information des
          populations&#160;»), qui rassemble différentes méthodes d’alerte,
          comme le Signal national d’alerte
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.correze.gouv.fr/Politiques-publiques/Securite-et-protection-des-populations/Securite-Civile/Le-Systeme-d-Alerte-et-d-Information-aux-Populations-SAIP"
                >
                  https://www.correze.gouv.fr/Politiques-publiques/Securite-et-protection-des-populations/Securite-Civile/Le-Systeme-d-Alerte-et-d-Information-aux-Populations-SAIP
                </AppLink>
              )
            }
          >
            104
          </InfoClick>{" "}
          (la sirène à midi le premier mercredi du mois) ou la diffusion
          cellulaire (l’envoi d’un message à l’ensemble des téléphones portables
          présents sur une zone, utilisé depuis longtemps aux États-Unis pour
          les alertes météorologiques, et qui servira en France pour annoncer le
          confinement de mars&#160;2020). Cette nouvelle méthode d’alerte
          nécessite de posséder un smartphone, d’installer l’application
          «&#160;Alerte attentat&#160;» et, surtout, d’être connecté à un réseau
          mobile non saturé. Le 14&#160;juillet 2016, à Nice, alors qu’un
          attentat terroriste fait plus de 80&#160;victimes aux alentours de
          23&#160;heures, l’application ne donnera l’alerte qu’à
          1&#160;h&#160;34. Lors des attentats du 20&#160;avril 2017 sur
          l’avenue des Champs-Élysées, et du 23&#160;mars 2018 à Carcassonne et
          Trèbes, l’application n’enverra tout simplement aucun message. Elle
          sera abandonnée en 2018, après avoir coûté 300&#160;000&#160;euros
          pour 900&#160;000&#160;téléchargements (sur une population de
          67&#160;millions de personnes), puis remplacée par un accord avec
          Google, Twitter et Facebook pour que ces plateformes relaient les
          messages du gouvernement.
        </Text>
        <Text>
          L’attentat du 14&#160;juillet à Nice est une bonne occasion pour un
          nouveau projet de loi, que l’on appellera loi relative à l’état
          d’urgence et portant des mesures de renforcement de la lutte
          antiterroriste. Déposée par le Premier ministre le 19&#160;juillet
          2016 en procédure accélérée, elle est adoptée le 21&#160;juillet 2016
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.vie-publique.fr/loi/20767-loi-prorogeant-lapplication-de-la-loi-ndeg-55-385-du-3-avril-1955-relativ"
                >
                  https://www.vie-publique.fr/loi/20767-loi-prorogeant-lapplication-de-la-loi-ndeg-55-385-du-3-avril-1955-relativ
                </AppLink>
              )
            }
          >
            105
          </InfoClick>
          . Trois&#160;jours&#160;: c’est le temps donné aux débats des deux
          chambres parlementaires et aux réunions de la commission mixte
          paritaire. Trois&#160;jours pour prolonger l’état d’urgence et
          suspendre le droit commun pour six&#160;mois de plus. Trois&#160;jours
          pour voter le durcissement des peines liées au terrorisme et exclure
          les personnes condamnées du régime de réduction de peine.
          Trois&#160;jours pour autoriser la fermeture des lieux de culte sur
          simple décision administrative. Trois&#160;jours pour permettre
          l’interdiction par l’autorité administrative des cortèges, défilés et
          rassemblements de personnes sur la voie publique. Trois&#160;jours.
        </Text>
        <Text>
          Cette interdiction des manifestations sur la voie publique tombe à
          pic. Depuis février, la loi relative au travail, à la modernisation du
          dialogue social et à la sécurisation des parcours professionnels, dite
          «&#160;loi Travail&#160;» ou «&#160;loi El Khomri&#160;», fait l’objet
          d’un important mouvement de contestation. Alors que l’on constate une
          augmentation générale des violences policières (pour la première fois
          en 2016, plus de mille plaintes sont déposées devant le Défenseur des
          droits) dans ces manifestations, voilà que sont donnés encore plus de
          moyens de répression à la police.
        </Text>
        <Text>
          Alors que ces violences sont au cœur d’une médiatisation intense, le
          19&#160;juillet 2016, Adama Traoré meurt à la gendarmerie de Persan
          (Val-d’Oise), à la suite de son interpellation à Beaumont-sur-Oise.
          Les expertises se multiplient pour déterminer les causes du décès et
          la responsabilité des gendarmes, mais aboutissent à des conclusions
          diverses, faisant planer la suspicion sur l’objectivité du système
          juridique lorsqu’il s’agit de la mise en cause de la gendarmerie.
          Malgré les répressions, le Comité vérité et justice pour Adama
          organisera des manifestations locales. Encore une fois, l’argument
          antiterroriste a bon dos lorsqu’il s’agit de réprimer un mouvement
          social qui n’a pourtant rien à voir avec les attentats.
        </Text>
        <Text>
          Du côté de la Quadrature, nous n’avons plus beaucoup d’espoir
          d’influer sur le débat parlementaire. Les militants se concentrent
          donc sur des actions a posteriori et se remettent à travailler sur les
          anciennes lois, à commencer par la loi Renseignement, dans leur viseur
          depuis 2015.
        </Text>
        <Text>
          En avril&#160;2016, <i>Le Monde</i> révèle la surveillance par la
          Direction générale de la sécurité extérieure (DGSE) de Thierry Solère
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://www.lemonde.fr/societe/article/2016/04/12/comment-la-dgse-a-surveille-thierry-solere_4900451_3224.html"
                >
                  https://www.lemonde.fr/societe/article/2016/04/12/comment-la-dgse-a-surveille-thierry-solere_4900451_3224.html
                </AppLink>
              )
            }
          >
            106
          </InfoClick>
          . Le téléphone et les e-mails du candidat aux législatives de 2012,
          face à l’ancien ministre de l’Intérieur Claude Guéant, ont été
          espionnés. En creusant, les Exégètes comprennent que les autorités se
          sont appuyées sur l’article&#160;20 de la loi de 1991, dépoussiéré et
          renuméroté par la loi Rens, qui prévoit que «&#160;les mesures prises
          par les pouvoirs publics pour assurer […] la surveillance et le
          contrôle des transmissions empruntant la voie hertzienne ne sont pas
          soumises aux dispositions du présent livre&#160;». La «&#160;voie
          hertzienne&#160;», c’est-à-dire toutes les transmissions sans fil.
          Alors que nous sommes entourés de wifi, de 3G/4G, de Bluetooth, de
          GSM, de RFID et NFC
          <InfoClick
            onClick={() =>
              setModalOpen(
                <Text>
                  GSM&#160;:<i> Global System for Mobile Communication,</i> «
                  système global pour les communications mobiles » ; RFID&#160;:{" "}
                  <i>Radio Frequency IDentification</i>, « méthode
                  d’identification utilisant les fréquences radio » ; NFC&#160;:{" "}
                  <i>Near Field Communication</i>, « communication en champ
                  proche ».
                </Text>
              )
            }
          >
            107
          </InfoClick>
          , la loi Rens a, en laissant cet article inchangé, exclut toutes les
          communications sans fil du champ des (toujours insuffisantes)
          procédures de contrôle instituées pour les techniques de
          renseignement.
        </Text>
        <Text>
          Ni une ni deux, en mai&#160;2016, les associations déposent une
          nouvelle demande de QPC, transmise par le Conseil d’État au Conseil
          constitutionnel en juillet, et qui aboutira en octobre à la censure de
          ces dispositions
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://exegetes.eu.org/posts/censure-surveillance-hertzienne-incontrolee/"
                >
                  https://exegetes.eu.org/posts/censure-surveillance-hertzienne-incontrolee/
                </AppLink>
              )
            }
          >
            108
          </InfoClick>
          . Mais l’accès aux données de connexion est dans tous les textes de
          lois du gouvernement. Et la loi de juillet&#160;2016 sur la
          prorogation de l’état d’urgence (celle débattue en trois&#160;jours)
          vient d’élargir encore ces accès en autorisant la collecte en temps
          réel des données de «&#160;l’entourage&#160;» des personnes
          surveillées
          <InfoClick
            onClick={() =>
              setModalOpen(
                <AppLink
                  white
                  href="https://exegetes.eu.org/dossiers/etatdurgencetempsreel/index.html"
                >
                  https://exegetes.eu.org/dossiers/etatdurgencetempsreel/index.html
                </AppLink>
              )
            }
          >
            109
          </InfoClick>
          &#160;! Cet élargissement se fonde sur le dernier décret de la loi
          Rens paru en janvier, que les Exégètes n’avaient pas encore pu
          attaquer. Le délai pour le faire étant passé, ils reproduisent la
          technique qu’ils avaient établie en 2015&#160;: ils demandent
          l’abrogation du décret au gouvernement en août&#160;2016, attendent
          pendant deux&#160;mois l’absence de réponse valant refus, pour ensuite
          attaquer, en décembre&#160;2016, le refus d’abrogation devant le
          Conseil d’État. Ils demanderont le transfert d’une QPC en 2017 au
          Conseil constitutionnel qui censurera le décret au mois
          d’août&#160;2017.
        </Text>
        <Text>
          La machine parlementaire ne s’arrête pas. En décembre&#160;2016, plus
          d’un an après les attentats du Bataclan, l’état d’urgence est prolongé
          pour la cinquième fois.
        </Text>
        {isModalOpen && (
          <Modal open={!!isModalOpen} onClose={() => setModalOpen(undefined)}>
            {isModalOpen}
          </Modal>
        )}
      </>
    </PagePattern>
  );
};

export default Chapter2Part3;
